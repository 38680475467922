import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TwoFactorAuthKey} from '../model/two-factor/two-factor-auth-key';
import {TwoFactorAuthUserInfo} from '../model/two-factor/two-factor-auth-user-info';

@Injectable({providedIn: 'root'})
export class TwoFactorAuthService {

    constructor(private httpClient: HttpClient) {
    }

    getTwoFactorAuthKeyByUser(email: string): Observable<TwoFactorAuthKey> {
        return this.httpClient.get<TwoFactorAuthKey>(`/twoFactorAuth/key?email=${email}`);
    }

    getTwoFactorAuthUserInfo(email: string): Observable<TwoFactorAuthUserInfo> {
        return this.httpClient.get<TwoFactorAuthUserInfo>(`/twoFactorAuth/user?email=${email}`);
    }

    confirmTwoFactorAuth(code: string, email: string) {
        return this.httpClient.post<void>(`/twoFactorAuth/confirm?code=${code}&email=${email}`, null);
    }

    switchTwoFactorAuthForCompany(enable2FA: boolean, accountId: number): Observable<void> {
        return this.httpClient.put<void>(`/switch/twoFactorAuth?enable2FA=${enable2FA}&accountId=${accountId}`, null);
    }
}
