import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DatapointsService } from '../../../data-access-layer/datapoints/datapoints.service';
import { take } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { DatapointField } from '../../../model/datapoint/datapoint-field';
import { SortOrder } from '../../../model/filter/draft-filter-sort';
import { Dataset } from '../../../model/dataset/dataset';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DatapointFilterObject } from '../../../model/datapoint/datapoint-filter-object';
import { ProjectedDatapoint } from '../../../model/datapoint/projected-datapoint';
import { DatapointsPageStateService } from '../datapoints-page-state.service';
import { FilterBarItem } from '../../../model/datapoint/draft/table/filter-bar-item';
import { NotifService } from '../../../core/notification/notif.service';
import { DatapointFilter } from '../../../model/datapoint/filter/datapoint-filter';
import { MaptycsPaginatorComponent } from '../../../core/maptycs-table/maptycs-paginator/maptycs-paginator.component';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { DialogModel } from '../../../model/dialog/dialog-model';
import { DatasetFieldType } from '../../../model/dataset/dataset-field-type';
import { TessadataService } from '../../../data-access-layer/tessadata.service';
import { ObjectUtils } from 'src/app/core/utils/object-utils';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, GridReadyEvent, SortModelItem, SideBarDef, GridApi, ColGroupDef, IServerSideDatasource, IsRowSelectable, RowNode } from 'ag-grid-community';
import { DatapointsFilterService } from '../datapoints-filter.service';
import { FormControl } from '@angular/forms';
import { DatasetField } from 'src/app/model/dataset/field/dataset-field';
import { DatapointFilterField } from 'src/app/model/datapoint/filter/datapoint-filter-field';
import { DatasetFieldScope } from 'src/app/model/dataset/dataset-field-scope';
import * as moment from 'moment';
import { ReportRequest } from 'src/app/model/datapoint/report/report-request';
import { AggregateGroupRequest } from 'src/app/model/datapoint/report/aggregate-group-request';
import { DatapointAggregateFieldType } from 'src/app/model/datapoint/report/datapoint-aggregate-field-type';
import { DatapointProjection } from 'src/app/model/datapoint/projection/datapoint-projection';
import { DatapointsAggregateService } from 'src/app/data-access-layer/datapoints/datapoints-aggregate.service';
import { isUndefined } from 'util';
import { GroupService } from 'src/app/data-access-layer/groups/group.service';
import { Group } from 'src/app/model/group/group';
import { groupTypes } from 'src/app/account/account-groups/account-create-groups-panel/account-create-groups-panel.constants';
import { DatapointConverterType } from 'src/app/model/dataset/rendering/datapoint-converter-options';
import { AgGridUtils } from 'src/app/core/utils/ag-grid-utils';
import { DatasetGeometryType } from 'src/app/model/dataset/dataset-geometry-type';
import { MaptycsApplication } from 'src/app/model/account/maptycs-application';
import { DateUtils } from 'src/app/core/utils/date-utils';
import { Datapoints } from '../datapoints';
import { WorkspaceItem } from 'src/app/model/workspace/workspace-item';

const DIRECTION_MAP = { ['asc']: SortOrder.ASCENDANT, ['desc']: SortOrder.DESCENDANT };

@Component({
  selector: 'map-ag-datapoint-table',
  templateUrl: './ag-datapoint-table.component.html',
  styleUrls: ['./ag-datapoint-table.component.scss']
})
export class AgDatapointTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() gridApi: GridApi;
  @Input('filter') filterObject: DatapointFilterObject;
  @Input() dataset: Dataset;
  @Input() filterItems: FilterBarItem[];
  @Input() tessadataFieldsByDataset: any;
  @Input() filterAttributes: any;
  @Input() formulas: WorkspaceItem[];
  @Output() Update = new EventEmitter<{ dataset: Dataset, datapointID: string }>();
  @Output() bindFilterIds = new EventEmitter<{ fieldIds: string[] }>();
  @ViewChild('updateDatapoint', { static: false }) updateDatapoint;
  @ViewChild('content', { static: true }) content: ElementRef;
  @ViewChild('agTableComponent', { static: true }) agTableComponent: AgGridAngular;
  @ViewChild('paginator', { static: false }) paginator: MaptycsPaginatorComponent;

  private readonly subscriptions: Subscription = new Subscription();
  private readonly filterBarItemMap: Map<string, any>;
  public readonly minWidth: number = 150;

  public overlayLoadingTemplate = AgGridUtils.overlayLoadingTemplate();
  public overlayNoRowsTemplate = AgGridUtils.overlayNoRowsTemplate();

  public paginationPageSize;
  public cacheBlockSize;
  public totalPages: number = 0;
  public totalRecords: number = 0;
  public totalRecordsSimplified: any;
  public autoSize: boolean = true;
  public rowData: any[] = [];
  public LIMIT: number;
  public SKIP: number;
  public rowSelection = 'multiple';
  public enableCharts = true;
  public gridOptions: Partial<GridOptions>;
  public gridColumnApi;
  public cacheOverFlowSize;
  public maxConcurrentDatasourceRequests;
  public infiniteInitialRowCount;
  public allColumns!: any[];
  public isFilterChanged: boolean;
  public oldGroupLength: number = 0;
  public oldLinkLength: number = 0;
  public rowGroupsIds = [];
  public groupedFilterObject: DatapointFilterObject;
  public pivotResult: any;
  public defaultColDef: ColDef = AgGridUtils.defaultColumnDef(this.minWidth);

  public defaultColGroupDef: Partial<ColGroupDef> = {
    marryChildren: true,
  };

  datapointProjection: DatapointProjection;
  public isPreviousPivotMode: boolean = false;
  groupDataLimit: number = 800;
  public columnTypes: {
    [key: string]: ColDef;
  } = AgGridUtils.columnTypes(this.minWidth);

  datapoints: Array<ProjectedDatapoint>;
  dataSource: MatTableDataSource<DatapointTableRow>;
  fetchDatapointsSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  columnDefs: (ColDef | ColGroupDef)[] = [];
  tempDatapoints: any;
  paginationInfo: { count: number, id: string };
  filter: DatapointFilter;
  selectAll: boolean = false;
  color = 'accent';
  hideShowColumns = new FormControl('');
  currentRowRange: string = '';
  selectedOption: any[] = ['id', 'created_on', 'updated_on']
  readonly formula = "FORMULA";
  readonly other = "OTHER";
  readonly global = "GLOBAL";
  readonly climate = "Climate";
  readonly nriUSA = 'NRI USA';
  groupColumnChildrens: any = {formula: [], internalScope: [], externalScope: [], nriScope: []};
  dynamicGroupColumnChildrens: any = {};
  

  public isGlobalFilterCall: boolean = false;

  public isRowSelectable: IsRowSelectable = (rowNode: RowNode) => {
    return !rowNode.group;
  };

  constructor(
    private readonly service: DatapointsPageStateService,
    private readonly notifService: NotifService,
    private readonly dpService: DatapointsService,
    private readonly tessadataService: TessadataService,
    public readonly dialog: MatDialog,
    private readonly datapointsFilterService: DatapointsFilterService,
    private readonly aggregateService: DatapointsAggregateService,
    private readonly groupService: GroupService
  ) {
    this.datapoints = [];
    this.rowData = [];
    this.LIMIT = 1000;
    this.SKIP = 0;
    this.filterBarItemMap = new Map<string, any>();

    this.cacheOverFlowSize = 2;
    this.maxConcurrentDatasourceRequests = 2;
    this.infiniteInitialRowCount = 2;
    this.paginationPageSize = 10000;
    this.cacheBlockSize = 700;

    this.gridOptions = {
      context: {
        componentParent: this
      },
      headerHeight: 45,
      rowHeight: 30,
      paginationPageSize: this.paginationPageSize,
      cacheBlockSize: this.cacheBlockSize,
      rowModelType: 'serverSide',
      serverSideInfiniteScroll: true,
      pagination: true,
      enableRangeSelection: true,
      columnTypes: this.columnTypes,
      blockLoadDebounceMillis: 100
    }
  }

  filterBarItems: FilterBarItem[] = []; // it can contain not populated fields yet, and they have suggestions

  ngOnInit(): void {
    this.isFilterChanged = false;
    if (this.filterObject) {
      this.filterObject.filter = this.datapointsFilterService.getActiveFilter();
      this.groupedFilterObject = ObjectUtils.clone(this.filterObject);
    }

    this.subscriptions.add(this.datapointsFilterService.onFilterChange().subscribe(newFilter => {
      this.checkIsGroupedOrLinksChanged(newFilter);
      if (newFilter.links.length !== 0) {
        this.isFilterChanged = true;
      }
      this.transformFilterToFilterModel(newFilter);
    }));

    this.subscriptions.add(this.datapointsFilterService.onFilterBarItemsChange().subscribe(newFilterBarItem => {
      if (newFilterBarItem && newFilterBarItem.length === 0) {
        this.filterBarItemMap.clear();
        return;
      }

      if (this.filterObject) {
        this.filterObject.filter.fields.filter(function (obj) {
          if (newFilterBarItem.some(barItem => barItem.id === obj.id)) {
            this.filterBarItemMap.set(obj.id, obj);
          }
        });
      }
    }));
  }

  checkIsGroupedOrLinksChanged(newFilter: DatapointFilter): void {
    if (this.filterObject.filter.groups.length !== this.oldGroupLength || newFilter.links.length !== this.oldLinkLength) {
      this.isFilterChanged = true;
    } else {
      this.isFilterChanged = false;
    }

  }

  ngOnChanges(): void {
  }

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: ['columns'], position: 'left'
  };

  transformData(datapoints: ProjectedDatapoint[]): ProjectedDatapoint[] {
    let transformDatapoints: ProjectedDatapoint[] = [];
    datapoints.forEach(datapoint => {
      let tempArryay = [];
      datapoint.fields.forEach(fields => {
        const fieldId = fields.id == '0' && fields.datasetID.split("_").length > 1 ? fields.datasetID + "-" + fields.id : fields.id;
        tempArryay.push({ [fieldId]: fields.textValue || (fields.numberValue) || fields.datetimeValue });
      });

      if (this.filterObject.projection.formulas) {
        let counter = 0;
        this.filterObject.projection.formulas.forEach(formula => {
          tempArryay.push({ [formula.id.toString()]: datapoint.formulaResults !== null ? datapoint.formulaResults[counter] : null });
          counter++;
        });
      }

      tempArryay.push({ ['id']: datapoint.id });
      let b = tempArryay.reduce(function (result, item) {
        var key = Object.keys(item)[0]; //first property: a, b, c
        result[key] = item[key];
        return result;
      }, {});
      transformDatapoints.push(b);
    });
    
    return transformDatapoints;
  }

  findTotalRecords(): Observable<any> {
    let clonedFilter = ObjectUtils.clone(this.filterObject);
    clonedFilter.limit = 0;
    clonedFilter.skip = 0;
    return this.dpService.getDatapointsCount(clonedFilter.filter, clonedFilter.projection);
  }

  fecthData(request: any): Observable<ProjectedDatapoint[]> {
    this.gridOptions.suppressNoRowsOverlay = true;
    this.filterObject.limit = request.endRow - request.startRow;
    this.filterObject.skip = request.startRow;
    if (request.rowGroupCols.length) {
      this.gridApi.refreshInfiniteCache();
      let fields = this.filterObject.filter.fields.filter((element, key) => !this.rowGroupsIds.includes(element.id));
      this.groupedFilterObject.filter.groups = this.filterObject.filter.groups;
      this.groupedFilterObject.projection.formulas = !isUndefined(this.filterObject.projection.formulas) && this.filterObject.projection.formulas.length ? this.filterObject.projection.formulas : [];
      return this.dpService.getDatapointsByFilterForTableView(this.groupedFilterObject, this.groupDataLimit, this.filterObject.skip, fields);
    } else {
      return this.dpService.getDatapointsByFilterForTableView(this.filterObject);
  }
  }

  cleanSort(): void {
    this.filterObject.sort.datasetID = this.dataset.id;
    this.filterObject.sort.links = [];
    this.filterObject.sort.fields = [];
  }

  onSort({ colId, dir }: any): void {
    let col = this.allColumns.find((column) => column.colId === colId);
    let activeDataset = this.getDataset(this.dataset.id);
    let activeField = activeDataset.fields.find(field => {
      return field.id === colId;
    });

    if (!isUndefined(activeField) && activeField.datasetID && activeField.datasetID !== this.dataset.id) {
      // empty native dataset fields, if sorted by foreign dataset
      this.filterObject.sort.fields = [];
      // 1
      this.filterObject.sort.datasetID = this.dataset.id;
      this.filterObject.sort.links = [{
        datasetID: activeField.datasetID,
        fields: [{ id: activeField.id, sortOrder: DIRECTION_MAP[dir] }],
        linkFields: [],
        links: []
      }];
    } else {
      // empty foreign dataset fields, if sorted by native dataset
      this.filterObject.sort.datasetID = this.dataset.id;
      this.filterObject.sort.links = [];
      this.filterObject.sort.fields = [{ id: colId, sortOrder: DIRECTION_MAP[dir] }];
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    this.gridColumnApi = params.columnApi;

    this.gridApi.addEventListener('filterChanged', (e) => {
      params.api.paginationGoToFirstPage();
      this.jumpToStartRow();
      this.isFilterChanged = true;
    });

    //this.setTotalRecords();

    setTimeout(() => {
      if (this.columnDefs == undefined || this.columnDefs.length <= 0) {
        this.prepareGridColumns();
      }
    }, 10);

    if (this.selectedOption) {
      //const isMetaFieldPresent = this.filterObject.projection.fields.some(p => thi)
    }

    this.selectedOption.forEach(field => {
      this.filterObject.projection.fields.push(field);
    });

    if (this.dataset.geometryType !== 'NONE' && this.dataset.geometryType !== 'COMPLEX') {
      this.filterObject.projection.geometryPrecision = 25;
    }

    const datasource = this.getServerSideDatasource();
    // register the datasource with the grid
    this.gridApi!.setServerSideDatasource(datasource);
  }

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: params => {
        this.rowGroupsIds = params.request.rowGroupCols.map(function (result) { return result["id"] });
        let sortModel: any[] = params.request.sortModel;
        let filterModel: any = params.request.filterModel;

        const isSortPresent = sortModel && sortModel.length > 0;
        if (isSortPresent) {
          this.onSort({ colId: sortModel[0].colId, dir: sortModel[0].sort });
        } else {
          this.cleanSort();
        }

        const isFilterPresent = filterModel && Object.keys(filterModel).length > 0;
        if (params.request.rowGroupCols.length < 1 && !params.request.pivotMode) {
          if (isFilterPresent && this.isFilterChanged) {
            this.transformFilterModelToFilter(params.request.filterModel);
          } else if (this.filterObject && this.filterObject.filter && this.filterObject.filter.fields && !this.isFilterChanged) {
            this.prepareFilterModelFromFilter(this.filterObject.filter.fields).then((resolve: any) => {
              if (resolve) {
                params.api.setFilterModel(resolve);
              }
            });

          } else if (this.filterObject.filter.links && this.filterObject.filter.links.length > 0 && this.isFilterChanged) {
            this.datapointsFilterService.resetAllFilterBarItems(Object.keys(filterModel));
            if (Object.keys(filterModel).length > 0) {
              this.filterObject.filter.fields = [];
            }
          } else {
            this.datapointsFilterService.resetAllFilterBarItems(Object.keys(filterModel));
            this.filterObject.filter.fields = [];
          }
        }

        this.oldGroupLength = this.filterObject.filter.groups.length;
        this.oldLinkLength = this.filterObject.filter.links.length;
        this.transformRowGroupToFilter(params.request).then((resolve: any) => {
          if (isUndefined(this.groupedFilterObject.projection.formulas) && params.request.groupKeys.length < 1) {
            this.prepareGridColumns();
          } else if (((!isUndefined(this.groupedFilterObject.projection.formulas) && !isUndefined(this.filterObject.projection.formulas)) && (this.groupedFilterObject.projection.formulas.length <= this.filterObject.projection.formulas.length))) {
            this.prepareGridColumns();
          }
          setTimeout(() => {
            if (params.request.rowGroupCols.length <= params.request.groupKeys.length && !this.isPreviousPivotMode) {
              this.findTotalRecords()
                .pipe()
                .subscribe(result => {
                  this.totalRecords = result.count;
                  this.totalRecordsSimplified = AgGridUtils.formatCash(this.totalRecords);
                  //get data now
                  this.fecthData(params.request)
                    .subscribe(response => {
                      if (!response || !response.length) {
                        this.gridOptions.suppressNoRowsOverlay = false;
                        this.gridOptions.api.showNoRowsOverlay();
                        this.totalRecords = response.length;
                        params.success({
                          rowData: [],
                          rowCount: this.totalRecords,
                        });
                      } else {
                        this.hideOverlay();
                        let datapointTransformed: ProjectedDatapoint[] = this.transformData(response);

                        this.gridApi.paginationSetPageSize(this.paginationPageSize);

                        params.success({
                          rowData: datapointTransformed,
                          rowCount: params.request.rowGroupCols.length ? AgGridUtils.getLastRowIndex(params.request, datapointTransformed) : this.totalRecords,
                        });
                      }
                    });
                });
            } else if (params.request.rowGroupCols.length && !params.request.pivotMode) {
              if (this.isPreviousPivotMode) {
                params.columnApi.setPivotResultColumns([]);
                this.columnDefs.forEach(element => {
                  if (element['children'] !== undefined && element['children'].length) {
                    element['children'].forEach(children => {
                      if (this.rowGroupsIds.includes(children.field)) {
                        children.hide = true;
                      }
                    });
                  }
                });
                this.gridApi.setColumnDefs(this.columnDefs);
                this.isPreviousPivotMode = false;
              }
              this.setGroupData(params, resolve);
            } else if (params.request.pivotMode && params.request.rowGroupCols.length && params.request.pivotCols.length < 1) {
              this.isPreviousPivotMode = params.request.pivotMode;
              params.columnApi.setPivotResultColumns([]);
              this.gridApi.setColumnDefs(this.columnDefs);
              this.setGroupData(params, resolve);
            } else if (params.request.pivotMode && params.request.rowGroupCols.length < 1 && params.request.pivotCols.length < 1) {
              this.isPreviousPivotMode = params.request.pivotMode;
              params.columnApi.setPivotResultColumns([]);
              params.success({
                rowData: [],
                rowCount: 0,
              });
            } else if (params.request.rowGroupCols.length && params.request.pivotCols.length && params.request.valueCols.length) {
              this.isPreviousPivotMode = params.request.pivotMode;
              if (this.filterObject.filter.fields.length > 0 && params.request.groupKeys.length < 1) {
                this.groupedFilterObject.filter.fields = this.filterObject.filter.fields;
              }
              this.fetchGroupData(params.request).subscribe(success => {
                this.setPivotData(params, success);
              });
            } else {
              params.success({
                rowData: [],
                rowCount: 0,
              });
            }
            this.hideOverlay();
            this.bindFilterIds.emit({ fieldIds: this.datapointsFilterService.getFilterBarItemIds() });
            this.isGlobalFilterCall = false;
          }, 100);
        });

      }
    };
  }

  setPivotData(params: { request: { pivotCols: string | any[]; valueCols: any[]; rowGroupCols: any[], groupKeys: string | any[]; }; success: (arg0: { rowData: any; rowCount: any; }) => void; columnApi: { setPivotResultColumns: (arg0: any) => void; }; }, success: { groupResults: any[]; }) {
    this.pivotResult = AgGridUtils.setPivotModeFilter(params.request, success.groupResults);
    this.hideOverlay();
    if (!params.request.groupKeys.length) {
      params.columnApi.setPivotResultColumns(this.pivotResult.pivotResultColDefs);
    }
    params.success({
      rowData: this.pivotResult.response,
      rowCount: this.pivotResult.response.length,
    });
  }

  setGroupData(params: { request: { valueCols: any[]; groupKeys: any[]; pivotMode: boolean }; success: (arg0: { rowData: ProjectedDatapoint[]; rowCount: number; }) => void; }, data: any = []) {
    if (this.filterObject.filter.fields.length > 0 && params.request.groupKeys.length < 1) {
      this.groupedFilterObject.filter.fields = this.filterObject.filter.fields;
    }
    if (!isUndefined(data) && data.length) {
      this.groupedFilterObject.filter.fields = data;
    }

    this.fetchGroupData(params.request).subscribe(success => {
      let data = [];
      let transformDatapoints: ProjectedDatapoint[] = [];
      success.groupResults.forEach(element => {
        if (element.buckets.length > 0) {
          element.buckets.forEach(bucketsElements => {
            data.push({ [bucketsElements.fieldID]: bucketsElements.value });
          })
        }
        if (element.values.length > 0) {
          params.request.valueCols.forEach((valueCol, key) => {
            data.push({ [valueCol.field]: element.values[key].result });
          });
        }

        this.columnDefs.forEach(element => {
          if (element['children'] !== undefined && element['children'].length) {
            element['children'].forEach(children => {
              if (children.type === 'dateColumn') {
                data.push({ [children.id]: null });
              }
            });
          }
        });
        let b = data.reduce(function (result, item) {
          var key = Object.keys(item)[0]; //first property: a, b, c
          result[key] = item[key];
          return result;
        }, {});
        transformDatapoints.push(b);
      });

      params.success({
        rowData: transformDatapoints,
        rowCount: transformDatapoints.length,
      });
    });
  }

  prepareFilterModelFromFilter(fields: DatapointFilterField[]): any {
    let filterModels: any = {};
    return new Promise((resolve, reject) => {
      if (!isUndefined(this.allColumns)) {
        fields.forEach(field => {
          const filterModel: any = this.mapFilterToTableFilter(field.id, field);
          if (filterModel) {
            filterModels[field.id] = filterModel;
          }
        });
      }
      resolve(filterModels);
      // resolve("something"); when you want to return something.
    });
  }

  mapFilterToTableFilter(colId: string, field: DatapointFilterField): any {
    const gridColumn = this.allColumns.find((column) => column.colId === colId);
    const activeDataset = this.getDataset(this.dataset.id);
    const activeField = activeDataset.fields.find(field => {
      return field.id === colId;
    });
    if (gridColumn && activeField) {
      const fitlerType = AgGridUtils.getGridDataTypeByColumnType(gridColumn.colDef.type);
      let type: any;
      let filter: any;
      let filterTo: any;
      switch (activeField.baseType) {
        case DatasetFieldType.NUMBER:
          if (field.maxNumberValue && field.minNumberValue) {
            type = 'inRange';
            filter = field.minNumberValue;
            filterTo = field.maxNumberValue;
          } else if (field.maxNumberValue) {
            type = 'lessThanOrEqual';
            filter = field.maxNumberValue;
          } else {
            type = 'greaterThanOrEqual';
            filter = field.minNumberValue;
          }
          break;
        case DatasetFieldType.DATE_TIME:
          if (field.maxDateValue && field.minDateValue) {
            type = 'inRange';
            filter = moment(field.minDateValue).format('YYYY-MM-DD HH:mm:ss');
            filterTo = moment(field.maxDateValue).format('YYYY-MM-DD HH:mm:ss');
          } else if (field.maxDateValue) {
            type = 'lessThanOrEqual';
            filter = moment(field.maxDateValue).format('YYYY-MM-DD HH:mm:ss');
          } else {
            type = 'greaterThanOrEqual';
            filter = moment(field.minDateValue).format('YYYY-MM-DD HH:mm:ss');
          }
          break;
        case DatasetFieldType.TEXT:
          type = 'equals';
          if (activeField.isHighCardinality) {
            type = 'contains';
            filter = field.searchValue;
          } else {
            filter = field.textValues.join(',');
          }
          break;
        default:
          break;
      }
      if (activeField.baseType === DatasetFieldType.DATE_TIME) {
        return {
          fitlerType: fitlerType,
          type: type,
          dateFrom: filter,
          dateTo: filterTo
        };
      } else {
        return {
          fitlerType: fitlerType,
          type: type,
          filter: filter,
          filterTo: filterTo
        };
      }
    }
    return {};
  }

  mapEqualityToTableEquality(colId: string) {
    let type = this.allColumns.find((column) => column.colId === colId && column.type);
  }

  transformFilterToFilterModel(newFilter: DatapointFilter) {
    this.gridApi.collapseAll();
    this.isGlobalFilterCall = true;
    if (newFilter
      && newFilter.fields
      && this.gridApi) {
      if ((newFilter.fields.length === 0 && newFilter.links.length === 0 && this.filterBarItemMap.size === 0) && (!isUndefined(this.filterObject.projection.formulas) && this.filterObject.projection.formulas.length > 0)) {
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
        this.prepareGridColumns();
        this.hideOverlay();
      } else if ((newFilter.fields.length === 0 && newFilter.links.length > 0 && this.filterBarItemMap.size === 0) && !this.isFilterChanged) {
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
        this.prepareGridColumns();
        this.hideOverlay();
      } else {
        this.prepareFilterModelFromFilter(newFilter.fields).then((resolve: any) => {
          // now you can call secondAfterInitMethod();
          let filterModels: any = resolve//this.prepareFilterModelFromFilter(newFilter.fields);
          if (newFilter.links.length > 0 && this.isFilterChanged) {
            this.gridApi.setFilterModel(filterModels);
            this.gridApi.onFilterChanged();
          } else if (this.isFilterChanged) {
            this.gridApi.onFilterChanged();
          } else if (this.rowGroupsIds.length > 0) {
            let tempFilterModel =  ObjectUtils.clone(filterModels);
            let iteratFilterModel: Object = {};
            for (const key in filterModels) {
              if (Object.prototype.hasOwnProperty.call(filterModels, key)) {
                const element = filterModels[key];
               // if (!this.rowGroupsIds.includes(key)) {
                  iteratFilterModel = iteratFilterModel !== undefined ? Object.assign(iteratFilterModel, { [key]: element }) : {
                    [key]: element
                //  };
                }
              }
            }

            for (const key in tempFilterModel) {
              if (Object.prototype.hasOwnProperty.call(tempFilterModel, key)) {
                const element = tempFilterModel[key];
                element.filter = null;
                element.filterTo = null;
              }
            }
            this.gridApi.setFilterModel(tempFilterModel);
            this.gridApi.setFilterModel(Object.keys(iteratFilterModel).length ? iteratFilterModel : null);
            this.gridApi.onFilterChanged();
          } else {
            this.gridApi.setFilterModel(filterModels);
            this.gridApi.onFilterChanged();
          }
          this.filterObject.filter = newFilter;
          if (this.groupedFilterObject.filter.fields.length < 1) {
            this.gridApi.showLoadingOverlay();
          }
          this.prepareGridColumns();
          this.hideOverlay();
        });
      }
      this.isFilterChanged = false;
    }
  }

  hideOverlay() {
    if (!isUndefined(this.gridApi.hideOverlay())) {
      this.gridApi.hideOverlay();
    }
  }

  transformFilterModelToFilter(filterModel: any) {
    this.filterObject.filter.fields = this.transaformFilterModel(filterModel, Object.keys(filterModel), false);
  }

  transformRowGroupToFilter(request: any) {
    return new Promise((resolve, reject) => {
      if (request.rowGroupCols.length) {
        let rowGroupColsArray = [];
        request.groupKeys.forEach((element, key) => {
          rowGroupColsArray.push({ "id": request.rowGroupCols[key].field, "searchValue": null, "textValues": [element], "distanceUnit": null });
        });

        let stripFilterFields = this.transaformFilterModel(request.filterModel, this.rowGroupsIds, true);
        // stripFilterFields = stripFilterFields.filter((element) => {
        //     return !this.rowGroupsIds.includes(element.id);
        // });
        this.groupedFilterObject.filter.fields = [];
        if (request.groupKeys.length > 0 && this.isGlobalFilterCall) {
          this.groupedFilterObject.filter.fields = stripFilterFields.length < 1 ? rowGroupColsArray : [...stripFilterFields, ...rowGroupColsArray];
          resolve(this.groupedFilterObject.filter.fields);
        } else if (request.groupKeys.length > 0 && !this.isGlobalFilterCall) {
          this.groupedFilterObject.filter.fields = request.pivotActive ? rowGroupColsArray : [...rowGroupColsArray, ...stripFilterFields];
          resolve(this.groupedFilterObject.filter.fields);
        } else {
          this.groupedFilterObject.filter.fields = rowGroupColsArray.length && stripFilterFields.length < rowGroupColsArray.length ? rowGroupColsArray : [...rowGroupColsArray, ...stripFilterFields];
          resolve(this.groupedFilterObject.filter.fields);
        }
      } else {
        resolve([]);
      }
    });
  }

  transaformFilterModel(filterModel: any, resetItemKeys: string[], resetFlag: boolean) {
    let filterFieldMap: Map<string, any> = new Map();
    this.datapointsFilterService.resetSelectedFilterBarItem(resetItemKeys, resetFlag, Object.keys(filterModel));

    for (let key in filterModel) {
      let value = filterModel[key];
      if (value.filterType === 'text') {
        let textFilterField = this.prepareTextFilter(key, value);
        filterFieldMap.set(textFilterField.id, textFilterField);
      } else if (value.filterType === 'number') {
        let numberFilterField = this.prepareNumberFilter(key, value);
        filterFieldMap.set(numberFilterField.id, numberFilterField);
      } else if (value.filterType === 'date') {
        let dateFilterField = this.prepareDateFilter(key, value);
        filterFieldMap.set(dateFilterField.id, dateFilterField);
      }
    }

    let stripFilterFields = this.filterObject.filter.fields.filter(function (obj) {
      return !filterFieldMap.has(obj.id);
    });

    filterFieldMap.forEach((value: any, key: string) => {
      stripFilterFields.push(value);
    });

    return stripFilterFields;
  }

  prepareDateFilter(key: string, filterModel: any) {
    let col = this.allColumns.find((column) => column.colId === key);
    let activeDataset = this.getDataset(this.dataset.id);
    let activeField = activeDataset.fields.find(field => {
      return field.id === col.colId;
    });

    const prepareResponse = AgGridUtils.prepareDateFilter(filterModel);
    let minDateValue = prepareResponse.minDateValue;
    let maxDateValue = prepareResponse.maxDateValue;

    const filterValues = { minDateValue: minDateValue == undefined ? null : minDateValue, maxDateValue: maxDateValue == undefined ? null : maxDateValue };
    if (this.datapointsFilterService.isFilterBarExist(activeField)) {
      this.datapointsFilterService.addFilterBarItem(activeField, activeDataset, false, [], filterValues)
    } else {
      this.datapointsFilterService.bindTextValuetoGlobalFilter(filterValues, activeField);
    }
    let field: DatapointFilterField = {
      id: key,
      minDateValue: new Date(minDateValue).getTime() || null,
      maxDateValue: new Date(maxDateValue).getTime() || null,
      distanceUnit: null
    };
    return field;
  }

  prepareNumberFilter(key: string, filterModel: any) {
    let col = this.allColumns.find((column) => column.colId === key);
    let activeDataset = this.getDataset(this.dataset.id);
    let activeField = activeDataset.fields.find(field => {
      return field.id === col.colId;
    });
    const preparedResponse = AgGridUtils.prepareNumberFilter(filterModel);
    let minNumberValue = preparedResponse.minNumberValue;
    let maxNumberValue = preparedResponse.maxNumberValue;
    const filterValues = { minNumberValue: minNumberValue, maxNumberValue: maxNumberValue };
    if (this.datapointsFilterService.isFilterBarExist(activeField)) {
      this.datapointsFilterService.addFilterBarItem(activeField, activeDataset, false, [], filterValues)
    } else {
      this.datapointsFilterService.bindTextValuetoGlobalFilter(filterValues, activeField);
    }
    let field: DatapointFilterField = {
      id: key,
      minNumberValue: minNumberValue || 0,
      maxNumberValue: maxNumberValue || Number.MAX_SAFE_INTEGER,
      distanceUnit: null
    };
    return field;
  }

  prepareTextFilter(key: string, filterModel: any): DatapointFilterField {
    let col = this.allColumns.find((column) => column.colId === key);
    let activeDataset = this.getDataset(this.dataset.id);

    let activeField = activeDataset.fields.find(field => {
      return field.id === col.colId;
    });
    const preparedResponse = AgGridUtils.prepareTextFilter(filterModel, activeField);
    let searchValue = preparedResponse.searchValue;
    let textValues: string[] = preparedResponse.textValues;
    const selectedStatisticValues = activeField.isHighCardinality ? [] : filterModel.filter.split(",").map(item => item.trim().toLowerCase());
    const params = activeField.isHighCardinality ? { searchValue: filterModel.filter } : [];
    if (this.datapointsFilterService.isFilterBarExist(activeField)) {
      this.datapointsFilterService.addFilterBarItem(activeField, activeDataset, false, selectedStatisticValues, params)
    } else {
      this.datapointsFilterService.bindTextValuetoGlobalFilter(selectedStatisticValues, activeField, params);
    }
    let field: DatapointFilterField = {
      id: key,
      searchValue: searchValue || null,
      textValues: textValues,
      distanceUnit: null
    };
    return field;
  }

  sortFields(fields: DatasetField[]) {
    fields.sort((item1, item2) => {
        if(item1 && item1.headerName && item2 && item2.headerName){
            return item1.headerName.trim().toLowerCase().localeCompare(item2.headerName.trim().toLowerCase())
        }
    })
    return fields;
}

  tempPrepareGridColumns() {
    let locationColumnDefs = [];
    let tempColumnDefs = [];
    let globalOverlaysDefs = [];
    
    for (const key in this.filterAttributes) {
      if (Object.prototype.hasOwnProperty.call(this.filterAttributes, key)) {
        const element = this.filterAttributes[key];
        if (key === 'A') {
          element.forEach(locationElement => {
            this.dynamicGroupColumnChildrens[locationElement.name] = [];
            let otherScopeFields = locationElement.fields.filter(el => !['created_on', 'updated_on', 'id'].includes(el.id));
            let internalScopeOptionalFields = locationElement.fields.filter(el => el.scope === 'INTERNAL' && ['created_on', 'updated_on', 'id'].includes(el.id));
            locationElement.fields = [...internalScopeOptionalFields, ...otherScopeFields];
            locationElement.fields.forEach(fieldElement => {
              if (((!fieldElement.isGenerated && !fieldElement.tags.includes('ID')) && this.filterObject.projection.fields.includes(fieldElement.id))
                  || ( ['id', 'created_on', 'updated_on'].includes(fieldElement.id) && fieldElement.isProjected)) {
                const fieldConfigObj = !['id', 'created_on', 'updated_on'].includes(fieldElement.id) ? { enableRowGroup: true } : {filter: false, pivot: false, enableRowGroup: false, sortable: false};
                let column = { ...this.getColumn(fieldElement, fieldElement.id, fieldElement.name, fieldElement.id), ...fieldConfigObj };
                // In grouping case If formula applied then hide grouped columns.
                if (this.rowGroupsIds.length && this.rowGroupsIds.includes(column.field)) {
                  column = { ...column, ...{ hide: true } };
                }
                this.dynamicGroupColumnChildrens[locationElement.name].push(this.setGroupingOrAggregation(fieldElement, column));
              }
            });
            locationColumnDefs.push({ headerName: locationElement.name, marryChildren: true, children: this.dynamicGroupColumnChildrens[locationElement.name] });
          });
        } else if (key === 'B') {
          for (const key in element) {
            if (Object.prototype.hasOwnProperty.call(element, key)) {
              const tessadataGroupsElement = element[key];
              if (key !== 'undefined') {
                this.dynamicGroupColumnChildrens[key] = [];
                tessadataGroupsElement.forEach(tessadataGroupsElementField => {
                  if (this.filterObject.projection.fields.includes(tessadataGroupsElementField.id)) {
                    let column = { ...this.getColumn(tessadataGroupsElementField, tessadataGroupsElementField.id, tessadataGroupsElementField.name, tessadataGroupsElementField.id), ...{ enableRowGroup: true, hide: true } };
                    this.dynamicGroupColumnChildrens[key].push(this.setGroupingOrAggregation(tessadataGroupsElementField, column));
                  }
                });
                tempColumnDefs.push({ headerName: key, marryChildren: true, children: this.dynamicGroupColumnChildrens[key] });
              }
            }
          }
        } else if (key === 'C' && element[0] && this.dataset.geometryType === DatasetGeometryType.POINT && this.dataset.application === MaptycsApplication.LOCATIONS && element[1][this.dataset.id].nriFields.length) {
          this.dynamicGroupColumnChildrens[this.nriUSA] = [];
          let tempChildrenArray = [];
          element[1][this.dataset.id].nriFields.forEach(nriField => {
            this.dynamicGroupColumnChildrens[this.nriUSA][nriField.name] = [];
            nriField.child.forEach(nriFieldChildElement => {
              if (this.filterObject.projection.fields.includes(nriFieldChildElement.child.id)) {
                let column = { ...this.getColumn(nriFieldChildElement.child, nriFieldChildElement.child.id, nriFieldChildElement.name, nriFieldChildElement.child.id), ...{ enableRowGroup: true, hide: true } };
                this.dynamicGroupColumnChildrens[this.nriUSA][nriField.name].push(this.setGroupingOrAggregation(nriFieldChildElement.child, column));
              }
            });
            tempChildrenArray.push({ headerName: nriField.name, marryChildren: true, children: this.dynamicGroupColumnChildrens[this.nriUSA][nriField.name] });
          });
          tempColumnDefs.push({ headerName: this.nriUSA, marryChildren: true, children: tempChildrenArray });
        }
      }
    }
    if (this.filterObject.projection.links.length) {
      this.dynamicGroupColumnChildrens[this.climate] = [];
      this.filterObject.projection.links.forEach((link) => {
        link.fields.forEach((fieldID) => {
          let datasetField = this.getDataset(link.datasetID).fields.find(searchedDatasetField => searchedDatasetField.id === fieldID);
          if (datasetField.isDisplayedInProjection) {
            const name = datasetField.displayName !== undefined && datasetField.displayName !== null ? datasetField.displayName : datasetField.name;
            let columnDef = { ...this.getColumn(datasetField, link.datasetID + '-' + fieldID, name, link.datasetID + '-' + fieldID), ...{ filter: false, pivot: false, enableRowGroup: false, sortable: false, hide: true  } };
            this.dynamicGroupColumnChildrens[this.climate].push(columnDef);
          }
        });
      });
      globalOverlaysDefs.push({ headerName: this.climate, marryChildren: true, children: this.dynamicGroupColumnChildrens[this.climate] });
    }
    if (this.filterObject.projection.formulas) {
      this.dynamicGroupColumnChildrens[this.formula] = [];
      this.filterObject.projection.formulas.forEach(formula => {
        let column = {
          field: formula.id.toString(),
          colId: formula.name.toString(),
          headerTooltip: formula.name,
          headerName: formula.name,
          filter: false,
          pivot: false,
          enableRowGroup: false,
          sortable: false
        };
        this.dynamicGroupColumnChildrens[this.formula].push(column);
      });
      tempColumnDefs.push({ headerName: this.formula, marryChildren: true, children: this.dynamicGroupColumnChildrens[this.formula] });
    }
    let prepareColumnDef = [];
    const formulaHeaders = tempColumnDefs.find(element => element.headerName == this.formula);
    const groupHeaders = this.sortFields(tempColumnDefs.filter(element => ![this.formula, this.nriUSA].includes(element.headerName)));
    const nriFieldHeaders = tempColumnDefs.find(element => element.headerName == this.nriUSA);

    if (!isUndefined(formulaHeaders)) {
      prepareColumnDef[0] = formulaHeaders;
    }
    if (locationColumnDefs.length) {
      const index = prepareColumnDef.length ? prepareColumnDef.length : 0;
      prepareColumnDef[index] = locationColumnDefs[0];
    }
    if (groupHeaders.length) {
      groupHeaders.forEach(element => {
        prepareColumnDef.push(element);
      });
    }
    if (!isUndefined(nriFieldHeaders)) {
      prepareColumnDef[prepareColumnDef.length] = nriFieldHeaders;
    }
    if (globalOverlaysDefs.length) {
      prepareColumnDef[prepareColumnDef.length] = globalOverlaysDefs[0];
    }
    tempColumnDefs = this.setCheckboxSelection(prepareColumnDef);
    this.setColumns(tempColumnDefs);
    return tempColumnDefs;
  } 

  prepareGridColumns(): (ColDef | ColGroupDef)[] {
    //this.fetchGroups(this.dataset.id).then((resolve: any) => {
      this.columnDefs = [];
      this.allColumns = [];
      this.groupColumnChildrens = {formula: [], internalScope: [], externalScope: [], nriScope: []};
      this.dynamicGroupColumnChildrens = {};
      this.columnDefs = this.tempPrepareGridColumns();

    //});
    return this.columnDefs;
  }

  getColumn(field, id, name, colId) {
    return  {
      field: id,
      colId: colId,
      headerTooltip: name,
      headerName: name,
      id: id,
      type: AgGridUtils.getGridColumnTypeByDatasetType(field),
      datasetID: this.dataset.id,
      valueFormatter: params=>{
        if (field.baseType == DatasetFieldType.DATE_TIME && params.value !== null) {
          return DateUtils.formatDate(params.value);
        }
     }
    };
  }

  prepareGroupwiseChildrens(datasetField: DatasetField, columnDef: ColDef, type) {
    if (isUndefined(datasetField) && type == DatapointConverterType.FORMULA) {
      this.groupColumnChildrens.formula.push(columnDef); 
    } else if (datasetField.scope == DatasetFieldScope.INTERNAL) {
      this.groupColumnChildrens.internalScope.push(columnDef); 
    } else if (datasetField.scope == DatasetFieldScope.EXTERNAL) {
      this.groupColumnChildrens.externalScope.push(columnDef);
    } else if (datasetField.scope == DatasetFieldScope.NRI) {
      this.groupColumnChildrens.nriScope.push(columnDef);
    }

  }

  prepareGroupwiseResponse() {
    if (!isUndefined(this.groupColumnChildrens.formula) && this.groupColumnChildrens.formula.length) {
      this.columnDefs.push({headerName: this.formula, marryChildren: true, children: this.groupColumnChildrens.formula});
    }
    if (this.groupColumnChildrens.internalScope.length) {
      this.columnDefs.push({headerName: DatasetFieldScope.INTERNAL, marryChildren: true, children: this.groupColumnChildrens.internalScope});
    }
    if (this.groupColumnChildrens.externalScope.length) {
      this.columnDefs.push({headerName: DatasetFieldScope.EXTERNAL, marryChildren: true, children: this.groupColumnChildrens.externalScope});
    } 
    if (this.groupColumnChildrens.nriScope.length) {
      this.columnDefs.push({headerName: DatasetFieldScope.NRI, marryChildren: true, children: this.groupColumnChildrens.nriScope});
    }
    this.columnDefs = this.setCheckboxSelection(this.columnDefs);
  }

  setColumns(columnDefs) {
    this.gridApi.setColumnDefs(columnDefs);
    this.allColumns = this.gridColumnApi.getColumns();
    this.allColumns.forEach(element => {
      // This is tempory solution we update it in coming sprint.
      if (element.colId == '0_1') {
        element.colId = '0';
      }
    });
    this.gridColumnApi.autoSizeAllColumns();
  }

  setCheckboxSelection(tempColumnDef) {
    let isCheckboxSet = false;
    tempColumnDef.forEach(element => {
      if (element.children.length && (!isCheckboxSet && ![this.climate, this.formula].includes(element.headerName))) {
        isCheckboxSet = true;
        element.children[0].checkboxSelection = true;
        return;
      }
    });
    return tempColumnDef;
  }

  setGroupingOrAggregation(datasetField: DatasetField, columnDef: ColDef) {
    if (!datasetField.isHighCardinality && !datasetField.isGenerated && datasetField.baseType === DatasetFieldType.TEXT) {
      columnDef = { ...columnDef, ...{ enableRowGroup: true, checkboxSelection: false, enablePivot: true } };
    } else if (!datasetField.isGenerated && datasetField.baseType === DatasetFieldType.NUMBER) {
      //columnDef = {...columnDef, ...{aggFunc: 'sum'  }} 
      columnDef = { ...columnDef, ...{ enableValue: true, enableRowGroup: false } }
    } else if (datasetField.baseType === DatasetFieldType.DATE_TIME) {
      columnDef = {...columnDef, ...{ enableRowGroup: false,  enablePivot: false  }}  
    }
    return columnDef;
  }

  getColumnVisibility(datasetField: DatasetField): boolean {
    switch (datasetField.id) {
      case 'id':
      case 'created_on':
      case 'updated_on':
        return true;
      default:
        return false;
    }
  }

  getSelectedDatapointIds() {
    let selectedDatapointIds = this.selectAll ? [] : this.gridApi.getSelectedRows().map(row => row.id);
    return selectedDatapointIds || [];
  }

  getSelectedDownloadRequestData() {
    let selectedDatapointIds = this.selectAll ? [] : this.gridApi.getSelectedRows().map(row => row.id);
    let columns = this.gridColumnApi.getColumns().filter((el) => el.visible && el.colId.split("-").length <= 1).map(row => row.colId);
    let overlayColumns = this.gridColumnApi.getColumns().filter((el) => el.visible && el.colId.split("-").length > 1).map(row => row.colId.split("-").slice(0, row.colId.split("-").length - 1).join("-"));
    return {selectedRows: selectedDatapointIds, selectedColumns: columns.length ? columns : [], overlayColumns: overlayColumns.length ? overlayColumns : []};
  }

  public fetchExternalData(externalDatasetsIds: string[], selectedDatapointIds?: string[]) {
    if (this.selectAll === false) {
      if (selectedDatapointIds.length > 0) {
        let clonedFilter = this.applyProjetionForTessadata(externalDatasetsIds);
        this.tessadataService.fetchExternalData(clonedFilter.projection, this.dataset.id, externalDatasetsIds, selectedDatapointIds, clonedFilter.filter).subscribe(success => {
          this.notifService.success('The fetching process has started');
        });
      }
    } else {
      let clonedFilter = this.applyProjetionForTessadata(externalDatasetsIds);
      this.tessadataService.fetchExternalDataByFilter(clonedFilter.projection, this.dataset.id, externalDatasetsIds, clonedFilter.filter).subscribe(success => {
        this.notifService.success('The fetching process has started');
      });
    }
  }

  private applyProjetionForTessadata(externalDatasetsIds: string[]): DatapointFilterObject {
    let clonedFilter = ObjectUtils.clone(this.filterObject);
    clonedFilter.projection.datasetID = this.dataset.id;
    let fields = clonedFilter.projection.fields.filter(f => {
      return externalDatasetsIds.find(externalDatasetsId => {
        return f.startsWith(externalDatasetsId);
      });
    });
    clonedFilter.projection.fields = fields;
    clonedFilter.projection.geometryPrecision = 25;
    return clonedFilter;
  }

  public deleteSelected(): void {
    if (this.selectAll === false) {
      let selectedDatapointIds = this.getSelectedDatapointIds();
      if (selectedDatapointIds.length > 0) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: new DialogModel(
            'Confirm Action',
            `Are you sure you want to delete ${selectedDatapointIds.length} datapoint(s)?`
          )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
          if (dialogResult) {
            this.subscriptions.add(this.dpService.deleteDatapoints(this.dataset.id, selectedDatapointIds).subscribe((res) => {
              this.fetchDatapoints();
            }));
          }
        });
      }
    } else {
      if (!this.totalRecords) {
        return;
      }
      const dialogRef = this.dialog.open(DialogComponent, {
        data: new DialogModel(
          'Confirm Action',
          `Are you sure you want to delete ${this.totalRecords} datapoint(s)?`
        )
      });
      dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
        if (dialogResult) {
          this.subscriptions.add(this.dpService.deleteDatapointsByFilter(this.dataset.id, this.filterObject.filter).subscribe((res) => {
            this.notifService.success(`Successfully deleted ${res} datapoints`);
            this.fetchDatapoints();
          }));
        }
      });
    }

  }

  onPaginationChanged(event) {
    if (event.newPage) {
      let parent = this.gridOptions.context.componentParent;
      if (event.api.paginationGetCurrentPage() == 0) {
        parent.currentRowRange = parent.rowData.length > 0 ? '1:' + event.api.paginationGetPageSize() : '';
      } else {
        let startRow = (event.api.paginationGetPageSize() * event.api.paginationGetCurrentPage()) + 1;
        let endRow = (event.api.paginationGetPageSize() * event.api.paginationGetCurrentPage()) + event.api.paginationGetPageSize();
        parent.currentRowRange = startRow + ':' + endRow;
      }
    }
  }

  clearPinned() {
    this.gridColumnApi.applyColumnState({ defaultState: { pinned: null } });
  }

  pinColumnLeft(columns: any[]) {
    let stateArr = columns.map(col => ({ colId: col, pinned: 'left' }));
    this.applyColumnState(stateArr);
    this.autoSizeAutoFit();
  }

  pinColumnRight(columns: any[]) {
    let stateArr = columns.map(col => ({ colId: col, pinned: 'right' }));
    this.applyColumnState(stateArr);
    this.autoSizeAutoFit();
  }

  hideShowColumn(toRemove: any[]) {
    let showColumns = this.allColumns.filter((el) => !toRemove.includes(el));
    this.gridColumnApi.setColumnsVisible(showColumns, true);
    this.gridColumnApi.setColumnsVisible(toRemove, false);
  }

  autoSizeAutoFitChange(event) {
    if (event) {
      this.autoSize = event.checked;
      this.autoSizeAutoFit();
    }
  }

  autoSizeAutoFit() {
    if (this.autoSize) {
      this.autoSizeAll(false);
    } else {
      this.sizeToFit();
    }
  }

  autoSizeAll(skipHeader: boolean) {
    const allColumnIds: string[] = [];
    this.gridColumnApi.getColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  sizeToFit() {
    this.gridColumnApi.sizeColumnsToFit(this.minWidth);
  }


  applyColumnState(stateArr: any[]) {
    this.gridColumnApi.applyColumnState({
      state: [...stateArr],
      defaultState: { pinned: null },
    });
  }

  jumpToCol(column: any) {
    if (column) {
      this.gridApi.ensureColumnVisible(column);
      this.gridApi.flashCells({ columns: [column] });
    }
  }

  jumpToStartRow() {
    let index = 1;
    if (typeof index === 'number' && !isNaN(index) && index > 0) {
      this.gridApi.ensureIndexVisible(index);
      // pick row at index
      var rowNode = this.gridApi.getDisplayedRowAtIndex(index - 1)!;
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    }
  }

  jumpToRow() {
    var value = (document.getElementById('row') as HTMLInputElement).value;
    const index = Number(value);
    if (typeof index === 'number' && !isNaN(index) && index > 0) {
      this.gridApi.ensureIndexVisible(index);
      // pick row at index
      var rowNode = this.gridApi.getDisplayedRowAtIndex(index - 1)!;
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    }
  }

  canUseTable(): boolean {
    if (this.rowData && this.rowData.length > 0) return true;
    return false;
  }

  getDataset(id): Dataset {
    return this.service.getDataset(id);
  }

  resetTable(): void {
    this.datapoints = [];
    this.rowData = [];
  }

  fetchDatapoints(resetTable = true): void {
    this.fetchDatapointsSubject.next(resetTable);
  }

  updateDatapointById(datapointID: string): void {
    this.Update.emit({ dataset: this.dataset, datapointID: datapointID });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getNriColumnNames(coldId: string, name: string) {
    const NriFields = this.tessadataFieldsByDataset[this.dataset.id].nriFields;
    let columnName = null;
    if (NriFields.length) {
      NriFields.forEach(element => {
        element.child.forEach(childElementName => {
          if (childElementName.child.id == coldId) {
            columnName = element.name + " (" + childElementName.name.trim() + ")";
          }
          return;
        });
        return;
      });
    }
    return columnName == null ? name : columnName;;
  }

  fetchGroupData(request: any): Observable<any> {
    let reportRequest = this.createReportRequest(request);
    this.prepareProjection(request);
    let limit = request.endRow - request.startRow;
    let skip = request.startRow;
    if (this.isGlobalFilterCall) {
        this.setTotalRecords();
    }
    return this.aggregateService.getTableViewDatapointsReport(this.dataset.id, this.groupedFilterObject.filter, reportRequest, this.datapointProjection, limit, skip);
  }

  private createReportRequest(requestParameter: any): ReportRequest {

    let groups: AggregateGroupRequest[] = [];
    if (requestParameter.pivotMode && requestParameter.pivotCols.length === requestParameter.rowGroupCols.length) {
      requestParameter.pivotCols.forEach(element => {
        groups.push({
          datasetID: this.dataset.id,
          fieldID: element.field
        });
      });
    } else if (requestParameter.rowGroupCols.length > 0) {
      groups.push({
        datasetID: this.dataset.id,
        fieldID: requestParameter.rowGroupCols[requestParameter.groupKeys.length].field
      });
    }

    let aggregateFieldCodes = [];
    let aggregateFieldFormula;
    let aggregateFieldType = DatapointAggregateFieldType.FIELD;
    if (requestParameter.valueCols.length > 0) {
      requestParameter.valueCols.forEach((element, key) => {
        let formulaId = `${this.dataset.id}.${element.field}`;
        aggregateFieldCodes.push({ aggregateFieldCode: `VAR_${formulaId}`, id: formulaId, sqlFunction: element.aggFunc !== undefined ? element.aggFunc.toUpperCase() : element.aggFunc });
      });
    } else if (requestParameter.rowGroupCols.length > 0 && !requestParameter.pivotMode) {

      requestParameter.rowGroupCols.forEach((element, key) => {
        let formulaId = `${this.dataset.id}.${element.field}`;
        aggregateFieldCodes.push({ aggregateFieldCode: `VAR_${formulaId}`, id: formulaId, sqlFunction: element.aggFunc !== undefined ? element.aggFunc.toUpperCase() : element.aggFunc });
      });
    }

    return {
      datasetID: this.dataset.id,
      groups: groups,
      aggregateFieldCodes: aggregateFieldCodes,
      aggregateFieldType: aggregateFieldType,
      aggregateFieldFormulaJson: aggregateFieldFormula
    };
  }

  private prepareProjection(requestParameter: any) {
    if (!this.datapointProjection) {
      this.datapointProjection = { datasetID: this.dataset.id, fields: [], links: [] };
    }
    const rowGroupColsIds = requestParameter.rowGroupCols.map(function (result) { return result["field"]; });
    const valueColsIds = requestParameter.valueCols.map(function (result) { return result["field"]; });
    this.datapointProjection.fields = [...rowGroupColsIds, ...valueColsIds];

    this.datapointProjection.links = [];
    let linkProjection: DatapointProjection = {
      datasetID: this.dataset.id,
      fields: []
    };
    this.datapointProjection.links.push(linkProjection);
  }

  public setTotalRecords() {
    this.findTotalRecords()
      .pipe()
      .subscribe(result => {
        this.totalRecords = result.count;
        this.totalRecordsSimplified = AgGridUtils.formatCash(this.totalRecords);
      });
  }

  public prepareColumnsForSaveAs() {
    let formulaNameMap = this.formulas.map(function (result: { [x: string]: any; }) { return result["name"]; });
      let invisibleColumnCount = 0;
      this.gridColumnApi.getColumns()!.forEach((column: { visible: any; }) => {
        if (!column.visible) {
          invisibleColumnCount = invisibleColumnCount + 1;
        }
      });
      if (invisibleColumnCount) {
        return this.gridColumnApi.getAllDisplayedColumns().map(function (result: { [x: string]: any; }) { return result["colId"]; }).filter((field: string) => field !== 'created_on' && field !== 'updated_on' && field !== 'id' && !formulaNameMap.includes(field));
      } else {
        return [];
      }
  }
}

export interface DatapointTableRow {
  datapointID: string;
  status?: number;
  selected?: boolean;
  geocodingAccuracy?: string;
  fields: { [key: string]: DatapointField }; //  Map that contains fields by ids
}

export interface ColumnDef {
  datasetID: string;
  columnName: string;
  columnID: string;
  fixedWidth?: boolean;
  width?: string;
  direction?: string;
  isFormula?: boolean;
  field: string,
  colId: any,
  headerTooltip: string,
  headerName: string,
  headerCheckboxSelection: boolean,
  checkboxSelection: boolean,
  filterParams: object,
  filter: any
}

function dateFormatJoin(arg0: Date, dateFormatJoin: any): string {
  throw new Error('Function not implemented.');
}