import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RenderingOptions } from '../../../../../model/dataset/rendering/rendering-options';
import { DatasetType } from '../../../../../model/dataset/dataset-type';
import { ColorScaleType } from '../../../../../model/dataset/rendering/color-scale-type';
import { ColorUtils } from '../../../../../core/utils/color-utils';
import { Grouping } from '../../../../../model/dataset/rendering/datapoint-converter-options';
import { DatapointsPageStateService } from '../../../datapoints-page-state.service';
import { Dataset } from '../../../../../model/dataset/dataset';
import { RenderingUtils } from '../../../../../core/utils/rendering-utils';
import { NotifService } from '../../../../../core/notification/notif.service';
import { DatasetFieldSpecificType } from '../../../../../model/dataset/dataset-field-specific.type';
import { RenderingErrorCodes } from '../../../../../model/dataset/rendering/rendering-error-codes';
import { DatasetFieldType } from '../../../../../model/dataset/dataset-field-type';
import {VisualizationType} from '../../../../../model/dataset/rendering/visualization-options';
import {DatasetGeometryType} from '../../../../../model/dataset/dataset-geometry-type';

@Component({
    selector: 'mpt-thematic-config-box',
    templateUrl: './thematic-config-box.component.html',
    styleUrls: ['./thematic-config-box.component.scss']
})
export class ThematicConfigBoxComponent implements OnInit {

    @Input() renderingOptions: RenderingOptions;
    @Input() dataset: Dataset; // the dataset that is colorized
    @Output() Reset: EventEmitter<void> = new EventEmitter<void>();

    @Output() submitChanges: EventEmitter<RenderingOptions> = new EventEmitter();
    @Output() viewModeChanged = new EventEmitter<VisualizationType>();

    groupings: string[] = Object.keys(Grouping);
    mainDataset: Dataset;
    defaultColor = '#ed6a22';

    constructor(
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly notifService: NotifService
    ) {
    }

    ngOnInit(): void {
        this.mainDataset = this.datapointsPageStateService.getActiveDataset();
    }

    get getVisualizationTypes() {
        let visualizationTypes = [VisualizationType.DEFAULT];
        switch (this.dataset.type) {
            case DatasetType.GLOBAL_OVERLAY:
            case DatasetType.ACCOUNT_OVERLAY:
                if (this.dataset.geometryType === DatasetGeometryType.COMPLEX) {
                    visualizationTypes.push(VisualizationType.THEMATIC_MAP);
                }
                break;
            case DatasetType.ACCOUNT_APPLICATION:
                visualizationTypes.push(VisualizationType.CLUSTER);
                // visualizationTypes.push(VisualizationType.HEATMAP);
                break;
            default:
                break;

        }
        return visualizationTypes;
    }

    onApplySettings() {
        let invalidAttribute = RenderingUtils.validate(this.renderingOptions);
        if (!invalidAttribute) {
            this.submitChanges.next(this.renderingOptions);
        } else {
            switch (invalidAttribute) {
                case RenderingErrorCodes.APPLICATION_FIELD:
                    this.notifService.error('You must choose a field for the colorization');
                    return;
                case RenderingErrorCodes.INTERVALS:
                    this.notifService.error('You must define at least one interval');
                    return;
                case RenderingErrorCodes.INTERVALS_OVERLAP:
                    this.notifService.error('The defined intervals must not overlap');
                    return;
            }

        }
    }

    onConstantColorChanged(event) {
        this.renderingOptions.datasetStylingOptions.defaultColor = ColorUtils.colorFromBase64(event.target.value);
        this.defaultColor = ColorUtils.colorToBase64(this.renderingOptions.datasetStylingOptions.defaultColor);
    }

    get DatasetType() {
        return DatasetType;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get ColorScaleType() {
        return ColorScaleType;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }
    reset(): void {
        this.Reset.emit();
    }

}
