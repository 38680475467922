import { Component, OnInit } from '@angular/core';
import {Constants} from '../../constants';

@Component({
  selector: 'map-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  logo = Constants.WHITE_LOGO;

  constructor() { }

  ngOnInit() {
  }

}
