import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'map-overlay-breadcrumbs',
  templateUrl: './overlay-breadcrumbs.component.html',
  styleUrls: ['./overlay-breadcrumbs.component.scss']
})
export class OverlayBreadcrumbsComponent implements OnInit {
  @Input () breadCrumbs = [];
  @HostBinding('class') class = 'breadcrumbs-inner';
  constructor() { }

  ngOnInit() {
  }

}
