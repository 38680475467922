import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatasetBitValues, datasetConfig, DatasetsArray, DirectedLinksArray} from './account-panel.constants';
import {AccountService} from '../../data-access-layer/account/account.service';
import {CreateAccountRequest} from '../../model/account/account';
import {NotifService} from '../../core/notification/notif.service';
import {UserStateService} from '../../auth/user-state-service';
import {AuthService} from '../../auth/auth.service';
import {Constants} from '../../constants';
import {DirectedLinkDatasetId} from '../../model/dataset/link/directed-link-dataset-id';
import {MatCheckboxChange} from '@angular/material';
import {Dataset} from '../../model/dataset/dataset';

@Component({
    selector: 'map-account-panel',
    templateUrl: './account-panel.component.html',
    styleUrls: ['./account-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountPanelComponent implements OnInit {
    @Output()
    updatedSuccessfully = new EventEmitter();
    datasets = datasetConfig;
    createAccountForm: FormGroup;
    datasetLinkMap = {};

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly accountService: AccountService,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly userStateService: UserStateService,
        private readonly auth: AuthService
    ) {
        this.initializeDatasetLinkMap();
    }

    ngOnInit() {
        this.initForm();
    }

    initForm(): void {
        let name = new FormControl('', [Validators.required, Validators.maxLength(Constants.ACCOUNT_NAME_LENGTH), Validators.pattern('^[A-Za-z0-9]+( [A-Za-z0-9]+)*$')]);
        this.createAccountForm = new FormGroup({
            name: name,
            checkboxes: this.formBuilder.group({}),
            atLeastOneDatasetSelected: new FormControl(true, Validators.requiredTrue)
        });
        const checkboxes = this.createAccountForm.get('checkboxes') as FormGroup;
        let datasets = datasetConfig.map((dataset)=> {
            return { title: dataset.text};
        });
        datasets.forEach((option: any) => {
            checkboxes.addControl(option.title, new FormControl(true));
        });
        this.createAccountForm.get('checkboxes').valueChanges.subscribe((v) => {
            this.createAccountForm.get('atLeastOneDatasetSelected').setValue(this.mapItems(v));
            this.changeDetector.detectChanges();
        });

    }

    mapItems(items) {
        let values = [];
        Object.keys(items).map((index) => {
            let item = items[index];
            if (item) {
                values.push(index);
            }
        });
        return !(values.length === 0);
    }

    submitAccount(): void {
        this.accountService.createAccount(this.constructCreateAccountRequest()).subscribe(account => {
            this.initForm();
            this.userStateService.initialize(this.auth.principal).then(() => {
                this.notifService.success('Successfully created account');
                this.updatedSuccessfully.emit();
                this.changeDetector.detectChanges();
            });
        }, (err) => {
                if (err && err.status === Constants.INTERNAL_SERVER_ERROR) {
                    this.createAccountForm.controls.name.setErrors({used: true});
                    this.notifService.error('Account with this username already exists.');
                    this.changeDetector.detectChanges();
                }
        });
    }

    private constructCreateAccountRequest(): CreateAccountRequest {
        const request: CreateAccountRequest = {
            name: this.createAccountForm.controls.name.value,
            links: [],
            datasets: []
        };
        this.getSelectedDatasets().forEach((datasetName)=> {
            let datasetRequest = DatasetsArray.find((createDatasetRequest)=> createDatasetRequest.application === datasetName);
            if(datasetRequest) {
                request.datasets.push(datasetRequest);
            }
        });
        DirectedLinksArray.forEach((DirectedLink)=> {
            if(this.isLinkSelected(DirectedLink)) {
                request.links.push(DirectedLink);
            }
        });
        return request;
    }
    linkedDatasets(dataset: { key: string; text: string }) {
        return this.datasets.filter((value) => {
            return value.key !== dataset.key;
        });
    }

    private isLinkSelected(DirectedLink: DirectedLinkDatasetId): boolean {
        return this.datasetLinkMap[DirectedLink.bitValue];
    }
    getSelectedDatasets(): string[] {
        let checkboxes = this.createAccountForm.controls.checkboxes.value;
        let datasetNames = [];
        for (const dataset in checkboxes) {
            if (checkboxes.hasOwnProperty(dataset) && checkboxes[dataset]) {
                datasetNames.push(dataset.toUpperCase());
            }
        }
        return datasetNames;
    }

    private initializeDatasetLinkMap(): void {
        this.datasets.forEach((dataset1) => {
            this.datasets.forEach((dataset2) => {
                if (dataset1.key !== dataset2.key) {
                    let index = (dataset1.bitValue + dataset2.bitValue);
                    this.datasetLinkMap[index] = true;
                }
            });
        });
    }

    toggleLinks($event: MatCheckboxChange, dataset: {key: string, text: string, bitValue: DatasetBitValues}) {
        let value = $event.checked;
        if(!value) {
            for (const mask in this.datasetLinkMap) {
                if (this.datasetLinkMap.hasOwnProperty(mask)) {
                    // tslint:disable-next-line:no-bitwise
                    if(Number(mask) & dataset.bitValue) {
                        this.datasetLinkMap[mask] = value;
                    }
                }
            }
        }
    }
}
