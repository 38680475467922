import { Component, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';

@Component({
    selector: 'map-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
    @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
    @Input() name: string;

    close(): void {
        this.sidenav.close();
    }

    open(): void {
        this.sidenav.open();
    }


    constructor() {
    }

    onOpenPanel() {

    }
}
