import { NgModule } from '@angular/core';
import { DraftsComponent } from './drafts/drafts.component';
import { DatapointsComponent } from './datapoints/datapoints.component';
import { DatapointsTableComponent } from './datapoints/datapoints-table/datapoints-table.component';
import { DatapointsOverlaysComponent } from './datapoints/datapoints-overlay/datapoints-overlays.component';
import { SharedModule } from '../shared/shared.module';
import { DraftsUploadComponent } from './drafts/drafts-upload/drafts-upload.component';
import { MultiFieldComponent } from './drafts/field/field.component';
import { MatInputModule, MatSnackBarModule, MatTreeModule } from '@angular/material';
import { GroupPanelComponent } from './group-panel/group-panel.component';
import { ApproveDraftComponent } from './drafts/approve-draft/approve-draft.component';
import { GroupTreeComponent } from './drafts/approve-draft/group-tree/group-tree.component';
import { DraftsUpdateComponent } from './drafts/drafts-update/drafts-update.component';
import { DraftsCreateComponent } from './drafts/drafts-create/drafts-create.component';
import { ProjectionSelectorComponent } from './drafts/projection-selector/projection-selector.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ColorizationBoxComponent } from './datapoints/datapoints-overlay/colorization-box/colorization-box.component';
import { MapModule } from '../map/map.module';
import { UpdateDatapointComponent } from './datapoints/datapoints-table/update-datapoint/update-datapoint.component';
import { DatapointsPageStateService } from './datapoints/datapoints-page-state.service';
import { DatapointsAnalyticsComponent } from './datapoints/datapoints-analytics/datapoints-analytics.component';
import { DatasetDownloadComponent } from './dataset-download/dataset-download.component';
import { BasicConfigBoxComponent } from './datapoints/datapoints-overlay/colorization-box/basic-config-box/basic-config-box.component';
import { ThematicConfigBoxComponent } from './datapoints/datapoints-overlay/colorization-box/thematic-config-box/thematic-config-box.component';
import { CountReportComponent } from './datapoints/datapoints-analytics/count-report/count-report.component';
import { RankingReportComponent } from './datapoints/datapoints-analytics/ranking-report/ranking-report.component';
import { AggregateReportComponent } from './datapoints/datapoints-analytics/aggregate-report/aggregate-report.component';
import { ColorIntervalsComponent } from './datapoints/datapoints-overlay/colorization-box/intervals/color-intervals.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DatapointsFilterService } from './datapoints/datapoints-filter.service';
import { OverlayDatapointsComponent } from '../overlays/overlay-datapoints/overlay-datapoints.component';
import { MaptycsTableComponent } from '../core/maptycs-table/maptycs-table.component';
import { DatapointsProfilePanelComponent } from './datapoints/datapoints-location-profile/datapoints-profile-panel.component';
import { DatapointsOverlayFilterComponent } from './datapoints/datapoints-overlay-filter/datapoints-overlay-filter.component';
import { EventNotificationComponent } from './event-notifications/event-notification/event-notification.component';
import { EventNotificationsComponent } from './event-notifications/event-notifications.component';
import { SliderComponent } from '../slider/slider.component';
import { SliderItemDirective } from '../slider/slider-item.directive';
import { EditOverlayTreeComponent } from '../overlays/edit-overlay-tree/edit-overlay-tree.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { WorkspaceItemDialogComponent } from './workspace-item/projection/workspace-item-dialog.component';
import { LocationProfileComponent } from './datapoints/datapoints-location-profile/location-profile/location-profile.component';
import { ClusterConfigBoxComponent } from './datapoints/datapoints-overlay/colorization-box/cluster-config-box/cluster-config-box.component';
import { LocationProfileAttachmentsComponent } from './datapoints/datapoints-location-profile/attachments/location-profile-attachments.component';
import { AttachmentService } from '../data-access-layer/attachment.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MapComponentTest } from '../map/test/map-component-test';
import { DatapointsClusterComponent } from './datapoints/datapoints-cluster/datapoints-cluster.component';
import { AddressLocationProfileComponent } from './datapoints/address-location-profile/address-location-profile.component';
import { SidePanelComponent } from '../core/side-panel/side-panel.component';
import { CreateOverlayGroupComponent } from '../overlays/create-overlay-group/create-overlay-group.component';
import { EditOverlayGroupComponent } from '../overlays/edit-overlay-group/edit-overlay-group.component';
import {GeographicalFilterComponent} from '../geographical-regions/geographical-filter/geographical-filter.component';
import { CreateExternalOverlayGroupComponent } from '../external-overlays/create-external-overlay-group/create-external-overlay-group.component';
import { EditExternalOverlayGroupComponent } from '../external-overlays/edit-external-overlay-group/edit-external-overlay-group.component';
import { DecimalOnlyDirective } from 'src/app/core/directives/decimal-only.directive'; // Added for MS-745 Directive
import { AgGridModule } from 'ag-grid-angular';
import { AgDatapointTableComponent } from './datapoints/ag-datapoint-table/ag-datapoint-table.component';
import { Crises24DatapointsAgtableComponent } from './datapoints/datapoints-table/crises24-datapoints-agtable/crises24-datapoints-agtable.component';
import { DynamicFilterMenuComponent } from './datapoints/dynamic-filter-menu/dynamic-filter-menu.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ClimateOverlayPermission, DatapointsClimateChartService } from './datapoints/climate-charts/datapoints-climate-chart.service';
import { ClimateModule } from './datapoints/climate-charts/climate.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MultiLevelDropdownComponent } from '../shared/multi-level-dropdown/multi-level-dropdown.component';

@NgModule({
    entryComponents: [
        ApproveDraftComponent,
        DatasetDownloadComponent,
        MaptycsTableComponent,
        DatapointsAnalyticsComponent,
        UpdateDatapointComponent,
        DatapointsClusterComponent,
        AddressLocationProfileComponent,
        EventNotificationsComponent,
        DraftsUpdateComponent,
        DraftsCreateComponent,
        SidePanelComponent,
        CreateOverlayGroupComponent,
        EditOverlayGroupComponent,
        CreateExternalOverlayGroupComponent,
        EditExternalOverlayGroupComponent,
        AgDatapointTableComponent,
        Crises24DatapointsAgtableComponent
    ],
    declarations: [
        MapComponentTest,
        GroupTreeComponent,
        DatapointsClusterComponent,
        ApproveDraftComponent,
        DraftsCreateComponent,
        DraftsComponent,
        DatapointsComponent,
        DatapointsTableComponent,
        DatapointsOverlaysComponent,
        DraftsUploadComponent,
        MultiFieldComponent,
        DraftsComponent,
        GroupPanelComponent,
        DraftsUpdateComponent,
        ProjectionSelectorComponent,
        ColorizationBoxComponent,
        UpdateDatapointComponent,
        DatapointsAnalyticsComponent,
        DatasetDownloadComponent,
        BasicConfigBoxComponent,
        ClusterConfigBoxComponent,
        CountReportComponent,
        RankingReportComponent,
        AggregateReportComponent,
        ThematicConfigBoxComponent,
        ColorIntervalsComponent,
        OverlayDatapointsComponent,
        MaptycsTableComponent,
        DatapointsProfilePanelComponent,
        LocationProfileComponent,
        DatapointsOverlayFilterComponent,
        EventNotificationComponent,
        EventNotificationsComponent,
        SliderComponent,
        SliderItemDirective,
        EditOverlayTreeComponent,
        WorkspaceItemDialogComponent,
        LocationProfileAttachmentsComponent,
        AddressLocationProfileComponent,
        CreateOverlayGroupComponent,
        EditOverlayGroupComponent,
        GeographicalFilterComponent,
        CreateExternalOverlayGroupComponent,
        EditExternalOverlayGroupComponent,
        DecimalOnlyDirective,
        AgDatapointTableComponent,
        Crises24DatapointsAgtableComponent,
        DynamicFilterMenuComponent,
        DashboardComponent,
        MultiLevelDropdownComponent
    ],
    imports: [
        SharedModule,
        MapModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MDBBootstrapModule.forRoot(),
        MatAutocompleteModule,
        MatInputModule,
        AgGridModule,
        NgxEchartsModule.forChild(),
        ClimateModule,
        MatTreeModule
    ],
    exports: [
        DraftsComponent,
        ColorIntervalsComponent
    ],
    providers: [
        DatapointsPageStateService,
        DatapointsFilterService,
        AttachmentService,
        DatapointsClimateChartService,
    ],
})
export class DatasetModule {
}
