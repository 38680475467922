import {Component, OnInit, ViewChild} from '@angular/core';
import {RoutingUtils} from '../core/utils/routing-utils';
import {Router} from '@angular/router';
import {CreateOverlayComponent} from '../overlays/overlay-create/create-overlay.component';
import {DatasetType} from '../model/dataset/dataset-type';

@Component({
    selector: 'map-create-global-overlay',
    templateUrl: './create-global-overlay.component.html',
    styleUrls: ['./create-global-overlay.component.scss']
})
export class CreateGlobalOverlayComponent implements OnInit {
    @ViewChild('createOverlay', {static: true}) createOverlayComponent: CreateOverlayComponent;

    constructor(
        private readonly router: Router
    ) {
    }

    ngOnInit() {
    }

    create() {
        this.createOverlayComponent.createOverlay();
        // *ngIf="createOverlayForm.controls.fields.value.length > 0"
    }

    navigateBackToOverlays() {
        this.router.navigateByUrl(RoutingUtils.getGlobalOverlaysRoute());
    }
    get DatasetType() {
        return DatasetType;
    }
}
