import { Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { OverlaysService } from '../data-access-layer/global-overlays/overlays.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Dataset } from '../model/dataset/dataset';
import { Router } from '@angular/router';
import { RoutingUtils } from '../core/utils/routing-utils';
import { EditOverlayAccountsComponent } from './overlay-edit-accounts/edit-overlay-accounts.component';
import { NotifService } from '../core/notification/notif.service';
import { SidePanelComponent } from '../core/side-panel/side-panel.component';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { DialogModel } from '../model/dialog/dialog-model';
import { take } from 'rxjs/operators';
import { DatapointsPageStateService } from '../dataset/datapoints/datapoints-page-state.service';
import { ThematicMapScripts } from '../scripts/thematic-map-scripts';
import { SidePanelService } from '../shared/services/side-panel.service';
import { AccountServiceState } from '../shared/services/account-service-state';
import { SidePanels } from '../shared/services/side-panel.helper';

type EditOverlayAccountsComponentType = {
    currentOverlay: Dataset;
}

@Component({
    selector: 'map-overlays',
    templateUrl: './overlays.component.html',
    styleUrls: ['./overlays.component.scss']
})
export class OverlaysComponent implements OnInit, OnDestroy {
    currentOverlay: Dataset;
    overlays: Dataset[];
    overlaysDataSource: MatTableDataSource<Dataset>;
    currentDisplayedColumns: string[] = ['Name', 'DatasetID', 'Actions', 'RefreshDate'];
    isTableDataLoading: boolean;
    editOverlayAccountsPanel: ComponentRef<SidePanelComponent>;
    breadCrumbs: ['Home', 'Overlays'];

    constructor(
        private readonly router: Router,
        private readonly overlayService: OverlaysService,
        private readonly notifService: NotifService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly sidePanelService: SidePanelService,
        private readonly accountServiceState: AccountServiceState,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly thematicMapScripts: ThematicMapScripts,
        public readonly dialog: MatDialog
    ) {
        this.isTableDataLoading = true;
    }

    ngOnInit(): void {
        this.fetchAllGlobalOverlays();
        this.datapointsPageStateService.activeAccount = undefined;
    }

    get componentName() {
        return this.constructor.name;
    }

    fetchAllGlobalOverlays(): void {
        this.overlayService.getAllGlobalOverlays().subscribe((overlays) => {
            this.overlays = overlays;
            this.overlaysDataSource = new MatTableDataSource(overlays);
            this.isTableDataLoading = false;
        }, (err) => {
            console.log(err);
        });
    }

    openSidebar(overlay?: Dataset): void {
        if (this.editOverlayAccountsPanel && this.currentOverlay && overlay.id === this.currentOverlay.id) {
            this.editOverlayAccountsPanel.instance.showPanel();
        } else {
            if (this.editOverlayAccountsPanel) this.editOverlayAccountsPanel.instance.hidePanel();
            this.currentOverlay = overlay;
            this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
            this.editOverlayAccountsPanel = this.sidePanelService.open<EditOverlayAccountsComponentType>(SidePanels.EDIT_OVERLAYS_ACCOUNT,
                {
                    id: 'overlayAccounts',
                    width: 400,
                    panelTitle: "Overlay Accounts",
                },
                {
                    currentOverlay: this.currentOverlay
                });

            this.accountServiceState.editOverlaysComponentInit$.pipe(take(1)).subscribe(() => {
                if(this.editOverlayAccountsPanel){
                  const { componentRef } = this.editOverlayAccountsPanel.instance;
                    if (componentRef) {
                        componentRef.instance.init(overlay);
                    }
                }
            });

            this.accountServiceState.onEditOverlaySuccess.subscribe(() => {
                if (this.editOverlayAccountsPanel) this.editOverlayAccountsPanel.instance.hidePanel();
            });
        }
    }

    closeSidebar(): void {
        this.currentOverlay = null;
        if (this.editOverlayAccountsPanel) this.editOverlayAccountsPanel.instance.hidePanel();
    }

    applyFilter(filterValue: string): void {
        this.overlaysDataSource.filter = filterValue.trim().toLowerCase();
    }

    deleteOverlay(overlay: Dataset): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete "${overlay.name}" overlay?`
            )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.overlayService.deleteOverlay(overlay.id).subscribe((res) => {
                    this.fetchAllGlobalOverlays();
                    this.notifService.success('Successfully deleted overlay');
                });
            }
        });
    }

    onOverlayCreatedOrUpdated(): void {
        this.fetchAllGlobalOverlays();
        this.closeSidebar();
    }

    editOverlay(overlay: Dataset): void {
        this.router.navigateByUrl(RoutingUtils.getGlobalOverlaysEditRoute(overlay.id));
    }

    createOverlay(): void {
        this.router.navigateByUrl(`private/global-overlays/create`);
    }

    refreshOverlay(datasetID: string) {
        this.overlayService.refresh(datasetID).subscribe(res => {
            this.fetchAllGlobalOverlays();
        });
    }

    handleTableDoubleClickEvent(row: Dataset) {
        this.router.navigateByUrl(RoutingUtils.getGlobalOverlaysDraftsRoute(row.id));
    }

    initializeThematicMap() {
        this.thematicMapScripts.initializeOverlays().subscribe(success => {
            this.notifService.success('The overlays were initialized');
            this.fetchAllGlobalOverlays();
        });
    }

    initializeAerisData() {
        this.overlayService.initAerisData().subscribe(v => {
            this.notifService.success('Aeris data successfully initialized');
            this.fetchAllGlobalOverlays();
        });
    }

    refreshAerisData() {
        this.overlayService.refreshAerisData().subscribe(v => {
            this.notifService.success('Aeris refresh has finished');
        });
    }

    ngOnDestroy() {
        if (this.editOverlayAccountsPanel) {
            this.editOverlayAccountsPanel.instance.closePanel();
            this.editOverlayAccountsPanel = null;
        }
    }
}
