import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Upload } from '../../model/upload/upload';
import { HttpClient } from '@angular/common/http';
import { UploadEntry } from '../../model/upload/upload-entry';
import { UploadTableOptionsRequest } from '../../model/upload/table/upload-table-options-request';

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    constructor(private readonly http: HttpClient) {
    }

    createUpload(datasetId: string): Observable<Upload> {
        return this.http.post<Upload>(`/uploads/dataset/${ datasetId }`, null);
    }

    getUpload(uploadId: number, datasetId: string): Observable<Upload> {
        return this.http.get<Upload>(`/uploads/${ uploadId }/dataset/${ datasetId }`);
    }


    uploadFile(uploadId: number, file: any): Observable<Upload> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.post<Upload>(`/uploads/${ uploadId }/file`, formData);
    }

    getUploadEntries(uploadId: number, sheetIndex: number, start: number, limit: number): Observable<UploadEntry[]> {
        return this.http.get<UploadEntry[]>(`/uploads/${ uploadId }/table/sheet/${ sheetIndex }/entries/${ start }/${ limit }`);
    }

    setTableUploadSheetOptions(uploadId: number, request: UploadTableOptionsRequest): Observable<void> {
        return this.http.put<void>(`/uploads/${ uploadId }/table/options`, request);
    }

    completeUpload(uploadId: number, isInDraft: boolean): Observable<Upload> {
        return this.http.post<Upload>(`/uploads/${ uploadId }/complete?isInDraft=${isInDraft}`, null);
    }

    uploadDatasetFields(datasetID: string, file: any): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.post(`/uploads/fields/${ datasetID }/file`, formData);
    }
}
