
export const AccountLinks  = [
    // {
    //     path: 'account',
    //     name: 'Account',
    //     component: 'AccountComponent',
    //     active: false,
    //     breadCrumbs: ['Home', 'Accounts']
    // },
    {
        path: 'fields',
        name: 'Fields',
        component: 'AccountFieldsComponent',
        active: false,
        breadCrumbs: ['Home', 'Accounts', 'Settings']
    },
    {
        path: 'groups',
        name: 'Groups',
        component: 'AccountGroupsComponent',
        active: false,
        breadCrumbs: ['Home', 'Accounts', 'Settings']
    },
    {
        path: 'members',
        name: 'Members',
        component: 'AccountMembersComponent',
        active: false,
        breadCrumbs: ['Home', 'Accounts', 'Settings']
    },
    {
        path: 'global-overlays',
        name: 'Global Overlays',
        component: 'AccountOverlaysComponent',
        active: false,
        breadCrumbs: ['Home', 'Accounts', 'Settings']
    },
    {
        path: 'private-overlays',
        name: 'Private Overlays',
        component: 'AccountPrivateOverlaysComponent',
        active: false,
        breadCrumbs: ['Home', 'Accounts', 'Settings']
    }
];
export function getLinks(val) {
    let links = AccountLinks;
    links.forEach((link)=> {
        link.active = (val === link.component);
    });
    return links;
}
export function getBreadcrumbs(val) {
   return AccountLinks.find((link)=> {
       return val === link.component;
    }).breadCrumbs;
}
