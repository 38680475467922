import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Point } from '../../model/geometry/point';

declare var google: any;

@Injectable({ providedIn: 'root' })
export class GeocodingService {
    private static geoCoder: google.maps.Geocoder;
    public currentPinAddress = '';

    reverseGeocoding(point: Point): void {
        if (!GeocodingService.geoCoder) {
            GeocodingService.geoCoder = new google.maps.Geocoder();
        }

        const location = {
            lat: point.y,
            lng: point.x
        };

        GeocodingService.geoCoder.geocode({ location }, (results: google.maps.GeocoderResult[]) => {
            if (results && results[0]) {
                this.currentPinAddress = results[0].formatted_address;
            }
        });
    }
}
