export interface DatapointConverterOptions {
    type: DatapointConverterType;
    progression?: Progression;
    grouping?: Grouping;
    datasetID: string;
    accountApplicationDatasetId?: string;
    accountApplicationFieldId?: string;
    accountApplicationGroups?: number[]; // this are location groups
    fieldID?: string;
    minNumber?: number;
    maxNumber?: number;
    minDate?: number;
    maxDate?: number;
    values?: string[];
    formula?: string;
    total?: number;
}


export enum DatapointConverterType {
    NONE = 'NONE',
    NUMBER_FIELD = 'NUMBER_FIELD',
    DATE_FIELD = 'DATE_FIELD',
    TEXT_FIELD = 'TEXT_FIELD',
    FORMULA = 'FORMULA'
}

export enum Progression {
    LINEAR = 'LINEAR'
}

export enum Grouping {
    COUNT = 'COUNT',
    AVERAGE = 'AVERAGE',
    SUM = 'SUM'
}
