import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DistanceUnit } from 'src/app/constants';
import { Dataset } from 'src/app/model/dataset/dataset';
import { DatasetFieldType } from 'src/app/model/dataset/dataset-field-type';
import { DatasetGeometryType } from 'src/app/model/dataset/dataset-geometry-type';
import { DatasetField } from 'src/app/model/dataset/field/dataset-field';

@Component({
  selector: 'map-dynamic-filter-menu',
  templateUrl: './dynamic-filter-menu.component.html',
  styleUrls: ['./dynamic-filter-menu.component.scss']
})
export class DynamicFilterMenuComponent implements OnInit {

  @Input() renderFor: string;
  @Input() inputParams: {items: any, overlaysFilterDistance: any, filterFieldSearchFilter: any};
  @Input() analyticsInputParams: {items: any, filterFieldSearchString: any, filterFieldSearchFilter: any, selectedFieldsCount: any};
  @ViewChild('childMenu', { static: true }) public childMenu;
  @Output() outputEvent = new EventEmitter();


  defaultDistanceUnit: DistanceUnit = DistanceUnit.KM;

  constructor() {
   }

  ngOnInit() {
  }

  get DatasetGeometryType() {
    return DatasetGeometryType;
  }
  
  get DistanceUnit() {
    return DistanceUnit;
  }

  get DatasetFieldType() {
    return DatasetFieldType;
}

  applyClick(dataset: Dataset, distance, distanceUnit: DistanceUnit) {
    this.outputEvent.emit({isDistanceFilterCall: true, dataset: dataset, distance, distanceUnit: distanceUnit});
  }

  filterMenuFieldClick(flag: boolean, dataset: Dataset, field: DatasetField) {
    this.outputEvent.emit({isDistanceFilterCall: false, event: flag, dataset: dataset, field: field});
  }

  dynamicFilterMenuEmitter($event) {
    if ($event.isDistanceFilterCall) {
      this.applyClick($event.dataset, $event.distance, $event.distanceUnit);
    } else {
      this.filterMenuFieldClick($event.event, $event.dataset, $event.field);
    }
  }

  onProjectionChange(dataset: Dataset, field: DatasetField) {
    this.outputEvent.emit({dataset: dataset, field: field});
  }

}
