import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Marker} from '../../model/overlay/marker';
import {Upload} from '../../model/upload/upload';

@Injectable({
    providedIn: 'root'
})
export class MarkersService {

    constructor(private readonly http: HttpClient) {
    }

    createMarker(file: any): Observable<number> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.post<number>(`/marker/upload`, formData);
    }

    getMarkers(): Observable<Marker[]> {
        return this.http.get<Marker[]>('/marker/all');
    }

    deleteMarker(markerID: number): Observable<any> {
        return this.http.delete<number>(`/marker/${markerID}`);
    }
}
