import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayTabsComponent } from './overlay-tabs/overlay-tabs.component';
import { SharedModule } from '../shared/shared.module';
import { OverlayComponent } from './overlay.component';
import { OverlaysComponent } from '../overlays/overlays.component';



@NgModule({
  declarations: [OverlayTabsComponent, OverlayComponent, OverlaysComponent],
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [OverlayComponent, OverlayTabsComponent]
})
export class OverlayModule { }
