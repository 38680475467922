import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LinkDataset} from '../../model/dataset/link/link-dataset';
import {Dataset} from '../../model/dataset/dataset';
import { GroupWithOverlaysTreeNode } from 'src/app/model/overlay/group/group-with-overlays-tree-node';

@Injectable({
    providedIn: 'root'
})
export class LinkDatasetService {

    constructor(private readonly http: HttpClient) {
    }

    getManualLinkDatasets(datasetID: string): Observable<LinkDataset[]> {
        return this.http.get<LinkDataset[]>(`/dataset/${datasetID}/links/manual`);
    }

    getOverlayLinkDatasets(datasetID: string): Observable<LinkDataset[]> {
        return this.http.get<LinkDataset[]>(`/dataset/${datasetID}/links/overlays`);
    }

    getLinkedOverlays(datasetID: string): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/dataset/${datasetID}/linkedOverlays`);
    }

    getLinkedAccountApplications(datasetID: string): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/dataset/${datasetID}/linkedAccountApplications`);
    }

    linkedGroupOverlays(datasetID:string):Observable<GroupWithOverlaysTreeNode[]>{
        return this.http.get<GroupWithOverlaysTreeNode[]>(`/dataset/${datasetID}/linkedGroupOverlays`);
    }
}
