import { NgModule } from '@angular/core';
import { FieldComponent } from './field/field.component';
import { AccountFieldsComponent } from './fields/account-fields.component';
import { FieldGroupComponent } from './field-group/field-group.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
    declarations: [
        FieldComponent,
        AccountFieldsComponent,
        FieldGroupComponent
    ],
    imports: [
        SharedModule,
    ],
    exports: [
        FieldComponent,
        AccountFieldsComponent,
        FieldGroupComponent
    ]
})
export class FieldsModule {
}
