import {DatasetField} from '../../model/dataset/field/dataset-field';
import {DatasetFieldSpecificType} from '../../model/dataset/dataset-field-specific.type';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {DatasetType} from '../../model/dataset/dataset-type';
import {Dataset} from '../../model/dataset/dataset';

export class DefaultField implements DatasetField {
    readonly id = null;
    readonly order = -1;

    readonly isArray = false;
    readonly isGenerated = false;
    readonly isHighCardinality = false;
    readonly isLocked = false;
    readonly isRequired = false;
    readonly isSummary = false;

    readonly name = '';
    readonly regex = null;
    readonly values = null;
    readonly type = DatasetFieldSpecificType.SHORT_TEXT_FIELD;

    constructor() {

    }
}

export class AccountApplicationBasicFormControlsConfig {
    readonly id = [this.dataset.id, Validators.required];
    readonly name = [this.dataset.name, Validators.required];
    readonly fields = [this.dataset.fields, Validators.required];
    readonly geometryDatasets = [this.dataset.geometryDatasets];
    readonly geometryType = [this.dataset.geometryType, Validators.required];
    readonly isGeocoded = [{value: this.dataset.isGeocoded, disabled: true}, Validators.required];
    readonly isLinkable = [this.dataset.isLinkable, Validators.required];
    readonly isRefreshable = [this.dataset.isRefreshable];
    readonly hasHazardHubEnabled = [this.dataset.hasHazardHubEnabled];
    readonly lastRefreshDate = [this.dataset.lastRefreshDate];
    readonly mainSummaryFieldIndex = [this.dataset.mainSummaryFieldIndex, Validators.required];
    readonly type = new FormControl({value:DatasetType.ACCOUNT_APPLICATION, disabled: true });
    readonly version = [this.dataset.version, Validators.required];

    readonly thematicMapSettings = this.formBuilder.group({
        isThematicMapDataset: this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.isThematicMapDataset : undefined,
        parentDatasetId: this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.parentDatasetId : undefined,
        parentLinkFieldId: this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.parentLinkFieldId : undefined,
        mainThematicFieldId: this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.mainThematicFieldId : undefined
    });

    constructor(
        readonly formBuilder: FormBuilder,
        readonly dataset: Dataset
    ) {
    }
}


export class AccountApplicationFormControlsConfig extends AccountApplicationBasicFormControlsConfig {

    readonly stylingOptions = this.formBuilder.group({
        type: new FormControl({value:DatasetType.ACCOUNT_APPLICATION, disabled: true }),
        colorizationFieldIndex: this.dataset.stylingOptions.colorizationFieldIndex,
        defaultColor: this.dataset.stylingOptions.defaultColor,
        colors: [this.dataset.stylingOptions.colors],
        markerId: this.dataset.stylingOptions.markerId,
        backgroundOpacity: [this.dataset.stylingOptions.backgroundOpacity, [Validators.min(0), Validators.max(1)]]
    });

    constructor(
        readonly formBuilder: FormBuilder,
        readonly dataset: Dataset
    ) {
        super(formBuilder, dataset);
    }
}
