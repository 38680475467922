import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EditOverlayComponent} from '../../../overlays/overlay-edit/edit-overlay.component';
import {RoutingUtils} from '../../../core/utils/routing-utils';
import {CreateOverlayComponent} from '../../../overlays/overlay-create/create-overlay.component';
import {DatasetType} from '../../../model/dataset/dataset-type';

@Component({
    selector: 'map-create-private-overlay',
    templateUrl: './create-private-overlay.component.html',
    styleUrls: ['./create-private-overlay.component.scss']
})
export class CreatePrivateOverlayComponent implements OnInit {
    accountId: number;
    @ViewChild('createOverlay', {static: true}) createOverlayComponent: CreateOverlayComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.accountId = this.route.snapshot.paramMap.get('accountId') ? Number(this.route.snapshot.paramMap.get('accountId')) : null;
    }


    ngOnInit() {
    }

    create() {
        this.createOverlayComponent.createOverlay();
    }

    navigateBackToPrivateOverlays() {
        this.router.navigateByUrl(RoutingUtils.getPrivateOverlaysRoute(this.accountId));
    }

    // createOverlay() {
    // // *ngIf="createOverlayForm.controls.fields.value.length > 0"
    // }
    get DatasetType() {
        return DatasetType;
    }
}
