import { Directive } from '@angular/core';

@Directive({
    selector: '[appSliderItem]'
})
export class SliderItemDirective {

    constructor() { }

}
