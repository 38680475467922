// tslint:disable:max-file-line-count
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { DatapointsPageStateService } from '../../datapoints-page-state.service';
import { Dataset } from '../../../../model/dataset/dataset';
import { DatasetField } from '../../../../model/dataset/field/dataset-field';
import { DatapointFilter } from '../../../../model/datapoint/filter/datapoint-filter';
import { DatapointProjection } from '../../../../model/datapoint/projection/datapoint-projection';
import { ReportRow } from '../../../../model/datapoint/report/count/report-row';
import { ChartDataSets, ChartLegendLabelItem } from 'chart.js';
import { DatapointsAggregateService } from '../../../../data-access-layer/datapoints/datapoints-aggregate.service';
import { DatasetGeometryType } from '../../../../model/dataset/dataset-geometry-type';
import { ReportRequest } from '../../../../model/datapoint/report/report-request';
import { AggregateGroupRequest } from '../../../../model/datapoint/report/aggregate-group-request';
import { ReportResultResponse } from '../../../../model/datapoint/report/report-result-response';
import { DatasetUtils } from '../../../../core/utils/dataset-utils';
import { Sort } from '@angular/material/sort';
import { ReportResultGroupResponse } from '../../../../model/datapoint/report/report-result-group-response';
import { ChartInfo } from '../../../../model/datapoint/report/chart-info';
import { ColorUtils } from '../../../../core/utils/color-utils';
import { ReportDisplayType } from '../../../../model/analytics/report-display-type';
import { DatasetFieldType } from '../../../../model/dataset/dataset-field-type';
import { ChartDisplayType } from '../../../../model/analytics/chart-display-type';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DatapointsFilterService } from '../../datapoints-filter.service';
import { Subscription } from 'rxjs';
import { UserStateService } from '../../../../auth/user-state-service';
import { DatasetFieldSpecificType } from '../../../../model/dataset/dataset-field-specific.type';
import { TableColumn, TableColumnType, TableColumnAlignment } from '../../../../model/upload/table/table-column';
import { TableCell } from '../../../../model/upload/table/table-cell';
import { TableRow } from '../../../../model/upload/table/table-row';
import { DownloadReportItem } from '../../../../model/download/item/download-report-item';
import { DownloadReportTableRequest } from '../../../../model/download/download-report-table-request';
import { ReportComponent } from '../report.component';
import { DownloadReportChartRequest, ValueKey } from '../../../../model/download/download-report-chart-request';
import { DownloadItemReportType } from '../../../../model/download/item/download-item-report-type';
import { DownloadReportChartValueRequest } from '../../../../model/download/download-report-chart-value-request';
import { OverlaysConstants } from '../../../../overlays/overlays.constants';
import { OverlaysService } from '../../../../data-access-layer/global-overlays/overlays.service';
import { WorkspaceItem } from '../../../../model/workspace/workspace-item';
import { DatapointAggregateFieldType } from '../../../../model/datapoint/report/datapoint-aggregate-field-type';

@Component({
    selector: 'map-aggregate-report',
    templateUrl: './aggregate-report.component.html',
    styleUrls: ['./aggregate-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AggregateReportComponent implements OnInit, OnDestroy, ReportComponent {

    @Input() uuid: string;
    @Output() closed = new EventEmitter();
    @Output() saveWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveAsWorkspaceItemToDashboard = new EventEmitter();

    _formulas: WorkspaceItem[] = [];
    @Input() set formulas(formulas: WorkspaceItem[]) {
        this._formulas = formulas;
    }

    _dataset: Dataset;
    _nriFields: any;
    @Input() set dataset(dataset: Dataset) {
        this._dataset = JSON.parse(JSON.stringify(dataset));
    }

    @Input() set nriFields(nriFields: any) {
        this._nriFields = JSON.parse(JSON.stringify(nriFields));
    }

    @Input() dashboardWorkspaceItems: WorkspaceItem[];
    @Input() isDashboardCall: boolean;

    private readonly subscriptions: Subscription = new Subscription();
    private LIMIT: number = 247;

    selectedAggregateField: DatasetField; // selected locations value
    selectedFormula: WorkspaceItem;
    selectedAggregateName: string;
    selectedBreakdownFieldsByDataset: Map<string, DatasetField[]> = new Map(); // dataset id, selected values for breakdown
    selectedBreakdownFieldsCount = 0;
    reportName: string;
    datasetFields: DatasetField[];
    datasetsToChooseFrom: Dataset[];
    filterAccountDatasets: Dataset[];
    displayType: ReportDisplayType = ReportDisplayType.TABLE;
    chartDisplayType: ChartDisplayType = ChartDisplayType.AGGREGATE;
    dataIsReady = false;

    datapointFilter: DatapointFilter;
    datapointProjection: DatapointProjection;
    tessadataFields: { nriFields: DatasetField[]; externalFields: DatasetField[] };
    tessadataGroupedFields : any[];

    tableColumnsExcludedOverlayGroups: number[];

    /** TABLE  */
    dynamicColumns: Map<string, string>; // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
    columnsToDisplay: string[];
    totalCount: number;
    totalAggregate: number;
    reportData: ReportRow[];
    downloadReportData: ReportRow[];

    /** CHART  */
    chartDatasets: ChartDataSets[];
    chartLabels: string[];
    chartColors: any[];
    chartOptions: any = {
        responsive: true,
        legend: {
            labels: {
                generateLabels: (chart) => {
                    let legendItems: ChartLegendLabelItem[] = [];

                    chart.data.datasets.forEach(dataset => {
                        legendItems.push({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor[0] // because all values os the secondary dataset have the same color
                        });
                    });
                    if (legendItems.length <= 10) { // not to overload the page
                        return legendItems;
                    } else {
                        return [];
                    }
                }
            }
        },
    };


    readonly COUNT_COLUMN_ID = 'count';
    readonly AGGREGATE_COLUMN_ID = 'aggregate';
    readonly PERCENTAGE_COLUMN_ID = 'percentage';
    readonly TOTAL_COLUMN_ID = 'total';
    readonly BLANK_COLUMN_ID = 'blank';
    readonly AVERAGE_COLUMN_ID = 'average';

    private static compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    constructor(
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly aggregateService: DatapointsAggregateService,
        private readonly userStateService: UserStateService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly overlayService: OverlaysService
    ) {
    }

    ngOnInit() {
        this.resetNRISelectedFlag();
        let datasetsToGroupBy = this.datapointsPageStateService.getLinkedAccountDatasets()
            .concat(
                this.datapointsPageStateService.getLinkedAccountOverlays().filter(overlay => overlay.geometryType === DatasetGeometryType.COMPLEX)
            );

        if (!datasetsToGroupBy.find(dataset => dataset.id === this._dataset.id)) {
            datasetsToGroupBy.splice(0, 0, this._dataset);
        }

        this.subscriptions.add(this.overlayService.getOverlayGroupsByTag(OverlaysConstants.HIDE_GROUP_FROM_TABLE_COLUMNS_TAG).subscribe(
            overlayGroups => this.tableColumnsExcludedOverlayGroups = overlayGroups.map(group => group.id)
        ));

        this._formulas = JSON.parse(JSON.stringify(this._formulas));
        this.datapointFilter = this.datapointsFilterService.getActiveFilter();
        this.tessadataFields = JSON.parse(JSON.stringify(this.datapointsPageStateService.getActiveTessadataFields()));
        this.tessadataFields.externalFields.forEach(field => field.selected = false);
        this.tessadataFields.nriFields = this.sortFields(this.tessadataFields.nriFields);
        if(this.tessadataFields.externalFields && this.tessadataFields.externalFields.length > 0){
            let externalDataset = this.sortFields(this.tessadataFields.externalFields);
            this.tessadataGroupedFields = externalDataset.reduce((groups, item) => ({
                                                  ...groups,
                                                  [item.tags[0]]: [...(groups[item.tags[0]] || []), item]
                                           }), []);
            for (const key in this.tessadataGroupedFields) {
                if (Object.prototype.hasOwnProperty.call(this.tessadataGroupedFields, key)) {
                    this.tessadataGroupedFields[key] = this.sortFields(this.tessadataGroupedFields[key]);
                }
            }

        }
        this.datasetsToChooseFrom = JSON.parse(JSON.stringify(datasetsToGroupBy));
        this.filterAccountDatasets = this.datapointsPageStateService.getFilterAccountDatasetsCloned();
        if(this.filterAccountDatasets){
            this.filterAccountDatasets.map((dataSet) => {
              if(dataSet.fields.length > 0){
                 dataSet.fields.map((field) => { field.selected = false;  })
              }
            });
        }
        this.datasetFields = this.sortFields(JSON.parse(JSON.stringify(this._dataset.fields)));

        this.subscriptions.add(this.datapointsFilterService.onFilterChange().subscribe(newFilter => {
            this.datapointFilter = newFilter;
            if (this.dataIsReady) {
                this.generateReportData();
            }
        }));
    }

    setSelectedAggregateField(field: DatasetField) {
        let selectedField = this.datasetFields.find(f => f.id === field.id);
        this.selectedAggregateField = selectedField;
        selectedField.selected = true;
    }

    setSelectedFormula(formula: WorkspaceItem) {
        let selectedFormula = this._formulas.find(f => f.id === formula.id);
        this.selectedFormula = selectedFormula;
        selectedFormula.selected = true;
    }


    getSelectedAggregateField(): DatasetField {
        return this.selectedAggregateField;
    }

    getSelectedFormula(): WorkspaceItem {
        return this.selectedFormula;
    }

    setSelectedBreakdownFieldsByDataset(selectedFieldsByDataset: Map<string, DatasetField[]>) {
        // the following is necessary for checking the selected field in the breakdown dropdown
        selectedFieldsByDataset.forEach((fields, datasetId) => {
            this.selectedBreakdownFieldsCount += fields.length;
            let selectedFields: DatasetField[] = [];
            let selectedDataset = this.datasetsToChooseFrom.find(d => d.id === datasetId);
            if (selectedDataset) {
                selectedDataset.fields.forEach(selectionField => {
                    let fieldIsSelected = fields.find(f => f.id === selectionField.id);
                    if (fieldIsSelected) {
                        selectionField.selected = true;
                        selectedFields.push(selectionField);
                    }
                });
                this.selectedBreakdownFieldsByDataset.set(selectedDataset.id, selectedFields);
            }
        });
    }

    setDatapointFilter(filter: DatapointFilter) {
        this.datapointFilter = filter;
    }

    includeThematicMapDatasets(includeThematicMapDatasets: boolean) {
        if (includeThematicMapDatasets) {
            let thematicDatasets: Dataset[] = this.datapointsPageStateService.accountOverlays.filter(dataset => dataset.thematicMapSettings && dataset.thematicMapSettings.isThematicMapDataset);
            this.datasetsToChooseFrom.push(...thematicDatasets);
        } else {
            this.datasetsToChooseFrom = this.datasetsToChooseFrom.filter(dataset => !dataset.thematicMapSettings || !dataset.thematicMapSettings.isThematicMapDataset);
        }
    }

    onFieldsMenuClick($event: MatCheckboxChange, dataset: Dataset, field: DatasetField) {

        let datasetFields = this.selectedBreakdownFieldsByDataset.get(dataset.id) || [];
        if ($event.checked) {
            datasetFields.push(field);
            field.selected = true;
            this.selectedBreakdownFieldsCount++;
        } else {
            datasetFields = datasetFields.filter(f => f.id !== field.id);
            field.selected = false;
            this.selectedBreakdownFieldsCount--;
        }

        if (datasetFields.length === 0) {
            this.selectedBreakdownFieldsByDataset.delete(dataset.id);
        } else {
            this.selectedBreakdownFieldsByDataset.set(dataset.id, datasetFields);
        }
    }

    generateReport() {
        if (this.selectedBreakdownFieldsByDataset.size > 0 && (this.selectedAggregateField || this.selectedFormula)) {
            // this.setReportName();
            this.generateReportData();
        } else {
            this.dataIsReady = false;
        }
    }

    generateReportData() {
        let reportRequest = this.createReportRequest();
        this.populateTableColumnsList();
        this.prepareProjection();
        this.populateAggregateFieldColumnName();
        let tempDatapointFilter: DatapointFilter = {datasetID: this._dataset.id};
        const datapointFilters = this.datapointFilter == undefined ? tempDatapointFilter : this.datapointFilter;
        this.subscriptions.add(this.aggregateService.getDatapointsReport(this._dataset.id, datapointFilters, reportRequest, this.datapointProjection).subscribe(success => {

            this.computeTotalCountAndAggregate(success.groupResults);
            this.convertDataToTableFormat(success.groupResults);
            this.populateTableColumnsList();
            this.populateChartData();

            this.dataIsReady = true;
            this.changeDetector.detectChanges();
        }));
    }

    private createReportRequest(): ReportRequest {
        let groups: AggregateGroupRequest[] = [];
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach(field => {
                groups.push({
                    datasetID: datasetId,
                    fieldID: field.id
                });
            });
        });

        let aggregateFieldType;
        let aggregateFieldCodes;
        let aggregateFieldFormula;

        if (this.selectedFormula) {
            aggregateFieldType = DatapointAggregateFieldType.FORMULA;
            aggregateFieldFormula = this.selectedFormula.data;
        } else {
            aggregateFieldType = DatapointAggregateFieldType.FIELD;
            let formulaId = `${this._dataset.id}.${this.selectedAggregateField.id}`;
            aggregateFieldCodes = [{ aggregateFieldCode: `VAR_${formulaId}`, id: formulaId }];
        }

        return {
            datasetID: this._dataset.id,
            groups: groups,
            aggregateFieldCodes: aggregateFieldCodes,
            aggregateFieldType: aggregateFieldType,
            aggregateFieldFormulaJson: aggregateFieldFormula
        };
    }

    private populateTableColumnsList() {
        this.dynamicColumns = new Map();
        this.columnsToDisplay = [];

        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach(field => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(datasetId, field.id);
                this.dynamicColumns.set(key, field.name);
                this.columnsToDisplay.push(key);
            });
        });

        this.columnsToDisplay.push(this.AGGREGATE_COLUMN_ID);
        this.columnsToDisplay.push(this.PERCENTAGE_COLUMN_ID);
        this.columnsToDisplay.push(this.COUNT_COLUMN_ID);
        this.columnsToDisplay.push(this.AVERAGE_COLUMN_ID);
    }


    private computeTotalCountAndAggregate(groupResults: ReportResultResponse[]) {
        this.totalCount = 0;
        this.totalAggregate = 0;
        groupResults.forEach(groupResult => {
            this.totalCount += groupResult.values[0].count;
            this.totalAggregate += groupResult.values[0].result;
        });

        this.totalAggregate = Math.round(this.totalAggregate * 100) / 100;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get ReportDisplayType() {
        return ReportDisplayType;
    }

    get ChartDisplayType() {
        return ChartDisplayType;
    }

    getFieldType(field: DatasetField) {
        return DatasetUtils.getDatasetFieldType(field);
    }

    setDisplayType(displayType: ReportDisplayType) {
        if (this.selectedBreakdownFieldsByDataset.size > 0) {
            this.displayType = displayType;
        }
    }

    sortData(sort: Sort) {
        const isAsc = sort.direction === 'asc';
        const fieldId = sort.active;

        let sortedData = this.reportData.sort((a, b) => {

            switch (fieldId) {
                case this.COUNT_COLUMN_ID:
                    return AggregateReportComponent.compare(a.count, b.count, isAsc);
                case this.PERCENTAGE_COLUMN_ID:
                    return AggregateReportComponent.compare(a.percentage, b.percentage, isAsc);
                case this.AGGREGATE_COLUMN_ID:
                    return AggregateReportComponent.compare(a.aggregate, b.aggregate, isAsc);
                case this.AVERAGE_COLUMN_ID:
                    return AggregateReportComponent.compare(a.average, b.average, isAsc);
                default: {
                    let aValue = a.dynamicFieldValuesByIds.get(fieldId);
                    let bValue = b.dynamicFieldValuesByIds.get(fieldId);
                    return AggregateReportComponent.compare(aValue, bValue, isAsc);
                }
            }
        });

        this.reportData = [...sortedData];
    }

    private convertDataToTableFormat(groupResults: ReportResultResponse[]) {
        this.reportData = [];
        this.downloadReportData = [];
        groupResults.forEach(groupResult => {
            let percentage: number = this.totalAggregate > 0 ? groupResult.values[0].result / this.totalAggregate * 100 : 0;
            let tableEntry: ReportRow = {
                count: groupResult.values[0].count,
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(groupResult.buckets),
                percentage: Math.round(percentage * 100) / 100,
                aggregate: groupResult.values[0].result,
                average: groupResult.values[0].result / groupResult.values[0].count
            };
            this.downloadReportData.push(tableEntry);
        });
        this.reportData = this.downloadReportData.slice(0, this.LIMIT);
    }

    getDynamicFieldValuesByIds(groupResultBuckets: ReportResultGroupResponse[]): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        groupResultBuckets.forEach(groupResultBucket => {
            let value = groupResultBucket.value ? groupResultBucket.value : 'N/A';
            tableEntries.set(DatasetUtils.createUniqueIdentifierForDatasetField(groupResultBucket.datasetID, groupResultBucket.fieldID), value);
        });
        return tableEntries;
    }

    /**
     * We need a structure like: Map<String, chartDataset[]>
     * The key if the main field value
     * The value is the chartDataset list for each of the other field's values (one chart dataset per second field value).
     * Each chart dataset array will contain the count values for the pair (mainField, secondField)
     */
    private populateChartData() {
        if (this.selectedBreakdownFieldsCount > 2) {
            return; // cannot create chart with more than 2 group by fields
        }
        if (this.reportData.length === 0) {
            return;
        }
        this.initializeChartData();

        let chartDatasetsInfo: ChartInfo[] = [];
        let mainDatasetFieldValues = [];  // chart labels
        let chartDatasets: ChartDataSets[] = [];

        if (this.selectedBreakdownFieldsCount <= 1) {
            let chartDataset = {
                data: [],
            };
            let chartDatasetInfo = {
                backgroundColor: [],
                count: [] // this is only a shortcut for download
            };
            chartDatasets.push(chartDataset);
            chartDatasetsInfo.push(chartDatasetInfo);

            this.reportData.forEach(entry => {
                mainDatasetFieldValues.push(entry.dynamicFieldValuesByIds.values().next().value);
                chartDatasetInfo.backgroundColor.push(ColorUtils.generateRandomHexColor());
                chartDatasetInfo.count.push(entry.count);
                switch (this.chartDisplayType) {
                    case ChartDisplayType.AGGREGATE:
                        chartDataset.data.push(entry.aggregate);
                        break;
                    case ChartDisplayType.COUNT:
                        chartDataset.data.push(entry.count);
                        break;
                    case ChartDisplayType.AVERAGE:
                        chartDataset.data.push(entry.average);
                        break;
                }
            });
        } else {
            let mainField = this.reportData[0].dynamicFieldValuesByIds.keys().next().value; // we will use this as the main field in the chart
            this.reportData.forEach(row => {
                let mainFieldValue = row.dynamicFieldValuesByIds.get(mainField);
                if (!mainDatasetFieldValues.includes(mainFieldValue)) {
                    mainDatasetFieldValues.push(mainFieldValue);
                }
            });

            this.reportData.forEach(row => {
                let mainDatasetFieldValue = row.dynamicFieldValuesByIds.get(mainField);
                let indexInDatasetArray = mainDatasetFieldValues.indexOf(mainDatasetFieldValue); // because the labels indexes must match the values indexes
                row.dynamicFieldValuesByIds.forEach((value, datasetFieldId) => {
                    if (datasetFieldId !== mainField) {
                        let chartDataset = chartDatasets.find(cd => cd.label === value);
                        let chartDatasetInfo = chartDatasetsInfo.find(bg => bg.label === value);
                        if (!chartDataset) {
                            chartDataset = {
                                data: new Array(mainDatasetFieldValues.length).fill(0),
                                label: value
                            };
                            chartDatasets.push(chartDataset);
                        }
                        if (!chartDatasetInfo) {
                            chartDatasetInfo = {
                                label: value,
                                backgroundColor: new Array(mainDatasetFieldValues.length).fill(ColorUtils.generateRandomHexColor()),
                                count: new Array(mainDatasetFieldValues.length).fill(0),
                            };
                            chartDatasetsInfo.push(chartDatasetInfo);
                        }
                        switch (this.chartDisplayType) {
                            case ChartDisplayType.AGGREGATE:
                                chartDataset.data[indexInDatasetArray] = row.aggregate;
                                break;
                            case ChartDisplayType.COUNT:
                                chartDataset.data[indexInDatasetArray] = row.count;
                                break;
                            case ChartDisplayType.AVERAGE:
                                chartDataset.data[indexInDatasetArray] = row.average;
                                break;
                        }
                        chartDatasetInfo.count[indexInDatasetArray] = row.count;
                    }
                });
            });
        }

        this.chartDatasets = chartDatasets;
        this.chartLabels = mainDatasetFieldValues;
        this.chartColors = chartDatasetsInfo;
    }


    private initializeChartData() {
        this.chartDatasets = [];
        this.chartLabels = [];
        this.chartColors = [];
    }

    private prepareProjection() {
        if (!this.datapointProjection) {
            this.datapointProjection = { datasetID: this._dataset.id, fields: [], links: [] };
        }

        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            if (datasetId === this._dataset.id) {
                this.datapointProjection.fields = fields.map(field => field.id);
            } else {
                let linkProjection: DatapointProjection = {
                    datasetID: datasetId,
                    fields: fields.map(field => field.id)
                };
                this.datapointProjection.links.push(linkProjection);
            }
        });
        if (this.selectedAggregateField) {
            this.datapointProjection.fields.push(this.selectedAggregateField.id);
        }
    }

    onAggregateFieldSelected(event: MatCheckboxChange, field: DatasetField) {
        if (event.checked) {
            this.selectedAggregateField = field;
            this._dataset.fields.forEach(f => {
                f.selected = f.id === field.id;
            });
            this.selectedFormula = undefined;
            this._formulas.forEach(formula => formula.selected = false);
        } else {
            this.selectedAggregateField = undefined;
        }

        this.changeDetector.detectChanges();
    }

    onChartDisplayTypeChanged() {
        this.populateChartData();
    }

    datasetIsSelected(dataset: Dataset): boolean {
        // we need .foreach and not .get to do the comparison by id, in case the selected fields are set via setter
        let isSelected = false;
        this.selectedBreakdownFieldsByDataset.forEach((fields, dsId) => {
            if (dsId === dataset.id) {
                isSelected = true;
            }
        });
        return isSelected;
    }

    getTableReportHeader(): TableColumn[] {
        let columns: TableColumn[] = [];
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach(field => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(datasetId, field.id),
                    name: field.name,
                    type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                    horizontalAlignment : TableColumnAlignment.LEFT
                });
            });
        });
        columns.push({
            id: DatasetUtils.createUniqueIdentifierForDatasetField(this._dataset.id, this.selectedAggregateField.id),
            type: TableColumnType.DECIMAL,
            name: this.selectedAggregateField.name,
            horizontalAlignment : TableColumnAlignment.RIGHT
        });
        columns.push({ id: this.PERCENTAGE_COLUMN_ID, name: 'Total %', type: TableColumnType.TEXT, horizontalAlignment : TableColumnAlignment.LEFT });
        columns.push({ id: this.COUNT_COLUMN_ID, name: 'Count', type: TableColumnType.INTEGER, horizontalAlignment : TableColumnAlignment.RIGHT });
        columns.push({ id: this.AVERAGE_COLUMN_ID, name: 'Average', type: TableColumnType.DECIMAL, horizontalAlignment : TableColumnAlignment.RIGHT });
        return columns;
    }

    getTableReportFooter(): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: 'Total' });
        this.dynamicColumns.forEach((key, value) => cells.push({ id: this.BLANK_COLUMN_ID, value: '' }));
        cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces
        cells.push({
            id: DatasetUtils.createUniqueIdentifierForDatasetField(this._dataset.id, this.selectedAggregateField.id),
            value: Math.round(this.totalAggregate)
        });
        cells.push({ id: this.PERCENTAGE_COLUMN_ID, value: '100%' });
        cells.push({ id: this.COUNT_COLUMN_ID, value: this.totalCount.toString() });
        cells.push({ id: this.AVERAGE_COLUMN_ID, value: Math.round(this.totalAggregate / this.totalCount) });

        return { cells: cells };
    }

    getTableReportRows(): TableRow[] {
        let rows: TableRow[] = [];
        this.downloadReportData.map(row => {
            let columns: TableCell[] = [];
            this.dynamicColumns.forEach((value, key) => {
                columns.push({ id: key, value: row.dynamicFieldValuesByIds.get(key) });
            });
            columns.push({
                id: DatasetUtils.createUniqueIdentifierForDatasetField(this._dataset.id, this.selectedAggregateField.id),
                value: row.aggregate
            });
            columns.push({ id: this.PERCENTAGE_COLUMN_ID, value: row.percentage.toString() + '%' });
            columns.push({ id: this.COUNT_COLUMN_ID, value: row.count });
            columns.push({ id: this.AVERAGE_COLUMN_ID, value: Math.round(row.average) });
            rows.push({ cells: columns });
        });

        return rows;
    }

    getTableReportDownloadRequest(): DownloadReportItem {
        if (this.dataIsReady) {
            let reportHeader = this.getTableReportHeader();
            let reportRows = this.getTableReportRows();
            let reportFooter = this.getTableReportFooter();
            let title = this.reportName || 'Aggregate';

            return new DownloadReportTableRequest(title, reportHeader, reportRows, reportFooter);
        } else {
            return null;
        }
    }

    getChartReportDownloadRequest(): DownloadReportItem {
        let breakdownFieldsNames: string[] = [];
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach(field => {
                breakdownFieldsNames.push(field.name);
            });
        });
        let request: DownloadReportChartRequest = {
            title: this.reportName || 'Aggregate',
            valueKey: this.chartDisplayType === ChartDisplayType.AGGREGATE && ValueKey.VALUE
                || this.chartDisplayType === ChartDisplayType.COUNT && ValueKey.COUNT
                || this.chartDisplayType === ChartDisplayType.AVERAGE && ValueKey.AVERAGE,
            type: undefined,
            columns: {
                value: this.chartDisplayType,
                categories: breakdownFieldsNames
            },
            values: []
        };

        this.chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                const count = this.chartColors[datasetIndex].count[valueIndex];
                const chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [this.chartLabels[valueIndex]],
                    colors: [this.chartColors[datasetIndex].backgroundColor[valueIndex]],
                    count: count,
                    value: value
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });


        if (this.displayType === ReportDisplayType.PIE_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;
        } else if (this.displayType === ReportDisplayType.BAR_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_BAR_CHART;
        }

        return request;
    }


    getReportDownloadRequest(): DownloadReportItem {
        if (this.getDisplayType() === ReportDisplayType.TABLE) {
            return this.getTableReportDownloadRequest();
        } else if (this.getDisplayType() === ReportDisplayType.BAR_CHART || this.getDisplayType() === ReportDisplayType.PIE_CHART) {
            return this.getChartReportDownloadRequest();
        }
    }

    getDisplayType(): ReportDisplayType {
        return this.displayType;
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    isTwoDimensionReport(): boolean {
        let selectedFieldNo = 0;
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => selectedFieldNo += fields.length);
        return selectedFieldNo === 2;
    }

    onFormulaSelected($event: MatCheckboxChange, selectedFormula: WorkspaceItem) {
        if ($event.checked) {
            this._formulas.forEach(formula => {
                formula.selected = formula.id === selectedFormula.id;
            });
            this.selectedFormula = selectedFormula;
            this.selectedAggregateField = undefined;
            this.datasetFields.forEach(f => f.selected = false);
        } else {
            this.selectedFormula = undefined;
            selectedFormula.selected = false;
            this.selectedAggregateField = undefined;
        }
        this.changeDetector.detectChanges();
    }

    private populateAggregateFieldColumnName() {
        if (this.selectedFormula) {
            this.selectedAggregateName = this.selectedFormula.name;
        } else {
            this.selectedAggregateName = this.selectedAggregateField.name;
        }
    }

    sortFields(fields: DatasetField[]) {
        fields.sort((item1, item2) => {
            if(item1 && item1.name && item2 && item2.name){
                return item1.name.trim().toLowerCase().localeCompare(item2.name.trim().toLowerCase())
            }
        });
        return fields;
    }

    resetNRISelectedFlag() {
        this._nriFields.forEach(outerElement => {
            outerElement.child.forEach(element => {
                element.child.selected = false;
            });
        });
    }

    getSaveButtonLabel() {
        return this.isDashboardCall ? 'Save' : 'Save to Dashboard';
    }

    getSaveAsButtonLabel() {
        return this.isDashboardCall ? 'Save as' : 'Save as Dashboard';
    }
}
