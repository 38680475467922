import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { DatasetField } from '../../../../model/dataset/field/dataset-field';
import { take } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DatasetFieldSpecificType } from '../../../../model/dataset/dataset-field-specific.type';
import { DatapointsService } from '../../../../data-access-layer/datapoints/datapoints.service';
import { isEnabled } from '../../../../../environments/environment';
import { Functionalities } from '../../../../../environments/app-functionalities';
import { DataPointsServiceState } from '../../../../shared/services/datapoints-service-state';
import { Dataset } from '../../../../model/dataset/dataset';

@Component({
    selector: 'map-update-datapoint',
    templateUrl: './update-datapoint.component.html',
    styleUrls: ['./update-datapoint.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateDatapointComponent implements OnInit, OnChanges {

    @Input() dataset: Dataset;

    datapoint;
    datasetFields: Map<string, DatasetField>;
    loading = true;
    updateDatapointForm: FormGroup;
    datapointID: string;

    alterDatapointsEnabled = isEnabled(Functionalities.ALTER_DATAPOINTS);

    constructor(
        private readonly dpService: DatapointsService,
        private readonly dataPointServiceState: DataPointsServiceState,
        private readonly formBuilder: FormBuilder,
        private readonly changeDetector: ChangeDetectorRef
    ) {
        this.datasetFields = new Map<string, DatasetField>();
        // this.updateDatapointForm = this.formBuilder.group({});
    }

    ngOnChanges(): void {

        if (this.dataset !== undefined && this.datapointID !== undefined) {
            // this.dataset.fields = this.dataset.fields.filter(field => !field.isGenerated);
            this.dataset.fields.filter(field => !field.isGenerated).forEach(field => {
                if (field.isGenerated) {
                    return;
                }
                this.datasetFields.set(field.id, field);
            });
            const formObject = this.constructFormObject();
            this.updateDatapointForm = this.formBuilder.group(formObject);
            this.fetchDatapoint(this.datapointID);
            this.changeDetector.detectChanges();
        }
    }

    ngOnInit(): void {
        this.dataPointServiceState.emitOnUpdateDatapointInit();
    }

    get datasetFieldsArray() {
        return Array.from(this.datasetFields.values());
    }

    onUpdate() {
        this.dataPointServiceState.emitDatapointUpdate(true);
    }

    constructFormObject() {
        let formObject = {};
        this.datasetFields.forEach((field) => {
            formObject[field.id] = new FormControl(null);
        });
        return formObject;
    }

    updateDatapoint() {
        this.dpService.updateDatapoint(this.dataset.id, this.datapoint.id, this.constructUpdateDatapointRequest()).subscribe((datapoint) => {
            this.onUpdate();
        }, error => {
            console.log(error);
        });
    }

    constructUpdateDatapointRequest() {
        let datapoint = this.datapoint;
        datapoint.fields = this.getParsedFieldsFromUpdateForm();
        return datapoint;
    }

    getParsedFieldsFromUpdateForm(): Array<any> {
        let fields = [];
        this.datasetFields.forEach((datasetField) => {
            let field: any = {
                id: datasetField.id
            };
            if (datasetField.id === 'id') {
                return;
            }
            if (datasetField.type === DatasetFieldSpecificType.NUMBER_FIELD) {
                field.numberValue = this.updateDatapointForm.get(datasetField.id).value;
            } else if (datasetField.type === DatasetFieldSpecificType.DATE_FIELD) {
                field.datetimeValue = this.updateDatapointForm.get(datasetField.id).value;
            } else {
                field.textValue = this.updateDatapointForm.get(datasetField.id).value;
            }
            fields.push(field);
        });
        return fields;
    }

    public fetchDatapoint(datapointID: string) {
        this.loading = true;
        this.dpService.getDatapoint(datapointID, this.dataset.id).pipe(take(1)).subscribe((datapoint) => {
            // this.updateDraftForm = this.formBuilder.group(this.constructFormObject());
            this.datapoint = datapoint;
            let fields = datapoint.fields.filter(field => this.datasetFields.get(field.id) !== undefined);
            fields.forEach((field) => {
                this.updateDatapointForm.controls[field.id].setValue(field.textValue || field.datetimeValue || field.numberValue);
            });
            this.loading = false;
            this.changeDetector.detectChanges();
        }, (err) => {
            this.loading = false;
            this.onUpdate();
            console.log(err);
        });
    }

    setDataset($event: any) {
        this.datapointID = $event.datapointID;
        this.dataset = $event.dataset;
        this.ngOnChanges();
    }
}
