// tslint:disable:max-file-line-count
import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../data-access-layer/account/account.service';
import { Account } from '../../model/account/account';
import { Dataset } from '../../model/dataset/dataset';
import { Constants, DatapointsViewMode, DistanceUnit } from '../../constants';
import { AuthService } from '../../auth/auth.service';
import { RenderingOptions } from '../../model/dataset/rendering/rendering-options';
import { DatapointConverterType } from '../../model/dataset/rendering/datapoint-converter-options';
import { VisualizationType } from '../../model/dataset/rendering/visualization-options';
import { DatapointFilter } from '../../model/datapoint/filter/datapoint-filter';
import { DatapointProjection } from '../../model/datapoint/projection/datapoint-projection';
import { DatasetField } from '../../model/dataset/field/dataset-field';
import { DatasetFieldType } from '../../model/dataset/dataset-field-type';
import { DatapointsAggregateService } from '../../data-access-layer/datapoints/datapoints-aggregate.service';
import { OverlaysService } from '../../data-access-layer/global-overlays/overlays.service';
import { ActivateOverlayEvent } from '../../model/events/activate-overlay-event';
import { DatasetUtils } from '../../core/utils/dataset-utils';
import { MapStateService } from '../../map/map-state.service';
import { defaultTileSize } from '../../core/map/map.constants';
import { SortOrder } from '../../model/filter/draft-filter-sort';
import { SortDirection } from '@angular/material/sort/typings/sort-direction';
import { LinkDatasetService } from '../../data-access-layer/dataset/link-dataset.service';
import { DatasetService } from '../../data-access-layer/dataset/dataset.service';
import { PanelComponent } from '../../shared/panel/panel.component';
import { DatapointFilterObject } from '../../model/datapoint/datapoint-filter-object';
import { DatasetGeometryType } from '../../model/dataset/dataset-geometry-type';
import { ColorScaleType } from '../../model/dataset/rendering/color-scale-type';
import { SidePanelComponent } from '../../core/side-panel/side-panel.component';
import { MapDrawType } from '../../model/map/map-draw-type';
import { DateAdapter, MAT_DATE_FORMATS, MatDialog } from '@angular/material';
import { MapShape } from '../../model/map/map-shape';
import { FilterBarItem } from '../../model/datapoint/draft/table/filter-bar-item';
import { DatasetFieldStatistics } from '../../model/analytics/dataset-field-statistics';
import { MapStateActionEnum } from '../../model/map/map-state-action.enum';
import { MapThematicOverlayService } from '../../map/map-thematic-overlay.service';
import { Subscription } from 'rxjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../core/utils/format-datepicker';
import { GroupPanelComponent } from '../../dataset/group-panel/group-panel.component';
import { DatapointsTableComponent } from '../../dataset/datapoints/datapoints-table/datapoints-table.component';
import { DatapointsAnalyticsComponent } from '../../dataset/datapoints/datapoints-analytics/datapoints-analytics.component';
import { DatapointsPageStateService } from '../../dataset/datapoints/datapoints-page-state.service';
import { DatapointsFilterService } from '../../dataset/datapoints/datapoints-filter.service';
import { DatasetDownloadComponent } from '../../dataset/dataset-download/dataset-download.component';
import { MapDetails } from '../../map/map/info-box/map-info-box.component';
import { UserStateService } from '../../auth/user-state-service';
import { DatapointsProfilePanelComponent } from '../../dataset/datapoints/datapoints-location-profile/datapoints-profile-panel.component';
import { Type } from '../../model/geometry/geometry-filter-shape';
import { AttachmentUtils } from '../../core/utils/attachment-utils';
import { DownloadService } from '../../data-access-layer/download/download.service';
import { NotifService } from '../../core/notification/notif.service';
import { DownloadDatapointRequest } from '../../model/download/download-datapoint-request';
import { TilingUtils } from '../../core/utils/tiling-utils';
import { MapsAPILoader } from '@agm/core';
import { SidePanelService } from '../../shared/services/side-panel.service';
import { DatapointsService } from '../../data-access-layer/datapoints/datapoints.service';
import { SidePanels } from '../../shared/services/side-panel.helper';
import { DataPointsServiceState } from '../../shared/services/datapoints-service-state';

declare const google: any;

// Constants

const DEFAULT_SORT: any = {
    fields: [],
    datasetID: null,
    links: [],
    linkFields: []
    // geocodingAccuracyOrder: SortOrder.ASCENDANT,
    // statusOrder: SortOrder.ASCENDANT
};
const LIMIT = 100;
const DEFAULT_SKIP = 0;
const DIRECTION_MAP = { asc: SortOrder.ASCENDANT, desc: SortOrder.DESCENDANT };

export interface MatSortChange {
    active: string;
    direction: SortDirection;
}

type UpdateDatapointComponentType = {
    dataset: Dataset;
}


/**
 * This is the main component of the page with datapoints. It contains header, footer, groupNodes and overlays panels, the map component, and the table component.
 */
@Component({
    selector: 'map-overlay-datapoints',
    templateUrl: './overlay-datapoints.component.html',
    styleUrls: ['./overlay-datapoints.component.scss'],
    providers: [
        InfiniteScrollModule,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})

export class OverlayDatapointsComponent implements OnInit, OnDestroy {

    @ViewChild('groupsPanel', { static: false }) groupsPanel: GroupPanelComponent;
    @ViewChild('pagePanel', { static: false }) pagePanel: PanelComponent;
    @ViewChild('tableComponent', { static: false }) tableComponent: DatapointsTableComponent;
    @ViewChild('groupsPanel', { static: false }) groupsComponent: any;
    @ViewChild('analyticsPanel', { static: false }) analyticsPanel: SidePanelComponent;
    @ViewChild('analyticsComponent', { static: false }) analyticsComponent: DatapointsAnalyticsComponent;
    @ViewChild('locationProfilePanel', { static: false }) locationProfilePanel: DatapointsProfilePanelComponent;

    account: Account;
    dataset: Dataset;

    fieldsByIdsAndDataset: Map<string, Map<string, DatasetField>> = new Map();
    isMapView = true;

    filter: DatapointFilter;
    projection: DatapointProjection;
    renderingOptions: RenderingOptions;

    filterBarItems: FilterBarItem[] = []; // it can contain not populated fields yet, and they have suggestions
    datapointFilterObject: DatapointFilterObject;
    mapReady = false;
    groupsReady = false;
    limit;
    sort;
    skip;
    mapDatapointInfo: any;
    availableDatasetsInAccount: Dataset[] = [];

    isThematicMapEnabled = false;
    datasetFloatingFields: DatasetField[] = [];
    datapointsCount = 0;
    floatingFieldsValuesByFields: Map<DatasetField, number>;

    // projection and filter search strings
    projectionFieldSearchString: string;
    filterFieldSearchString: string;

    // projection and filter search filters
    filterFieldSearchFilter: (field: DatasetField) => boolean;
    projectionFieldSearchFilter: (field: DatasetField) => boolean;

    private readonly subscriptions: Subscription = new Subscription();
    public filterStatisticValuesString: string;
    filterStatisticValuesFilter: (value: string) => boolean;
    updateDatapointPanel: ComponentRef<SidePanelComponent>;
    trackByIndexFunction = (index: number): number => index;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly sidePanelService: SidePanelService,
        private readonly datapointServiceState: DataPointsServiceState,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly router: Router,
        private readonly mapStateService: MapStateService,
        private readonly accountService: AccountService,
        private readonly datasetService: DatasetService,
        private readonly linkDatasetService: LinkDatasetService,
        private readonly overlayService: OverlaysService,
        private readonly authService: AuthService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly datapointAggregateService: DatapointsAggregateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly mapThematicOverlayService: MapThematicOverlayService,
        readonly userStateService: UserStateService,
        public readonly downloadService: DownloadService,
        public readonly notifService: NotifService,
        public dialog: MatDialog,
        private readonly mapsAPILoader: MapsAPILoader
    ) {
        this.limit = LIMIT;
        this.sort = DEFAULT_SORT;
        this.skip = DEFAULT_SKIP;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.subscriptions = new Subscription();

        this.projectionFieldSearchString = '';
        this.filterFieldSearchString = '';

        this.filterFieldSearchFilter = (field: DatasetField) => {
            return field.name.toLowerCase().includes(this.filterFieldSearchString.toLowerCase());
        };
        this.projectionFieldSearchFilter = (field: DatasetField) => {
            return field.name.toLowerCase().includes(this.projectionFieldSearchString.toLowerCase());
        };
        this.filterStatisticValuesString = '';

        this.filterStatisticValuesFilter = (value: string) => {
            return value.toLowerCase().includes(this.filterStatisticValuesString.toLowerCase());
        };
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get MapDrawType() {
        return MapDrawType;
    }

    get MapShape() {
        return MapShape;
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    get DistanceUnit() {
        return DistanceUnit;
    }

    constructFilterObject(): DatapointFilterObject {
        const { dataset, limit, skip, sort, projection, filter } = this;
        sort.datasetID = this.dataset.id;
        let filterObject: DatapointFilterObject = { projection, filter, limit, skip, sort };

        return filterObject;
    }

    ngOnInit(): void {
        this.datapointsFilterService.clearFilterBar();
        this.datapointsPageStateService.reset();
        this.mapThematicOverlayService.reset();

        let datasetId = this.route.snapshot.paramMap.get('datasetId');
        let accountId = +this.route.snapshot.paramMap.get('accountId');
        let viewMode = this.route.snapshot.paramMap.get(Constants.VIEW_MODE_PARAM_NAME);

        this.availableDatasetsInAccount = this.userStateService.availableDatasetsInAccount(accountId);

        this.isMapView = !viewMode || viewMode.toUpperCase() === DatapointsViewMode.MAP;
        this.mapsAPILoader.load().then(() => {
            this.subscriptions.add(this.datasetService.getDataset(datasetId).subscribe((dataset) => {
                this.dataset = dataset;
                if (dataset.id === datasetId) {
                    if (dataset.geometryType === 'NONE' && viewMode.toLowerCase() === Constants.VIEW_MODE_MAP) {
                        this.switchView(Constants.VIEW_MODE_TABLE);
                    }
                    dataset.fields.forEach(field => {
                        field.isProjected = true;
                        field.isDisplayedInProjection = true;
                    });
                }
                this.datapointsPageStateService.storeDataset(dataset);
                this.fieldsByIdsAndDataset.set(dataset.id, new Map<string, DatasetField>(dataset.fields.map(field => [field.id, field])));
                this.datapointsPageStateService.setActiveDataset(this.dataset);
                this.initContext(this.dataset);
                this.fetchDatapoints();
                this.datasetFloatingFields = this.dataset.fields.filter(field => field.isFloating);
            }));
        });
        //
        this.subscriptions.add(this.datapointsFilterService.onFilterChange().subscribe(newFilter => {
            this.filter = newFilter;
            this.fetchDatapoints();
        }));

        this.subscriptions.add(this.datapointsFilterService.onFilterBarItemsChange().subscribe(filterBarItems => this.filterBarItems = filterBarItems));
    }

    private initializeShapesFromFilter() {
        let geometryFilter = this.datapointsFilterService.getActiveFilter().geometryFilter;
        if (geometryFilter) {
            geometryFilter.unionShapes.forEach(shape => {
                switch (shape.type) {
                    case Type.POLYGON:
                        let polygon = new google.maps.Polygon();
                        let paths: google.maps.LatLng[] = [];
                        let i = 0;
                        while (i < shape.coordinates.length) {
                            paths.push(new google.maps.LatLng(shape.coordinates[i + 1], shape.coordinates[i]));
                            i += 2;
                        }
                        polygon.setPath(paths);
                        this.mapStateService.insertShape(polygon, google.maps.drawing.OverlayType.POLYGON);

                        break;
                    case Type.CIRCLE:
                        let circle = new google.maps.Circle();
                        circle.setCenter(new google.maps.LatLng(shape.center.y, shape.center.x));
                        circle.setRadius(shape.radius);
                        this.mapStateService.insertShape(circle, google.maps.drawing.OverlayType.CIRCLE);

                        break;
                    case Type.BOUNDS:
                        let rectangle = new google.maps.Rectangle();
                        const bounds = {
                            south: shape.bottom,
                            west: shape.left,
                            north: shape.top,
                            east: shape.right
                        };
                        rectangle.setBounds(bounds);
                        this.mapStateService.insertShape(rectangle, google.maps.drawing.OverlayType.RECTANGLE);

                        break;

                }
            });

        }
    }

    onMapReady() {
        this.mapReady = true;
        if (this.groupsReady) {
            this.fetchDatapoints();
            this.initializeShapesFromFilter();
        }
    }

    onGroupsFetched(groupIDs: number[]) {
        this.groupsReady = true;
        this.filter.groups = groupIDs;
        if (this.mapReady) {
            this.fetchDatapoints();
        }
    }

    initContext(dataset: Dataset) {
        this.renderingOptions = {
            datasetStylingOptions: this.dataset.stylingOptions,
            converterOptions: { type: DatapointConverterType.NONE, datasetID: dataset.id },
            visualizationOptions: { type: VisualizationType.DEFAULT }
        };

        this.filter = {
            datasetID: dataset.id,
            groups: this.getGroups() || [],
            fields: [],
            links: []

        };
        this.datapointsFilterService.initFilter(this.filter);

        this.projection = {
            datasetID: dataset.id,
            fields: this.dataset.fields.map(field => field.id),
            links: []
        };
    }

    getGroups() {
        return this.groupsComponent.groups;
    }

    fetchDatapoints() {
        if (this.isMapView) {
            this.updatePortfolioLayer();
        } else {
            this.datapointFilterObject = this.constructFilterObject();
        }
    }


    onFilterMenuFieldClick(event: boolean, dataset: Dataset, field: DatasetField) {
        if (event) {
            this.datapointsFilterService.addFilterBarItem(field, dataset);
        } else { // need to remove the filter filterBarItem
            this.datapointsFilterService.removeFilterBarItem(dataset.id, field.id);
        }
    }

    applyFilters() {
        this.datapointsFilterService.updateFilter();
    }


    navigateToDrafts(): void {
        this.router.navigate([`private/global-overlays/${this.dataset.id}/drafts`]);
    }

    switchView(viewMode: string): void {
        const currentViewMode = this.isMapView ? DatapointsViewMode.MAP : DatapointsViewMode.TABLE;

        if (viewMode.toUpperCase() === currentViewMode) {
            return;
        }

        this.router.navigate([`../${viewMode}`], { relativeTo: this.route, replaceUrl: true });
    }

    switchApplication(datasetId: string): void {
        const groupId = this.route.snapshot.paramMap.get('groupId');
        const viewMode = this.route.snapshot.paramMap.get(Constants.VIEW_MODE_PARAM_NAME);

        if (datasetId === this.dataset.id) {
            return;
        }

        this.router.navigate([`private/account/${this.account.id}/dataset/${datasetId}/datapoints/${viewMode}`], { replaceUrl: true });
    }

    updatePortfolioLayer(): void {
        let token = this.authService.getToken();

        let projectionCopy = JSON.parse(JSON.stringify(this.projection));

        if (this.renderingOptions.converterOptions && this.renderingOptions.converterOptions.fieldID) {
            projectionCopy.fields = ['id', this.renderingOptions.converterOptions.fieldID];
        } else {
            projectionCopy.fields = ['id'];
        }

        let renderingOptionsJson = JSON.stringify(this.renderingOptions);
        let filterJson = JSON.stringify(this.filter);
        let projectionJson = JSON.stringify(projectionCopy);

        let layer = new google.maps.ImageMapType({
            getTileUrl: (coord, zoom) => `${TilingUtils.getUrlWithSubdomainForTile(coord, zoom)}/render/dataset/${this.dataset.id}/tile/${coord.x}/${coord.y}/${zoom}?token=${token}&filter=${filterJson}&projection=` +
                `${projectionJson}&renderingOptions=${renderingOptionsJson}`,
            minZoom: 1,
            maxZoom: 15,
            name: this.dataset.id,
            tileSize: new google.maps.Size(defaultTileSize.width, defaultTileSize.height)
        });
        this.mapStateService.updateOverlay(layer.name, layer);
    }

    prepareProjectionByRenderingOptions(datasetID: string, event: ActivateOverlayEvent): DatapointProjection {

        if (event.projection) {
            return event.projection;
        }

        let projection: DatapointProjection = { datasetID };
        let linkProjection: DatapointProjection;
        let fields: string[] = [];

        let haveLinkColorization = datasetID !== event.renderingOptions.converterOptions.datasetID;

        if (haveLinkColorization) {
            linkProjection = { datasetID: event.renderingOptions.converterOptions.datasetID };
        }
        switch (event.renderingOptions.datasetStylingOptions.type) {
            case ColorScaleType.CONSTANT:
            case ColorScaleType.FIXED:
            case ColorScaleType.GRADIENT:
            case ColorScaleType.INTERVAL:
                if (event.renderingOptions.converterOptions.fieldID) {
                    fields = [event.renderingOptions.converterOptions.fieldID];
                }
        }
        if (haveLinkColorization) {
            linkProjection.fields = fields;
            projection.links = [linkProjection];
        } else {
            projection.fields = fields;
        }
        return projection;
    }

    createNewOverlayLayer(overlay: Dataset, event: ActivateOverlayEvent) {
        let filter = this.prepareFilter(overlay, event);
        let filterJson = JSON.stringify(filter);
        let projection: DatapointProjection = this.prepareProjectionByRenderingOptions(overlay.id, event);
        let projectionJson = JSON.stringify(projection);
        let renderingOptionsJson = JSON.stringify(event.renderingOptions);
        let token = this.authService.getToken();

        let layer = new google.maps.ImageMapType({
            getTileUrl: (coord, zoom) => `${TilingUtils.getUrlWithSubdomainForTile(coord, zoom)}/render/dataset/${overlay.id}/tile/${coord.x}/${coord.y}/${zoom}?token=${token}&filter=` +
                `${filterJson}&projection=${projectionJson}&renderingOptions=${renderingOptionsJson}`,
            minZoom: 1,
            maxZoom: 15,
            opacity: overlay.geometryType === DatasetGeometryType.POINT ? 1 : 0.7,
            name: overlay.id,
            tileSize: new google.maps.Size(defaultTileSize.width, defaultTileSize.height)
        });
        return layer;
    }

    private prepareFilter(overlay: Dataset, event: ActivateOverlayEvent) {
        if (event.filter) {
            return event.filter;
        }

        let filter: DatapointFilter;
        if (overlay.id === this.dataset.id) {
            filter = this.filter;
        } else {
            filter = { datasetID: overlay.id };
        }
        return filter;
    }

    onUpdateOverlaySettings(event: ActivateOverlayEvent) {
        let renderingOptions = event.renderingOptions;
        let datasetID = event.datasetID;
        let overlay = this.datapointsPageStateService.getDataset(datasetID);

        if (this.dataset.id === datasetID) {
            this.renderingOptions = renderingOptions;
            // this.updatePortfolioLayer();
        }
        this.insertOverlay(overlay, event, MapStateActionEnum.UPDATE_OVERLAY);
    }

    drawShapes(shape: MapShape, type: string): void {
        const drawingType = type === MapDrawType.MEASURE ? MapDrawType.MEASURE : MapDrawType.FILTER;

        switch (shape) {
            case MapShape.CIRCLE:
                this.mapStateService.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE, drawingType);
                break;
            case MapShape.RECTANGLE:
                this.mapStateService.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE, drawingType);
                break;
            case MapShape.POLYGON:
                this.mapStateService.setDrawingMode(google.maps.drawing.OverlayType.POLYGON, drawingType);
                break;
            case MapShape.POLYLINE:
                this.mapStateService.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE, drawingType);
                break;
        }
    }

    removeShapes(type: string): void {
        const drawingType = type === MapDrawType.MEASURE ? MapDrawType.MEASURE : MapDrawType.FILTER;
        this.mapStateService.clearShapes(drawingType);
    }

    ngOnDestroy(): void {
        if (this.updateDatapointPanel) {
            this.updateDatapointPanel.instance.closePanel();
            this.updateDatapointPanel = null;
        }
        this.datapointsPageStateService.reset();
        this.mapThematicOverlayService.reset();
        this.subscriptions.unsubscribe();
    }


    open($event: string) {

    }

    onUpdated() {
        this.pagePanel.close();
        this.fetchDatapoints();
    }

    deleteSelected() {
        this.tableComponent.deleteSelected();
    }

    filterStatisticValues(id: string, $event: any) {
        const filterValue = $event.target.value;
        const filterBarItem = this.filterBarItems.find((currentFilterBarItem) => {
            return currentFilterBarItem.id === id;
        });
        const index = this.filterBarItems.indexOf(filterBarItem);
        this.filterBarItems[index].filteredStatisticValues = this.filterBarItems[index].statistics.values.filter((value) => {
            return value.toLowerCase().includes(filterValue.toLowerCase());
        });
    }

    removeFilterBarItem(filterBarItem: FilterBarItem) {
        filterBarItem.datasetField.selected = false;
        this.datapointsFilterService.removeFilterBarItem(filterBarItem.dataset.id, filterBarItem.datasetField.id);
    }

    removeFilterBar() {
        this.dataset.fields.forEach((datasetField) => {
            datasetField.selected = false;
        });
        this.filterBarItems = [];
        this.datapointsFilterService.clearFilterBar();
    }

    onProjectionChange(selected: any, dataset: Dataset, field: DatasetField) {
        if (selected) {
            this.addProjectionField(dataset, field);
        } else {
            this.removeProjectionField(dataset, field);
        }
    }

    addProjectionField(dataset, field) {
        if (dataset.id !== this.dataset.id) {
            let existingLinks = this.projection.links.find(link => link.datasetID === dataset.id);
            if (!existingLinks) {
                let link: DatapointProjection = {
                    datasetID: dataset.id,
                    fields: [field.id]
                };
                this.projection.links.push(link);
            } else {
                existingLinks.fields.push(field.id);
            }


        } else {
            this.projection.fields.push(field.id);
        }
        this.fetchDatapoints();
    }

    removeProjectionField(dataset, field) {
        if (dataset.id !== this.dataset.id) {
            let searchedProjectionLink = this.projection.links.find(link => link.datasetID === dataset.id);
            let index = this.projection.links.indexOf(searchedProjectionLink);
            searchedProjectionLink.fields.splice(searchedProjectionLink.fields.indexOf(field.id), 1);
            if (searchedProjectionLink.fields.length < 1) {
                this.projection.links.splice(index, 1);
            }
        } else {
            let searchedField = this.projection.fields.find(Field => Field === field.id);
            this.projection.fields.splice(this.projection.fields.indexOf(searchedField), 1);
        }
        this.fetchDatapoints();
    }


    openDownload(): void {
        const dialogRef = this.dialog.open(DatasetDownloadComponent, {
            width: '450px'
        });

        this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
            this.downloadDatapoints(result);
        }));
    }

    downloadDatapoints(result) {
        let request: DownloadDatapointRequest = {
            datapointRequest: {
                limit: this.tableComponent.selectAll ? -1 : this.datapointFilterObject.limit,
                projection: result.projection === 'CURRENT_FIELDS' ? this.datapointFilterObject.projection : this.getDefaultFilterObject().projection,
                skip: this.tableComponent.selectAll ? 0 : this.datapointFilterObject.skip,
                sort: result.sort === 'CURRENT_SORT' ? this.datapointFilterObject.sort : this.getDefaultFilterObject().sort
            },
            filter: result.filter === 'CURRENT_FILTER' ? this.filter : this.getDefaultFilterObject().filter,
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: result.fileType,
            // reportRequest: {},
        };
        const finalFileName = this.generateFileName(result.fileName.toString(), result.fileType.toLowerCase().toString());
        this.subscriptions.add(
            this.downloadService.downloadDatapoints(this.dataset.id, request, result.fileName).subscribe(
                response => {
                    AttachmentUtils.downloadFileWithName(response, finalFileName);
                }, error => this.notifService.error('Something went wrong during download')));
    }

    generateFileName(name: string, type: string): string {
        return name + '.' + type;
    }

    getDefaultFilterObject(): DatapointFilterObject {
        let projection = {
            datasetID: this.dataset.id,
            fields: this.dataset.fields.map(field => field.id),
            links: []
        };

        let filter = {
            datasetID: this.dataset.id,
            groups: this.getGroups() || [],
            fields: [],
            links: []

        };
        let sort = DEFAULT_SORT;
        sort.datasetID = this.dataset.id;
        let filterObject: DatapointFilterObject = { projection, filter, limit: LIMIT, skip: DEFAULT_SKIP, sort };
        return filterObject;
    }

    openDetails($event: MapDetails) {
        this.mapDatapointInfo = $event;
        if (!$event.refresh) {
            this.locationProfilePanel.onExpandedClick(true);
        }
    }

    openEditPanel($event: any) {
        if (this.updateDatapointPanel) {
            this.updateDatapointPanel.instance.showPanel();
        } else {
            this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
            this.updateDatapointPanel = this.sidePanelService.open<UpdateDatapointComponentType>(SidePanels.UPDATE_DATAPOINT,
                {
                    width: 400,
                    id: "update-datapoint-panel",
                    panelTitle: "Edit",
                },
                {
                    dataset: this.dataset,
                });

            this.datapointServiceState.onUpdateDataPointInit$.subscribe(() => {
                const { componentRef } = this.updateDatapointPanel.instance;
                if (componentRef) {
                    componentRef.instance.setDataset($event);
                }
            });

            this.datapointServiceState.datapointUpdate$.subscribe(() => {
                this.onUpdated()
                if (this.updateDatapointPanel) this.updateDatapointPanel.instance.hidePanel();
            });
        }
    }

    private insertOverlay(overlay: Dataset, event: ActivateOverlayEvent, mapStateAction: MapStateActionEnum) {
        let scaleType = event.renderingOptions.datasetStylingOptions.type;
        if (scaleType === ColorScaleType.FIXED || scaleType === ColorScaleType.GRADIENT) {
            let datasetID = event.renderingOptions.converterOptions.datasetID;
            let fieldID = event.renderingOptions.converterOptions.fieldID;
            let datasetFields = this.datapointsPageStateService.getDataset(datasetID).fields;
            let colorizationField = datasetFields.find(field => field.id === fieldID);
            this.subscriptions.add(this.datapointAggregateService.getDatapointsFieldStatistics(datasetID, colorizationField.id, { datasetID: datasetID })
                .subscribe(statistics => {
                    // this.renderingOptions.converterOptions.datasetID = this.selectedDatasetForColorization.id;
                    this.setFieldStatistics(colorizationField, event.renderingOptions, statistics);
                    this.putOverlayOnMap(mapStateAction, overlay.id, overlay, event);
                }));

        } else { // it is constant or interval. we don't need min and max
            if (scaleType === ColorScaleType.INTERVAL) {
                if (event.renderingOptions.visualizationOptions.type !== VisualizationType.THEMATIC_MAP) {
                    let datasetID = event.renderingOptions.converterOptions.datasetID;
                    let fieldID = event.renderingOptions.converterOptions.fieldID;
                    let colorizationField = this.datapointsPageStateService.getDataset(datasetID).fields.find(field => field.id === fieldID);
                    this.setFieldStatisticsForInterval(colorizationField, event.renderingOptions);
                }
            }
            this.putOverlayOnMap(mapStateAction, overlay.id, overlay, event);
        }
    }

    private setFieldStatistics(colorizationField: DatasetField, renderingOptions: RenderingOptions, statistics: DatasetFieldStatistics) {
        switch (DatasetUtils.getDatasetFieldType(colorizationField)) {
            case DatasetFieldType.NUMBER:
                renderingOptions.converterOptions.type = DatapointConverterType.NUMBER_FIELD;
                renderingOptions.converterOptions.maxNumber = statistics.maxValue;
                renderingOptions.converterOptions.minNumber = statistics.minValue;
                break;
            case DatasetFieldType.TEXT:
                renderingOptions.converterOptions.type = DatapointConverterType.TEXT_FIELD;
                renderingOptions.converterOptions.values = statistics.values;
                break;
            case DatasetFieldType.DATE_TIME:
                renderingOptions.converterOptions.type = DatapointConverterType.DATE_FIELD;
                renderingOptions.converterOptions.maxDate = statistics.maxValue;
                renderingOptions.converterOptions.minDate = statistics.minValue;
                break;
        }
    }

    private setFieldStatisticsForInterval(colorizationField: DatasetField, renderingOptions: RenderingOptions) {
        // min, max are 0 because they are not needed for interval scale type
        switch (DatasetUtils.getDatasetFieldType(colorizationField)) {
            case DatasetFieldType.NUMBER:
                renderingOptions.converterOptions.type = DatapointConverterType.NUMBER_FIELD;
                renderingOptions.converterOptions.maxNumber = 0;
                renderingOptions.converterOptions.minNumber = 0;
                break;
            case DatasetFieldType.TEXT:
                renderingOptions.converterOptions.type = DatapointConverterType.TEXT_FIELD;
                renderingOptions.converterOptions.values = [];
                break;
            case DatasetFieldType.DATE_TIME:
                renderingOptions.converterOptions.type = DatapointConverterType.DATE_FIELD;
                renderingOptions.converterOptions.maxDate = 0;
                renderingOptions.converterOptions.minDate = 0;
                break;
        }
    }

    private putOverlayOnMap(mapStateAction: MapStateActionEnum, datasetID: string, overlay: Dataset, event: ActivateOverlayEvent) {
        switch (mapStateAction) {
            case MapStateActionEnum.UPDATE_OVERLAY:
                this.mapStateService.updateOverlay(datasetID, this.createNewOverlayLayer(overlay, event));
                break;
            case MapStateActionEnum.INSERT_OVERLAY:
                this.mapStateService.insertOverlay(datasetID, this.createNewOverlayLayer(overlay, event));
                break;
        }
    }

}



