import { Component, OnInit} from '@angular/core';
import { MatTableDataSource} from '@angular/material';
import {OverlaysService} from '../../data-access-layer/global-overlays/overlays.service';
import {Dataset} from '../../model/dataset/dataset';
import {ActivatedRoute} from '@angular/router';
import {forkJoin} from 'rxjs';
import {UserStateService} from '../../auth/user-state-service';
import {Account} from '../../model/account/account';
import {AccountService} from '../../data-access-layer/account/account.service';
import {DatapointsPageStateService} from '../../dataset/datapoints/datapoints-page-state.service';
import {NotifService} from '../../core/notification/notif.service';

@Component({
    selector: 'map-account-overlays',
    templateUrl: './account-overlays.component.html',
    styleUrls: ['./account-overlays.component.scss']
})
export class AccountOverlaysComponent implements OnInit {
    accountId: number;
    currentAccount: Account;
    isTableDataLoading: boolean;
    globalOverlays: Dataset[];
    accountOverlays: Dataset[];
    overlaysDataSource: MatTableDataSource<Dataset>;
    currentDisplayedColumns: string[] = ['Name', 'DatasetID', 'Actions'];
    allSelected: boolean;
    breadCrumbs = ['Home', 'Accounts', 'Settings'];
    isSuperadmin: boolean;
    get componentName() {
        return this.constructor.name;
    }

    constructor(
        private readonly overlayService: OverlaysService,
        private readonly userStateService: UserStateService,
        private readonly route: ActivatedRoute,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly accountService: AccountService,
        private readonly notifService: NotifService,
    ) {
        this.globalOverlays = [];
        this.accountOverlays = [];
    }

    ngOnInit() {
        this.accountId = +this.route.snapshot.paramMap.get('accountId');
        this.init();
        this.isSuperadmin = this.userStateService.isSuperadmin;
    }

    applyFilter(filterValue: string): void {
        this.overlaysDataSource.filter = filterValue.trim().toLowerCase();
    }

    fetchOverlays(): void {
        forkJoin(
            this.overlayService.getAllGlobalOverlays(),
            this.overlayService.getAccountGlobalOverlays(this.accountId)
        ).subscribe(([globalOverlays, accountOverlays]: [Dataset[], Dataset[]]) => {
            this.globalOverlays = globalOverlays;
            this.accountOverlays = accountOverlays;
            if (this.userStateService.isSuperadmin) {
                this.overlaysDataSource = new MatTableDataSource(globalOverlays);
            } else if (this.userStateService.isAccountAdmin(this.accountId)) {
                this.overlaysDataSource = new MatTableDataSource(accountOverlays);
            }
            this.overlaysDataSource.data.forEach(overlay => {
                overlay.hasOverlay = this.accountHasOverlay(overlay.id);
            });
            this.checkForAllSelected();
        });
    }

    accountHasOverlay(overlayId: string): boolean {
        const overlay = this.accountOverlays.find(accountOverlay => accountOverlay.id === overlayId);
        return overlay ? true : false;
    }

    asssignOverlay(overlay: Dataset): void {
        this.accountOverlays.push(overlay);
        overlay.hasOverlay = true;
        // this.overlayService.getAccountsForGlobalOverlay(overlay.id)
        //     .pipe(
        //         map(accounts => accounts.map(account => account.id))
        //     )
        //     .subscribe(accountIds => {
        //         console.log(accountIds);
        //         accountIds = accountIds.filter(accountId => accountId !== this.currentAccount.id);
        //         console.log(accountIds);
        //         accountIds.push(this.accountId);
        //         this.overlayService.assignAccountsToGlobalOverlay(accountIds, overlay.id).subscribe((res) => {
        //             this.updateAccountOverlays();
        //             this.notifService.success('Overlay applied');
        //             },
        //             (err) => {
        //             // here it's a 500 internal error occur
        //             console.log(err);
        //             });
        //     });
    }

    checkForAllSelected() {
        let overlayUnselected;
        overlayUnselected = this.overlaysDataSource.data.find( overlay => overlay.hasOverlay === false);
        !overlayUnselected  ? this.allSelected =  true : this.allSelected = false;
    }

    unasssignOverlay(overlay: Dataset): void {
        this.accountOverlays = this.accountOverlays.filter(accountOverlay => accountOverlay.id !== overlay.id);
        overlay.hasOverlay = false;
        // this.overlayService.getAccountsForGlobalOverlay(overlay.id)
        //     .pipe(
        //         map(accounts => accounts.map(account => account.id))
        //     )
        //     .subscribe(accountIds => {
        //         accountIds = accountIds.filter(accountId => accountId !== this.accountId);
        //         this.overlayService.assignAccountsToGlobalOverlay(accountIds, overlay.id).subscribe(() => this.updateAccountOverlays());
        //     });
    }

    // updateAccountOverlays(): void {
    //     this.overlayService.getAccountGlobalOverlays(this.accountId).subscribe(accountOverlays => this.accountOverlays = accountOverlays);
    // }

    applyOverlays() {
        let datasetIDs = [];
        this.overlaysDataSource.data.forEach((overlay)=> {
            if(overlay.hasOverlay) {
                datasetIDs.push(overlay.id);
            }
        });
        this.overlayService.assignGlobalOverlaysToAccount({accountID: this.accountId, datasetIDs}).subscribe(() => {
            this.init();
            this.notifService.success('Changes applied.');
        });

    }

    private init(): void {
        if (this.accountId) {
            this.accountService.getAccount(this.accountId).subscribe((account) => {
                this.currentAccount = account;
                this.datapointsPageStateService.activeAccount = account;
            });
            this.fetchOverlays();
            if (!this.userStateService.isSuperadmin) {
                this.currentDisplayedColumns.splice(this.currentDisplayedColumns.indexOf('Actions'), 1);
            }
        }
    }

    toggleOverlay(event: boolean, overlay: Dataset) {
        if (event) {
            this.asssignOverlay(overlay);
        } else {
            this.unasssignOverlay(overlay);
        }
        this.checkForAllSelected();
    }

    toggleAll() {
        this.overlaysDataSource.data.forEach((overlay)=> {
            overlay.hasOverlay = this.allSelected;
        });
    }
}
