import { DatapointsClimateChartService } from "src/app/dataset/datapoints/climate-charts/datapoints-climate-chart.service";
import jsPDF from "jspdf";
import { NotifService } from "../notification/notif.service";

export class Climate {

    public static downloadOverlayProfileChart(datapointsClimateChartService: DatapointsClimateChartService, notifService: NotifService, fileName: string) {
        const graphTypes = datapointsClimateChartService.chartGraphicsConfiguration.graphType;
        const format = datapointsClimateChartService.chartGraphicsConfiguration.format;
        let options = {orientation: 'p', unit: 'mm'};
        const doc = new jsPDF(options);
        doc.setFont("arial", "bold");
        doc.setFontSize(14);
        let counter = 1;
        let isAddPage: boolean = false;
        for (const key in graphTypes) {
            if (Object.prototype.hasOwnProperty.call(graphTypes, key)) {
                const element = graphTypes[key];
                if (element.instance !== null && element.instance !== undefined) {
                    const img = new Image();
                    img.src = element.instance.getDataURL({
                        pixelRatio: 2,
                        backgroundColor: '#fff'
                    });
                    if (counter % 2 !== 0) {
                        if (isAddPage) {
                            doc.addPage("landscape");
                            isAddPage = false;
                        }
                        doc.rect(10, 24 , 170, 100);
                        doc.text(5, 20, element.title);
                        doc.addImage(img, format, 15, 30, 150, 90, "alias"+counter, 'SLOW');
                    } else {
                        isAddPage = true;
                        doc.rect(10, 144 , 170, 100);
                        doc.text(element.title, 5, 140);
                        doc.addImage(img, format, 15, 150, 150, 90, "alias"+counter, 'SLOW');
                    }
                    counter++;
                }
            }
        }
        if (counter > 1) {
            doc.save(fileName + ".pdf");
        } else {
            notifService.success('No data found for chart.');
        }
    }

    public static resetGraphTypes(datapointsClimateChartService: DatapointsClimateChartService) {
        const graphTypes = datapointsClimateChartService.chartGraphicsConfiguration.graphType;
        for (const key in graphTypes) {
            if (Object.prototype.hasOwnProperty.call(graphTypes, key)) {
                const element = graphTypes[key];
                element.instance = null;
            }
        }
    }
}