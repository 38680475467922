import { Component, ContentChildren, ViewChild, QueryList, ElementRef, AfterViewInit, OnDestroy} from '@angular/core';
import {SliderItemDirective} from './slider-item.directive';
import {Subscription} from 'rxjs';

@Component({
  selector: 'map-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements AfterViewInit, OnDestroy {
    @ContentChildren(SliderItemDirective, { read: ElementRef }) items: QueryList<ElementRef<HTMLDivElement>>;
    @ViewChild('slides', {static: false}) slidesContainer: ElementRef<HTMLDivElement>;
    showControls = false;
    scrolledToLast = false;
    scrolledToFirst = true;
    private itemsSubscribtion: Subscription;

    constructor() { }

    ngAfterViewInit(): void {
        this.itemsSubscribtion = this.items.changes.subscribe(() => {
            this.showControls =  (( this.slidesContainer.nativeElement.scrollWidth - this.slidesContainer.nativeElement.offsetWidth) > 0);
        });
    }

    onClickLeft(): void {
        this.items.first.nativeElement.scrollIntoView();
        this.scrolledToFirst = true;
        this.scrolledToLast = false;
    }

    onClickRight(): void {
        this.items.last.nativeElement.scrollIntoView();
        this.scrolledToFirst = false;
        this.scrolledToLast = true;
    }

    ngOnDestroy(): void {
        this.itemsSubscribtion.unsubscribe();
    }

}
