export class NumberUtils {

    public static formatNumber(value: any): string {
        if (value !== null && value !== undefined) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else if (value === 0) {
            return '0';
        } else {
            return null;
        }
    }
}