export enum AerisDescriptions {
    AIR_QUALITY = 'Air Quality Index which provides pollutant data around the world',
    EARTHQUAKES = 'Global earthquake data including magnitude, depth, and type',
    FIRES = 'Wildfires with data including cause, area',
    RIVERS = 'Lake and river gauges collected and distributed by NOAA’s AHPS department',
    RIVERS_GAUGES = 'Enhanced data from rivers and lake gauges',
    STORMCELLS = 'Observed storm cells',
    CLIMATE_NORMALS = 'The 30-year climate normals collected by NOAA',
    OBSERVATIONS = 'Current conditions provided by the closest weather station',
    STORM_REPORTS = 'Local storm reports transmitted by the National Weather Service',
    TIDES = 'Tide levels',
    TROPICALCYCLONES='Provides information on active tropical cyclones across the globe',

    // aeris data without locations for markers
    ALERTS_SUMMARY = 'Summary of active weather events/alerts',
    LIGHTNING_SUMMARY = 'A summary of lightning strikes that took place across the globe'
}
