import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import {GroupService} from 'src/app/data-access-layer/groups/group.service';
import {Dataset} from 'src/app/model/dataset/dataset';
import {GroupTreeNode} from 'src/app/model/overlay/group/group-with-overlays-tree-node';
import {DatapointsPageStateService} from '../datapoints/datapoints-page-state.service';
import {DatapointsFilterService} from '../datapoints/datapoints-filter.service';
import {isEnabled} from '../../../environments/environment';
import {NotifService} from '../../core/notification/notif.service';
import {Functionalities} from '../../../environments/app-functionalities';

const IS_PART_OF = Functionalities.GROUPS;

@Component({
    selector: 'map-group-panel',
    templateUrl: './group-panel.component.html',
    styleUrls: ['./group-panel.component.scss']
})
export class GroupPanelComponent implements OnChanges {
    isExpanded = false;
    groupNodes: GroupTreeNode[] = [];
    isEnabled = isEnabled(IS_PART_OF);
    @Input() disabled = false;
    private ignoreClickOutside: boolean;

    @Input() dataset: Dataset;
    @Output() groupsReady = new EventEmitter<number[]>();

    constructor(
        private readonly groupService: GroupService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly datapointsPageState: DatapointsPageStateService,
        private readonly notifService: NotifService,
        private readonly eRef: ElementRef,
        private readonly changeDetector: ChangeDetectorRef
    ) {
    }

    @HostListener('document:click', ['$event'])
    clickOut(event) {
        if (!this.eRef.nativeElement.contains(event.target) && this.isExpanded && !this.ignoreClickOutside) {
            this.isExpanded = false;
            event.stopPropagation();
        }
        this.ignoreClickOutside = false;
    }

    ngOnChanges() {
        if (this.dataset) {
            this.groupService.getGroupsAsTree(this.dataset.id, this.dataset.accountID).subscribe(groups => {
                this.groupNodes = groups;
                // this.groupNodes["0"].isOpen = true
                // this.groupNodes["0"].isSelected = true
                const selectedGroups = [];
                this.groupNodes.forEach((groupNode, i) => { // check only root groups
                    selectedGroups.push(groupNode.value.id);
                    groupNode.isSelected = true;
                    this.recursiveOperation(groupNode, gr => gr.isSelected = true);
                });
                this.emitGroupsUpdatedEvent(this.extractSelectedGroupsIds());
                // this.groupsReady.emit(selectedGroups);
                this.datapointsPageState.activeGroups = selectedGroups;
            });
        }
    }

    open(): void {
        this.isExpanded = true;
        this.ignoreClickOutside = true;
    }

    emitGroupsUpdatedEvent(groups: number[]): void {
        this.groupsReady.emit(groups);
    }

    groupWasClicked(node: GroupTreeNode, event: any) {
        // TODO make a delay between events
        let operation: (group: GroupTreeNode) => void; // will be applied recursively
        operation = groupNode => {
            groupNode.isSelected = event.checked;
            groupNode.children.forEach(child => {
                child.isSelected = event.checked;
                // if (event.checked && !child.isSelected) { // we must activate it
                //     child.isSelected = true;
                // } else if (!event.checked && child.isSelected) { // disableOverlay it
                //     child.isSelected = false;
                // }
            });
        };
        this.recursiveOperation(node, operation);

        this.publishSelectedGroups();
    }

    publishSelectedGroups() {
        let newGroups = this.extractSelectedGroupsIds();
        if (newGroups.length > 0) {
            this.datapointsFilterService.updateGroups(newGroups);
            this.datapointsPageState.activeGroups = newGroups;
            this.emitGroupsUpdatedEvent(newGroups);
        } else {
            this.notifService.error('Please select at least one group');
        }

    }

    private extractSelectedGroupsIds(): number[] {
        const selectedGroups = [];
        this.groupNodes.forEach(rootGroupNode => {
            this.recursiveOperation(rootGroupNode, groupNode => {
                if (groupNode.isSelected) {
                    selectedGroups.push(groupNode.value.id);
                }
            });
        });
        return selectedGroups;
    }

    public get groupsIds() {
        const groupIds = [];
        this.groupNodes.forEach(rootGroupNode => {
            this.recursiveOperation(rootGroupNode, groupNode => {
                groupIds.push(groupNode.value.id);
            });
        });
        return groupIds;
    }

    public get selectedGroupsIds() {
        return this.extractSelectedGroupsIds();
    }

    recursiveOperation(groupNode: GroupTreeNode, operation: (node: GroupTreeNode) => void) {
        operation(groupNode);
        groupNode.children.forEach(child => this.recursiveOperation(child, operation));
        // this.changeEvent();

    }

    markGroupsAsSelected(selectedGroups: number[]) {
        this.groupNodes.forEach(rootGroupNode => {
            this.recursiveOperation(rootGroupNode, gr => gr.isSelected = selectedGroups.includes(gr.value.id));
        });
        // this.emitGroupsUpdatedEvent(selectedGroups);
        this.datapointsPageState.activeGroups = selectedGroups;
    }

}


