import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeographicalRegion} from '../model/geographical-region/geographical-region';

@Injectable({
    providedIn: 'root'
})
export class GeographicalRegionsService {

    constructor(private readonly http: HttpClient) {
    }

    findRegions(name: string, skip: number, limit: number): Observable<GeographicalRegion[]> {
        let params = new HttpParams();
        if (name) {
            params = params.set('name', name);
        }
        if (skip) {
            params = params.set('skip', skip.toString());
        }
        if (limit) {
            params = params.set('limit', limit.toString());
        }
        return this.http.get<GeographicalRegion[]>('/geographical-zones', {params: params});
    }

    getRegion(regionID: number): Observable<GeographicalRegion> {
        return this.http.get<GeographicalRegion>(`/geographical-zones/get/${regionID}`);
    }

    getRegionsCount(name: string): Observable<number> {
        let params = new HttpParams();
        if (name) {
            params = params.set('name', name);
        }
        return this.http.get<number>('/geographical-zones/count', {params: params});
    }

    updateRegion(regionID: number, name: string): Observable<GeographicalRegion> {
        let params = new HttpParams()
            .set('name', name);
        return this.http.put<GeographicalRegion>(`/geographical-zones/${regionID}`, {}, {params: params});
    }

    deleteRegion(regionID: number): Observable<GeographicalRegion> {
        return this.http.delete<GeographicalRegion>(`/geographical-zones/${regionID}`);
    }

    deleteRegionsByName(name: string): Observable<GeographicalRegion> {
        let params = new HttpParams();
        if (name) {
            params = params.set('name', name);
        }
        return this.http.delete<GeographicalRegion>('/geographical-zones', {params: params});
    }

    uploadZonesFile(files: any): Observable<any> {
        const formData = new FormData();
        for (const file of files) {
            formData.append('file', file);
        }
        return this.http.post(`/geographical-zones`, formData);
    }
}
