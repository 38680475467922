import { Component, Input, OnInit } from '@angular/core';
import { UserStateService } from 'src/app/auth/user-state-service';

@Component({
  selector: 'map-overlay-tabs',
  templateUrl: './overlay-tabs.component.html',
  styleUrls: ['./overlay-tabs.component.scss']
})
export class OverlayTabsComponent implements OnInit {

  @Input () componentName: string;
  @Input () breadCrumbs = [];

  accountLinks  = [];
  constructor(
    readonly userStateService: UserStateService
  ) { }

  ngOnInit() {
    if (this.userStateService.isSuperadmin) {
      this.accountLinks.push({
          path: 'global-overlays',
          name: 'Global Overlays',
          component: 'OverlaysComponent',
          active: false,
          breadCrumbs: ['Home', 'Overlays']
      });
      this.accountLinks.push({
        path: 'external-overlays',
        name: 'External Overlays',
        component: 'ExternalOverlaysComponent',
        active: false,
        breadCrumbs: ['Home', 'Overlays']
      });
      this.accountLinks.push({
        path: 'accounts',
        name: 'Account',
        component: 'AccountComponent',
        active: false,
        breadCrumbs: ['Home', 'Account']
      });
  }
  }

}
