import { DatasetGeometryType } from '../../model/dataset/dataset-geometry-type';
import { LatLngLiteral } from '@agm/core';
import { Rectangle } from '../geometry/rectangle';
import { BoundsFilter } from '../../model/geometry/bounds-filter';
import { Type } from '../../model/geometry/geometry-filter-shape';

export class GISUtils {

    private static readonly MAP_CLICK_ZONES_PIXEL_TOLERANCE = 5;
    private static readonly MAP_CLICK_POINTS_PIXEL_TOLERANCE = 15;

    public static milesToKm(miles: number): number {
        return miles * 1.60934;
    }

    public static milesToFoot(feet: number): number {
        return feet / 3280.84;
    }

    public static milesToMeter(meter: number): number {
        return meter / 1000;
    }

    /**
     * Yields a coordinate pair (point) - an array [latitude, longitude] - at a given location which is a given distance
     * away from the original point. The distance is determined by the Tolerance which is determined based on the zoom-level
     * at which the initial point was generated. This is useful for creating a radius from an original point - such as a
     * mouse-click on a map - to generate a circular area of reference, for example.
     */
    static getLatitudeTolerancePoint(zoom, latLng) {
        let lat = null;
        let lng = null;
        if (!(latLng instanceof Object)) {
            throw new Error(`Invalid Argument: [latLng] must be an Object or an Array`);
        }
        if (latLng instanceof Array) {
            lat = latLng[0];
            lng = latLng[1];
        } else {
            lat = latLng.lat();
            lng = latLng.lng();
        }
        const toleranceDegrees = GISUtils.calculateMapClickToleranceDegrees(zoom, DatasetGeometryType.POINT);
        const outwardLat = lat as number + toleranceDegrees;
        return [outwardLat, lng];
    }

    /**
     * Generates a set of coordinates for each corner of a rectangle of a given size around a central datapoint. The size
     * of the rectangle is determined by the supplied zoom-level, and the four corners are equidistant diagonally from the
     * supplied latitude/longitude point at the center.
     */
    static getRectangleTolerancePoints(zoom, latLng: LatLngLiteral): BoundsFilter {
        // let lat = latLng.lat;
        // let lng = latLng.lng;
        // const toleranceDegrees = GISUtils.calculateMapClickToleranceDegrees(zoom, DatasetGeometryType.POINT);
        // const top = {y: lat + toleranceDegrees, x: lng - toleranceDegrees};
        // const bottom = {y: lat + toleranceDegrees, x: lng + toleranceDegrees};
        // const left = {y: lat - toleranceDegrees, x: lng - toleranceDegrees};
        // const right = {y: lat - toleranceDegrees, x: lng + toleranceDegrees};
        // return {top, bottom, left, right, type: Type.BOUNDS};
        return null;
    }

    public static calculateMapClickToleranceDegrees(zoom, geometryType: DatasetGeometryType): number {
        const tolerance = geometryType === DatasetGeometryType.POINT ? GISUtils.MAP_CLICK_POINTS_PIXEL_TOLERANCE : GISUtils.MAP_CLICK_ZONES_PIXEL_TOLERANCE;
        return (tolerance / (Math.pow(2, zoom) * 256)) * 360;
    }

    static getMeasuredMeters(shape, type) {
        switch (type) {
            case FILTER_TO_LEAFLET_SHAPES_ENUM.CIRCLE: {
                const r = shape.getRadius();
                return Math.PI * Math.pow(r, 2);
            }
            case FILTER_TO_LEAFLET_SHAPES_ENUM.BOUNDS: {
                const bounds = shape.getBounds();
                const sw = bounds.getSouthWest();
                const ne = bounds.getNorthEast();
                const southWest = new google.maps.LatLng(sw.lat(), sw.lng());
                const northEast = new google.maps.LatLng(ne.lat(), ne.lng());
                const southEast = new google.maps.LatLng(sw.lat(), ne.lng());
                const northWest = new google.maps.LatLng(ne.lat(), sw.lng());
                return google.maps.geometry.spherical.computeArea([northEast, northWest, southWest, southEast]);
            }
            case FILTER_TO_LEAFLET_SHAPES_ENUM.POLYGON: {
                return google.maps.geometry.spherical.computeArea(shape.getPath());
            }
            case FILTER_TO_LEAFLET_SHAPES_ENUM.POLYLINE: {
                return google.maps.geometry.spherical.computeLength(shape.getPath());
            }
            default:
                return console.warn(`shape of type [${ type }] needs to be handled for linear distance`);
        }
    }

    /**
     * Takes a Path of coordinate points (represented as an array of coordinate number pairs) and calculates the total
     * length/distance measured in meters.
     */
    static getLinearDistanceMeters(linePoints) {
        if (!Array.isArray(linePoints) || linePoints.length === 1) {
            return 0;
        }

        let currentPoint = linePoints[0];
        let distance = 0.00;
        for (let i = 1; i <= linePoints.length - 1; i++) {
            const nextPoint = linePoints[i];
            distance += currentPoint.distanceTo(nextPoint);
            currentPoint = nextPoint;
        }
        return distance;
    }


}

const FILTER_TO_LEAFLET_SHAPES_ENUM = {
    CIRCLE: 'circle',
    BOUNDS: 'rectangle',
    POLYGON: 'polygon',
    POLYLINE: 'polyline'
};
