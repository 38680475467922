import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'map-filter-input',
    templateUrl: './filter-input.component.html',
    styleUrls: ['./filter-input.component.scss']
})
export class FilterInputComponent {
    @Output() keyup = new EventEmitter();
    @ViewChild('inputElement', {static: true}) inputElement: ElementRef;

    constructor() {
    }

}
