import {ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {WorkspaceItemType} from '../../../model/workspace/workspace-item-type';
import {WorkspaceItemService} from '../../../data-access-layer/workspace-item/workspace-item.service';
import {CreateWorkspaceItemRequest} from '../../../model/workspace/create-workspace-item-request';
import {WorkspaceItemAccess} from '../../../model/workspace/workspace-item-access';
import {MatDialog} from '@angular/material';
import {NotifService} from '../../../core/notification/notif.service';
import {UpdateWorkspaceItemRequest} from '../../../model/workspace/update-workspace-item-request';
import {WorkspaceItem} from '../../../model/workspace/workspace-item';
import {UserStateService} from '../../../auth/user-state-service';
import {MatMenuTrigger} from '@angular/material/menu';
import {DatapointsPageStateService} from '../../datapoints/datapoints-page-state.service';
import {DatasetField} from '../../../model/dataset/field/dataset-field';
import {DatasetFieldType} from '../../../model/dataset/dataset-field-type';
import {MatInput} from '@angular/material/input';
import {DatasetFieldScope} from '../../../model/dataset/dataset-field-scope';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-workspace-item-dialog',
    templateUrl: './workspace-item-dialog.component.html',
    styleUrls: ['./workspace-item-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WorkspaceItemDialogComponent {

    private readonly BRACKET_OPEN = '[';
    private readonly BRACKET_CIRCULAR_OPEN = '(';
    private readonly BRACKET_CLOSED = ']';
    private NAME_MAX_LENGTH = 50;

    @ViewChild('dialogContent', {static: false}) modalTemplate: TemplateRef<any>;
    @ViewChild('fieldsMenuTrigger', {static: false}) fieldsMenuTrigger: MatMenuTrigger;
    @ViewChild('formulaInput', {static: false}) formulaInput: MatInput;

    @Input() datasetId: string;
    @Input() accountId: number;
    @Output() itemSubmitted = new EventEmitter<WorkspaceItemType>();

    data: any;
    name: string;
    formula: string;
    access = WorkspaceItemAccess.MEMBER;
    type: WorkspaceItemType;
    isReplace: boolean;
    principalIsAdmin = false;
    isView: boolean = false;

    workspaceItems: WorkspaceItem[] = [];
    selectedWorkspaceItem: WorkspaceItem;

    numericDatasetFields: DatasetField[];
    fieldsFilter: string;
    workspaceItemsCreateForm: FormGroup;

    constructor(private readonly dialog: MatDialog,
                private readonly cd: ChangeDetectorRef,
                private readonly workspaceItemService: WorkspaceItemService,
                private readonly datapointsPageStateService: DatapointsPageStateService,
                private readonly notifService: NotifService,
                private readonly userStateService: UserStateService, private fb: FormBuilder) {
        this.workspaceItemsCreateForm =  this.fb.group({
            name: ['', Validators.maxLength(this.NAME_MAX_LENGTH)]
        });
    }

    open(data: any, workspaceItemType: WorkspaceItemType, isReplace: boolean, isView: boolean = false) {
        // console.log(data)
        this.data = data;
        this.isView = isView;
        this.selectedWorkspaceItem = null;
        this.isReplace = isReplace;
        this.type = workspaceItemType;
        this.workspaceItems = [];
        this.principalIsAdmin = this.userStateService.isSuperadmin || this.userStateService.isAccountAdmin(this.accountId);
        if (this.principalIsAdmin) {
            this.workspaceItemService.getAllWorkspaceItemsForUser(this.datasetId, workspaceItemType).subscribe(
                items => {
                    this.workspaceItems = items;
                    this.dialog.open(this.modalTemplate);
                }
            );
        } else {
            this.workspaceItemService.getWorkspaceItemsForMember(this.datasetId, workspaceItemType).subscribe(
                items => {
                    this.workspaceItems = items;
                    this.dialog.open(this.modalTemplate);
                }
            );
        }
        if (workspaceItemType === WorkspaceItemType.DASHBOARD) { 
            this.access = WorkspaceItemAccess.GLOBAL;
        }
        if (workspaceItemType === WorkspaceItemType.FORMULA) {
            this.numericDatasetFields = this.datapointsPageStateService.getActiveDataset().fields
                .filter(f => f.baseType === DatasetFieldType.NUMBER)
                .filter(f => f.scope === DatasetFieldScope.INTERNAL);
                this.formula='';

                this.access = !this.userStateService.isAccountAdmin(this.accountId) ? WorkspaceItemAccess.MEMBER : data.access;
                if (data.data !== undefined) {
                    this.selectedWorkspaceItem = data;
                    this.name = data.name;
                    // this.formula = JSON.parse(data.data).formula;
                    this.formula = this.prepareFormula(JSON.parse(data.data).formula);

                }

        } else {
            this.formula='';
        }
    }

    prepareFormula(formula) {
        let splitFormula = formula.split(/[ .:;?!~,`"&|()<>*+{}\[\]\r\n/\\]+/);
        let validIds = splitFormula.filter(element => element.includes("FIELD_"));
            validIds.forEach(element => {
            let temp_element_array = element.split('_');
            let datasetField = this.numericDatasetFields.find(filterElement =>  filterElement.id == temp_element_array[1]);
            formula = this.replaceFormula(formula, element,datasetField.name);
        });
        return formula;
    }

    replaceFormula(formula, element, name) {
        return formula.trim().replace(element, this.BRACKET_OPEN + name + this.BRACKET_CLOSED);
    }

    handleFormulaFieldsInput(event): void {
        if ([this.BRACKET_OPEN, this.BRACKET_CIRCULAR_OPEN].includes(event.key)) {
            this.fieldsMenuTrigger.openMenu();
        }
    }

    onFormulaFieldSelected(field: DatasetField, $event) {
        $event.preventDefault(); // st probably mat menu is taking the focus
        this.formula += field.name + this.BRACKET_CLOSED;
        // this.fieldsFilter = undefined;

        setTimeout(() => {
            this.formulaInput.focus();

        }, 50);
        // this.cd.detectChanges();
    }

    /**
     * Here we have to replace the fields names with the field ids. E.g
     */
    formatFormula(formula: string): string {
        let regex = new RegExp('\\[(.*?)\\]', 'g');
        let quotedFieldNames = this.formula.match(regex);
        let fieldsByNames = {};
        this.numericDatasetFields.forEach(field => {
            fieldsByNames[field.name] = field;
        });
        let formattedFormula = formula;
        if (quotedFieldNames) {
            quotedFieldNames.forEach(fieldName => {
                fieldName = fieldName.replace('[', '');
                fieldName = fieldName.replace(']', '');
                let field: DatasetField = fieldsByNames[fieldName];
                if (field) {
                    formattedFormula = formattedFormula.replace(this.BRACKET_OPEN + fieldName + this.BRACKET_CLOSED, 'FIELD_' + field.id);
                } else {
                    this.notifService.error(`Incorrect field name '${fieldName}'`);
                    throw new Error();
                }
            });
        }
        return formattedFormula;
    }

    get WorkspaceItemAccess() {
        return WorkspaceItemAccess;
    }

    get WorkspaceItemType() {
        return WorkspaceItemType;
    }

    onWorkspaceItemSelected() {
        this.access = this.selectedWorkspaceItem.access;
        this.name = this.selectedWorkspaceItem.name;
    }

    submit() {
        let isFormula = this.type === WorkspaceItemType.FORMULA;
        if (!this.selectedWorkspaceItem) {
            let request: CreateWorkspaceItemRequest = {
                accountId: this.accountId,
                datasetId: this.datasetId,
                data: isFormula ? JSON.stringify({formula: this.formatFormula(this.formula)}) : JSON.stringify(this.data),
                name: this.name,
                type: this.type,
                access: this.access
            };
            this.workspaceItemService.createWorkspaceItem(request).subscribe(
                success => {
                    switch (this.type) {
                        case WorkspaceItemType.UPLOAD_TEMPLATE:
                            this.notifService.success('View was successfully created');
                            break;
                        case WorkspaceItemType.FILTER:
                            this.notifService.success('Filter was successfully saved');
                            break;
                        case WorkspaceItemType.TABLE_PROJECTION:
                            this.notifService.success('Projection was successfully saved');
                            break;
                        case WorkspaceItemType.ANALYTICS:
                            this.notifService.success('View was successfully created');
                            break;
                        case WorkspaceItemType.FORMULA:
                            this.notifService.success('Formula was successfully created');
                            break;
                        case WorkspaceItemType.DASHBOARD:
                            this.workspaceItemService.setDashboardCall(true);
                            this.notifService.success('View was successfully created for dashboard');
                            break;

                    }

                    this.itemSubmitted.emit(this.type);
                    this.close();
                },
                error => this.notifService.error(error.error.message)
            );
        } else {
            let request: UpdateWorkspaceItemRequest = {
                id: this.selectedWorkspaceItem.id,
                data: isFormula ? JSON.stringify({formula: this.formatFormula(this.formula)}) : JSON.stringify(this.data),
                access: this.access,
                name: this.selectedWorkspaceItem.name
            };
            this.workspaceItemService.updateWorkspaceItem(request).subscribe(
                success => {
                    if (this.isReplace && this.type == WorkspaceItemType.FORMULA) {
                        this.notifService.success('Formula was successfully updated.');
                    } else {
                        this.notifService.success('View was successfully updated');
                    }
                    this.itemSubmitted.emit(this.type);
                    this.close();
                },
                error => this.notifService.error(error.error.message)
            );
        }
    }

    close() {
        this.name = null;
        this.access = WorkspaceItemAccess.MEMBER;
        this.dialog.closeAll();
    }

    get nameMaxLength() {
        return this.NAME_MAX_LENGTH;
    }

    disabledSubmitButton() {
        if (this.type == WorkspaceItemType.FORMULA) {
            return !this.name || !this.access || !this.formula || this.name.length > this.nameMaxLength || this.isView;
        } else if(this.type === WorkspaceItemType.DASHBOARD){
            return !this.name || this.name.length > this.nameMaxLength;
        } else {
            return !this.name || this.name.length > this.nameMaxLength || this.isView;
        }
    }

}
