import { Validators } from '@angular/forms';

export const groupTypes = {
    ROW_GROUP: 1,
    COLUMN_GROUP: 2
}

export const displayGroupTypes = {
    1: 'Row Group',
    2: 'Column Group'
}

export const createGroupControlsConfig = {
    name: ['', Validators.required],
    parentID: ['', [Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(1)],],
    type: [groupTypes.ROW_GROUP, Validators.required],
};


