import {Component, Input, OnInit} from '@angular/core';
import { UserStateService } from 'src/app/auth/user-state-service';

@Component({
  selector: 'settings-header-breadcrumbs',
  templateUrl: './settings-header-breadcrumbs.component.html',
  styleUrls: ['./settings-header-breadcrumbs.component.scss']
})
export class SettingsHeaderBreadcrumbsComponent implements OnInit {
    // @Input () accountSettingsBreadCrumbs = [];
    @Input () componentName: string;
    @Input () breadCrumbs = [];
     accountLinks  = [
        {
            path: 'fields',
            name: 'Fields',
            component: 'AccountFieldsComponent',
            active: false,
            breadCrumbs: ['Home', 'Accounts', 'Settings']
        },
        {
            path: 'groups',
            name: 'Groups',
            component: 'AccountGroupsComponent',
            active: false,
            breadCrumbs: ['Home', 'Accounts', 'Settings']
        },
        {
            path: 'members',
            name: 'Members',
            component: 'AccountMembersComponent',
            active: false,
            breadCrumbs: ['Home', 'Accounts', 'Settings']
        },
        {
            path: 'global-overlays',
            name: 'Global Overlays',
            component: 'AccountOverlaysComponent',
            active: false,
            breadCrumbs: ['Home', 'Accounts', 'Settings']
        },
    ];
    
    constructor(
       readonly userStateService: UserStateService
    ) { }

  ngOnInit() {
    // pushed object for tessadata is logged in user is superadmin ONLY
    //  do private overlays visible for superadmin only
      if (this.userStateService.isSuperadmin) {
          this.accountLinks.push({
              path: 'private-overlays',
              name: 'Private Overlays',
              component: 'AccountPrivateOverlaysComponent',
              active: false,
              breadCrumbs: ['Home', 'Accounts', 'Settings']
          });
          this.accountLinks.push({
              path: 'external-overlays',
              name: 'Tessadata Dataset',
              component: 'AccountExternalOverlaysComponent',
              active: false,
              breadCrumbs: ['Home', 'Accounts', 'Settings']
          });
      }
  }

}
