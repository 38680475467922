import {Injectable} from '@angular/core';
import {FilterBarItem} from '../../../model/datapoint/draft/table/filter-bar-item';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Dataset} from '../../../model/dataset/dataset';

export interface DatapointsOverlayFilterStore {
    datasetID?: string;
    filters: FilterBarItem[];
}

@Injectable({
    providedIn: 'root'
})
export class DatapointsOverlayFilterService {
    private readonly filterStore: Map<string, DatapointsOverlayFilterStore>;
    private readonly selectedOverlay: ReplaySubject<DatapointsOverlayFilterStore | null>;
    onUncheckLayer: Subject<Dataset>;

    constructor() {
        this.filterStore = new Map<string, DatapointsOverlayFilterStore>();
        this.selectedOverlay = new ReplaySubject<any>(1);
        this.onUncheckLayer = new Subject<Dataset>();
    }

    addOverlay(datasetID: string) {
        this.filterStore.set(datasetID, {datasetID, filters: []});
    }

    removeOverlay(datasetID: string) {
        this.filterStore.delete(datasetID);
    }

    selectFilter(datasetID?: string) {
        const filter = this.filterStore.get(datasetID);
        if (filter) {
            this.selectedOverlay.next(filter);
        } else {
            this.selectedOverlay.next({datasetID: '', filters: []});
        }
    }

    getSelected(): Observable<DatapointsOverlayFilterStore> {
        return this.selectedOverlay.asObservable();
    }

    updateFilters(datasetID: string, filters: FilterBarItem[]) {
        this.filterStore.set(datasetID, {datasetID, filters});
    }

    getOverlayFilter(id) {
        return this.filterStore.get(id);
    }
}
