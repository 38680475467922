import {RenderingOptions} from '../../../model/dataset/rendering/rendering-options';
import {DatapointConverterType} from '../../../model/dataset/rendering/datapoint-converter-options';
import {VisualizationType} from '../../../model/dataset/rendering/visualization-options';
import {DatasetUtils} from '../../../core/utils/dataset-utils';
import {DatasetFieldType} from '../../../model/dataset/dataset-field-type';
import {RenderingUtils} from '../../../core/utils/rendering-utils';
import {Dataset} from '../../../model/dataset/dataset';
import {IntervalOption} from '../../../model/dataset/rendering/interval-option';


export function checkIntervalsValidity(dataset: Dataset, intervals: IntervalOption[], ) {
    let colorizationField = dataset.fields[dataset.stylingOptions.colorizationFieldIndex];
    let renderingOptions: RenderingOptions = {
        datasetStylingOptions: dataset.stylingOptions,
        converterOptions: {
            type: DatapointConverterType.NONE,
            datasetID: dataset.id,
            fieldID: colorizationField.id,
        },
        visualizationOptions: {type: VisualizationType.DEFAULT},
    };
    renderingOptions.datasetStylingOptions.intervalOptions = [...intervals];

    switch (DatasetUtils.getDatasetFieldType(colorizationField)) {
        case DatasetFieldType.NUMBER:
            renderingOptions.converterOptions.type = DatapointConverterType.NUMBER_FIELD;
            break;
        case DatasetFieldType.TEXT:
            renderingOptions.converterOptions.type = DatapointConverterType.TEXT_FIELD;
            break;
        case DatasetFieldType.DATE_TIME:
            renderingOptions.converterOptions.type = DatapointConverterType.DATE_FIELD;
            break;
    }
    return  RenderingUtils.validate(renderingOptions);
}
