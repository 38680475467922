import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { DateUtils } from 'src/app/core/utils/date-utils';

export class DetailCellRenderer implements ICellRendererComp {
    eGui!: HTMLElement;

    public special_keys = ['created_on', 'updated_on'];

    init(params: ICellRendererParams) {
        let record = params.data;
        let keys = Object.keys(record);

        this.eGui = document.createElement('div');
        this.eGui.classList.add('custom-crisis24-ag-table');
        let style  = this.eGui.appendChild(document.createElement('table')).style;
           style.borderCollapse = 'collapse';
        keys.forEach(key => {
            if (key !== 'id') {
                this.eGui.firstElementChild.innerHTML += `<tr> 
                                                    <td style="width:100px;line-height: 1.42857143;padding: 3px;"><b>`+ this.getLabel(key) + `: </b></td>
                                                    <td style="line-height: 1.42857143;word-break: break-word;white-space:normal;padding: 3px;">` + this.getValue(key, record) + `</td>
                                                    </tr>
                                            `;
                                            this.eGui.firstElementChild.innerHTML += "</table>";
            }
        });
    }

    getLabel(key: string) {
        if (key.includes('crisis24')) {
            let label = this.capitalizeFirstLetter(key.split('_')[1]);
            return label == 'Redius' ? 'Radius' : label;
        } else if (this.special_keys.includes(key)){
            const split_key = key.split('_');
            return this.capitalizeFirstLetter(split_key[0])+' '+split_key[1];
        } else {
            return this.capitalizeFirstLetter(key);
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getValue(key: string, element: { [x: string]: any; }) {
        if (this.special_keys.includes(key)) {
            return DateUtils.formatDate(element[key]);
        } else {
            return element[key];
        }
    }

    getGui() {
        return this.eGui;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}