import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'hasId'
})
export class HasIdPipe implements PipeTransform {
    transform(items: any[]): any {
        return items.filter(item => item.id !== undefined && item.id !== null);
    }

}
