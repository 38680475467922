import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { NotifService } from '../../core/notification/notif.service';
import { CreateOverlayGroupRequest, OverlaysService, UpdateOverlayGroupRequest } from '../../data-access-layer/global-overlays/overlays.service';
import { DialogModel } from '../../model/dialog/dialog-model';
import { GroupWithOverlaysTreeNode } from '../../model/overlay/group/group-with-overlays-tree-node';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { DataPointsServiceState } from '../../shared/services/datapoints-service-state';

@Component({
    selector: 'map-edit-overlay-group',
    templateUrl: './edit-overlay-group.component.html',
    styleUrls: ['./edit-overlay-group.component.scss']
})
export class EditOverlayGroupComponent implements OnInit {

    @Input() map: {} = {};
    @Input() selectedNode: { group: { parentId: number; name: string; tag?: string; id: number; } };
    @Input() groupNodes: GroupWithOverlaysTreeNode[] = [];
    constructor(
        public readonly dialog: MatDialog,
        public readonly notifService: NotifService,
        private readonly overlayService: OverlaysService,
        private datapointsServiceState: DataPointsServiceState
    ) { }

    ngOnInit() {
    }


    getGroupNodes() {
        return this.groupNodes;
    }

    deleteGroupModal(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete "${this.selectedNode.group.name}" group?`
            )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.deleteGroup(this.selectedNode.group.id);
            }
        });
    }

    private deleteGroup(groupId: number) {
        this.overlayService.deleteOverlayGroup(groupId).subscribe(() => {
            this.datapointsServiceState.emitOnDeleteOverlayGroupSuccess();
            this.notifService.success('Group Deleted Successfully');
        }, (err) => {
            this.notifService.error(err.error.message);
        });
    }

    createGroup(groupName: string, parentID?: number): void {
        let request: CreateOverlayGroupRequest = {
            name: groupName,
            parentID: parentID ? parentID : null,
            tag: '',
            id: null
        };
        this.overlayService.createOverlayGroup(request).subscribe((res) => {
            this.datapointsServiceState.emitOnCreateOverlayGroupSuccess();
            this.notifService.success('New Group Created');
        }, (err) => {
            this.notifService.error(err.error.message);
            console.log(err);
        });
    }

    editGroup(newGroupName: string, parentID: number, groupID: any): void {
        let request: UpdateOverlayGroupRequest = {
            id: parentID,
            name: newGroupName,
            parentID: groupID
        };
        this.overlayService.updateOverlayGroup(request).subscribe((res) => {
            this.datapointsServiceState.emitOnEditOverlayGroupSuccess();
        });
    }

}
