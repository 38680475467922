// mat-input-commified.directive.ts
import {Directive, ElementRef, forwardRef, HostListener, Input} from '@angular/core';
import {MAT_INPUT_VALUE_ACCESSOR} from '@angular/material';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {CommaFormatPipe} from "../pipes/comma-separator.pipe";

// import {numberWithCommas} from './helpers';

@Directive({
    selector: 'input[matInputCommified]',
    providers: [
        {provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputCommifiedDirective}


    ]
})
export class MatInputCommifiedDirective {

    // tslint:disable-next-line:variable-name
    private _value: string | null;

    constructor(private readonly elementRef: ElementRef<HTMLInputElement>,
                private readonly commaFormatPipe: CommaFormatPipe) {
    }

    get value(): string | null {
        return this._value;
    }

    @Input('value')
    set value(value: string | null) {
        this._value = value;
        this.formatValue(value);
    }

    private formatValue(value: string | null) {
        if (value !== null) {
            this.elementRef.nativeElement.value = this.commaFormatPipe.transform(value);
        } else {
            this.elementRef.nativeElement.value = '';
        }
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value) {
        // here we cut any non numerical symbols
        this._value = value.replace(/[^\d.-]/g, '');
    }

    @HostListener('blur')
    _onBlur() {
        this.formatValue(this._value); // add commas
    }

    @HostListener('focus')
    onFocus() {
        this.unFormatValue(); // remove commas for editing purpose
    }

    private unFormatValue() {
        const value = this.elementRef.nativeElement.value;
        this._value = value.replace(/[^\d.-]/g, '');
        if (value) {
            this.elementRef.nativeElement.value = this._value;
        } else {
            this.elementRef.nativeElement.value = '';
        }
    }
}
