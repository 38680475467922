import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commaFormat'
})
export class CommaFormatPipe implements PipeTransform {

    private smallNumberFormat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2});
    private bigNumberFormat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2});

    transform(value: any, numberFormatOptions?: any) {
        if (!value || isNaN(value)) {
            return value;
        }
        if (numberFormatOptions) {
            return new Intl.NumberFormat('en-US', numberFormatOptions).format(value);
        }
        return Number.parseFloat(value) < 1 ? this.smallNumberFormat.format(value) : this.bigNumberFormat.format(value);
    }
}
