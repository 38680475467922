import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IntegrationClientInfo} from '../../model/integration/integration-client-info';

@Injectable({
    providedIn: 'root'
})
export class IntegrationService {

    constructor(private readonly httpClient: HttpClient) {
    }


    getIntegrationClient(id: number): Observable<IntegrationClientInfo>{
        return this.httpClient.get<IntegrationClientInfo>(`/integration/client-info/${id}`);
    }

    getIntegrationClients(): Observable<IntegrationClientInfo[]>{
        return this.httpClient.get<IntegrationClientInfo[]>(`/integration/client-info`);
    }

    createIntegrationClient(request: IntegrationClientInfo): Observable<IntegrationClientInfo>{
        return this.httpClient.post<IntegrationClientInfo>(`/integration/client-info`, request);
    }

    updateIntegrationClient(id: number, request: IntegrationClientInfo): Observable<IntegrationClientInfo>{
        return this.httpClient.put<IntegrationClientInfo>(`/integration/client-info/${id}`, request);
    }

    deleteIntegrationClient(id: number): Observable<void>{
        return this.httpClient.delete<void>(`/integration/client-info/${id}`);
    }

}