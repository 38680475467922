// tslint:disable:max-file-line-count
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DraftService } from '../../data-access-layer/draft/draft.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetService } from '../../data-access-layer/dataset/dataset.service';
import { MatTableDataSource } from '@angular/material/table';
import { DatasetField } from '../../model/dataset/field/dataset-field';
import { debounceTime, take } from 'rxjs/operators';
import { DraftsUploadComponent } from './drafts-upload/drafts-upload.component';
import { Dataset } from '../../model/dataset/dataset';
import { PanelComponent } from 'src/app/shared/panel/panel.component';
import { MatCheckboxChange, MatDialog, MatDialogConfig, MatSort } from '@angular/material';
import { ApproveDialogDraftData, ApproveDraftComponent } from './approve-draft/approve-draft.component';
import { DatapointSort, SortOrder } from 'src/app/model/filter/draft-filter-sort';
import { DraftFilterObject } from 'src/app/model/filter/draft-filter-object';
import { DraftFilter, DraftFilterField } from 'src/app/model/filter/draft-filter';
import { DraftFilterProjection } from 'src/app/model/filter/draft-filter-projection';
import { AccountService } from 'src/app/data-access-layer/account/account.service';
import { Account } from '../../model/account/account';
import { DatapointDraft } from 'src/app/model/datapoint/draft/datapoint-draft';
import { SortDirection } from '@angular/material/sort/typings/sort-direction';
import { DraftsAggregateService } from '../../data-access-layer/draft/drafts-aggregate.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../core/utils/format-datepicker';
import { DownloadService } from '../../data-access-layer/download/download.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AttachmentUtils } from '../../core/utils/attachment-utils';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { RoutingUtils } from '../../core/utils/routing-utils';
import { DatasetFieldSpecificType } from '../../model/dataset/dataset-field-specific.type';
import { DatasetType } from '../../model/dataset/dataset-type';
import { NotifService } from '../../core/notification/notif.service';
import { DraftTableRow } from '../../model/datapoint/draft/table/draft-table-row';
import { FilterBarItem } from '../../model/datapoint/draft/table/filter-bar-item';
import { DatasetFieldStatistics } from '../../model/analytics/dataset-field-statistics';
import { DatasetGeometryType } from '../../model/dataset/dataset-geometry-type';
import { DatasetDownloadComponent } from '../dataset-download/dataset-download.component';
import { DatapointFilterObject } from '../../model/datapoint/datapoint-filter-object';
import { SidePanelComponent } from '../../core/side-panel/side-panel.component';
import { SortEvent } from '../../core/directives/sort.directive';
import { DatapointsService } from '../../data-access-layer/datapoints/datapoints.service';
import { MaptycsTableComponent } from '../../core/maptycs-table/maptycs-table.component';
import { DraftField } from '../../model/datapoint/draft/draft-field';
import { UserStateService } from '../../auth/user-state-service';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { DialogModel } from '../../model/dialog/dialog-model';
import { isEnabled } from 'src/environments/environment';
import { DatasetFieldType } from 'src/app/model/dataset/dataset-field-type';
import { DatapointsFilterService } from '../datapoints/datapoints-filter.service';
import { Constants } from '../../constants';
import { Column } from '../datapoints/datapoints-table/datapoints-table.component';
import { DraftStatusTransform } from '../../core/pipes/status.pipe';
import { Functionalities } from '../../../environments/app-functionalities';
import {FilteringErrorCodes} from '../datapoints/filtering-error-codes';

// Constants

const DEFAULT_SORT: DatapointSort = {
    fields: [],
    datasetID: null,
    links: [],
    geocodingAccuracyOrder: SortOrder.ASCENDANT,
    statusOrder: SortOrder.ASCENDANT
};
const LIMIT = 50;
const DEFAULT_SKIP = 0;
const DIRECTION_MAP = { asc: SortOrder.ASCENDANT, desc: SortOrder.DESCENDANT };

export interface MatSortChange {
    active: string;
    direction: SortDirection;
}

// TODO split this component up. Don't add more logic.
@Component({
    selector: 'map-drafts',
    templateUrl: './drafts.component.html',
    styleUrls: ['./drafts.component.scss'],
    providers: [
        InfiniteScrollModule,
        // {provide: DateAdapter, useClass: AppDateAdapter},
        // {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
})
export class DraftsComponent implements OnInit {
    @ViewChild('uploadComponent', { static: false }) uploadComponent: DraftsUploadComponent;
    @ViewChild('approveDraftComponent', { static: false }) approveDraftComponent: ApproveDraftComponent;
    @ViewChild('pagePanel', { static: false }) pagePanel: PanelComponent;
    @ViewChild(MatSort, { static: false }) htmlSortElement: MatSort;
    @ViewChild('updateDraftPanel', { static: false }) updateDraftPanel: SidePanelComponent;
    @ViewChild('createDraftPanel', { static: false }) createDraftPanel: SidePanelComponent;
    @ViewChild('maptycsTable', { static: false }) maptycsTable: MaptycsTableComponent;
    @ViewChild('content', { static: false }) content: ElementRef;

    datapointsEnabled = isEnabled(Functionalities.DATAPOINTS);

    private readonly limit: number;
    dataset: Dataset;
    datasetFieldsByIds: Map<string, DatasetField>;
    drafts: DatapointDraft[] = [];
    updatingDraftId: string;
    dataSource: MatTableDataSource<DraftTableRow[]>;
    displayedColumns: string[] = [];
    dynamicColumns: string[] = [];
    sort: DatapointSort;
    datasetId: string;
    filterObject: DraftFilterObject;
    account?: Account;
    filterFields: any[];
    pendingFilters: FilterBarItem[] = [];
    projectionSelectionForm: FormGroup;
    fetchDraftsSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    filterSelectionForm: FormGroup;
    filterSearchString: string;
    filteredProjectionDatasetFields: any[];
    filteredDatasetFilterFields: any[];
    columns: Column[] = [];
    paginationInfo: any;
    dialogData: ApproveDialogDraftData;
    projectionSearchString: string;
    filterFieldSearchFilter: (field: DatasetField) => boolean;
    filterFieldSearchString: string;
    filteredProjectionDatasetFieldsFilter: (field: DatasetField) => boolean;
    statisticSearchString: string;
    statisticSearchFilter: (value: string) => boolean;

    availableDatasetsInAccount: Dataset[] = [];
    filterStatisticValuesString: string;
    filterStatisticValuesFilter: (value: string) => boolean;
    trackByIndexFunction = (index: number): number => index;
    isDraftFetchApiCall = false;

    constructor(
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly dpService: DatapointsService,
        private readonly fb: FormBuilder,
        private readonly aggregate: DraftsAggregateService,
        private readonly accountService: AccountService,
        private readonly draftService: DraftService,
        private readonly datasetService: DatasetService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly notifService: NotifService,
        private readonly downloadService: DownloadService,
        readonly userStateService: UserStateService,
        public dialog: MatDialog
    ) {
        this.filterSearchString = '';
        this.projectionSearchString = '';
        this.filteredProjectionDatasetFields = [];
        this.filteredDatasetFilterFields = [];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.dataSource = new MatTableDataSource<DraftTableRow[]>([]);
        this.datasetFieldsByIds = new Map<string, DatasetField>();
        this.limit = LIMIT;
        this.sort = Object.assign({}, DEFAULT_SORT);
        this.selectAll = false;
        this.filterObject = {
            projection: {
                fields: [],
                datasetType: null,
                application: null,
                accountID: null,
                datasetID: null
            }, filter: null, sort: this.sort, limit: this.limit, skip: DEFAULT_SKIP
        };
        this.filterFieldSearchString = '';
        this.projectionSearchString = '';
        this.statisticSearchString = '';
        this.filterFieldSearchFilter = (field: DatasetField) => {
            return field.name.toLowerCase().includes(this.filterFieldSearchString.toLowerCase());
        };
        this.filteredProjectionDatasetFieldsFilter = (field: DatasetField) => {
            return field.name.toLowerCase().includes(this.projectionSearchString.toLowerCase());
        };
        this.statisticSearchFilter = (value: string) => {
            return value.toLowerCase().includes(this.statisticSearchString.toLowerCase());
        };
        this.filterStatisticValuesString = '';
        this.filterStatisticValuesFilter = (value: string) => {
            return value.toLowerCase().includes(this.filterStatisticValuesString.toLowerCase());
        };
    }

    ngOnInit(): void {

        this.datasetId = this.route.snapshot.paramMap.get('datasetId');
        let accountID = +this.route.parent.snapshot.paramMap.get('accountId');


        this.datasetService.getDataset(this.datasetId).subscribe(
            dataset => {
                this.dataset = dataset;
                if (!this.dataset) {
                    if (accountID) {
                        this.redirectToFirstExistingDataset();
                    } else {
                        this.redirectToOverlaysPage();
                    }
                }

                if (this.dataset.type !== DatasetType.GLOBAL_OVERLAY) {
                    this.accountService.getAccount(accountID).subscribe((account) => {
                        this.account = account;
                        this.availableDatasetsInAccount = this.userStateService.availableDatasetsInAccount(this.account.id);
                    });
                }
                this.fetchDraftsSubject
                    .pipe(debounceTime(500))
                    .subscribe(reset => {
                        if (reset === true) {
                            this.resetTable();
                        }
                        if (!this.isDraftFetchApiCall) {
                            this.fetchDraftsByFilter();
                            this.setIsDraftFetchApiCall();
                        }
                    });

                this.initializeProjectionForm();
                this.initializeFilterDatasetFieldSelectionForm();
                this.filteredProjectionDatasetFields = this.filteredDatasetFilterFields = this.dataset.fields;
                this.filterFields = this.dataset.fields;
                this.filterFields.forEach((field) => field.selected = false);
                this.datasetId = this.dataset.id;
                this.filterObject = this.constructFilterObject();
                this.selectAll = false;
                this.dataset.fields.forEach(field => {
                    this.datasetFieldsByIds.set(field.id, field);
                });
                this.fetchDrafts(); 
                this.setIsDraftFetchApiCall(false);
            }, err => {
                console.log(err);
            });
    }

    fetchDraftsByFilter = () => {
        this.draftService.findDraftsByFilter(this.dataset.id, this.filterObject)
            .pipe(take(1))
            .subscribe(
                drafts => {
                drafts.forEach(draft => {
                    draft.selected = this.maptycsTable.selectAll;
                    draft.fields.unshift(this.getDatapointField(draft.geocodingAccuracy, 'geocodingAccuracyOrder'));
                    draft.fields.unshift(this.getDatapointField(DraftStatusTransform(draft.status), 'statusOrder'));
                });
                this.drafts = drafts;
                this.columns = this.getColumns();

                this.content.nativeElement.scroll({ behavior: 'smooth', top: 0 });
                this.getPaginationInfo(this.filterObject.filter);
            },
             err => {
                    this.notifService.error('Something went wrong... Please check the FILTERS.');
             });
    }

    private initializeProjectionForm() {
        let projectionFormGroup = {};
        this.dataset.fields.forEach((field) => {
            let checked = !field.isGenerated; // we don't enable generated fields as default
            projectionFormGroup[field.id] = new FormControl(checked);
        });
        this.projectionSelectionForm = this.fb.group(projectionFormGroup);
        this.projectionSelectionForm.valueChanges
            .pipe(debounceTime(1000))
            .subscribe(val => {
                this.applyProjection(val);
            });
    }

    private initializeFilterDatasetFieldSelectionForm(): void {
        let filterSelectionForm = {};
        this.dataset.fields.forEach((field) => {
            filterSelectionForm[field.id] = new FormControl(false);
        });
        this.filterSelectionForm = this.fb.group(filterSelectionForm);
    }

    resetTable(): void {
        this.maptycsTable.selectAll = false;
        this.skip = 0;
        this.drafts = [];
    }

    fetchDrafts(resetTable = true) {
        this.fetchDraftsSubject.next(resetTable);
    }

    onUploadComplete() {
        this.notifService.success('Successfully uploaded drafts');
        this.setIsDraftFetchApiCall(false);
        this.fetchDrafts();
    }

    redirectToFirstExistingDataset(): void {
        let routeSubscribtion = this.route.params.subscribe(params => {
            let id = params.datasetId;
            this.router.navigateByUrl(this.router.url.replace(id, this.account.datasets[0].id)).then(() => routeSubscribtion.unsubscribe);
        });
    }

    redirectToOverlaysPage(): void {
        this.router.navigateByUrl(RoutingUtils.getGlobalOverlaysRoute());
    }

    constructFilterObject(): DraftFilterObject {
        const { dataset, limit, skip, sort, } = this;
        sort.datasetID = this.datasetId;
        let projectedFields = Object.keys(this.projectionSelectionForm.value).filter(key => this.projectionSelectionForm.value[key]);
        let projection: DraftFilterProjection = {
            accountID: dataset.accountID,
            application: dataset.application,
            datasetID: dataset.id,
            datasetType: dataset.type,
            fields: projectedFields
        };
        let filter: DraftFilter = {
            application: dataset.application,
            datasetID: dataset.id,
            datasetType: dataset.type,
            fields: [],
        };
        return { projection, filter, limit, skip, sort };
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    openImport(): void {
        this.uploadComponent.openComponent();
    }

    openApprove(): void {
        let selectedDraftIds: number[] = this.getSelectedDraftRowIds();
        if (selectedDraftIds.length <= 0) {
            return;
        }
        let componentData: ApproveDialogDraftData = {
            accountID: this.account ? this.account.id : null,
            dataset: this.dataset,
            drafts: selectedDraftIds,
            selectAll: this.selectAll,
            filter: this.filterObject
        };
        this.dialogData = componentData;
        this.approveDraftComponent.openComponent();
    }

    openDownload(): void {
        const dialogRef = this.dialog.open(DatasetDownloadComponent, {
            width: '450px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.downloadDrafts(result);
            }
        });
    }

    downloadDrafts(result) {
        let projection = result.projection === 'CURRENT_FIELDS' ? this.filterObject.projection : this.getDefaultFilterObject().projection;
        let request = {
            datapointRequest: {
                limit: this.selectAll ? -1 : this.limit,
                projection: { ...projection, geometryPrecision: 25 },
                skip: this.selectAll ? 0 : this.skip,
                sort: result.sort === 'CURRENT_SORT' ? this.filterObject.sort : this.getDefaultFilterObject().sort
            },
            filter: result.filter === 'CURRENT_FILTER' ? this.filterObject.filter : this.getDefaultFilterObject().filter,
            timeZone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: result.fileType,
            // reportRequest: {},
        };
        const finalFileName = this.generateFileName(result.fileName.toString(), result.fileType.toLowerCase().toString());
        this.downloadService.downloadDrafts(this.dataset.id, request, result.fileName).subscribe((response) => {
            AttachmentUtils.downloadFileWithName(response, finalFileName);
        }, error => this.notifService.error('Something went wrong during download'));
    }

    generateFileName(name: string, type: string): string {
        return name + '.' + type;
    }

    getDefaultFilterObject(): DatapointFilterObject {
        let projection = {
            datasetID: this.dataset.id,
            fields: this.dataset.fields.map(field => field.id),
            links: []
        };

        let filter = {
            datasetID: this.dataset.id,
            // groups: this.getGroups() || [],
            fields: [],
            links: []

        };
        let sort = Object.assign({}, DEFAULT_SORT);
        sort.datasetID = this.dataset.id;
        let filterObject: DatapointFilterObject = { projection, filter, limit: LIMIT, skip: DEFAULT_SKIP, sort };
        return filterObject;
    }

    navigateToDatapoints(): void {
        if (this.dataset.type === DatasetType.GLOBAL_OVERLAY) {
            this.router.navigate([RoutingUtils.getGlobalOverlaysDatapointsRoute(this.datasetId)]);
        } else {
            this.router.navigate([RoutingUtils.getAccountDatasetDatapointsRoute(this.account.id, this.dataset.id)]);
        }

    }

    switchApplication(datasetId: string): void {
        if (datasetId === this.dataset.id) {
            return;
        }
        this.router.navigate([RoutingUtils.getAccountDatasetDraftsRoute(this.account.id, datasetId)], { replaceUrl: true });
    }

    updateSelectedDraft(): void {
        const draftID = this.getSelectedDraftRowIds()[0];
        if (!draftID) {
            return;
        }
        this.updateDraftById(draftID);
    }

    updateDraftById(draftID: any): void {
        this.updatingDraftId = draftID;
        this.updateDraftPanel.showPanel();
    }

    getSelectedDraftRowIds(): number[] {
        return this.maptycsTable.selected.items.map(draft => draft.id) || [];
    }

    get selectAll() {
        if (this.maptycsTable) {
            return this.maptycsTable.selectAll;
        }
        return false;
    }

    set selectAll(value: boolean) {
        if (this.maptycsTable) {
            this.maptycsTable.selectAll = value;
        }
    }

    deleteSelectedDrafts(): void {

        if (this.maptycsTable.selectAll) {
            // if (!this.paginationInfo.count) {
            //     return;
            // }
            const dialogRef = this.dialog.open(DialogComponent, {
                data: new DialogModel(
                    'Confirm Action',
                    `Are you sure you want to delete ${this.paginationInfo.count} draft(s)?`
                )
            });
            dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
                if (dialogResult) {
                    this.draftService.deleteDraftsByFilter(this.dataset.id, this.filterObject.filter).subscribe((res) => {
                        this.notifService.success(`Successfully deleted ${res} drafts`);
                        this.setIsDraftFetchApiCall(false);
                        this.fetchDrafts();
                    });
                }   
            });
        } else {
            let selectedDraftIds: number[] = this.getSelectedDraftRowIds();
            if (selectedDraftIds.length > 0) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    data: new DialogModel(
                        'Confirm Action',
                        `Are you sure you want to delete ${selectedDraftIds.length} draft(s)?`
                    )
                });
                dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
                    if (dialogResult) {
                        this.draftService.deleteDrafts(this.dataset.id, selectedDraftIds).subscribe((res) => {
                            this.notifService.success(`Successfully deleted ${res} drafts`);
                            this.setIsDraftFetchApiCall(false);
                            this.fetchDrafts();
                        });
                    }
                });
            }
        }
    }

    approveDrafts(): void {
        this.openApproveDialog();
    }

    openApproveDialog(): void {
        let selectedDraftIds: number[] = this.getSelectedDraftRowIds();
        if (selectedDraftIds.length <= 0) {
            return;
        }
        let componentData: ApproveDialogDraftData = {
            accountID: this.account ? this.account.id : null,
            dataset: this.dataset,
            drafts: selectedDraftIds,
            selectAll: this.selectAll,
            filter: this.filterObject
        };
        let config: MatDialogConfig = {
            data: componentData,
            width: '800px',
            height: '450px',
            panelClass: 'dialogApprove'
        };
        const dialogRef = this.dialog.open(ApproveDraftComponent, config);
        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult === 'ok') {
                this.fetchDrafts();
                this.setIsDraftFetchApiCall(false);
            } else {
                console.log('nothing approved, canceling');
            }
        });
    }

    onFilterSelect(datasetField: DatasetField, event: MatCheckboxChange): void {
        if (event.checked) {
            this.addFilter(datasetField);
        } else {
            this.removeFilter(datasetField);
        }
    }

    removeFilter(datasetField: any): void {
        const existingFilterBarItem = this.pendingFilters.find(filterBarItem => filterBarItem.datasetField.id === datasetField.id);
        this.pendingFilters.splice(this.pendingFilters.indexOf(existingFilterBarItem), 1);
        this.filterSelectionForm.get(datasetField.id).setValue(false);
        this.applyFilters();
    }

    addFilter(datasetField: DatasetField) {
        this.getFilterBarItem(datasetField).then((filterBarItem) => {
            this.pendingFilters.push(filterBarItem);
        });
    }

    removeAllFilters(): void {
        this.dataset.fields.forEach((field) => {
            this.filterSelectionForm.get(field.id).setValue(false);
        });
        this.pendingFilters = [];
        this.filterObject.filter.fields = [];
        this.setIsDraftFetchApiCall(false);
        this.fetchDrafts();
    }

    getFilterBarItem(datasetField: DatasetField): Promise<FilterBarItem> {
        return new Promise((resolve, reject) => {
            let filterBarItem: FilterBarItem = {
                id: datasetField.id,
                datasetField: datasetField,
                dataset: this.dataset,
                statistics: {
                    values: [],
                    maxValue: null,
                    minValue: null,
                    averageValue: null,
                    id: null,
                    stdevValue: null
                },
                maxNumberValue: null,
                minNumberValue: null,
                minDateValue: null,
                maxDateValue: null,
                searchValue: '',
                statisticValues: null,
                displayedTitle: `${datasetField.name}(${this.dataset.name}) : Filter is not active`
                // comment
            };
            if (!datasetField.isHighCardinality) {
                this.aggregate.getDraftsFieldStatistics(this.dataset.id, datasetField.id, this.filterObject.filter).pipe(take(1)).subscribe((statistics: DatasetFieldStatistics) => {
                    const valueListExists = statistics.values && statistics.values.length > 0;
                    try {
                        // this try block is to remove null values, bug from a backend workaround
                        statistics.values = statistics.values.filter(value => value !== null);
                    } catch (err) {

                    }
                    let { averageValue, maxValue, minValue, values } = statistics;
                    statistics.maxDateValue = new Date(maxValue);
                    statistics.minDateValue = new Date(minValue);
                    if (valueListExists) {
                        let statisticValues = [];
                        values.forEach((value: string) => {
                            statisticValues.push({ selected: false, value: value });
                        });
                        filterBarItem.statisticValues = {};
                        values.forEach((value => {
                            filterBarItem.statisticValues[value] = false;
                        }));
                    }
                    filterBarItem.statistics = statistics;
                    resolve(filterBarItem);
                });
            } else {
                resolve(filterBarItem);
            }
        });
    }

    createFilterFieldObject(filterBarItem: FilterBarItem): DraftFilterField {
        let textValues = [];
        let statistics = filterBarItem.statisticValues;
        try {
            for (let prop in statistics) {
                if (statistics.hasOwnProperty(prop) && statistics[prop]) {
                    textValues.push(prop);
                }
            }
        } catch (err) {
        }
        let field: DraftFilterField = {
            flags: null,
            id: filterBarItem.datasetField.id,
            maxDateValue: filterBarItem.maxDateValue ? new Date(filterBarItem.maxDateValue).getTime() : null,
            minDateValue: filterBarItem.minDateValue ? new Date(filterBarItem.minDateValue).getTime() : null,
            maxNumberValue: filterBarItem.maxNumberValue || null,
            minNumberValue: filterBarItem.minNumberValue || null,
            searchValue: filterBarItem.searchValue || null,
            textValues: textValues
        };
        return field;
    }

    applyFilters() {
        let fields = [];
        let isInvalidFilter = '';
        for (const filterBarItem of this.pendingFilters) {
            isInvalidFilter = this.datapointsFilterService.validatorsForFiltersInputs(filterBarItem);
            if (isInvalidFilter) {
                break;
            }
        }
        switch (isInvalidFilter) {
            case FilteringErrorCodes.MIN_GREATER_THAN_MAX:
                this.notifService.error('Error. Min cannot be greater then Max.');
                return;
            case FilteringErrorCodes.EXCEED_MIN_ALLOWED:
                this.notifService.error('Error. Exceeded the maximum allowed length.');
                return;
            case FilteringErrorCodes.EXCEED_MAX_ALLOWED:
                this.notifService.error('Error. Exceeded the maximum allowed Max length.');
                return;
            case FilteringErrorCodes.EXCEED_LENGTH_CHARTS:
                this.notifService.error(`Error. Exceed the maximum limit of characters.`);
                return;
        }
        this.pendingFilters.forEach((filterBarItem) => {
            filterBarItem.displayedSearchValue = this.datapointsFilterService.constructFilterDisplayValue(filterBarItem);
            if (filterBarItem.displayedSearchValue) {
                filterBarItem.displayedTitle = `${filterBarItem.datasetField.name}(${filterBarItem.dataset.name}) - ${filterBarItem.displayedSearchValue}`;

            } else {
                filterBarItem.displayedTitle = `${filterBarItem.datasetField.name}(${filterBarItem.dataset.name}) : Filter is not active`;
            }
            fields.push(this.createFilterFieldObject(filterBarItem));
        });
        this.filterObject.filter.fields = fields;
        this.setIsDraftFetchApiCall(false);
        this.fetchDrafts();
    }

    set skip(value: number) {
        this.filterObject.skip = value;
    }

    get skip() {
        return this.filterObject.skip;
    }

    get datasetFieldType(): any {
        return DatasetFieldSpecificType;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    applyProjection(projection) {
        let projectionArray = [];
        for (const field in projection) {
            if (projection.hasOwnProperty(field) && projection[field]) {
                projectionArray.push(field);
            }
        }
        this.filterObject.projection.fields = projectionArray;
        this.setIsDraftFetchApiCall(false);
        this.fetchDrafts();
    }

    onCreated() {
        this.createDraftPanel.hidePanel();
        this.setIsDraftFetchApiCall(false);
        this.fetchDrafts();
    }

    onUpdated() {
        this.updateDraftPanel.hidePanel();
        this.updatingDraftId = null;
        this.setIsDraftFetchApiCall(false);
        this.fetchDrafts();
    }

    linkDrafts() {
        if (this.selectAll) {
            this.draftService.linkDraftsByFilter(this.dataset.id, this.filterObject.filter).subscribe((res) => {
                this.notifService.success(`Linking process has started. Please refresh the page after a few moments`);
            });
        } else {
            this.draftService.linkDrafts(this.dataset.id, this.getSelectedDraftRowIds()).subscribe((res) => {
                this.notifService.success(`Linking process has started. Please refresh the page after a few moments`);
            });
        }
    }

    tileDrafts() {
        const message = this.dataset.isGeoserver ? 'Linking' : 'Tiling';
        if (this.selectAll) {
            this.draftService.tileDraftsByFilter(this.dataset.id, this.filterObject.filter).subscribe((res) => {
                this.notifService.success(message + ` process has started. Please refresh periodically to check the status`);
            });
        } else {
            this.draftService.tileDrafts(this.dataset.id, this.getSelectedDraftRowIds()).subscribe((res) => {
                this.notifService.success(message + `process has started. Please refresh periodically to check the status`);
            });
        }
    }

    createDraft() {
        this.createDraftPanel.showPanel();
    }

    onSort({ column, direction }: SortEvent) {
        let field = this.getColumns().find(col => {
            return col.columnName === column;
        });
        if (direction === '') {
            this.filterObject.sort.fields = [];
            this.filterObject.sort.datasetID = this.datasetId;
            this.setIsDraftFetchApiCall(false);
            this.fetchDrafts(false);
            return;
            // this.filterObject.sort.links = [];
        }
        if (column !== 'Status' && column !== 'Geocoding Accuracy') {
            this.filterObject.sort.fields = [{ id: field.columnID.toString(), sortOrder: DIRECTION_MAP[direction] }];
        } else {
            this.filterObject.sort[field.columnID] = DIRECTION_MAP[direction];
        }
        this.setIsDraftFetchApiCall(false);
        this.fetchDrafts(false);
    }

    onPaginationChange(paginationChangeEvent: { numberOfItemsPerPage: number, currentPage: number,  onPageLoadCall: boolean }) {
        // this.filterObject.limit = paginationChangeEvent.numberOfItemsPerPage;
        this.skip = (paginationChangeEvent.currentPage) * this.limit;
        if (!paginationChangeEvent.onPageLoadCall) {
            this.setIsDraftFetchApiCall(false);
        }
        this.fetchDrafts(false);
    }

    getColumns(): { datasetID: string, columnName: string, columnID: string }[] {
        let columns = [];
        columns.push({ datasetID: this.dataset.id, columnName: 'Status', columnID: 'statusOrder' });
        columns.push({
            datasetID: this.dataset.id,
            columnName: 'Geocoding Accuracy',
            columnID: 'geocodingAccuracyOrder'
        });
        this.filterObject.projection.fields.forEach(fieldID => {
            let datasetField = this.dataset.fields.find(field => field.id === fieldID);
            let headerName = datasetField.displayName === null || datasetField.displayName === undefined ? datasetField.name : datasetField.displayName;
            if (datasetField) {
                let column = {
                    datasetID: this.dataset.id,
                    columnName: headerName,
                    columnID: fieldID,
                    fixedWidth: datasetField.baseType === DatasetFieldType.NUMBER,
                    width: '120px'
                };
                columns.push(column);
            }
        });
        return columns;
    }

    getPaginationInfo(filter: DraftFilter) {
        this.draftService.getDatapointsPaginationInfo(filter).subscribe((count) => {
            this.paginationInfo = count;
        }, err => {
            console.log(err);
        });
    }

    private getDatapointField(value: any, id: string): DraftField {
        return {
            valid: true,
            unparsedValue: null,
            id: id,
            datasetID: this.dataset.id,

            textValue: value,
            textArrayValue: [],

            datetimeValue: null,
            datetimeArrayValue: [],

            numberValue: null,
            numberArrayValue: [],

        };
    }

    onApproveComplete() {
        // this.notifService.success('Successfully uploaded drafts');
        this.dialogData = null;
        this.setIsDraftFetchApiCall(false);
        this.fetchDrafts();
    }

    removeFilterBar() {
        this.removeAllFilters();
        // this.initializeDatasetFilterSelectedState();
        // this.filterBarItems = [];
        // this.datapointsFilterService.clearFilterBar();
    }


    // updateDraftById(draftID: any): void {
    //     this.updatingDraftId = draftID;

    //     this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
    //     this.updateDraftPanel = this.sidePanelService.open<UpdateDraftComponentType>(SidePanels.UPDATE_DRAFTS,
    //         {
    //             width: 400,
    //             panelTitle: "Update Draft",
    //             id: "update-draft-panel"
    //         },
    //         {
    //             dataset: this.dataset,
    //             draftId: this.updatingDraftId
    //         });

    //     this.datapointsServiceState.onUpdateDraftSuccess$.subscribe(() => {
    //         this.onUpdated();
    //     });
    // }


    // createDraft() {
    //     this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
    //     this.createDraftPanel = this.sidePanelService.open<CreateDraftComponentType>(SidePanels.CREATE_DRAFT,
    //         {
    //             width: 400,
    //             panelTitle: "Create Draft",
    //             id: "create-draft-panel"
    //         },
    //         {
    //             dataset: this.dataset,
    //             fields: this.dataset.field
    //         });

    //     this.datapointsServiceState.onCreateDraftSuccess$.subscribe(() => {
    //         this.onCreated();
    //     });
    // }
    
    public setIsDraftFetchApiCall(flag: boolean = true) {
        this.isDraftFetchApiCall = flag;
    }


    fetchGlobalGeoserverOverlays = () => {
        this.draftService.fetchGeoserverOverlays(this.dataset.id)
            .subscribe(
                response => {
                    this.notifService.success(`Fetch process has started. Please refresh periodically to check the status`);
            },
             err => {
                    this.notifService.error('Something went wrong... Please check the FILTERS.');
             });
    }
}
