import {Pipe, PipeTransform} from '@angular/core';
import {DateUtils} from '../utils/date-utils';

@Pipe({
    name: 'maptycsDate'
})
export class DatePipe implements PipeTransform {

    transform(value: number, isTimeDisplayed?: boolean) {
       return DateUtils.formatDate(value, isTimeDisplayed);
    }
}
