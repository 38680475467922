import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Group } from '../../model/group/group';
import { GroupTreeNode } from '../../model/overlay/group/group-with-overlays-tree-node';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    constructor(private readonly http: HttpClient) {
    }

    getGroups(datasetID: string, accountID: number): Observable<Group[]> {
        return this.http.get<Group[]>(`/groups/account/${ accountID }/dataset/${ datasetID }/all`);
    }

    // getGroupsAsTree(datasetID: string, accountID: number): Observable<GroupTreeNode[]> {
    //     return this.http.get<GroupTreeNode[]>(`/groups/account/${accountID}/dataset/${datasetID}/tree`);
    // }

    getGroupsAsTree(datasetID: string, accountID?: number): Observable<GroupTreeNode[]> {
        if (accountID) {
            return this.http.get<GroupTreeNode[]>(`/groups/dataset/${ datasetID }/tree`, {params: {accountId: accountID.toString()}});
        } else {
            return this.http.get<GroupTreeNode[]>(`/groups/dataset/${ datasetID }/tree`);
        }
    }

    createGroup(data: any): Observable<Group> {
        return this.http.post<Group>(`/groups`, data);
    }

    updateGroup(data: any): Observable<Group> {
        return this.http.put<Group>(`/groups/`, data);
    }

    deleteGroup(id: number): Observable<Group> {
        return this.http.delete<Group>(`/groups/${ id }`);
    }

    bulkDeleteGroups(ids: number[]): Promise<any[]> {
        let promises = [];
        ids.forEach((id) => {
            promises.push(this.deleteGroup(id).toPromise());
        });
        return Promise.all(promises);
    }

    // getAllAccountApplicationGroups(accountID: number, datasetID: string): Observable<GroupDataset[]> {
    //   return this.http.get<GroupDataset[]>(`/groupNodes/account/${accountID}/dataset/${datasetID}/all`);
    // }
}
