import { RenderingOptions } from '../../model/dataset/rendering/rendering-options';
import { VisualizationType } from '../../model/dataset/rendering/visualization-options';
import { ColorScaleType } from '../../model/dataset/rendering/color-scale-type';
import { RenderingErrorCodes } from '../../model/dataset/rendering/rendering-error-codes';
import { IntervalOption } from '../../model/dataset/rendering/interval-option';

export class RenderingUtils {

    /**
     * This method will return a key for the first invalid data.
     */
    static validate(renderingOptions: RenderingOptions): string | null {
        switch (renderingOptions.visualizationOptions.type) {
            case VisualizationType.DEFAULT:
                return RenderingUtils.validateBasicRendering(renderingOptions);
            case VisualizationType.CLUSTER:
                return RenderingUtils.validateBasicRendering(renderingOptions);
            case VisualizationType.THEMATIC_MAP:
                return RenderingUtils.validateThematicMapRendering(renderingOptions);
            case VisualizationType.HEATMAP:
                break;


        }
        return null;
    }


    private static validateBasicRendering(renderingOptions: RenderingOptions): string | null {
        switch (renderingOptions.datasetStylingOptions.type) {
            case ColorScaleType.CONSTANT:
                if (!renderingOptions.datasetStylingOptions.defaultColor) {
                    return RenderingErrorCodes.DEFAULT_COLOR;
                }
                break;
            case ColorScaleType.FIXED:
            case ColorScaleType.GRADIENT:
                if (!renderingOptions.datasetStylingOptions.colors || !renderingOptions.datasetStylingOptions.colors.length) {
                    return RenderingErrorCodes.COLORS;
                } // must have at least one color
                if (!renderingOptions.converterOptions.fieldID) {
                    return RenderingErrorCodes.FIELD;
                }
                break;
            case ColorScaleType.INTERVAL:
                if (!renderingOptions.datasetStylingOptions.defaultColor) {
                    return RenderingErrorCodes.DEFAULT_COLOR;
                }
                if (!renderingOptions.converterOptions.fieldID) {
                    return RenderingErrorCodes.FIELD;
                }
                if (!renderingOptions.datasetStylingOptions.intervalOptions.length) {
                    return RenderingErrorCodes.INTERVALS;
                }
                return this.validateIntervalsOverlapping(renderingOptions);
        }

        return null;
    }

    private static validateIntervalsOverlapping(renderingOptions: RenderingOptions): string | null {
        let sortedIntervalOptions: IntervalOption[] = renderingOptions.datasetStylingOptions.intervalOptions.sort(this.getCompareIntervalsFunction());
        for (let i = 1; i <= sortedIntervalOptions.length - 1; i++) {
            let currentIntervalMax = sortedIntervalOptions[i].maxValue;
            let currentIntervalMin = sortedIntervalOptions[i].minValue;
            let previousIntervalMax = sortedIntervalOptions[i - 1].maxValue;
            let previousIntervalMin = sortedIntervalOptions[i - 1].minValue;

            if (!currentIntervalMax && i !== sortedIntervalOptions.length - 1) { // only the last interval can have no upper bound
                return RenderingErrorCodes.INTERVALS_OVERLAP;
            }

            if (!currentIntervalMin) { // because we are on the second interval in the list
                return RenderingErrorCodes.INTERVALS_OVERLAP;
            }

            if (currentIntervalMin < previousIntervalMax) {
                return RenderingErrorCodes.INTERVALS_OVERLAP;
            }
            if (currentIntervalMin === previousIntervalMin && currentIntervalMax === previousIntervalMax) {
                return RenderingErrorCodes.DUPLICATE_INTERVAL;
            }
        }
    }

    private static getCompareIntervalsFunction(): (a: IntervalOption, b: IntervalOption) => number {
        return (a: IntervalOption, b: IntervalOption) => {
            if (a.maxValue == null || b.minValue == null) {
                return 1;
            }
            if (b.maxValue == null || a.minValue == null) {
                return -1;
            }
            return a.maxValue <= b.minValue ? -1 : 1;
        };
    }

    private static validateThematicMapRendering(renderingOptions: RenderingOptions): string | null {
        if (!renderingOptions.datasetStylingOptions.defaultColor) {
            return RenderingErrorCodes.DEFAULT_COLOR;
        }
        if (!renderingOptions.converterOptions.accountApplicationFieldId) {
            return RenderingErrorCodes.APPLICATION_FIELD;
        }
        if (!renderingOptions.datasetStylingOptions.intervalOptions.length) {
            return RenderingErrorCodes.INTERVALS;
        }
        return this.validateIntervalsOverlapping(renderingOptions);
    }
}
