import { ChartDataSets, ChartLegendLabelItem } from "chart.js";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { DatapointsPageStateService } from "src/app/dataset/datapoints/datapoints-page-state.service";
import { ChartDisplayType } from "src/app/model/analytics/chart-display-type";
import { ReportDisplayType } from "src/app/model/analytics/report-display-type";
import { AggregateGroupRequest } from "src/app/model/datapoint/report/aggregate-group-request";
import { ReportRow } from "src/app/model/datapoint/report/count/report-row";
import { DatapointAggregateFieldType } from "src/app/model/datapoint/report/datapoint-aggregate-field-type";
import { ReportRequest } from "src/app/model/datapoint/report/report-request";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { DatasetUtils } from "../utils/dataset-utils";
import { DatapointProjection } from "src/app/model/datapoint/projection/datapoint-projection";
import { DatapointFilter } from "src/app/model/datapoint/filter/datapoint-filter";
import { ReportResultResponse } from "src/app/model/datapoint/report/report-result-response";
import { ReportResultGroupResponse } from "src/app/model/datapoint/report/report-result-group-response";
import { ChartInfo } from "src/app/model/datapoint/report/chart-info";
import { ColorUtils } from "../utils/color-utils";
import { ReportType } from "src/app/model/analytics/report-type";
import { TreeStructure } from "src/app/model/menu/tree-structure";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource, Sort } from "@angular/material";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { DownloadReportItem } from "src/app/model/download/item/download-report-item";
import {
    TableColumn,
    TableColumnAlignment,
    TableColumnType,
} from "src/app/model/upload/table/table-column";
import { TableRow } from "src/app/model/upload/table/table-row";
import { TableCell } from "src/app/model/upload/table/table-cell";
import { DownloadReportTableRequest } from "src/app/model/download/download-report-table-request";
import { DownloadItemReportType } from "src/app/model/download/item/download-item-report-type";
import {
    DownloadReportChartRequest,
    ValueKey,
} from "src/app/model/download/download-report-chart-request";
import { DownloadReportChartValueRequest } from "src/app/model/download/download-report-chart-value-request";

export class Count {
    treeControl = new NestedTreeControl<TreeStructure>((node) => node.children);
    dataSource = new MatTreeNestedDataSource<TreeStructure>();

    constructor(
        public readonly datapointsPageStateService: DatapointsPageStateService,
        public readonly aggregateService: DatapointsAggregateService
    ) {}

    readonly COUNT_COLUMN_ID = "count";
    readonly PERCENTAGE_COLUMN_ID = "percentage";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";

    private static compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    private LIMIT: number = 247;

    countData: any[] = [];

    public process(
        dataset: Dataset,
        countIndex: number,
        analytic,
        analyticData,
        groupsIds: any,
        countFieldsData: any
    ) {
        this.countData[countIndex] = {
            id: analytic.id,
            reportName: analytic.name,
            reportType: ReportType.COUNT,
            displayType: ReportDisplayType.TABLE,
            selectedFieldsByDatasetCount: 0,
            selectedFieldsByDataset: new Map(),
            table: {
                dynamicColumns: new Map(), // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
                columnsToDisplay: [],
                totalCount: 0,
                totalAggregate: 0,
                reportData: [],
                downloadReportData: [],
            },
            chart: {
                chartDatasets: [],
                chartLabels: [],
                chartColors: [],
                chartOptions: {
                    responsive: true,
                    legend: {
                        labels: {
                            generateLabels: (chart) => {
                                let legendItems: ChartLegendLabelItem[] = [];

                                chart.data.datasets.forEach((dataset) => {
                                    legendItems.push({
                                        text: dataset.label,
                                        fillStyle: dataset.backgroundColor[0], // because all values os the secondary dataset have the same color
                                    });
                                });
                                if (legendItems.length <= 10) {
                                    // not to overload the page
                                    return legendItems;
                                } else {
                                    return [];
                                }
                            },
                        },
                    },
                },
            },
            chartDisplayType: ChartDisplayType.COUNT,
            groupByFieldsDatasets: [],
            isTwoDimensionReport: this.isTwoDimensionReport(
                analyticData.selectedFieldsByDataset[dataset.id]
            ),
        };
        if (analyticData.selectedFieldsByDataset) {
            const selectedFieldsByDataset = new Map<string, DatasetField[]>();

            for (let datasetIdKey of Object.keys(
                analyticData.selectedFieldsByDataset
            )) {
                selectedFieldsByDataset.set(
                    datasetIdKey,
                    analyticData.selectedFieldsByDataset[datasetIdKey]
                );
            }

            selectedFieldsByDataset.forEach((fields, datasetId) => {
                this.countData[countIndex].selectedFieldsByDatasetCount +=
                    fields.length;
                let selectedFields: DatasetField[] = [];

                const selectedDataset = this.collectLinkedDatasetChooseToForm(
                    dataset
                ).find((d) => d.id === datasetId);

                if (selectedDataset) {
                    selectedDataset.fields.forEach((selectionField) => {
                        let fieldIsSelected = fields.find(
                            (f) => f.id === selectionField.id
                        );
                        if (fieldIsSelected) {
                            selectionField.selected = true;
                            selectedFields.push(selectionField);
                        }
                    });
                    this.countData[countIndex].selectedFieldsByDataset.set(
                        selectedDataset.id,
                        selectedFields
                    );
                    this.countData[countIndex].datasetFields =
                        selectedDataset.fields;
                }
            });

            // this.markDatasetFieldsAsSelected(countIndex, dataset);

            const params: any = {
                report: this.countData[countIndex],
                dataset,
                selectedFieldsByDataset:
                    this.countData[countIndex].selectedFieldsByDataset,
                groupsIds,
            };

            this.countData[countIndex].tessadataFields =
                countFieldsData.tessadataFields;
            this.countData[countIndex].tessadataGroupedFields =
                countFieldsData.tessadataGroupedFields;

            return this.generateReportData(params);
        }
    }

    isCountFieldSelected(
        fieldId: string,
        countIndex: any,
        dataset: Dataset
    ): boolean {
        if (
            this.countData[countIndex].selectedFieldsByDataset
                .get(dataset.id)
                .find((field) => field.id === fieldId)
        ) {
            return true;
        }
        return false;
    }

    markDatasetFieldsAsSelected(countIndex: number, dataset: Dataset) {
        this.dataSource.data = this.prepareDataset(dataset, countIndex);
        this.countData[countIndex].dataSource = this.dataSource;
        this.countData[countIndex].treeControl = this.treeControl;
    }

    generateReportData(params: any) {
        let reportRequest = this.createReportRequest(params);
        this.populateTableColumnsList(params.report);
        const datapointFilter: DatapointFilter = {
            datasetID: params.dataset.id,
            groups: params.groupsIds,
            fields: [],
            links: [],
        };
        const datapointProjection = this.prepareProjection(params);

        this.aggregateService
            .getDatapointsReport(
                params.dataset.id,
                datapointFilter,
                reportRequest,
                datapointProjection
            )
            .subscribe((success) => {
                this.computeTotalCount(success.groupResults, params.report);
                this.convertDataToTableFormat(
                    success.groupResults,
                    params.report
                );
                this.populateTableColumnsList(params.report);
                this.populateChartData(params.report);
                params.report.tableDownloadRequest =
                    this.getTableReportDownloadRequest(params.report);
                params.report.chartDownloadRequest =
                    this.getChartReportDownloadRequest(params.report);

                return params.report;
            });
        return params.report;
    }

    collectLinkedDatasetChooseToForm(dataset: Dataset) {
        let datasetsToGroupBy = this.datapointsPageStateService
            .getLinkedAccountDatasets()
            .concat(
                this.datapointsPageStateService
                    .getLinkedAccountOverlays()
                    .filter(
                        (overlay) =>
                            overlay.geometryType === DatasetGeometryType.COMPLEX
                    )
            );
        datasetsToGroupBy = [...[dataset], ...datasetsToGroupBy];
        if (!datasetsToGroupBy.find((dataset) => dataset.id === dataset.id)) {
            datasetsToGroupBy.splice(0, 0, dataset);
        }
        return JSON.parse(JSON.stringify(datasetsToGroupBy));
    }

    private createReportRequest(params: any): ReportRequest {
        let groups: AggregateGroupRequest[] = [];
        params.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                groups.push({
                    datasetID: datasetId,
                    fieldID: field.id,
                });
            });
        });
        return {
            datasetID: params.dataset.id,
            aggregateFieldCodes: [{ aggregateFieldCode: "1", id: "count" }],
            groups: groups,
            aggregateFieldType: DatapointAggregateFieldType.FIELD,
        };
    }

    private populateTableColumnsList(report) {
        report.table.dynamicColumns = new Map();
        report.table.columnsToDisplay = [];

        report.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                report.table.dynamicColumns.set(key, field.name);
                report.table.columnsToDisplay.push(key);
            });
        });

        report.table.columnsToDisplay.push(this.PERCENTAGE_COLUMN_ID);
        report.table.columnsToDisplay.push(this.COUNT_COLUMN_ID);
    }

    private prepareProjection(params) {
        const datapointProjection = {
            datasetID: params.dataset.id,
            fields: [],
            links: [],
        };
        params.selectedFieldsByDataset.forEach((fields, datasetId) => {
            if (datasetId === params.dataset.id) {
                datapointProjection.fields = fields.map((field) => field.id);
            } else {
                let linkProjection: DatapointProjection = {
                    datasetID: datasetId,
                    fields: fields.map((field) => field.id),
                };
                datapointProjection.links.push(linkProjection);
            }
        });

        return datapointProjection;
    }

    private computeTotalCount(groupResults: ReportResultResponse[], report) {
        groupResults.forEach((groupResult) => {
            report.table.totalCount += groupResult.values[0].count;
        });
    }

    private convertDataToTableFormat(
        groupResults: ReportResultResponse[],
        report: any
    ) {
        report.table.downloadReportData = [];
        report.table.reportData = [];
        groupResults.forEach((groupResult) => {
            let percentage: number =
                report.table.totalCount > 0
                    ? (groupResult.values[0].count / report.table.totalCount) *
                      100
                    : 0;
            let tableEntry: ReportRow = {
                count: groupResult.values[0].count,
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    groupResult.buckets
                ),
                percentage: Math.round(percentage * 100) / 100,
            };
            report.table.downloadReportData.push(tableEntry);
        });
        report.table.reportData = report.table.downloadReportData.slice(
            0,
            this.LIMIT
        );
    }

    getDynamicFieldValuesByIds(
        groupResultBuckets: ReportResultGroupResponse[]
    ): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        groupResultBuckets.forEach((groupResultBucket) => {
            let value = groupResultBucket.value
                ? groupResultBucket.value
                : "N/A";
            tableEntries.set(
                DatasetUtils.createUniqueIdentifierForDatasetField(
                    groupResultBucket.datasetID,
                    groupResultBucket.fieldID
                ),
                value
            );
        });
        return tableEntries;
    }

    /**
     * We need a structure like: Map<String, chartDataset[]>
     * The key if the main field value
     * The value is the chartDataset list for each of the other field's values (one chart dataset per second field value).
     * Each chart dataset array will contain the count values for the pair (mainField, secondField)
     */
    private populateChartData(report: any) {
        if (report.selectedFieldsByDatasetCount > 2) {
            return; // cannot create chart with more than 2 group by fields
        }
        if (report.table.reportData.length === 0) {
            return;
        }

        report.chart.chartDatasets = [];
        report.chart.chartLabels = [];
        report.chart.chartColors = [];

        let chartColors: ChartInfo[] = [];
        let mainDatasetFieldValues = []; // chart labels
        let chartDatasets: ChartDataSets[] = [];

        if (report.selectedFieldsByDatasetCount <= 1) {
            let chartDataset = {
                data: [],
            };
            let chartColor = {
                backgroundColor: [],
            };
            chartDatasets.push(chartDataset);
            chartColors.push(chartColor);

            report.table.reportData.forEach((entry) => {
                mainDatasetFieldValues.push(
                    entry.dynamicFieldValuesByIds.values().next().value
                );
                chartDataset.data.push(entry.count);
                chartColor.backgroundColor.push(
                    ColorUtils.generateRandomHexColor()
                );
            });
        } else {
            let mainField = report.table.reportData[0].dynamicFieldValuesByIds
                .keys()
                .next().value; // we will use this as the main field in the chart
            report.table.reportData.forEach((row) => {
                let mainFieldValue = row.dynamicFieldValuesByIds.get(mainField);
                if (!mainDatasetFieldValues.includes(mainFieldValue)) {
                    mainDatasetFieldValues.push(mainFieldValue);
                }
            });

            report.table.reportData.forEach((row) => {
                let mainDatasetFieldValue =
                    row.dynamicFieldValuesByIds.get(mainField);
                let indexInDatasetArray = mainDatasetFieldValues.indexOf(
                    mainDatasetFieldValue
                ); // because the labels indexes must match the values indexes
                row.dynamicFieldValuesByIds.forEach((value, datasetFieldId) => {
                    if (datasetFieldId !== mainField) {
                        let chartDataset = chartDatasets.find(
                            (cd) => cd.label === value
                        );
                        let backgroundColor = chartColors.find(
                            (bg) => bg.label === value
                        );
                        if (!chartDataset) {
                            chartDataset = {
                                data: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(0),
                                label: value,
                            };
                            chartDatasets.push(chartDataset);
                        }
                        if (!backgroundColor) {
                            backgroundColor = {
                                label: value,
                                backgroundColor: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(ColorUtils.generateRandomHexColor()),
                            };
                            chartColors.push(backgroundColor);
                        }
                        chartDataset.data[indexInDatasetArray] = row.count;
                    }
                });
            });
        }
        report.chart.chartDatasets = chartDatasets;
        report.chart.chartLabels = mainDatasetFieldValues;
        report.chart.chartColors = chartColors;
    }

    prepareDataset(dataset: Dataset, countIndex: number): TreeStructure[] {
        let sortData = [];
        sortData.push({
            name: dataset.name,
            children: this.getDatapointFields(dataset, countIndex),
        });
        return sortData;
    }

    getDatapointFields(dataset: Dataset, countIndex: number): TreeStructure[] {
        let prepareFields = [];
        dataset.fields.forEach((field) => {
            if (!field.isGenerated && !field.tags.includes("ID")) {
                if (
                    !field.isHighCardinality &&
                    field.baseType === DatasetFieldType.TEXT
                ) {
                    prepareFields.push({
                        id: field.id,
                        name: field.name,
                        selected: this.isCountFieldSelected(
                            field.id,
                            countIndex,
                            dataset
                        ),
                        params: {
                            field: field,
                            showCheckBox: true,
                            dataset: dataset,
                            callType: "dataset",
                        },
                    });
                }
            }
        });
        return prepareFields;
    }

    isTwoDimensionReport(selectedFieldsByDataset): boolean {
        let selectedFieldNo = 0;
        selectedFieldsByDataset.forEach(
            (fields, datasetId) => (selectedFieldNo += fields.length)
        );
        return selectedFieldNo === 2;
    }

    getTableReportDownloadRequest(report): DownloadReportItem {
        const {
            selectedFieldsByDataset,
            dynamicColumns,
            totalCount,
            downloadReportData,
            reportName,
        } = this.getDownloadRequestParams(report);

        let reportHeader = this.getTableReportHeader(selectedFieldsByDataset);
        let reportRows = this.getTableReportRows(
            downloadReportData,
            dynamicColumns
        );
        let reportFooter = this.getTableReportFooter(
            dynamicColumns,
            totalCount
        );
        let title = reportName || "Count";
        return new DownloadReportTableRequest(
            title,
            reportHeader,
            reportRows,
            reportFooter
        );
    }

    getTableReportHeader(selectedFieldsByDataset): TableColumn[] {
        let columns: TableColumn[] = [];
        selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });
        columns.push({
            id: this.PERCENTAGE_COLUMN_ID,
            name: "Total %",
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
        columns.push({
            id: this.COUNT_COLUMN_ID,
            name: "Count",
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        return columns;
    }

    getTableReportFooter(dynamicColumns, totalCount): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces
        cells.push({ id: this.PERCENTAGE_COLUMN_ID, value: "100%" });
        cells.push({ id: this.COUNT_COLUMN_ID, value: totalCount });
        return { cells: cells };
    }

    getTableReportRows(downloadReportData, dynamicColumns): TableRow[] {
        let rows: TableRow[] = [];
        downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });
            columns.push({
                id: this.PERCENTAGE_COLUMN_ID,
                value: row.percentage.toString() + "%",
            });
            columns.push({ id: this.COUNT_COLUMN_ID, value: row.count });
            rows.push({ cells: columns });
        });
        return rows;
    }

    sortData(sort: Sort, report: any) {
        const isAsc = sort.direction === "asc";
        const fieldId = sort.active;
        let sortedData = report.table.reportData.sort((a, b) => {
            switch (fieldId) {
                case this.COUNT_COLUMN_ID:
                    return this.compare(a.count, b.count, isAsc);
                case this.PERCENTAGE_COLUMN_ID:
                    return this.compare(a.percentage, b.percentage, isAsc);
                default: {
                    let aValue = a.dynamicFieldValuesByIds.get(fieldId);
                    let bValue = b.dynamicFieldValuesByIds.get(fieldId);
                    return this.compare(aValue, bValue, isAsc);
                }
            }
        });
        report.table.reportData = [...sortedData];
    }

    private compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    getChartReportDownloadRequest(report): DownloadReportItem {
        const {
            selectedFieldsByDataset,
            chartDatasets,
            chartColors,
            chartLabels,
            reportName,
        } = this.getDownloadRequestParams(report);

        if (!chartDatasets || !chartDatasets.length) {
            return null;
        }

        let breakdownFieldsNames: string[] = [];
        selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });
        let request: DownloadReportChartRequest = {
            title: reportName || "Count",
            valueKey: ValueKey.COUNT,
            type: undefined,
            columns: {
                value: "Count",
                categories: breakdownFieldsNames,
            },
            values: [],
        };

        chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                let chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [chartLabels[valueIndex]],
                    colors: [
                        chartColors[datasetIndex].backgroundColor[valueIndex],
                    ],
                    count: value,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        // Setting default request type as PIE CHART
        request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;

        return request;
    }

    getDownloadRequestParams(report) {
        return {
            selectedFieldsByDataset: report.selectedFieldsByDataset,
            dynamicColumns: report.table.dynamicColumns,
            totalCount: report.table.totalCount,
            downloadReportData: report.table.downloadReportData,
            reportName: report.reportName,
            chartDatasets: report.chart.chartDatasets,
            chartColors: report.chart.chartColors,
            chartLabels: report.chart.chartLabels,
            displayType: report.displayType,
        };
    }
}
