import { FormBuilder, Validators } from '@angular/forms';
import { DatasetGeometryType } from 'src/app/model/dataset/dataset-geometry-type';
import { DatasetType } from 'src/app/model/dataset/dataset-type';
import { ColorScaleType } from '../model/dataset/rendering/color-scale-type';
import { Dataset } from '../model/dataset/dataset';


export class OverlaysConstants {

    public static readonly  HIDE_GROUP_FROM_TABLE_COLUMNS_TAG = 'hide_from_table_columns';
    public static readonly NRI_EXTERNAL_DATASET_ID = "risk_index_tract";

}

export class CreateExternalOverlayControlsConfig {
    readonly externalDatasetKey = ['', Validators.required];
    readonly id = undefined;
    readonly datasetLabel = ['', Validators.required];
    readonly externalGroupId = ['', Validators.required];
    readonly fields = [[]];

    constructor(private readonly formBuilder: FormBuilder) {
    }
}

export class EditExternalOverlayControlsConfig {
    readonly externalDatasetKey = [this.dataset.externalDatasetKey, Validators.required];
    readonly id = [this.dataset.id];
    readonly datasetLabel = [this.dataset.datasetLabel, Validators.required];
    readonly fields = [this.dataset.fields];
    readonly externalGroupId = [this.dataset.externalGroupId];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dataset: Dataset
    ) {
    }
}

