import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import LatLngLiteral = google.maps.LatLngLiteral;
import {AgmInfoWindow} from '@agm/core';
import {DatapointsPageStateService} from '../../../dataset/datapoints/datapoints-page-state.service';
import {DatapointsService} from '../../../data-access-layer/datapoints/datapoints.service';
import {Dataset} from '../../../model/dataset/dataset';
import {DatapointFilterObject} from '../../../model/datapoint/datapoint-filter-object';
import {BoundsFilter} from '../../../model/geometry/bounds-filter';
import {GISUtils} from '../../../core/utils/gis-utils';
import {Type} from '../../../model/geometry/geometry-filter-shape';
import {DatasetType} from '../../../model/dataset/dataset-type';
import {DatasetField} from '../../../model/dataset/field/dataset-field';
import {Datapoint} from '../../../model/datapoint/datapoint';
import {Point} from '../../../model/geometry/point';
import {DatapointsFilterService} from '../../../dataset/datapoints/datapoints-filter.service';
import {MapStateService} from '../../map-state.service';
import {Subscription} from 'rxjs';
import {DatapointFilter} from '../../../model/datapoint/filter/datapoint-filter';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectUtils } from 'src/app/core/utils/object-utils';
import { isUndefined } from 'util';
import { Crisis24Alert } from 'src/app/model/datapoint/crisis24-alert';

@Component({
    selector: 'map-info-box',
    templateUrl: './map-info-box.component.html',
    styleUrls: ['./map-info-box.component.scss']
})
export class MapInfoBoxComponent implements OnInit, OnDestroy {

    private static readonly MAP_CLICK_ZONES_PIXEL_TOLERANCE = 5;
    private static readonly MAP_CLICK_POINTS_PIXEL_TOLERANCE = 15;

    @ViewChild('infoWindow', {static: true}) infoWindow: AgmInfoWindow;
    @Output() DetailsOpen = new EventEmitter<MapDetails>();
    infoWindowCoordinates: LatLngLiteral = {lng: 0, lat: 0};

    // datasetID - fieldID
    fieldsByIdsAndDataset: Map<string, Map<string, DatasetField>> = new Map();
    foundDatapointsByDatasetId = {}; // key = datasetID, value = datapoints
    activeOverlays: Dataset[] = [];

    haveData = false; // at least one point is found

    datasetsByIds: Record<string, Dataset> = {};
    overlaysWithMatchedPoints: Dataset[] = []; // datasets that have at least one point
    foundDatapoints = 0;
    private mapServiceSubscribtion: Subscription;

    constructor(
        private readonly datapoinsPageStateService: DatapointsPageStateService,
        private readonly datapointsService: DatapointsService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly mapStateService: MapStateService,
        public domSanitizer: DomSanitizer
    ) {

    }

    ngOnInit(): void {
        new ObjectUtils().formatStringWithHTMLtags();
    }

    init() {
        this.mapServiceSubscribtion = this.mapStateService.getActiveOverlaysSubject().subscribe(()=> {
            if (this.infoWindow !== undefined) {
                this.infoWindow.close();
            }
        });
        this.haveData = false;
        this.foundDatapointsByDatasetId = [];
        this.activeOverlays = this.datapoinsPageStateService.activeDatasetsOnMap;
        this.foundDatapointsByDatasetId = {};
        this.overlaysWithMatchedPoints = [];
    }

    open(zoom: number, coordinates: LatLngLiteral) {
        this.init();
        this.infoWindowCoordinates = coordinates;
        let requests = [];
        let datasets = [];
        this.activeOverlays.forEach(dataset => {
            this.fieldsByIdsAndDataset.set(dataset.id, new Map<string, DatasetField>(dataset.fields.map(field => [field.id, field])));
            this.datasetsByIds[dataset.id] = dataset;
            let tolerance = GISUtils.calculateMapClickToleranceDegrees(zoom, dataset.geometryType);
            let boundsFilter: BoundsFilter = {
                top: coordinates.lat + tolerance,
                bottom: coordinates.lat - tolerance,
                left: coordinates.lng - tolerance,
                right: coordinates.lng + tolerance,
                type: Type.BOUNDS
            };
            let groups = dataset.type === DatasetType.ACCOUNT_APPLICATION ? this.datapoinsPageStateService.activeGroups : [];
            let projectionFields = this.getProjectionFields(dataset);
            let activeFilter = this.datapointsFilterService.getActiveFilter();
            let filter: DatapointFilter = {datasetID: dataset.id, groups: groups, geometryFilter: {intersectionShapes: [boundsFilter], unionShapes: []}};
            filter.geometryFilter = {intersectionShapes: [boundsFilter], unionShapes: [], coords: coordinates};
            if(dataset.type === DatasetType.ACCOUNT_APPLICATION) {
                filter.fields = activeFilter.fields;
                filter.links = activeFilter.links;
            }

            let request: DatapointFilterObject = {
                filter: filter,
                projection: {datasetID: dataset.id, fields: projectionFields, geometryPrecision: zoom},
                sort: null,
                limit: 20,
                skip: 0
            };
            requests.push(this.datapointsService.getDatapointsByFilter(request).toPromise());
            datasets.push(dataset);
        });
        this.foundDatapoints = 0;
        Promise.all(requests).then((responses) => {
            for (let i = 0; i < responses.length; i++) {
                // comment
                let datapoints = responses[i];
                this.foundDatapoints += datapoints.length;
                let dataset = datasets[i];
                this.processDatapoints(datapoints, dataset);
            }
        //    this.openDetails(true);
        });
    }

    processDatapoints(datapoints, dataset) {
        this.foundDatapointsByDatasetId[dataset.id] = datapoints;
        if (datapoints.length) {
            this.overlaysWithMatchedPoints.push(dataset);
            this.haveData = true;
        }
    }

    getProjectionFields(dataset: Dataset): string[] {
        let selectedDataset: Dataset;
        if (this.datapoinsPageStateService.getFilterAccountDatasets() !== undefined && this.datapoinsPageStateService.getFilterAccountDatasets().length > 0) {
            selectedDataset = this.datapoinsPageStateService.getFilterAccountDatasets().find(element => element.id == dataset.id);
            if (selectedDataset == undefined) {
                selectedDataset = dataset;
            }
        }

        let mainSummaryFieldId = dataset.fields[selectedDataset.mainSummaryFieldIndex].id;
        let summaryFieldIds = dataset.fields.filter(field => field.isSummary && field.id !== mainSummaryFieldId).map(field => field.id);
        return [mainSummaryFieldId, ...summaryFieldIds];
    }

    get Object() {
        return Object;
    }


    openDetails(isRefreshed = false) {
        this.openDetailsByDatapoint(this.overlaysWithMatchedPoints[0].id, 0); 
    }

    openDetailsByDatapoint(datasetId: string, datapointIndex: number) {
        let datapoint = this.foundDatapointsByDatasetId[datasetId][datapointIndex];
        let object = {datapointID: datapoint.rootID, dataset: this.datasetsByIds[datasetId], refresh: false, location: datapoint.location,  version: datapoint.version};
        this.DetailsOpen.emit(object);
        this.infoWindow.close();
    }

    openDetailedView(isListCall: boolean = false) {
        const datasetId = this.overlaysWithMatchedPoints[0].id; // assume we have only one dataset and one datapoint
        const datapointIndex = 0;
        let datapoint = this.foundDatapointsByDatasetId[datasetId][datapointIndex];
        const radiusField = datapoint.fields.find(element => element.id === "crisis24_radius");
        let object = {datapointID: datapoint.rootID, dataset: this.datasetsByIds[datasetId], refresh: false, location: datapoint.location,  version: datapoint.version, radius: isUndefined(radiusField) ? null : radiusField.numberValue, isListCall: isListCall};
        this.DetailsOpen.emit(object);
        this.infoWindow.close();
    }

    ngOnDestroy(): void {
        try {
        this.mapServiceSubscribtion.unsubscribe();
        } catch(err) {
            //
        }
    }

    public isShowViewAlertsButton() {
        return this.overlaysWithMatchedPoints.length && this.overlaysWithMatchedPoints[0].id === 'crisis24_alert';
    }

    public formatedKeyName(field: DatasetField) {
        const filterField = this.fieldsByIdsAndDataset.get(this.overlaysWithMatchedPoints[0].id).get(field.id);
        let name = this.getOverlayName(filterField)
        return ['Redius'].includes(name) ? 'Radius' : name;
    }

    getOverlayName(overlay) {
        return overlay.displayName !== undefined && overlay.displayName !== null ? overlay.displayName : overlay.name;
    }
}

export interface MapDetails {
    datapointID: string;
    dataset: Dataset;
    refresh: boolean;
    location?: Point;
    version: number;
    radius?: number;
    isPopupCall?: boolean;
    isListCall?: boolean;
}