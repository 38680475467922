export interface VisualizationOptions {
    type: VisualizationType;
    radius?: number; // in meters
}

export enum VisualizationType {
    DEFAULT = 'DEFAULT',
    HEATMAP = 'HEATMAP',
    CLUSTER = 'CLUSTER',
    THEMATIC_MAP = 'THEMATIC_MAP'
}
