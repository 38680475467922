import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[trimValue]'
})
export class TrimDirective {
    constructor(
        private el: ElementRef,
        private control: NgControl
    ) { }

    @HostListener('keyup')
    applyTrim() {
        let ele = this.el.nativeElement as HTMLInputElement;
        if (typeof ele.value === 'string') {
            const value = ele.value.trim();
            this.control.control.setValue(value);
        }
    }
}
