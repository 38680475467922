import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewRef} from '@angular/core';
import {Account} from '../../model/account/account';
import {LoaderStateService} from '../../loader.service';
import {AccountService} from '../../data-access-layer/account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {UserStateService} from '../../auth/user-state-service';
import {DatapointsPageStateService} from '../../dataset/datapoints/datapoints-page-state.service';
import {isEnabled} from '../../../environments/environment';
import {Functionalities} from '../../../environments/app-functionalities';
import {Constants} from '../../constants';

@Component({
    selector: 'map-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

    accounts: Account[] = [];
    loading = false;

    overlayComponentEnabled = isEnabled(Functionalities.OVERLAYS);
    thematicMapFeatureEnabled = isEnabled(Functionalities.THEMATICMAP);

    logo = Constants.LOGO;

    private subscription;

    constructor(
        private readonly loader: LoaderStateService,
        private readonly accountService: AccountService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly authService: AuthService,
        readonly userStateService: UserStateService) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit(): void {
        this.subscription = this.loader.state.subscribe((value) => {
            this.loading = value;
            if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
                this.cdRef.detectChanges();
            }
        }, (err) => {
            console.log(err);
        });

        this.userStateService.userStateSubject.subscribe(state => {
           this.accounts = this.userStateService.availableAccounts;
        });
    }


    navigateToDatapoints(account: Account): void {
        let datasets = this.userStateService.availableDatasetsInAccount(account.id);
        if (datasets && datasets.length) {
            this.router.navigate([`private/account/${account.id}/dataset/${datasets[0].id}/datapoints/map`]);
        }
    }

    navigateToSettings(account: Account): void {
        this.router.navigate([`private/account/${account.id}/settings/fields`]);
    }

    logout(): void {
        this.authService.logout();
    }

}
