import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogModel} from '../../../model/dialog/dialog-model';

@Component({
    selector: 'restrict-dialog',
    styleUrls: ['./restrict-dialog.component.scss'],
    templateUrl: './restrict-dialog.component.html'
})
export class RestrictDialogComponent {
    title: string;
    message: string;
    cancelButton: string;
    confirmButton: string;

    constructor(public dialogRef: MatDialogRef<RestrictDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogModel) {
        this.title = data.title;
        this.message = data.message;
        this.confirmButton = data.confirmButton ? data.confirmButton : 'Ok';
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }
}
