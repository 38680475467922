import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {EditOverlayComponent} from '../overlays/overlay-edit/edit-overlay.component';
import {RoutingUtils} from '../core/utils/routing-utils';
import {DatasetType} from '../model/dataset/dataset-type';

@Component({
    selector: 'map-edit-global-overlay',
    templateUrl: './edit-global-overlay.component.html',
    styleUrls: ['./edit-global-overlay.component.scss']
})
export class EditGlobalOverlayComponent implements OnInit {
    @ViewChild('editOverlay', {static: true}) editOverlayComponent: EditOverlayComponent;

    constructor(
        private readonly router: Router
    ) {
    }

    ngOnInit() {
    }

    updateOverlay() {
        this.editOverlayComponent.updateOverlay();
    }

    navigateBackToOverlays() {
        this.router.navigateByUrl(RoutingUtils.getGlobalOverlaysRoute());
    }
    get DatasetType() {
        return DatasetType;
    }
}
