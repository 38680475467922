import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {Dataset} from '../../model/dataset/dataset';
import {NgbdSortableHeaderDirective, SortDirection, SortEvent} from '../directives/sort.directive';
import {Column} from '../../dataset/datapoints/datapoints-table/datapoints-table.component';

interface TableItem {
    name?: string;
    id?: string;
    fields?: any[];
    formulaResults: number[];
    selected?: boolean;

    [flags: string]: any;
}

@Component({
    selector: 'map-maptycs-table',
    templateUrl: './maptycs-table.component.html',
    styleUrls: ['./maptycs-table.component.scss']
})
export class MaptycsTableComponent implements OnInit, OnChanges {
    selectAll = false;
    @Input() draftsTable: boolean;
    @Input() dataset: Dataset;
    @Input() columns: Column[];
    @Input() items: TableItem[];
    @Output() rowClickEvent: EventEmitter<string> = new EventEmitter<string>();
    @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
    @Output() sort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();
    @HostBinding('class') class = 'maptycs-table';
    sortable: { column: string | number | symbol | ''; direction: 'asc' | 'desc' | '' };
    // get sortableClass(column) {
    //     return  (column.columnName ===  sortable.column) ? sortable.direction : '';
    // }
    constructor(
        private readonly maptycsTableTemplateRef: ElementRef
    ) {
        this.sortable = {column: '', direction: ''};
        this.items = [];
    }

    get selected(): { items: any[], selectAll: boolean } {
        return {
            items: this.items.filter(item => {
                return item.selected;
            }),
            selectAll: this.selectAll
        };
    }


    ngOnInit(): void {
    }

    ngOnChanges(): void {
        this.maptycsTableTemplateRef.nativeElement.scroll({behavior: 'smooth', top: 0});
    }

    onSort(column: Column): void {
        const flip: {[key: string]: SortDirection} = { ['asc']: 'desc', ['desc']: '', ['']: 'asc' };
        let direction;
        direction = flip[this.sortable.direction];
        this.sortable = { column: column.columnName, direction: direction};
        this.sort.emit({column:column.columnName, direction: this.sortable.direction});
    }

    toggleSelectAllRows(): void {
        this.items.forEach((row: any) => row.selected = this.selectAll);
    }

    onClick(id: any): void {
        this.rowClickEvent.emit(id);
    }
}
