import {Component, OnInit} from '@angular/core';
import {MatCheckboxChange, MatDialog, MatTableDataSource} from '@angular/material';
import {OverlaysService} from '../../data-access-layer/global-overlays/overlays.service';
import {Dataset} from '../../model/dataset/dataset';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {UserStateService} from '../../auth/user-state-service';
import {Account} from '../../model/account/account';
import {AccountService} from '../../data-access-layer/account/account.service';
import {DatapointsPageStateService} from '../../dataset/datapoints/datapoints-page-state.service';
import {RoutingUtils} from '../../core/utils/routing-utils';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {DialogModel} from '../../model/dialog/dialog-model';
import {NotifService} from '../../core/notification/notif.service';

@Component({
    selector: 'map-account-private-overlays',
    templateUrl: './account-private-overlays.component.html',
    styleUrls: ['./account-private-overlays.component.scss']
})
export class AccountPrivateOverlaysComponent implements OnInit {
    accountId: number;
    currentAccount: Account;
    isTableDataLoading: boolean;
    globalOverlays: Dataset[];
    accountOverlays: Dataset[];
    overlaysDataSource: MatTableDataSource<Dataset>;
    currentDisplayedColumns: string[] = ['Name', 'DatasetID', 'Actions'];
    allSelected: boolean;
    breadCrumbs = ['Home', 'Accounts', 'Settings'];
    get componentName() {
        return this.constructor.name;
    }

    constructor(
        private readonly notifService: NotifService,
        private readonly overlayService: OverlaysService,
        public readonly dialog: MatDialog,
        private readonly userStateService: UserStateService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly accountService: AccountService
    ) {
        this.globalOverlays = [];
        this.accountOverlays = [];
    }

    ngOnInit() {
        this.accountId = +this.route.snapshot.paramMap.get('accountId');
        this.init();
    }

    applyFilter(filterValue: string): void {
        this.overlaysDataSource.filter = filterValue.trim().toLowerCase();
    }

    fetchOverlays(): void {
        this.overlayService.getAccountPrivateOverlays(this.accountId).subscribe((overlays)=> {
            this.accountOverlays = overlays;
            // if (this.userStateService.isSuperadmin) {
            //     this.overlaysDataSource = new MatTableDataSource(globalOverlays);
            // } else if (this.userStateService.isAccountAdmin(this.accountId)) {
            this.overlaysDataSource = new MatTableDataSource(this.accountOverlays);
            // }
            // this.overlaysDataSource.data.forEach(overlay => {
            //     overlay.hasOverlay = this.accountHasOverlay(overlay.id);
            // });
            this.allSelected = false;
        });
        // forkJoin(
        //     this.overlayService.getAllGlobalOverlays(),
        //     this.overlayService.getAccountGlobalOverlays(this.accountId)
        // ).subscribe(([globalOverlays, accountOverlays]: [Dataset[], Dataset[]]) => {

        // });
    }

    accountHasOverlay(overlayId: string): boolean {
        const overlay = this.accountOverlays.find(accountOverlay => accountOverlay.id === overlayId);
        return overlay ? true : false;
    }

    asssignOverlay(overlay: Dataset): void {
        this.accountOverlays.push(overlay);
        overlay.hasOverlay = true;
        this.overlayService.getAccountsForGlobalOverlay(overlay.id)
            .pipe(
                map(accounts => accounts.map(account => account.id))
            )
            .subscribe(accountIds => {
                accountIds.push(this.accountId);
                this.overlayService.assignAccountsToGlobalOverlay(accountIds, overlay.id).subscribe(() => this.updateAccountOverlays());
            });
    }

    unasssignOverlay(overlay: Dataset): void {
        this.accountOverlays = this.accountOverlays.filter(accountOverlay => accountOverlay.id !== overlay.id);
        overlay.hasOverlay = false;
        this.overlayService.getAccountsForGlobalOverlay(overlay.id)
            .pipe(
                map(accounts => accounts.map(account => account.id))
            )
            .subscribe(accountIds => {
                accountIds = accountIds.filter(accountId => accountId !== this.accountId);
                this.overlayService.assignAccountsToGlobalOverlay(accountIds, overlay.id).subscribe(() => this.updateAccountOverlays());
            });
    }

    updateAccountOverlays(): void {
        this.overlayService.getAccountGlobalOverlays(this.accountId).subscribe(accountOverlays => this.accountOverlays = accountOverlays);
    }

    applyAll() {
        // let selected = $event.checked;
        let datasetIDs = [];
        this.overlaysDataSource.data.forEach((overlay)=> {
            if(overlay.hasOverlay) {
                datasetIDs.push(overlay.id);
            }
        });
        this.overlayService.assignGlobalOverlaysToAccount({accountID: this.accountId, datasetIDs}).subscribe(() => {
            this.init();
        });

    }

    private init(): void {
        if (this.accountId) {
            this.accountService.getAccount(this.accountId).subscribe((account) => {
                this.currentAccount = account;
                this.datapointsPageStateService.activeAccount = account;
            });
            this.fetchOverlays();
            if (!this.userStateService.isSuperadmin) {
                this.currentDisplayedColumns.splice(this.currentDisplayedColumns.indexOf('Actions'), 1);
            }
        }
    }

    toggleOverlay($event: MatCheckboxChange, overlay: Dataset) {
        let selected = $event.checked;
        if (selected) {
            this.asssignOverlay(overlay);
        } else {
            this.unasssignOverlay(overlay);
        }
    }

    toggleAll() {
        this.overlaysDataSource.data.forEach((overlay)=> {
            overlay.hasOverlay = this.allSelected;
        });
    }

    editOverlay(overlay: Dataset) {
        this.router.navigateByUrl(RoutingUtils.getPrivateOverlaysEditRoute(overlay.id, this.accountId));
    }
    createOverlay() {
        this.router.navigateByUrl(RoutingUtils.getPrivateOverlaysCreateRoute(this.accountId));
    }
    deleteOverlay(overlay: Dataset): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete "${ overlay.name }" overlay?`
            )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.overlayService.deleteOverlay(overlay.id).subscribe((res) => {
                    this.init();
                    this.notifService.success('Successfully deleted overlay');
                });
            }
        });
    }

}
