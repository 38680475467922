import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Constants } from '../../constants';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class HttpDomainInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.startsWith('http')) {
            req = req.clone({url: Constants.BASE_DOMAIN + req.url});
        }
        return next.handle(req);
    }
}
