import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {AccountMember} from '../../model/member/account-member';
import {Constants} from '../../constants';

@Component({
    selector: 'map-group-permission-login',
    templateUrl: './group-permission-login.component.html',
    styleUrls: ['./group-permission-login.component.scss']
})
export class GroupPermissionLoginComponent implements OnInit {

    groupId: number;
    token: string;
    displayError = false;

    logo = Constants.WHITE_LOGO;

    constructor(private readonly route: ActivatedRoute,
                private readonly authService: AuthService,
                private readonly router: Router) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.groupId = parseInt(this.route.snapshot.paramMap.get('groupId'));
            this.token = this.route.snapshot.paramMap.get('token');

        });
        if (this.groupId && this.token) {
            this.authService.loginUsingGroupPermissionToken(this.token, this.groupId).subscribe(response => {
                this.authService.setupAuthenticationDetails(response);
                let membership: AccountMember = this.authService.getPrincipal().membership[0];
                let datasetId = Object.keys(membership.datasetGroups)[0];
                this.router.navigate([`private/account/${membership.accountID}/dataset/${datasetId}/datapoints/map`], {
                    replaceUrl: true,
                    state: {openThematicMap: true}
                });
            }, error => {
                this.authService.cleanAuthenticationDetails();
                this.displayError = true;
            });
        } else {
            this.displayError = true;
        }
    }

}
