import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'map-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  breadCrumbs = ['Home', 'Overlays'];
  @Input () showLinks = true;
    headerLinks;
  constructor(
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
  }

}
