import { LoginRequest } from './login-request';

export default class LoginDto implements LoginRequest {
    public readonly email: string;
    public readonly password: string;
    public readonly code?: string;

    constructor(email: string, password: string, code: string) {
        this.email = email;
        this.password = password;
        this.code = code
    }

}
