import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogModel } from '../../model/dialog/dialog-model';

@Component({
    selector: 'map-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
    title: string;
    message: string;
    cancelButton: string;
    confirmButton: string;

    constructor(public dialogRef: MatDialogRef<DialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogModel) {
        this.title = data.title;
        this.message = data.message;
        this.cancelButton = data.cancelButton ? data.cancelButton : 'No';
        this.confirmButton = data.confirmButton ? data.confirmButton : 'Yes';
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

}
