export enum TessadataNriFields {
    SUMMARY_RISK_RATING = 'risk_index_tract_risk_ratng',
    SUMMARY_EAL_RATING = 'risk_index_tract_eal_ratng',
    SUMMARY_SOVI_RATING = 'risk_index_tract_sovi_ratng',
    SUMMARY_RESL_RATING = 'risk_index_tract_resl_ratng',

    AVALANCHE_RSK = 'risk_index_tract_avln_riskr',
    AVALANCHE_EAL = 'risk_index_tract_avln_ealr',
    AVALANCHE_HLR = 'risk_index_tract_avln_hlrr',

    COASTAL_FLOODING_RSK = 'risk_index_tract_cfld_riskr',
    COASTAL_FLOODING_EAL = 'risk_index_tract_cfld_ealr',
    COASTAL_FLOODING_HLR = 'risk_index_tract_cfld_hlrr',

    COLDWAVE_RSK = 'risk_index_tract_cwav_riskr',
    COLDWAVE_EAL = 'risk_index_tract_cwav_ealr',
    COLDWAVE_HLR = 'risk_index_tract_cwav_hlrr',

    DROUGHT_RSK = 'risk_index_tract_drgt_riskr',
    DROUGHT_EAL = 'risk_index_tract_drgt_ealr',
    DROUGHT_HLR = 'risk_index_tract_drgt_hlrr',

    EARTHQUAKE_RSK = 'risk_index_tract_erqk_riskr',
    EARTHQUAKE_EAL = 'risk_index_tract_erqk_ealr',
    EARTHQUAKE_HLR = 'risk_index_tract_erqk_hlrr',

    HAIL_RSK = 'risk_index_tract_hail_riskr',
    HAIL_EAL = 'risk_index_tract_hail_ealr',
    HAIL_HLR = 'risk_index_tract_hail_hlrr',

    HEATWAVE_RSK = 'risk_index_tract_hwav_riskr',
    HEATWAVE_EAL = 'risk_index_tract_hwav_ealr',
    HEATWAVE_HLR = 'risk_index_tract_hwav_hlrr',

    HURRICANE_RSK = 'risk_index_tract_hrcn_riskr',
    HURRICANE_EAL = 'risk_index_tract_hrcn_ealr',
    HURRICANE_HLR = 'risk_index_tract_hrcn_hlrr',

    ICESTORM_RSK = 'risk_index_tract_istm_riskr',
    ICESTORM_EAL = 'risk_index_tract_istm_ealr',
    ICESTORM_HLR = 'risk_index_tract_istm_hlrr',

    LANDSLIDE_RSK = 'risk_index_tract_lnds_riskr',
    LANDSLIDE_EAL = 'risk_index_tract_lnds_ealr',
    LANDSLIDE_HLR = 'risk_index_tract_lnds_hlrr',

    LIGHTNING_RSK = 'risk_index_tract_ltng_riskr',
    LIGHTNING_EAL = 'risk_index_tract_ltng_ealr',
    LIGHTNING_HLR = 'risk_index_tract_ltng_hlrr',

    RIVERINEFLOOD_RSK = 'risk_index_tract_rfld_riskr',
    RIVERINEFLOOD_EAL = 'risk_index_tract_rfld_ealr',
    RIVERINEFLOOD_HLR = 'risk_index_tract_rfld_hlrr',

    STRONGWIND_RSK = 'risk_index_tract_swnd_riskr',
    STRONGWIND_EAL = 'risk_index_tract_swnd_ealr',
    STRONGWIND_HLR = 'risk_index_tract_swnd_hlrr',

    TORNADO_RSK = 'risk_index_tract_trnd_riskr',
    TORNADO_EAL = 'risk_index_tract_trnd_ealr',
    TORNADO_HLR = 'risk_index_tract_trnd_hlrr',

    TSUNAMI_RSK = 'risk_index_tract_tsun_riskr',
    TSUNAMI_EAL = 'risk_index_tract_tsun_ealr',
    TSUNAMI_HLR = 'risk_index_tract_tsun_hlrr',

    VOLCANIC_RSK = 'risk_index_tract_vlcn_riskr',
    VOLCANIC_EAL = 'risk_index_tract_vlcn_ealr',
    VOLCANIC_HLR = 'risk_index_tract_vlcn_hlrr',

    WILDFIRE_RSK = 'risk_index_tract_wfir_riskr',
    WILDFIRE_EAL = 'risk_index_tract_wfir_ealr',
    WILDFIRE_HLR = 'risk_index_tract_wfir_hlrr',

    WINTER_RSK = 'risk_index_tract_wntw_riskr',
    WINTER_EAL = 'risk_index_tract_wntw_ealr',
    WINTER_HLR = 'risk_index_tract_wntw_hlrr'

}
