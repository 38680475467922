import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'map-dataset-download',
    templateUrl: './dataset-download.component.html',
    styleUrls: ['./dataset-download.component.scss']
})
export class DatasetDownloadComponent {
    downloadDatapointsForm: FormGroup;
    advancedOptions = false;

    constructor(
        private readonly fb: FormBuilder,
        public dialogRef: MatDialogRef<DatasetDownloadComponent>) {
        let groupObject = {
            filter: new FormControl('CURRENT_FILTER'),
            fileName: new FormControl('datapoints'),
            fileType: new FormControl('XLSX'),
            projection: new FormControl('CURRENT_FIELDS'),
            sort: new FormControl('CURRENT_SORT')
        };
        this.downloadDatapointsForm = fb.group(groupObject);
    }


    onNoClick(): void {
        this.dialogRef.close(null);
    }

    submitDownloadRequest() {
        this.dialogRef.close(this.downloadDatapointsForm.value);
    }
}
