import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { TreeStructure } from "src/app/model/menu/tree-structure";
import { isUndefined } from "util";

export class Datapoints {

    parentOverlay: any;

    prepareClimateData(overlays, isProjectedCall: boolean): TreeStructure[] {

        if (isProjectedCall) {
            overlays = this.prepareViewFilterAccountOverlays(overlays, isProjectedCall);
        }
        let sortData = [];
        overlays.forEach((group, key) => {
            if ((group.children.length > 0 && group.overlays.length <= 0) ||
                (group.children.length <= 0 && group.overlays.length > 0 && this.pointOverlayGroupCount(group.overlays)) ||
                (group.children.length > 0 && group.overlays.length > 0 && this.pointOverlayGroupCount(group.overlays))) {
                const children = this.climateRecursiveCall(group, isProjectedCall);
                if (children.length) {
                    const data = children.length ? { id: group.group.id, name: group.group.name, children: children, params: { showCheckBox: true } } : { id: group.group.id, name: group.group.name, params: { showCheckBox: true } };
                    sortData.push(data);
                }
            }
        });
        return sortData;
    }

    climateRecursiveCall(group, isProjectedCall: boolean): TreeStructure[] {
        let climateData = [];
        if (group.children.length > 0 && group.overlays.length <= 0) {
            climateData = this.getClimateChildren(group, isProjectedCall);
        } else if (group.children.length <= 0 && group.overlays.length > 0) {
            climateData = this.getClimateOverlays(group, isProjectedCall);
        } else if (group.children.length > 0 && group.overlays.length > 0) {
            climateData = [...this.getClimateChildren(group, isProjectedCall), ...this.getClimateOverlays(group, isProjectedCall)];
        }
        return climateData;
    }

    private getClimateChildren(group, isProjectedCall: boolean): TreeStructure[] {
        let childrenData = [];
        group.children.forEach(overlay => {
            if (overlay.children.length > 0 || overlay.overlays.length > 0) {
                const children = this.climateRecursiveCall(overlay, isProjectedCall);
                const data = children.length ? { id: overlay.group.id, name: overlay.group.name, children: this.climateRecursiveCall(overlay, isProjectedCall), params: { showCheckBox: true } } : { id: overlay.group.id, name: overlay.group.name, params: { showCheckBox: true } };
                childrenData.push(data);
            }
        });
        return childrenData;
    }

    private getClimateOverlays(group, isProjectedCall: boolean): TreeStructure[] {
        let overlayData = [];
        group.overlays.forEach(overlay => {
            if (this.pointOverlayCount(overlay, isProjectedCall)) {
                let fields;
                if (isProjectedCall) {
                    fields = overlay.fields.filter(field => !field.isGenerated && !field.tags.includes('ID') && field.displayInDropdowns && field.isProjected);
                } else {
                    fields = overlay.fields.filter(field => !field.isGenerated && !field.tags.includes('ID') && field.displayInDropdowns);
                }
                let prepareFields = [];
                fields.forEach((element, key) => {
                    const field = prepareFields.length == 0 ? overlay : null;
                    const name = element.displayName == null || element.displayName == undefined ? element.name : element.displayName;
                    prepareFields.push({ id: element.id, name: name, params: { field: element, overlay: overlay, showCheckBox: true, callType: 'climate', key: key } });
                });
                const name = overlay.displayName == null || overlay.displayName == undefined ? overlay.name : overlay.displayName;
                const data = prepareFields.length ? { id: overlay.id, name: name, selected: false, children: prepareFields, params: { showCheckBox: true } } : { id: overlay.id, name: name, selected: false, children: [{ id: overlay.id, name: name, params: { showCheckBox: false, overlay: overlay } }], params: { showCheckBox: false } };
                overlayData.push(data);
            }
        });
        return overlayData;
    }

    pointOverlayGroupCount(overlays: Dataset[]) {
        const filteredOverlayGroups = overlays.filter(overlay => [DatasetGeometryType.POINT, DatasetGeometryType.COMPLEX].includes(overlay.geometryType));
        return filteredOverlayGroups.length;
    }

    pointOverlayCount(overlay: Dataset, isProjectedCall: boolean) {
        let fields;
        if (isProjectedCall) {
            fields = overlay.fields.filter(field => !field.isGenerated && !field.tags.includes('ID') && field.displayInDropdowns && field.isProjected);
        } else {
            fields = overlay.fields.filter(field => !field.isGenerated && !field.tags.includes('ID') && field.displayInDropdowns);
        }
        return [DatasetGeometryType.POINT, DatasetGeometryType.COMPLEX].includes(overlay.geometryType) || fields.length;
    }

    prepareClimateGridData(overlays) {
        let sortData = [];
        overlays.forEach((group, key) => {
            if (!isUndefined(group.children) && group.children.length > 0 || !isUndefined(group.overlays) && group.overlays.length > 0) {
                const children = this.climateGridRecursiveCall(group);
                if (children.length) {
                    sortData.push(children);
                }
            }
        });
        var merged = sortData.reduce(function (prev, next) {
            return prev.concat(next);
        });
        return { headerName: 'Climate', headerTooltip: 'Climate', marryChildren: true, children: merged } //{...parentLayer, ... {children: merged}};
    }

    climateGridRecursiveCall(group) {
        let childrenData = [];
        if (group.children !== undefined && group.children.length > 0) {
            group.children.forEach(overlay => {
                const children = this.climateGridRecursiveCall(overlay);
                const data = children.length ? { headerName: overlay.name, headerTooltip: overlay.name, children: children, marryChildren: true, filter: false, pivot: false, enableRowGroup: false, sortable: false } : { headerName: overlay.name, headerTooltip: overlay.name, field: group.id.toString(), coldId: group.id.toString(), marryChildren: false, hide: true, filter: false, pivot: false, enableRowGroup: false, sortable: false };
                childrenData.push(data);
            });
        }
        return childrenData;
    }

    prepareViewFilterAccountOverlays(overlays, isProjected: boolean = false) {
        let groupIds = [];
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
                return;
            } else if (element.children.length > 0) {
                this.filterAccountOverlaysCallback(element, isProjected);
            }

            if ((!isUndefined(element.overlays) && element.overlays.length > 0)) {
                const overlays = element.overlays.filter(overlay => overlay.geoWorkspace == 'ipcc');
                element.overlays = overlays;
                if (element.overlays.length > 0) {
                    element.overlays.forEach((sub_element, sub_element_key) => {
                        let filterValue: any;
                        if (isProjected) {
                            filterValue = sub_element.fields.filter(field_element => field_element.displayInDropdowns == true && field_element.isProjected == true);
                        } else {
                            filterValue = sub_element.fields.filter(field_element => field_element.displayInDropdowns == true);
                        }
                        sub_element.fields = [];
                        if (filterValue.length) {
                            sub_element.fields = filterValue;
                        } else {
                            element.overlays.splice(sub_element_key, 1);
                        }

                    });
                }

            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                groupIds.push(element.group.id);
            }
        });
        overlays = overlays.filter(overlay => !groupIds.includes(overlay.group.id));
        return overlays;
    }

    filterAccountOverlaysCallback(element, isProjected: boolean) {

        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (sub_element.children.length <= 0 && sub_element.overlays.length <= 0) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(params => !groupIds.includes(params.group.id))
                    this.filterAccountOverlaysCallback(sub_element, isProjected);
                    return;
                }

                if (sub_element.overlays.length) {
                    const overlays = sub_element.overlays.filter(overlay => overlay.geoWorkspace == 'ipcc');
                    sub_element.overlays = overlays;
                    if (sub_element.overlays.length > 0) {
                        sub_element.overlays.forEach((overlay, sub_element_key) => {
                            let filterValue: any;
                            if (isProjected) {
                                filterValue = overlay.fields.filter(field_element => field_element.displayInDropdowns == true && field_element.isProjected == true);
                            } else {
                                filterValue = overlay.fields.filter(field_element => field_element.displayInDropdowns == true);
                            }
                            overlay.fields = [];
                            if (filterValue.length) {
                                overlay.fields = filterValue;
                            } else {
                                sub_element.overlays.splice(sub_element_key, 1);
                            }
                        });
                    }
                }

                if (sub_element.children.length <= 0 && sub_element.overlays <= 0) {
                    groupIds.push(sub_element.group.id);
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(params => !groupIds.includes(params.group.id))
                this.filterAccountOverlaysCallback(this.parentOverlay, isProjected);
            }
        }

    }

}