import { TableUploadColumnValue } from './table-upload-column-value';

export interface TableUploadColumnMapping {
    type: TableUploadColumnMappingType;
    columnID: string;
    fieldID?: string;
    dateFormat?: string;
    staticValue?: TableUploadColumnValue;
}

export enum TableUploadColumnMappingType {
    LONGITUDE = 'LONGITUDE',
    LATITUDE = 'LATITUDE',
    FIELD = 'FIELD',
    STATIC = 'STATIC'
}
