import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatCheckboxChange, MatTableDataSource } from '@angular/material';
import { UserStateService } from 'src/app/auth/user-state-service';
import { NotifService } from 'src/app/core/notification/notif.service';
import { OverlaysService } from 'src/app/data-access-layer/global-overlays/overlays.service';
import {Account} from 'src/app/model/account/account';
import { Dataset } from 'src/app/model/dataset/dataset';
import { DatasetField } from 'src/app/model/dataset/field/dataset-field';
import { AccountServiceState } from 'src/app/shared/services/account-service-state';
@Component({
  selector: 'map-edit-external-overlay-account',
  templateUrl: './edit-external-overlay-account.component.html',
  styleUrls: ['./edit-external-overlay-account.component.scss']
})
export class EditExternalOverlayAccountComponent implements OnInit {

  @ViewChild('selectAll',{static: false}) selectAll: MatCheckbox;

  @Input() currentOverlay;
  @Output() updatedSuccessfully = new EventEmitter<string>();

  accountsDataSource: MatTableDataSource<Account>;
  fields: DatasetField[] = [];
  filteredAccounts: Account[];
  private accounts: Account[];
  loading = false;

  constructor(
      private readonly overlaysService: OverlaysService,
      private readonly notifService: NotifService,
      private readonly changeDetector: ChangeDetectorRef,
      private readonly userStateService: UserStateService,
      private readonly accountServiceState: AccountServiceState,
  ) {
  }

  ngOnInit(): void {
      this.accountServiceState.emitOnInitEditOverlay();
    }

  fetchAccountsInfo(overlay: Dataset) {
      this.loading = true;
      this.userStateService.userStateSubject.subscribe(ready =>{
          let accounts = this.userStateService.availableAccounts;
          this.overlaysService.getAccountsForExtrnalOverlay(overlay.datasetId).subscribe((accountsForOverlay) => {
              accounts.forEach(account => account.isSelected = accountsForOverlay.find(a => a.id === account.id));
              this.accountsDataSource = new MatTableDataSource(accounts);
              this.accounts = this.filteredAccounts = accounts;
              this.changeDetector.detectChanges();
          });
          this.loading = false;
      });
  }

  applyFilter(filterValue: string): void {
      let searchValue = filterValue.trim().toLowerCase();
      this.filteredAccounts = this.accounts.filter((account) => {
          return account.name.trim().toLowerCase().includes(searchValue);
      });
  }


  assignAccountsToExternalOverlay(): void {
      this.loading = true;
      this.selectAll.checked = false;
      let selectedAccountsIds = this.accountsDataSource.data
          .filter(account => account.isSelected)
          .map(account => account.id);

      this.overlaysService.assignAccountsToExternlOverlay(selectedAccountsIds, this.currentOverlay.datasetId).subscribe((res) => {
          this.notifService.success(`Successfully assign overlay to ${selectedAccountsIds.length} accounts`);
          this.fetchAccountsInfo(this.currentOverlay);
          this.updatedSuccessfully.emit();
          this.loading = false;
      }, (err) => {
          console.log(err);
          this.fetchAccountsInfo(this.currentOverlay);
      });
  }

  init(overlay: Dataset) {
      this.fetchAccountsInfo(overlay);
  }

  toggleSelectAll($event: MatCheckboxChange) {
      let selected = $event.checked;
      this.accounts.forEach((account) => {
          account.isSelected = selected;
      });
  }

}
