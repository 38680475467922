import {Constants} from '../../constants';
import {Attachment} from '../../model/attachment/attachment';
import {HttpResponse} from '@angular/common/http';

export class AttachmentUtils {

    public static getFileIconByExtension(fileName: string): string {
        const splitted = fileName.split('.');
        const ext = splitted.length ? splitted[splitted.length - 1].toLowerCase() : undefined;
        const icon = Constants.EXTENSIONS[ext] || Constants.EXTENSIONS.other;
        return `../../assets/icons/files/${icon}.svg`;
    }

    public static isPdf(attachment: Attachment): boolean {
        return attachment.mimeType === 'pdf';
    }

    public static isImage(attachment: Attachment): boolean {
        return attachment.mimeType === 'jpg';
    }

    public static downloadFileWithName(data: Response, fileName: string) {
        // @ts-ignore
        const blob = new Blob([data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // To IE or Edge browser, using msSaveorOpenBlob method to download file.
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }
    }

    public static downloadFile(data: HttpResponse<any>) {
        const fileNameHeader = data.headers.get('Content-Disposition').split('filename=');
        if (!fileNameHeader.length) {
            return;
        }
        return this.downloadFileWithName(data.body, fileNameHeader[1].split('"')[1]);
    }


}
