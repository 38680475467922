import {Component, DoCheck, OnInit} from '@angular/core';
import {UserStateService} from './auth/user-state-service';
import {AuthService} from './auth/auth.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {state} from '@angular/animations';
import {Router} from '@angular/router';

@Component({
    selector: 'map-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    private static readonly DEFAULT_PAGE_TITLE = 'Maptycs';
    private static readonly DEFAULT_FAVICON = 'favicon.png';
    principal;
    favicon: HTMLLinkElement = document.querySelector('#favicon');

    constructor(private readonly userStateService: UserStateService,
                private readonly titleService: Title,private readonly router: Router,
                private readonly authService: AuthService) {
        this.titleService.setTitle(environment.pageTitle || AppComponent.DEFAULT_PAGE_TITLE);
        if (environment.customFavicon) {
            this.favicon.href = environment.customFavicon;
        }
    }

    ngOnInit(): void {

        this.principal = this.authService.getPrincipal();
        if (this.principal) {
            this.userStateService.initialize(this.principal);
        }
        console.log('App component INIT. for test only');
    }

}
