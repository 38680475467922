import {Constants, DistanceUnit} from '../../constants';

export class ComputationUtils {

    static getDistanceInMeters(value: number, unit: DistanceUnit): number {
        if (!value || !unit) {
            return null;
        }
        switch (unit) {
            case DistanceUnit.KM:
                return value * Constants.ONE_KM_IN_METERS;
            case DistanceUnit.MILES:
                return value * Constants.ONE_MILE_IN_METERS;
        }
    }

    static getDistanceInUnit(distanceInM: number, unit: DistanceUnit): number {
        if (!distanceInM || !unit) {
            return 0;
        }
        switch (unit) {
            case DistanceUnit.KM:
                return Number((distanceInM / Constants.ONE_KM_IN_METERS).toFixed(2));
            case DistanceUnit.MILES:
                return Number((distanceInM / Constants.ONE_MILE_IN_METERS).toFixed(2));
        }

    }
}
