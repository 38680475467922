import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../data-access-layer/account/account.service';
import { Account } from '../../model/account/account';
import { UserStateService } from '../../auth/user-state-service';
import { AuthService } from '../../auth/auth.service';
import { Constants } from '../../constants';
import { Subject } from 'rxjs';
import { Group } from '../../model/group/group';
import { NotifService } from '../../core/notification/notif.service';
import { MatCheckboxChange } from '@angular/material';
import { Modules } from 'src/app/model/modules/modules';

@Component({
    selector: 'map-edit-account',
    templateUrl: './edit-account.component.html',
    styleUrls: ['./edit-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditAccountComponent implements OnInit {
    @Input() account: Account;
    @Input() emitAccountOnEditSubject: Subject<Group>;
    @Output() Update: EventEmitter<string> = new EventEmitter<string>();
    editAccountForm: FormGroup;
    loading = false;
    selectedValue: boolean = false;
    filteredModules: Modules[];
    private modules: Modules[];

    constructor(
        private readonly accountService: AccountService,
        private readonly userStateService: UserStateService,
        private readonly auth: AuthService, private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.editAccountForm = new FormGroup({
            id: new FormControl(null),
            name: new FormControl(null, [Validators.required, Validators.maxLength(Constants.ACCOUNT_NAME_LENGTH),  Validators.pattern('^[A-Za-z0-9]+( [A-Za-z0-9]+)*$')])
        });
        this.emitAccountOnEditSubject.subscribe(account => {
            this.selectedValue = false;
          //  this.fetchAccountsModules(account);
            this.editAccountForm.patchValue({
                id: account.id,
                name: account.name
            });
        });
    }

    submitAccount() {
        if (this.editAccountForm.valid) {
            let { name } = this.editAccountForm.getRawValue();
            this.account.name = name;
            // let modulePayload = this.modules.map(element => {
            //     return { [element.moduleId.toString()]: element.isSelected };
            // });
            // modulePayload = Object.assign({}, ...modulePayload );
            
            this.accountService.updateAccount(this.account.id, this.account).subscribe((res) => {
                // this.accountService.updateAccountModules(this.account.id, modulePayload).subscribe((moduleRes) => {
                //     this.userStateService.initialize(this.auth.principal).then(() => {
                //         this.Update.emit('updated');
                //     });
                // });
                this.Update.emit('updated');
            }, err => {
                this.Update.emit('Error: Not updated;');
                this.notifService.success('Account with this username already exists.');
            });
        }
    }

    fetchAccountsModules(account: any) {
        this.loading = true;
        this.accountService.getAssignModules(account.id).subscribe(response =>{
            this.loading = false;
            response.forEach(element => {
                element.isSelected = element.assigned;
            });
            this.modules = this.filteredModules = response;
            this.changeDetector.detectChanges();
        });
    }

    toggleSelectAll($event: MatCheckboxChange) {
        let selected = $event.checked;
        this.filteredModules.forEach((account) => {
            account.isSelected = selected;
        });
    }

    applyFilter(filterValue: string): void {
        let searchValue = filterValue.trim().toLowerCase();
        this.filteredModules = this.modules.filter((account) => {
            return account.moduleName.trim().toLowerCase().includes(searchValue);
        });
    }
}
