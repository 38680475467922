import { Component, forwardRef, Input, OnInit, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// TODO selector must be the same as file name. Two concepts for 'field' in the app?
@Component({
    selector: 'multi-field',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiFieldComponent),
            multi: true
        }
    ],

    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss']
})
export class MultiFieldComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() placeholder = 'Enter your value here';
    @Input() fieldType: FieldType;
    @Input() fieldName: string;
    @Input() readonly: boolean;
    @Input() appearance = 'outline';
    @Input() floatLabel = 'always';

    val;
    dateTimeValue: Date;
    invalidChars = [
        '-',
        '+',
        'e',
    ];

    constructor() {
    }

    get value() {
        return this.val;
    }

    set value(val) {
        // if (this.fieldType === FieldType.DATE) {
        //     this.dateTimeValue = new Date(this.val);
        // }
        this.val = val;
        this.onChange(val);
        this.onTouched();
    }

    onChange: any = () => {};

    onTouched: any = () => {};

    onKeydown(event) {
        if (this.invalidChars.includes(event.key)) {
            event.preventDefault();
        }
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value) {
        if (!value) {
            this.dateTimeValue = null;
            this.val = null;
            return;
        }

        this.dateTimeValue = new Date(value);
        this.value = value;
    }


    ngOnInit() {
    }

    ngOnChanges() {

    }

    registerChange(value) {
        this.value = value;
    }

    registerDateChange() {
        this.value = this.convertDateToTimestamp(this.dateTimeValue);
    }

    convertDateToTimestamp(date) {
        return new Date(date).getTime();
    }
}

export enum FieldType {
    TEXT = 'SHORT_TEXT_FIELD',
    DATE = 'DATE_FIELD',
    NUMBER = 'NUMBER_FIELD',
}
