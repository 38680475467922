import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortList',
})
export class SortListPipe implements PipeTransform {

  transform(list: any[]): any {
      if (list.length >= 2) {
          list.sort((a: any, b: any) => {
              if (isNaN(a) && isNaN(b)) {
                  if (a < b ) {
                      return -1;
                  } else if (a > b) {
                      return 1;
                  } else {
                      return 0;
                  }
              } else {
                  return a - b;
              }
          });
      }
      return list;
  }

}
