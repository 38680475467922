import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Group } from '../../model/group/group';
import { Dataset } from '../../model/dataset/dataset';
import { Account } from 'src/app/model/account/account';
import { GroupService } from '../../data-access-layer/groups/group.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AccountService } from 'src/app/data-access-layer/account/account.service';
import { NotifService } from '../../core/notification/notif.service';
import { SidePanelComponent } from '../../core/side-panel/side-panel.component';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { DialogModel } from '../../model/dialog/dialog-model';
import { take } from 'rxjs/operators';
import { DatapointsPageStateService } from '../../dataset/datapoints/datapoints-page-state.service';
import { Subject } from 'rxjs';
import { FilterInputComponent } from '../../shared/filter-input/filter-input.component';
import { displayGroupTypes, groupTypes } from './account-create-groups-panel/account-create-groups-panel.constants';

@Component({
    selector: 'map-account-groups',
    templateUrl: './account-groups.component.html',
    styleUrls: ['./account-groups.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountGroupsComponent implements OnInit {
    currentDataset: Dataset;
    currentAccount: Account;
    groups: Group[];
    rowGRoups: Group[];
    currentGroup: Group = null;
    groupsDataSource: MatTableDataSource<Group>;
    currentDisplayedColumns: string[] = ['Select', 'Name',/* 'Type',*/ 'Parent_group', 'Application', 'Created_on', 'Actions'];
    selectedGroups: number[] = [];
    isTableDataLoading: boolean;
    groupsByIds: Map<number, Group> = new Map();
    @ViewChild('createGroupPanel', { static: false }) createGroupPanel: SidePanelComponent;
    @ViewChild('updateGroupPanel', { static: false }) updateGroupPanel: SidePanelComponent;
    @ViewChild('filterInput', { static: true }) filterInput: FilterInputComponent;
    breadCrumbs = ['Home', 'Accounts', 'Settings'];
    emitGroupOnClickSubject: Subject<Group> = new Subject();

    get componentName() {
        return this.constructor.name;
    }

    constructor(
        private readonly groupService: GroupService,
        private readonly accountService: AccountService,
        private readonly route: ActivatedRoute,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        public readonly dialog: MatDialog
    ) {
        this.isTableDataLoading = true;
        this.groups = [];
    }

    ngOnInit(): void {
        this.fetchAccountAndGroups();
    }

    fetchAccountAndGroups(accountID: number = this.getAccountIDFromUrl()): void {
        this.accountService.getAccount(accountID).subscribe((account) => {
            this.currentAccount = account;
            this.datapointsPageStateService.activeAccount = account;
            this.currentDataset = account.datasets[0];
            this.fetchGroups(this.currentDataset.id);
        });
    }

    fetchGroups(datasetID: string): void {
        this.groupService.getGroups(datasetID, this.currentAccount.id).subscribe((groups) => {
            this.groups = groups;
            this.rowGRoups = groups.filter(element => element.type == groupTypes.ROW_GROUP);
            
            this.groupsDataSource = new MatTableDataSource(this.rowGRoups);
            this.rowGRoups.forEach(group => this.groupsByIds.set(group.id, group));
            this.isTableDataLoading = false;
            this.changeDetector.detectChanges();
        }, err => {
            console.log(err);
        });
    }


    clearFilterState(): void {
        this.filterInput.inputElement.nativeElement.value = ('');
        this.applyFilter('');
    }

    toggleDataset(newDataset: Dataset): void {
        this.currentDataset = newDataset;
        this.fetchGroups(newDataset.id);
        this.clearFilterState();
        this.selectedGroups = [];
    }

    selectOrDeselectItem(id: number): void {
        let itemExists = this.selectedGroups.indexOf(id) !== -1;
        if (itemExists) {
            this.removeElementFromList(this.selectedGroups, id);
        } else {
            this.selectedGroups.push(id);
        }
    }

    removeElementFromList(list: number[], element: number): void {
        const index = list.indexOf(element);
        list.splice(index, 1);
    }

    bulkDeleteGroups(): void {
        if (!this.selectedGroups.length) {
            return;
        }
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete ${this.selectedGroups.length} group(s)?`
            )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.groupService.bulkDeleteGroups(this.selectedGroups).then((res) => {
                    this.selectedGroups = [];
                    this.groupsByIds = new Map();
                    this.fetchGroups(this.currentDataset.id);
                }).catch(err => {
                    this.changeDetector.detectChanges();
                    this.fetchGroups(this.currentDataset.id);
                    this.groupsByIds = new Map();
                    this.selectedGroups = [];
                    this.notifService.error(err.error.message);
                });
            }
        });
    }

    deleteGroup(group: any): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete "${group.name}" group?`
            )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.groupService.deleteGroup(group.id).toPromise().then((res) => {
                    this.groupsByIds = new Map();
                    this.fetchGroups(this.currentDataset.id);
                    this.selectedGroups = [];
                }).catch(err => {
                    this.changeDetector.detectChanges();
                    this.selectedGroups = [];
                    this.fetchGroups(this.currentDataset.id);
                    this.notifService.error(err.error.message);
                });
            }
        });
    }

    createGroup(): void {
        if (this.updateGroupPanel) this.updateGroupPanel.hidePanel();
        if (this.createGroupPanel) this.createGroupPanel.showPanel();
    }

    updateGroup(group: Group): void {
        this.currentGroup = group;
        if (this.createGroupPanel) this.createGroupPanel.hidePanel();
        if (this.updateGroupPanel) this.updateGroupPanel.showPanel();
        this.emitGroupOnClickSubject.next(group);
    }

    applyFilter(filterValue: string): void {
        this.groupsDataSource.filter = filterValue.trim().toLowerCase();
    }

    getAccountIDFromUrl(): number {
        return parseInt(this.route.snapshot.paramMap.get('accountId'), 0);
    }

    onGroupCreated(): void {
        this.fetchGroups(this.currentDataset.id);
        if (this.createGroupPanel) this.createGroupPanel.hidePanel();
        this.selectedGroups = [];
    }

    onGroupUpdated(): void {
        this.fetchGroups(this.currentDataset.id);
        if (this.updateGroupPanel) this.updateGroupPanel.hidePanel();
        this.currentGroup = null;
        this.selectedGroups = [];
    }

    displayGroupType(type) {
        return displayGroupTypes[type];
    }

    showDeleteAction(type) {
        return type === groupTypes.ROW_GROUP ? true : false;
    }
    // createGroup(): void {
    //     if (this.updateGroupPanel) this.updateGroupPanel.instance.closePanel();
    //     this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
    //     this.createGroupPanel = this.sidePanelService.open<AccountCreateGroupPanelComponentType>(SidePanels.CREATE_GROUP,
    //         {
    //             id: 'create-group-panel',
    //             width: 400,
    //             panelTitle: "Create Group",
    //             panelIcon: "fa-plus-circle"
    //         },
    //         {
    //             groups: this.groups
    //         });

    //     this.accountServiceState.onCreateGroupSuccess$.subscribe(() => this.onGroupCreated());
    // }

    //   updateGroup(group: Group): void {
    //     this.currentGroup = group;
    //     if (this.createGroupPanel) this.createGroupPanel.instance.closePanel();

    //     this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
    //     this.updateGroupPanel = this.sidePanelService.open<AccountUpdateGroupsPanelComponentType>(SidePanels.UPDATE_GROUPS,
    //         {
    //             id: 'update-group-panel',
    //             width: 400,
    //             panelTitle: "Update Group",
    //             panelIcon: "fa-pencil"
    //         },
    //         {
    //             groups: this.groups,
    //             group: this.currentGroup,
    //             emitGroupOnClickSubject: this.emitGroupOnClickSubject
    //         });

    //     this.emitGroupOnClickSubject.next(group);
    //     this.accountServiceState.onUpdateGroupSuccess$.subscribe(() => this.onGroupUpdated());
    // }
}

