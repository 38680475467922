import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from '../../shared/error-state-matcher';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'map-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    readonly registerForm: FormGroup;
    readonly matcher: CustomErrorStateMatcher;
    private token: string;
    private isValid: boolean;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {
        this.registerForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, {
            validators: [
                passwordConfirm('password', 'confirmPassword')
            ]
        });
        this.matcher = new CustomErrorStateMatcher();
    }

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
        this.verifyInvitationToken();
    }

    onSubmit(): void {
        if (this.registerForm.valid) {
            this.authService.acceptMemberInvitation(this.token, this.registerForm.value.name, this.registerForm.value.password).subscribe(res => {
                this.authService.logout(); // make sure we clean everything
            });
        }
    }

    private verifyInvitationToken(): void {
        this.authService.verifyInvitationToken(this.token).subscribe(isValid => {
            this.isValid = isValid;
            this.tokenGuard();
        }, error => {
            this.isValid = false;
            this.tokenGuard();
        });
    }

    private tokenGuard(): void {
        if (!this.isValid) {
            this.router.navigate(['/public/login']);
        }
    }

}

function passwordConfirm(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}
