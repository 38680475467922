import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ClimateChart } from '../model/climate/climate-chart';
import { Observable } from 'rxjs';
import { Point } from '../model/geometry/point';

@Injectable({
    providedIn: 'root'
})
export class ClimateService {
    constructor(private readonly http: HttpClient) {
    }

    fetchMaxTemperatureByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/maxTemperature/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }

    fetchMeanTemperatureByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/meanTemperature/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }

    fetchMinTemperatureByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/minTemperature/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }

    fetchSnowfallByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/snowfall/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }

    fetchPrecipitationByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/precipitation/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }

    fetchSurfacewindByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/surfaceWind/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }

    fetchPopulationDensityByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/populationDensity/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }

    fetchSeaLevelRiseByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/seaLevelRise/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }

    fetchSeaLevelRiseWarmingByLocation(coordinates: Point, datasetID: string): Observable<ClimateChart> {
        return this.http.get<ClimateChart>(`/climate/fetch/seaLevelRiseWarming/${datasetID}?location=` + coordinates.y + ' ' + coordinates.x);
    }


    isMaxTemperatureEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/maxTemperature/${datasetID}/isEnabled`);
    }

    isMeanTemperatureEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/meanTemperature/${datasetID}/isEnabled`);
    }

    isMinTemperatureEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/minTemperature/${datasetID}/isEnabled`);
    }

    isSnowFallEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/snowFall/${datasetID}/isEnabled`);
    }

    isPrecipitationEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/precipitation/${datasetID}/isEnabled`);
    }

    isSurfaceWindEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/surfaceWind/${datasetID}/isEnabled`);
    }

    isPopulationDensityEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/populationDensity/${datasetID}/isEnabled`);
    }

    isSeaLevelRiseEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/seaLevelRise/${datasetID}/isEnabled`);
    }

    isSeaLevelRiseWarmingEnabled(datasetID: string): Observable<boolean> {
        return this.http.get<boolean>(`/climate/seaLevelRiseWarming/${datasetID}/isEnabled`);
    }
   
}