import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MembersService } from '../../../data-access-layer/members/members.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { createUserControlsConfig } from './invitation-constants';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { AcceptMemberInvitationRequest } from '../../../model/member/accept-member-invitation-request';

@Component({
    selector: 'map-invitation',
    templateUrl: './invitation.component.html',
    styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
    tokenValidity = false;
    createUserForm: FormGroup;

    constructor(
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly formBuilder: FormBuilder,
        private readonly memberService: MembersService,
        private readonly route: ActivatedRoute
    ) {
        this.createUserForm = this.formBuilder.group(createUserControlsConfig);
    }

    ngOnInit() {
        localStorage.removeItem('access_token');
        this.validateToken(this.getInvitationTokenFromUrl());
    }

    getInvitationTokenFromUrl(): string {
        const token = this.route.snapshot.paramMap.get('token');
        return token;
    }

    createUser(): void {
        if (this.tokenValidity) {
            this.confirmInvitation();
        }
    }

    confirmInvitation(): void {
        const userCreateRequest = this.constructUserCreateRequest();
        this.memberService.acceptMemberInvitation(userCreateRequest).subscribe((res) => {
            this.router.navigateByUrl('/login');
        }, (err) => {
            console.log('err', err);
        });
    }

    validateToken(token: string): void {
        this.memberService.validateToken(token).subscribe((res) => {
            this.tokenValidity = true;
        }, (err) => {
            console.log('Invalid Token ', err);
            this.router.navigateByUrl('');
        });
    }

    constructUserCreateRequest(): AcceptMemberInvitationRequest {
        let request: AcceptMemberInvitationRequest = {
            name: this.createUserForm.controls.name.value,
            token: this.getInvitationTokenFromUrl(),
            password: this.createUserForm.controls.password.value
        };
        return request;
    }
}
