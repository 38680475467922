import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {RoutingUtils} from '../../../core/utils/routing-utils';
import {EditOverlayComponent} from '../../../overlays/overlay-edit/edit-overlay.component';
import {DatasetType} from '../../../model/dataset/dataset-type';

@Component({
  selector: 'map-edit-private-overlay',
  templateUrl: './edit-private-overlay.component.html',
  styleUrls: ['./edit-private-overlay.component.scss']
})
export class EditPrivateOverlayComponent implements OnInit {
    accountId: number;
    @ViewChild('editOverlay', {static: true}) editOverlayComponent: EditOverlayComponent;
  constructor(
      private readonly route: ActivatedRoute,
      private readonly router: Router
  ) {
      this.accountId = this.route.snapshot.paramMap.get('accountId') ? Number(this.route.snapshot.paramMap.get('accountId')) : null;
  }

  ngOnInit() {
  }

    updateOverlay() {
        this.editOverlayComponent.updateOverlay();
    }

    navigateBackToPrivateOverlays() {
        this.router.navigateByUrl(RoutingUtils.getPrivateOverlaysRoute(this.accountId));
    }
    get DatasetType() {
        return DatasetType;
    }
}
