import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ColorScaleType} from '../../../../model/dataset/rendering/color-scale-type';
import {Dataset} from '../../../../model/dataset/dataset';
import {RenderingOptions} from '../../../../model/dataset/rendering/rendering-options';
import {DatapointsAggregateService} from '../../../../data-access-layer/datapoints/datapoints-aggregate.service';
import {DatapointConverterType, Grouping} from '../../../../model/dataset/rendering/datapoint-converter-options';
import {FormBuilder} from '@angular/forms';
import {VisualizationType} from '../../../../model/dataset/rendering/visualization-options';
import {DatasetFieldSpecificType} from '../../../../model/dataset/dataset-field-specific.type';
import {DatasetType} from '../../../../model/dataset/dataset-type';
import {DatasetGeometryType} from '../../../../model/dataset/dataset-geometry-type';
import {DatapointsPageStateService} from '../../datapoints-page-state.service';

@Component({
    selector: 'mpt-colorization-box',
    templateUrl: './colorization-box.component.html',
    styleUrls: ['./colorization-box.component.scss']
})
export class ColorizationBoxComponent implements OnInit {

    @Input() dataset: Dataset;
    @Input() accountApplicationDataset: Dataset; // for thematic map,  dataset is the selected overlay and accountApplicationDataset is the location dataset of the selected account
    renderingOptions: RenderingOptions; // @Input() via setter

    @Output() overlaySettingsUpdated = new EventEmitter<RenderingOptions>();
    @Output() resetRenderingOptions = new EventEmitter<{ dataset: Dataset, renderingOptions: RenderingOptions }>();

    accountOverlays: Dataset[] = [];
    selectedDatasetForColorization: Dataset; // this is populated when colorization is done by a link dataset. need to think more on this


    constructor(
        private readonly datapointAggregateService: DatapointsAggregateService,
        private readonly formBuilder: FormBuilder,
        private readonly datapointsPageStateService: DatapointsPageStateService
    ) {
    }

    @Input('renderingOptions')
    set setRenderingOptions(renderingOptions: RenderingOptions) {
        this.renderingOptions = renderingOptions;
        let converterDatasetID = this.renderingOptions.converterOptions.datasetID;
        this.selectedDatasetForColorization = this.datapointsPageStateService.getDataset(converterDatasetID);
    }

    visualizationTypeChanged(newValue: VisualizationType) {
        if (newValue === VisualizationType.THEMATIC_MAP) {
            this.renderingOptions.converterOptions.type = DatapointConverterType.NONE;
            this.renderingOptions.converterOptions.grouping = Grouping.SUM;
            this.renderingOptions.converterOptions.accountApplicationDatasetId = this.accountApplicationDataset.id;
            this.renderingOptions.datasetStylingOptions.type = ColorScaleType.INTERVAL;
        } else if (newValue === VisualizationType.CLUSTER) {
            this.renderingOptions.converterOptions.grouping = Grouping.COUNT;
            this.renderingOptions.converterOptions.fieldID = null;
            this.renderingOptions.converterOptions.type = DatapointConverterType.NONE;
            this.renderingOptions.datasetStylingOptions.type = ColorScaleType.CONSTANT;
            this.renderingOptions.converterOptions.minNumber = 0; // need these number for backend, don't know why.
            this.renderingOptions.converterOptions.maxNumber = 0;
        } else if (newValue === VisualizationType.DEFAULT) {

        }
    }

    ngOnInit(): void {
        this.accountOverlays = this.datapointsPageStateService.getLinkedAccountDatasetsNotCloned()
            .concat(this.datapointsPageStateService.getLinkedAccountOverlaysNotCloned().filter(overlay => overlay.geometryType === DatasetGeometryType.COMPLEX));
    }

    // TODO: add required validation
    onApplySettings(renderingOptions: RenderingOptions) {
        // if(this.dataset.id === this.accountApplicationDataset.id) {
        //     return;
        // }
        this.renderingOptions = renderingOptions;
        this.overlaySettingsUpdated.emit(renderingOptions);
    }

    get getVisualizationTypes() {
        let visualizationTypes = [VisualizationType.DEFAULT];
        switch (this.dataset.type) {
            case DatasetType.GLOBAL_OVERLAY:
            case DatasetType.ACCOUNT_OVERLAY:
                if (this.dataset.geometryType === DatasetGeometryType.COMPLEX) {
                    visualizationTypes.push(VisualizationType.THEMATIC_MAP);
                }
                break;
            case DatasetType.ACCOUNT_APPLICATION:
                visualizationTypes.push(VisualizationType.CLUSTER);
                // visualizationTypes.push(VisualizationType.HEATMAP);
                break;
            default:
                break;

        }
        return visualizationTypes;
    }

    get ColorScaleType() {
        return ColorScaleType;
    }

    get VisualizationType() {
        return VisualizationType;
    }

    get DatasetType() {return DatasetType;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }
    reset() {
        this.resetRenderingOptions.emit({dataset:this.dataset, renderingOptions:this.renderingOptions});
    }

}
