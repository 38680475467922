import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderStateService } from 'src/app/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public loader: LoaderStateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loader.update(true);
        return next.handle(req).pipe(
            finalize(() => {
                    this.loader.update(false);
                }
            )
        );
    }
}
