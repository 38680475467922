import {Injectable, NgZone} from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class NotifService {

    constructor(private readonly snackBar: MatSnackBar, private zone: NgZone) {
    }

    private openSnackBar(message: string, action: string) {
        this.zone.run(() => {
            this.snackBar.open(message, action, {
                duration: 4000
            });
        });
    }

    success(message: string) {
        this.openSnackBar(message, 'Close');
    }

    error(message: string, shortMessage?: string) {
        this.openSnackBar(message, 'Close');
    }
}
