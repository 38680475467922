import {AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {ColorUtils} from '../../core/utils/color-utils';
import {DatasetFieldSpecificType} from '../../model/dataset/dataset-field-specific.type';
import {DatasetGeometryType} from '../../model/dataset/dataset-geometry-type';
import {DatasetType} from '../../model/dataset/dataset-type';
import {MatCheckboxChange, MatDialog, MatTable, MatTableDataSource} from '@angular/material';
import {DatasetField} from '../../model/dataset/field/dataset-field';
import {DefaultField} from '../../fields/fields/fields.constants';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {FormGroup, NgForm} from '@angular/forms';
import {DatasetStylingOptions} from '../../model/dataset/rendering/dataset-styling-options';
import {Dataset} from '../../model/dataset/dataset';
import {NotifService} from '../../core/notification/notif.service';
import {DownloadService} from '../../data-access-layer/download/download.service';
import {AttachmentUtils} from '../../core/utils/attachment-utils';
import {UploadService} from '../../data-access-layer/upload/upload.service';
import {
    CITY_FIELD_TAG,
    COUNTRY_FIELD_TAG,
    CREATED_ON_FIELD_TAG,
    ID_FIELD_TAG,
    STREET_FIELD_TAG,
    UPDATED_ON_FIELD_TAG,
    ZIPCODE_FIELD_TAG
} from '../../fields/field/field.constants';
import {DatasetFieldFloatingOption} from '../../model/dataset/dataset-field-floating-option';
import {OverlayGroup} from '../../model/overlay/group/overlay-group';
import {DialogComponent} from '../dialog/dialog.component';
import {DialogModel} from '../../model/dialog/dialog-model';
import {take} from 'rxjs/operators';
import {RestrictDialogComponent} from './restrict-dialog/restrict-dialog.component';
import {DatasetConfigFilterEnum} from '../../model/dataset/dataset-config-filter.enum';
import {isEnabled} from '../../../environments/environment';
import {Functionalities} from '../../../environments/app-functionalities';
import {DatasetFieldType} from '../../model/dataset/dataset-field-type';
import {DatasetFieldScope} from '../../model/dataset/dataset-field-scope';
import { GroupService } from 'src/app/data-access-layer/groups/group.service';
import { Group } from 'src/app/model/group/group';
import { groupTypes } from 'src/app/account/account-groups/account-create-groups-panel/account-create-groups-panel.constants';
import { isNull, isUndefined } from 'util';
import { Modules } from 'src/app/model/modules/modules';
import { AccountService } from 'src/app/data-access-layer/account/account.service';

@Component({
    selector: 'map-dataset-config',
    templateUrl: './dataset-config.component.html',
    styleUrls: ['./dataset-config.component.scss']
})
export class DatasetConfigComponent implements OnInit, OnChanges, AfterViewChecked {
    @ViewChild('tableForm', {static: false}) tableForm: NgForm;
    @ViewChild('table', {static: false}) table: MatTable<DatasetField>;

    @ViewChild('tableContainer', {read: ElementRef, static: false}) tableContainer: ElementRef;

    @Input() datasetStylingOptions: DatasetStylingOptions;
    @Input() thematicDatasets: Dataset[];
    @Input() isAccountApplicationDataset: boolean;
    @Input() displayColorizationBox: boolean;
    @Input() isCreate: boolean;
    @Input() groups: OverlayGroup[];
    @Input() dataset: Dataset;
    @Input() groupes: {rowGroups: Group[], columnGroups: Group[]};
    private datasetFields: DatasetField[];
    fieldsChanged = false;
    @Output() resetThematicDatasetSettings: EventEmitter<any> = new EventEmitter<any>();
    tessadataEnabled = isEnabled(Functionalities.TESSADATA_DATA);
    isAddedField: boolean;
    modules: Modules[];
    

    @Input()
    set form(form: FormGroup) {
        if (!form) {
            return;
        }
        this.detailsForm = form;
        this.isThematicOverlay = (this.detailsForm.controls.thematicMapSettings as FormGroup).controls.isThematicMapDataset.value;
        this.isGeoServerChecked = !isUndefined(this.detailsForm.controls.isGeoserver) ? this.detailsForm.controls.isGeoserver.value : false;
    }

    @Input()
    set fields(fields: DatasetField[]) {
        this.datasetFields = fields;
        this.applyFilter();
    }

    @Output() fileUploaded = new EventEmitter<null>();

    dataSource: MatTableDataSource<DatasetField>;
    displayedColumns: string[] = [];
    isThematicOverlay: boolean;
    detailsForm: FormGroup;
    requiredFieldsTags: string[];
    filter = DatasetConfigFilterEnum.INTERNAL;
    isGeoServerChecked: boolean;
    isShowGeoServerFields: boolean;

    constructor(
        private readonly notifService: NotifService,
        private readonly downloadService: DownloadService,
        private readonly uploadService: UploadService,
        public readonly dialog: MatDialog,
        private readonly accountService: AccountService,
    ) {
        
        this.dataSource = new MatTableDataSource<DatasetField>([]);
        this.thematicDatasets = [];
        this.isThematicOverlay = false;
        this.isAccountApplicationDataset = false;
        this.displayColorizationBox = true;
        this.isGeoServerChecked = false;
        this.isShowGeoServerFields = false;
        
    }

    ngOnInit(): void {
        if (this.isAccountApplicationDataset) {
            this.displayedColumns = ['id', 'name', 'type', 'isSummary', 'required', 'isArray', 'isGenerated', 'isHighCardinality', 'isFloating', 'floatingOptions', 'dateFormat', /* 'isRowGroup', 'isAvgGroup', 'columnGroup', */'actions'];
        } else {
           // this.fetchAccountsModules();
            this.displayedColumns = ['id', 'name', 'displayName', 'type', 'isSummary', 'required', 'isArray', 'isGenerated', 'isHighCardinality', 'displayInDropdowns', 'isFloating', 'floatingOptions', 'dateFormat', 'actions'];
        }
    }

    ngOnChanges() {
        this.requiredFieldsTags = [ID_FIELD_TAG, CREATED_ON_FIELD_TAG, UPDATED_ON_FIELD_TAG];
        if (this.detailsForm.controls.isGeocoded.value) {
            this.requiredFieldsTags.push(STREET_FIELD_TAG, CITY_FIELD_TAG, COUNTRY_FIELD_TAG, ZIPCODE_FIELD_TAG);
        }
        this.fieldsChanged = false;
    }

    addNewField(): void {
        let currentFields = this.detailsForm.controls.fields.value;
        currentFields.push(new DefaultField());
        this.datasetFields = currentFields;
        this.applyFilter();
        this.fieldsChangesEvent();
        // move to bottom
        this.isAddedField = true;
        this.scrollToBottom();
    }

    ngAfterViewChecked() {
        if (this.isAddedField === true) {
            this.scrollToBottom();
        }
        this.isAddedField = false;
    }

    scrollToBottom(): void {
        try {
            this.tableContainer.nativeElement.scrollTop = this.tableContainer.nativeElement.scrollHeight;
        } catch(err) {}
    }

    openRemoveFieldModal(field: DatasetField): void {
        let fieldIndex;
        if (this.datasetFields && this.datasetFields.length > 0) {
            fieldIndex = this.datasetFields.indexOf(field);

        } else {
            fieldIndex = this.detailsForm.controls.fields.value.indexOf(field);
            this.removeField(fieldIndex);
            return;
        }
        if (this.datasetStylingOptions && this.datasetStylingOptions.colorizationFieldIndex === fieldIndex) {
            const dialogRef = this.dialog.open(RestrictDialogComponent, {
                data: new DialogModel(
                    'Warning',
                    `Cannot delete "${field.name}", this dataset is currently colorized by this field.`
                )
            });
            dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
                return;
            });
        } else {
            const dialogRef = this.dialog.open(DialogComponent, {
                data: new DialogModel(
                    'Confirm Action',
                    `Are you sure you want to delete "${field.name}" field?`
                )
            });
            dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
                if (dialogResult) {
                    this.removeField(fieldIndex);
                }
            });
        }

    }

    removeField(index: number): void {
        const colorizedByField = this.getColorizedByField();
        let currentFields = this.detailsForm.controls.fields.value;
        currentFields.splice(index, 1);
        this.datasetFields = currentFields;
        this.applyFilter();
        this.updateStylingOptions(colorizedByField);
        this.fieldsChangesEvent();
    }

    thematicMapChanged(event: MatCheckboxChange) {
        this.isThematicOverlay = event.checked;
    }

    geometryTypeChanged($_event) {
        this.isGeoServerChecked = false;
        this.detailsForm.controls.isGeoserver.setValue(this.isGeoServerChecked);
        this.resetGeoServerRelatedFields();
        if ($_event.value == DatasetGeometryType.COMPLEX && !this.isAccountApplicationDataset && this.detailsForm.controls.type.value === DatasetType.GLOBAL_OVERLAY) {
            this.isShowGeoServerFields = true;
        } else {
            this.isShowGeoServerFields = false;
        }
    }

    geoServerChanged(event: MatCheckboxChange) {
        this.isGeoServerChecked = event.checked;
        this.resetGeoServerRelatedFields();
    }

    resetGeoServerRelatedFields() {
        const controls = this.detailsForm.controls;
        controls.geoserverDatasetId.setValue(null);
        controls.rasterId.setValue(null);
        controls.id.setValue(null);
        controls.geoWorkspace.setValue(null);
    }

    checkGeoServerAttributsValues() {
        const controls = this.detailsForm.controls;
        if ([null, ""].includes(controls.geoserverDatasetId.value) || [null, ""].includes(controls.geoWorkspace.value)) {
            controls.id.setValue(null);
        } else {
            controls.id.setValue(controls.geoserverDatasetId.value);
        }
    }

    rowDropped(event: CdkDragDrop<MatTableDataSource<DatasetField>>) {
        const colorizedByField = this.getColorizedByField();
        let currentFields = this.detailsForm.controls.fields.value;
        const prevIndex = currentFields.findIndex((d) => d === event.item.data);
        moveItemInArray(currentFields, prevIndex, event.currentIndex);
        this.datasetFields = currentFields;
        this.applyFilter();
        this.updateStylingOptions(colorizedByField);
        this.fieldsChangesEvent();
    }


    download() {
        const fileName = `${this.detailsForm.value.name} fields.xlsx`;
        this.downloadService.downloadDatasetFields(this.detailsForm.value.id, fileName).subscribe(response => {
            AttachmentUtils.downloadFileWithName(response, fileName);
        });
    }

    upload(event: Event) {
        const target = (event.target as HTMLInputElement);
        const datasetID = this.detailsForm.value.id;
        if (target.files && target.files.length && datasetID) {
            const file = target.files[0];
            this.uploadService.uploadDatasetFields(datasetID, file).subscribe(response => {
                this.fileUploaded.emit(null);
            }, error => {
                this.notifService.error(error.error.message);
            });
        }
        this.fieldsChangesEvent();
    }

    onFilterChanged(): void {
        requestAnimationFrame(() => {
            this.applyFilter();
        });
    }

    get ColorUtils() {
        return ColorUtils;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    get DatasetType() {
        return DatasetType;
    }

    get DatasetConfigFilterEnum() {
        return DatasetConfigFilterEnum;
    }

    get floatingOptions() {
        return Object.keys(DatasetFieldFloatingOption);
    }

    fieldIsRequired(tags: string[]): boolean {
        return tags.some(r => this.requiredFieldsTags.includes(r));
    }

    setFloatingOption(field: DatasetField) {
        if (field.isFloating) {
            field.floatingOption = DatasetFieldFloatingOption.SUM;
        } else {
            field.floatingOption = null;
        }
    }

    private getColorizedByField() {
        if (!this.datasetStylingOptions) {
            return null;
        }
        return this.detailsForm.controls.fields.value[this.datasetStylingOptions.colorizationFieldIndex];
    }

    private updateStylingOptions(colorizedByField) {
        if (colorizedByField) {
            let fields = this.detailsForm.controls.fields.value;
            let newStylingOptions = this.datasetStylingOptions;
            newStylingOptions.colorizationFieldIndex = fields.indexOf(fields.find((field) => field.id === colorizedByField.id));
            this.datasetStylingOptions = newStylingOptions;
        }
    }

    private applyFilter(): void {
        let filteredFields;
        switch (this.filter) {
            case DatasetConfigFilterEnum.EXTERNAL:
                filteredFields = this.datasetFields.filter(field => !field.id || field.scope === DatasetFieldScope.EXTERNAL);
                break;
            case DatasetConfigFilterEnum.ALL:
                filteredFields = this.datasetFields;
                break;
            case DatasetConfigFilterEnum.NRI:
                filteredFields = this.datasetFields.filter(field => !field.id || field.scope === DatasetFieldScope.NRI);
                break;
            default:
                filteredFields = this.datasetFields.filter(field => !field.id || field.scope === DatasetFieldScope.INTERNAL);
        }
        
        this.dataSource = new MatTableDataSource(filteredFields);
    }

    fieldsChangesEvent() {
        this.resetThematicDatasetSettings.emit();
        // this.thematicMapChanged({ checked: false } as MatCheckboxChange);
        this.fieldsChanged = true;
    }

    moveToBottom(currentIndex: number) {
        const currentFields = this.detailsForm.controls.fields.value;
        moveItemInArray(currentFields, currentIndex, currentFields.length);
        this.dataSource = new MatTableDataSource(currentFields);
    }

    moveToTop(currentIndex: number) {
        const currentFields = this.detailsForm.controls.fields.value;
        moveItemInArray(currentFields, currentIndex, 0);
        this.dataSource = new MatTableDataSource(currentFields);
    }

    fetchAccountsModules() {
        this.accountService.getModules().subscribe(response =>{
            response.forEach(element => {
                element.isSelected = false;
            });
            this.modules = response;
        });
    }
}

function substringIdOrNew(list: DatasetField[], key: string): DatasetField[] {
    return list.filter(item => !item.id || item.id.startsWith(key));
}

