import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NotifService } from 'src/app/core/notification/notif.service';
import { CreateOverlayGroupRequest, OverlaysService } from 'src/app/data-access-layer/global-overlays/overlays.service';
import { GroupWithOverlaysTreeNode } from 'src/app/model/overlay/group/group-with-overlays-tree-node';
import { DataPointsServiceState } from 'src/app/shared/services/datapoints-service-state';

@Component({
  selector: 'map-create-external-overlay-group',
  templateUrl: './create-external-overlay-group.component.html',
  styleUrls: ['./create-external-overlay-group.component.scss']
})
export class CreateExternalOverlayGroupComponent implements OnInit {
  @Input() groupNodes: GroupWithOverlaysTreeNode[] = [];
  constructor(
    public readonly dialog: MatDialog,
        public readonly notifService: NotifService,
        private readonly overlayService: OverlaysService,
        private readonly datapointsServiceState: DataPointsServiceState
  ) { }

  ngOnInit() {
  }

  getGroupNodes() {
    return this.groupNodes;
}

createGroup(groupName: string, parentID?: number): void {
    let request: CreateOverlayGroupRequest = {
        name: groupName,
        parentID: parentID ? parentID : null,
        tag: '',
        id: null
    };
    this.overlayService.createExternalOverlayGroup(request).subscribe((res) => {
        this.datapointsServiceState.emitOnCreateOverlayGroupSuccess();
        this.notifService.success('New Group Created');
    }, (err) => {
        this.notifService.error(err.error.message);
        console.log(err);
    });
}

}
