import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { Account, AccountTableRow } from '../../model/account/account';
import { PrincipalUser } from '../../model/auth/principal-user';
import { LoaderStateService } from '../../loader.service';
import { AccountService } from '../../data-access-layer/account/account.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import {UserStateService} from '../../auth/user-state-service';

@Component({
    selector: 'map-private',
    templateUrl: './private.component.html',
    styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit {

    loading = false;

    private subscription;

    constructor(
        private readonly loader: LoaderStateService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly router: Router) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit(): void {
        this.subscription = this.loader.state.subscribe((value) => {
            this.loading = value;
            if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
                this.cdRef.detectChanges();
            }
        }, (err) => {
            console.log(err);
        });

    }

}
