import { Component, ComponentRef, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidePanelComponent } from 'src/app/core/side-panel/side-panel.component';
import { RoutingUtils } from 'src/app/core/utils/routing-utils';
import { OverlaysService } from 'src/app/data-access-layer/global-overlays/overlays.service';
import { GroupWithOverlaysTreeNode } from 'src/app/model/overlay/group/group-with-overlays-tree-node';
import { DataPointsServiceState } from 'src/app/shared/services/datapoints-service-state';
import { SidePanels } from 'src/app/shared/services/side-panel.helper';
import { SidePanelService } from 'src/app/shared/services/side-panel.service';
import { Functionalities } from 'src/environments/app-functionalities';
import { isEnabled } from 'src/environments/environment.prod';

const IS_PART_OF = Functionalities.OVERLAYS;

type CreateGroupPanelType = {
    groupNodes: GroupWithOverlaysTreeNode[]
}

type UpdateGroupPanelType = {
    map: {};
    selectedNode: { group: { parentId: number; name: string; tag?: string; id: number; } };
    groupNodes: GroupWithOverlaysTreeNode[]
}

@Component({
  selector: 'map-edit-external-overlay-tree',
  templateUrl: './edit-external-overlay-tree.component.html',
  styleUrls: ['./edit-external-overlay-tree.component.scss']
})
export class EditExternalOverlayTreeComponent implements OnInit {

  isEnabled = isEnabled(IS_PART_OF);
  createGroupPanel: ComponentRef<SidePanelComponent>;
  editGroupPanel: ComponentRef<SidePanelComponent>;

  groupNodes: GroupWithOverlaysTreeNode[] = [];
  map: {} = {};
  selectedNode: { group: { parentId: number; name: string; tag?: string; id: number; } };

  constructor(
    private readonly datapointsServiceState: DataPointsServiceState,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly sidePanelService: SidePanelService,
    private readonly overlayService: OverlaysService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.init();
  }

  private init() {
    this.selectNode(null);
    this.overlayService.getAllExternalGroups().subscribe(res => {
            this.groupNodes = res;
            this.createMap();
        });
}

selectNode(groupNode: any) {
  this.selectedNode = groupNode;
  if (groupNode) {
      this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
      if (this.editGroupPanel) {
          this.editGroupPanel.instance.hidePanel();
      }
      this.editGroupPanel = this.sidePanelService.open<UpdateGroupPanelType>(SidePanels.EDIT_EXTERNAL_GROUP_OVERLAY,
          {
              width: 400,
              id: "EditOverlayGroups",
              panelTitle: "Edit Overlay Groups",
          },
          {
              groupNodes: this.groupNodes,
              selectedNode: this.selectedNode,
              map: this.map
          });
      this.datapointsServiceState.onEditOverlayGroupSuccess$.subscribe(() => {
          this.init();
          if (this.editGroupPanel) this.editGroupPanel.instance.hidePanel();
      });
      this.datapointsServiceState.onDeleteOverlayGroupSuccess$.subscribe(() => {
          this.init();
          if (this.editGroupPanel) this.editGroupPanel.instance.hidePanel();
      });
  } else {
      if (this.editGroupPanel) this.editGroupPanel.instance.hidePanel();
  }
}

createMap() {
  this.groupNodes.forEach(rootGroup => {
      if (!this.map[rootGroup.group.id]) {
          this.map[rootGroup.group.id] = rootGroup;
      }
      this.recursiveOperation(rootGroup, group => {
          if (!this.map[group.group.id]) {
              this.map[group.group.id] = group;
          }
      });
  });
}

recursiveOperation(groupNode: GroupWithOverlaysTreeNode, operation: (node: GroupWithOverlaysTreeNode) => void) {
  operation(groupNode);
  groupNode.children.forEach(child => this.recursiveOperation(child, operation));
}


expandAll(): void {
  this.selectNode(null);
  this.groupNodes.forEach(rootGroup => {
      this.map[rootGroup.group.id].group.isOpen = true;
      this.recursiveOperation(rootGroup, group => {
          this.map[group.group.id].group.isOpen = true;
      });
  });
}

collapseAll(): void {
  this.selectNode(null);

  this.groupNodes.forEach(rootGroup => {
      this.map[rootGroup.group.id].group.isOpen = false;
      this.recursiveOperation(rootGroup, group => {
          this.map[group.group.id].group.isOpen = false;
      });
  });
}

showCreateGroupPanel(): void {
  if (this.createGroupPanel) {
      this.createGroupPanel.instance.showPanel();
  } else {
      this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
      this.createGroupPanel = this.sidePanelService.open<CreateGroupPanelType>(SidePanels.CREATE_EXTERNAL_GROUP_OVERLAY,
          {
              width: 400,
              id: "CreateOverlayGroups",
              panelTitle: "Create Overlay Groups",
          },
          {
              groupNodes: this.groupNodes
          });

      this.datapointsServiceState.onCreateOverlayGroupSuccess$.subscribe(() => {
          this.init();
          if (this.createGroupPanel) this.createGroupPanel.instance.hidePanel();
      })
  }
}

openNode(groupNode: any) {
  this.map[groupNode.group.id].group.isOpen = !this.map[groupNode.group.id].group.isOpen;
}

isGroupOpen(groupNode: any) {
  try {
      return this.map[groupNode.group.id].group.isOpen;

  } catch (err) {
      return false;
  }
}

navigateBackToPrivateOverlays() {
    this.router.navigateByUrl(RoutingUtils.getExternalOverlaysRoute());
}

ngOnDestroy() {
  if (this.editGroupPanel) {
      this.editGroupPanel.instance.closePanel();
      this.editGroupPanel = null;
  }

  if (this.createGroupPanel) {
      this.createGroupPanel.instance.closePanel();
      this.createGroupPanel = null;
  }
}

}
