import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DatapointsOverlayFilterService, DatapointsOverlayFilterStore} from './datapoints-overlay-filter.service';
import {DatapointsPageStateService} from '../datapoints-page-state.service';
import {Dataset} from '../../../model/dataset/dataset';
import {DatasetField} from '../../../model/dataset/field/dataset-field';
import {FilterBarItem} from '../../../model/datapoint/draft/table/filter-bar-item';
import {DatasetFieldType} from '../../../model/dataset/dataset-field-type';
import {DatapointsFilterService} from '../datapoints-filter.service';
import {isEnabled} from 'src/environments/environment.prod';
import {DistanceUnit} from '../../../constants';
import {Functionalities} from '../../../../environments/app-functionalities';
import {DatasetType} from '../../../model/dataset/dataset-type';

const IS_PART_OF = Functionalities.OVERLAYS;

@Component({
    selector: 'map-datapoints-overlay-filter',
    templateUrl: './datapoints-overlay-filter.component.html',
    styleUrls: ['./datapoints-overlay-filter.component.scss']
})
export class DatapointsOverlayFilterComponent implements OnInit {
    isEnabled = isEnabled(IS_PART_OF);
    overlay!: Dataset;
    filters: FilterBarItem[];
    search: string;
    trackByIndexFunction = (index: number): number => index;

    @Output() filterUpdated: EventEmitter<DatapointsOverlayFilterStore>;

    constructor(
        private readonly datapointsOverlayFilterService: DatapointsOverlayFilterService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
    ) {
        this.filters = [];
        this.search = '';
        this.filterUpdated = new EventEmitter<DatapointsOverlayFilterStore>();
    }

    ngOnInit() {
        this.datapointsOverlayFilterService.getSelected().subscribe(selected => {
            if (selected.datasetID) {
                this.overlay = this.datapointsPageStateService.getDataset(selected.datasetID);
            } else {
                this.overlay = undefined;
            }
            this.filters = selected.filters;
        });
        this.datapointsOverlayFilterService.onUncheckLayer.subscribe(layer => {
          let overlayFromStore = this.datapointsOverlayFilterService.getOverlayFilter(layer.id);
          let overlay;
          if (overlayFromStore) {
              overlay = this.datapointsPageStateService.getDataset(overlayFromStore.datasetID);
              overlay.fields.forEach(field => field.selected = false);
          }
        });
    }

    onFilterMenuFieldClick(event: boolean, dataset: Dataset, field: DatasetField) {
        if (event) {
            this.addFilterBarItem(field, dataset);
        } else {
            this.removeFilterBarItem(field.id);
        }
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DistanceUnit() {
        return DistanceUnit;
    }

    filterFieldSearch = (field: DatasetField) => field.name.toLowerCase().includes(this.search.toLowerCase());
    filterValueSearch = (value: string) => value.toLowerCase().includes(this.search.toLowerCase());

    addFilterBarItem(datasetField: DatasetField, dataset?: Dataset, isDistanceItem?: boolean, selectedStatisticValues?: string[]) {
        let filterBarItem = this.datapointsFilterService.createFilterBarItem(datasetField, dataset);
        this.filters.push(filterBarItem);
        if (!selectedStatisticValues) {
            selectedStatisticValues = [];
        }
        if (!datasetField.isHighCardinality && !isDistanceItem) {
            let groups= dataset.type === DatasetType.ACCOUNT_APPLICATION ? this.datapointsPageStateService.activeGroups : [];
            this.datapointsFilterService.fetchFilterBarItemStatistics(filterBarItem, dataset, datasetField, groups, selectedStatisticValues);
        }
        return filterBarItem;
    }

    removeFilterBarItem(fieldId: string) {
        this.filters = this.filters.filter(item => !(item.datasetField.id === fieldId));
        const overlayField = this.overlay.fields.find(field => field.id === fieldId);

        if (overlayField) {
            overlayField.selected = false;
        }

        this.applyFilters();
    }

    applyFilters(filterItem?: FilterBarItem) {
        if (filterItem) {
            if (filterItem.maxNumberValue === null && filterItem.statistics.maxValue !== 0) {
                filterItem.maxNumberValue = filterItem.statistics.maxValue;
            }
            this.datapointsFilterService.validatorsForFiltersInputs(filterItem);
        }
        this.filters.forEach(filter => {
            this.datapointsFilterService.validatorsForFiltersInputs(filter);
            this.datapointsFilterService.populateFilterBarItemDisplayValues(filter);
        });
        this.filterUpdated.emit({datasetID: this.overlay.id, filters: this.filters});
        this.datapointsOverlayFilterService.updateFilters(this.overlay.id, this.filters);
    }


    removeFilterBar() {
        this.filters = [];
        this.overlay.fields.forEach(field => field.selected = false);
        this.applyFilters();
        this.overlay = undefined;
    }

    resetFilters() {
        this.filters = [];
        if (this.overlay) {
            this.overlay.fields.forEach(field => field.selected = false);
        }
    }

}
