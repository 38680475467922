import { Functionalities } from './app-functionalities';

export const environment = {
    production: true,
    baseUrl: 'https://dev.maptycs.com/api/v2',
    token: 'dtjumtnq22q9rlc0u5nlt17ntehabpnh',
    aerisClient: '3xBPMX4XHtn7FxUBevi5b',
    aerisSecret: 'oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV',
    whitelist: ['dev.maptycs.com'],
    googleApiKey: 'AIzaSyDQ7kyEV7wPExWfk9Fqme-DG5EwHXQkTbE', //'AIzaSyDR_0lTsteYonh6ooBObYAxl83pYDzwYQE',
    authToken: '5bqnpgtvlfoajcjav5t9dnhevp8j4f72',
    logo: 'login-logo-white-blue.svg',
    whiteLogo: 'login-logo.svg',
    pageTitle: '', // if null it will be overriden from AppComponent
    customFavicon: '', // if null or empty it will be ignored,
    agGridLicense: 'CompanyName=Maptycs,LicensedApplication=Maptycs,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-030382,SupportServicesEnd=9_July_2023_[v2]_MTY4ODg1NzIwMDAwMA==50f169ef21d6b6278dfba15242abfc6d'
};

// here we can put only disabled features
export const Config: { name: Functionalities, enabled: boolean }[] = [];


export function isEnabled(functionalityName: Functionalities): boolean {
    let component = Config.find((f) => {
        return f.name === functionalityName;
    });
    if (component) {
        return component.enabled;
    }
    return true;
}
