import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {RenderingOptions} from '../../../../../model/dataset/rendering/rendering-options';
import {ColorScaleType} from '../../../../../model/dataset/rendering/color-scale-type';
import {ColorUtils} from '../../../../../core/utils/color-utils';
import {Dataset} from '../../../../../model/dataset/dataset';
import {DatasetField} from '../../../../../model/dataset/field/dataset-field';
import {DatapointsPageStateService} from '../../../datapoints-page-state.service';
import {DatasetGeometryType} from '../../../../../model/dataset/dataset-geometry-type';
import {Account} from '../../../../../model/account/account';
import {DatasetType} from '../../../../../model/dataset/dataset-type';
import {NotifService} from '../../../../../core/notification/notif.service';
import {RenderingUtils} from '../../../../../core/utils/rendering-utils';
import {FormBuilder} from '@angular/forms';
import {RenderingErrorCodes} from '../../../../../model/dataset/rendering/rendering-error-codes';
import {DatasetFieldType} from '../../../../../model/dataset/dataset-field-type';
import {VisualizationType} from '../../../../../model/dataset/rendering/visualization-options';
import {DatapointsAggregateService} from '../../../../../data-access-layer/datapoints/datapoints-aggregate.service';
import { DatapointsFilterService } from '../../../datapoints-filter.service';
import { DatapointFilter } from 'src/app/model/datapoint/filter/datapoint-filter';

@Component({
    selector: 'mpt-basic-config-box',
    templateUrl: './basic-config-box.component.html',
    styleUrls: ['./basic-config-box.component.scss']
})
export class BasicConfigBoxComponent implements OnInit, OnChanges  {


    @Output() submitChanges: EventEmitter<RenderingOptions> = new EventEmitter();
    @Output() viewModeChanged = new EventEmitter<VisualizationType>();

    @Input() dataset: Dataset; // the dataset that is colorized
    @Input() renderingOptions: RenderingOptions; // the component manipulate directly this object

    account: Account;
    mainDataset: Dataset; // not for the colorization box, but for the main page
    scaleTypes = Object.values(ColorScaleType);

    base64Colors: string[] = [];
    colorizeByDatasetOptions: Dataset[] = [];

    selectedDatasetForColorization: Dataset; // this is populated when colorization is done by a link dataset. need to think more on this
    selectedFieldForColorization: DatasetField;

    defaultColor = '#ed6a22';
    @Output() Reset: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly formBuilder: FormBuilder,
        private readonly notifService: NotifService,
        private readonly datapointAggregateService: DatapointsAggregateService,
        private readonly datapointsFilterService: DatapointsFilterService
    ) {
    }

    ngOnInit(): void {
        // this.initContext();
    }

    get getVisualizationTypes() {
        let visualizationTypes = [VisualizationType.DEFAULT];
        switch (this.dataset.type) {
            case DatasetType.GLOBAL_OVERLAY:
            case DatasetType.ACCOUNT_OVERLAY:
                if (this.dataset.geometryType === DatasetGeometryType.COMPLEX) {
                    visualizationTypes.push(VisualizationType.THEMATIC_MAP);
                }
                break;
            case DatasetType.ACCOUNT_APPLICATION:
                visualizationTypes.push(VisualizationType.CLUSTER);
                // visualizationTypes.push(VisualizationType.HEATMAP);
                break;
            default:
                break;

        }
        return visualizationTypes;
    }

    colorScaleTypeChanged(newValue: ColorScaleType) {
        if (newValue !== ColorScaleType.CONSTANT) {
            this.populateBase64Colors();
        }
        this.checkScaleTypeByStatisticalValues();
    }

    private populateBase64Colors() {
        if (!this.renderingOptions.datasetStylingOptions.colors || this.renderingOptions.datasetStylingOptions.colors.length === 0) {
            this.base64Colors = ColorUtils.defaultColorScale();
        } else { // it already has an interval
            this.base64Colors = this.renderingOptions.datasetStylingOptions.colors.map(color => ColorUtils.colorToBase64(color));
        }
        if (this.renderingOptions.datasetStylingOptions.defaultColor) {
            this.defaultColor = ColorUtils.colorToBase64(this.renderingOptions.datasetStylingOptions.defaultColor);
        }
    }

    onFieldsMenuFieldClick(dataset: Dataset, field: DatasetField) {
        // for Locations(account_app) we need to see what fields can be on this dataset, because right now it cannot be found on fields array from Locations some 'field' that enter in here and we need the index from
        // that array to construct colorizationFieldIndex
        this.selectedDatasetForColorization = dataset;
        this.selectedFieldForColorization = field;
        this.renderingOptions.converterOptions.fieldID = field.id;
        this.renderingOptions.converterOptions.datasetID = dataset.id;
        if (dataset.type === DatasetType.ACCOUNT_APPLICATION || dataset.type === DatasetType.GLOBAL_OVERLAY) {
            this.renderingOptions.datasetStylingOptions.colorizationFieldIndex = dataset.fields.indexOf(field);
        }
        this.checkScaleTypeByStatisticalValues();
    }

    onColorChange(event, index: number) {
        this.base64Colors[index] = event.target.value;
    }

    onRemoveColor(index: number, event: Event) {
        event.stopPropagation();
        if (this.base64Colors.length > 1) { // don't delete last element
            this.base64Colors.splice(index, 1);
        }
        this.checkScaleTypeByStatisticalValues();
    }

    onConstantColorChanged(event) {
        this.renderingOptions.datasetStylingOptions.defaultColor = ColorUtils.colorFromBase64(event.target.value);
        this.defaultColor = ColorUtils.colorToBase64(this.renderingOptions.datasetStylingOptions.defaultColor);
    }


    get DatasetType() {
        return DatasetType;
    }

    get ColorScaleType() {
        return ColorScaleType;
    }

    get ColorUtils() {
        return ColorUtils;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    onApplySettings() {
        this.renderingOptions.datasetStylingOptions.colors = this.base64Colors.map(color => ColorUtils.colorFromBase64(color));
        let invalidAttribute = RenderingUtils.validate(this.renderingOptions);
        if (!invalidAttribute) {
            this.submitChanges.next(this.renderingOptions);
        } else {
            switch (invalidAttribute) {
                case RenderingErrorCodes.COLORS:
                    this.notifService.error('You must select some colors for the colorization');
                    return;
                case RenderingErrorCodes.FIELD:
                    this.notifService.error('You must choose a field for the colorization');
                    return;
                case RenderingErrorCodes.INTERVALS:
                    this.notifService.error('You must define at least one interval');
                    return;
                case RenderingErrorCodes.INTERVALS_OVERLAP:
                    this.notifService.error('The defined intervals must not overlap');
                    return;
                case RenderingErrorCodes.DUPLICATE_INTERVAL:
                    this.notifService.error('The defined intervals must not have duplicates');
                    return;
            }
        }
    }

    checkScaleTypeByStatisticalValues() {
        // let colorizationField = this.selectedDatasetForColorization.fields[this.renderingOptions.datasetStylingOptions.colorizationFieldIndex];
        let gradientScaleType = this.renderingOptions.datasetStylingOptions.type === ColorScaleType.GRADIENT;
        let fixedScaleType = this.renderingOptions.datasetStylingOptions.type === ColorScaleType.FIXED;
        let constantScaleType = this.renderingOptions.datasetStylingOptions.type === ColorScaleType.CONSTANT;
        if (!constantScaleType) {
            const filter: DatapointFilter = {
                            datasetID: this.selectedDatasetForColorization.id,
                            groups: this.datapointsFilterService.getActiveFilter().groups
                        }
            this.datapointAggregateService.getDatapointsFieldStatistics(this.selectedDatasetForColorization.id, this.selectedFieldForColorization.id, filter).subscribe(statistics => {
                if (statistics.values) {
                    // not allowing falsy/null values
                    let values = statistics.values.filter(Boolean);
                    if (values.length === 0) {
                        this.notifService.error('Not available Field values... CONSTANT Color will apply.');
                        this.base64Colors = ColorUtils.defaultColorScale();
                        return;
                    }
                    if (this.base64Colors.length > values.length && fixedScaleType) {
                        let indexToSplice = values.length;
                        this.notifService.error('Not allowing so many COLORS because of the available Field values.');
                        this.base64Colors.splice(indexToSplice, this.base64Colors.length);
                    }
                    if (values.length === 1 && gradientScaleType) {
                        this.notifService.error('No GRADIENT could be generated with only one value.');
                    }
                    if (values.length > 1 && this.base64Colors.length === 1) {
                        this.base64Colors.push('#000000');
                        this.notifService.error('More COLOR is required to visualize this.');
                    }
                } else {
                    if (statistics.minValue && statistics.maxValue && statistics.minValue !== statistics.maxValue) {
                        let numberOfColorsAllowed = statistics.maxValue - statistics.minValue + 1;
                        if (this.base64Colors.length > numberOfColorsAllowed && fixedScaleType) {
                            this.base64Colors.splice(numberOfColorsAllowed, this.base64Colors.length);
                            this.notifService.error(`Exceeded maximum number of COLORS allowed.`);
                        }
                        if (this.base64Colors.length === 1 && (fixedScaleType || gradientScaleType)) {
                            this.base64Colors.push('#000000');
                            this.notifService.error('More COLOR is required to visualize this.');
                        }
                    }
                    if (statistics.minValue === statistics.maxValue) {
                        this.base64Colors = [this.base64Colors[0]];
                        this.notifService.error('Only one value available on this numeric field.');
                    }
                }
            });
        }
    }

    addColors() {
        this.base64Colors.push('#000000');
        this.checkScaleTypeByStatisticalValues();
    }

    private initContext(): void {
        this.selectedFieldForColorization = this.dataset.fields[this.renderingOptions.datasetStylingOptions.colorizationFieldIndex];
        this.selectedDatasetForColorization = this.dataset;
        this.mainDataset = this.datapointsPageStateService.getActiveDataset();
        this.account = this.datapointsPageStateService.activeAccount;
        if (this.renderingOptions.datasetStylingOptions && this.renderingOptions.datasetStylingOptions.colorizationFieldIndex) {
            let datasetField = this.dataset.fields[this.renderingOptions.datasetStylingOptions.colorizationFieldIndex];
            this.onFieldsMenuFieldClick(this.dataset, datasetField);
        }

        let privateComplexOverlays = this.datapointsPageStateService.getLinkedAccountOverlaysNotCloned().filter(overlay => overlay.geometryType === DatasetGeometryType.COMPLEX);
        this.colorizeByDatasetOptions = this.datapointsPageStateService.getLinkedAccountDatasetsNotCloned().concat(privateComplexOverlays);
        this.colorizeByDatasetOptions.unshift(this.dataset);
        this.populateBase64Colors();
    }

    ngOnChanges(): void {
        this.initContext();
    }

    reset(): void {
        this.Reset.emit();
    }
}
