import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ColDef, ColGroupDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent, IFilter, ITooltipParams } from 'ag-grid-community';
import { AgGridUtils } from 'src/app/core/utils/ag-grid-utils';
import { DateUtils } from 'src/app/core/utils/date-utils';
import { DatapointsService } from 'src/app/data-access-layer/datapoints/datapoints.service';
import { MapStateService } from 'src/app/map/map-state.service';
import { Crisis24Alert, Crisis24Circle } from 'src/app/model/datapoint/crisis24-alert';
import { Datapoint } from 'src/app/model/datapoint/datapoint';
import { DatapointFilterObject } from 'src/app/model/datapoint/datapoint-filter-object';
import { ProjectedDatapoint } from 'src/app/model/datapoint/projected-datapoint';
import { Dataset } from 'src/app/model/dataset/dataset';
import { DatasetFieldType } from 'src/app/model/dataset/dataset-field-type';
import { Point } from 'src/app/model/geometry/point';
import { isUndefined } from 'util';
import { DatapointsPageStateService } from '../../datapoints-page-state.service';
import { DetailCellRenderer } from './detailCellRenderer';

@Component({
  selector: 'map-crises24-datapoints-agtable',
  templateUrl: './crises24-datapoints-agtable.component.html',
  styleUrls: ['./crises24-datapoints-agtable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Crises24DatapointsAgtableComponent implements OnInit {

  @Input() datapointID: string;
  @Input() dataset: Dataset;
  @Input() location?: Point;
  @Input() version: number;
  @Input() radius?: number;
  @Input() isListCall?: boolean;

  datapointFilterObject: DatapointFilterObject

  private gridApi!: GridApi<any>;

  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public rowSelection: 'single' | 'multiple' = 'single';
  public gridOptions: Partial<GridOptions>;
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    filter: true,
    resizable: true,
  };
  public autoGroupColumnDef: ColDef = {
    minWidth: 200,
  };
  public groupDefaultExpanded = 1;
  public rowData: any[] = [];
  public datapoints: any;
  public crisis24CircleGlobalObject = [];

  public readonly limit = 1000;

  constructor(
    private readonly mapStateService: MapStateService,
    private readonly datapointsService: DatapointsService,
    private readonly service: DatapointsPageStateService,) { 

      this.gridOptions = {
        masterDetail: true,
        detailCellRenderer: DetailCellRenderer,
        groupDefaultExpanded: 1,
        onFirstDataRendered: this.onFirstDataRendered
      }
    }

  ngOnInit() {
    this.fetchDatasetInfo();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.forEachNode(function (node) {
      node.setExpanded(node.id === '0');
    });
  }


  fetchDatasetInfo() {
    this.datapointFilterObject = this.prepareRequestPayload();
    this.prepapreColumns();
  }

  transformData(datapoints: ProjectedDatapoint[]): ProjectedDatapoint[] {
    let transformDatapoints: ProjectedDatapoint[] = [];
    let crisis24CircleObject: Crisis24Circle [] = [];
    datapoints.forEach(datapoint => {
      const tempCrisis24CircleObject: Crisis24Circle = {location: datapoint.location, radius: Number(this.getRadius(datapoint).numberValue), severity: this.getSeverity(datapoint).textValue};
      crisis24CircleObject.push(tempCrisis24CircleObject);
      this.crisis24CircleGlobalObject.push(tempCrisis24CircleObject);
      let tempArryay = [];
      datapoint.fields.forEach(fields => {
        tempArryay.push({ [fields.id]: fields.textValue || (fields.numberValue) || fields.datetimeValue });
      });

      if (this.datapointFilterObject.projection.formulas) {
        let counter = 0;
        this.datapointFilterObject.projection.formulas.forEach(formula => {
          tempArryay.push({ [formula.id.toString()]: datapoint.formulaResults !== null ? datapoint.formulaResults[counter] : null });
          counter++;
        });
      }
      tempArryay.push({ ['id']: datapoint.id });
      let b = tempArryay.reduce(function (result, item) {
        var key = Object.keys(item)[0]; //first property: a, b, c
        result[key] = item[key];
        return result;
      }, {});
      transformDatapoints.push(b);
    });
    this.mapStateService.crisisCircle(crisis24CircleObject);
    return transformDatapoints;
  }

  onFilterChanged($event) {
    if ($event.columns[0].colId == this.dataset.id.split('_')[0]+"_severity") {
      const filterInstance: IFilter = this.gridOptions.api.getFilterInstance($event.columns[0].colId);
      const filterValues = filterInstance.getModel() !== null ? filterInstance.getModel().values : [];
      if (filterValues.length) {
        let filterIds = [];
        this.datapoints.forEach(datapoint => {
          if (filterValues.includes(this.getSeverity(datapoint).textValue)) {
            filterIds.push(datapoint.id);
          }
        });
        const filteredDatapoints = this.datapoints.filter(element => filterIds.includes(element.id));
        let crisis24CircleObject: Crisis24Circle [] = [];
        filteredDatapoints.forEach(datapoint => {
          crisis24CircleObject.push({location: datapoint.location, radius: Number(this.getRadius(datapoint).numberValue), severity: this.getSeverity(datapoint).textValue});
        });
        this.mapStateService.crisisCircle(crisis24CircleObject);
      } else {
        this.mapStateService.crisisCircle(this.crisis24CircleGlobalObject);
      }
    }
  }

  prepareRequestPayload(): DatapointFilterObject {
    let projection = {
      datasetID: this.dataset.id,
      fields: this.dataset.fields.map(element => element.id),
      links: [],
      geometryPrecision:25
    };

    let filter = {
      datasetID: this.dataset.id,
      groups: [],
      fields: [],
      links: []

    };
    let sort = null;
    return { projection, filter, limit: this.limit, skip: 0, sort };
  }

  getDataset(id): Dataset {
    return this.service.getDataset(id);
  }

  prepapreColumns(): (ColDef | ColGroupDef)[] {
    if (this.datapointFilterObject.projection.formulas) {
        this.datapointFilterObject.projection.formulas.forEach(formula => {
          let column = {
            field: formula.id.toString(),
            colId: formula.name.toString(),
            headerTooltip: formula.name,
            headerName: formula.name,
            filter: false,
            pivot: false,
            enableRowGroup: false,
            sortable: false
          };
          this.columnDefs.push(column);
        });
    }
    this.datapointFilterObject.projection.fields.forEach(fieldID => {
        let datasetField = this.dataset.fields.find(field => field.id === fieldID);
        if (datasetField.isDisplayedInProjection && datasetField.id !== 'crisis24_content') {
          datasetField.name = datasetField.name === "Redius" ? "Radius" : datasetField.name;
          let column = this.getColumn(datasetField, this.dataset.id, datasetField.name, fieldID);
          this.columnDefs.push(column);
        }
    });
    this.datapointFilterObject.projection.links.forEach((link) => {
        link.fields.forEach((fieldID) => {
            let datasetField = this.getDataset(link.datasetID).fields.find(searchedDatasetField => searchedDatasetField.id === fieldID);
            if (datasetField.isDisplayedInProjection) {
                let dataset = this.getDataset(link.datasetID);
                let column = this.getColumn(datasetField, dataset.id, datasetField.name + ' / ' + dataset.name, fieldID);
                this.columnDefs.push(column);
            }
        });
    });
    this.columnDefs[1] = {...this.columnDefs[1], ... { cellRenderer: 'agGroupCellRenderer'}};
    return this.columnDefs;
  }

  getColumn(field, id, name, colId) {
    return  {
      field: colId,
      colId: colId,
      headerTooltip: name,
      headerName: name,
      tooltipField: colId,
      hide: colId == 'id' ? true : false,
      type: AgGridUtils.getGridColumnTypeByDatasetType(field),
      filter: this.isListCall,
      sortable: this.isListCall,
      valueFormatter: params=>{
        if (field.baseType == DatasetFieldType.DATE_TIME && params.value !== null) {
          return DateUtils.formatDate(params.value);
        }
     }
    };
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.datapointsService.getDatapointsByFilter(this.datapointFilterObject).subscribe(response => {
      if (!this.isListCall) {
        response = response.filter(element => element.rootID === this.datapointID);
      }
      this.datapoints = response;
      let datapointTransformed: ProjectedDatapoint[] = this.transformData(response);
      params.api!.setRowData(datapointTransformed);
    });
  }

  getRadius(datapoint: Datapoint) {
    return datapoint.fields.find(element => element.id == this.dataset.id.split('_')[0] + '_radius');;
  }

  getSeverity(datapoint: Datapoint) {
    return datapoint.fields.find(element => element.id == this.dataset.id.split('_')[0] + '_severity');;
  }

  // onSelectionChanged() {
  //   const selectedRows = this.gridApi.getSelectedRows();
  //   this.datapointsService.getDatapoint(selectedRows[0].id, this.dataset.id).subscribe(response => {
  //     const radius = response.fields.find(element => element.id == this.dataset.id.split('_')[0] + '_redius');
  //     this.mapStateService.crisisCircle(response.location, radius.numberValue);
  //   });
  // }

}
