import {Injectable} from '@angular/core';
import {OverlaysService} from '../data-access-layer/global-overlays/overlays.service';
import {CreateDatasetRequest} from '../model/dataset/create-dataset-request';
import {DatasetGeometryType} from '../model/dataset/dataset-geometry-type';
import {DatasetType} from '../model/dataset/dataset-type';
import {DatasetField} from '../model/dataset/field/dataset-field';
import {ColorScaleType} from '../model/dataset/rendering/color-scale-type';
import {DatasetFieldSpecificType} from '../model/dataset/dataset-field-specific.type';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {Dataset} from '../model/dataset/dataset';
import {DatasetService} from '../data-access-layer/dataset/dataset.service';
import {mergeMap, switchMap, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ThematicMapScripts {

    private readonly overlaySizesInMb = [6, 100, 300, 300];

    constructor(private readonly overlayService: OverlaysService, private readonly datasetService: DatasetService) {

    }


    initializeOverlays(): Observable<any> {
        let datasets: Dataset[] = [];
        let datasetsObservables = [];
        for (let i = 1; i <= 4; i++) {
            datasetsObservables.push(this.overlayService.createOverlay(this.getThematicDatasetRequest(i)).pipe(tap(dataset => {
                datasets[i - 1] = dataset;
            })));
        }
        return forkJoin(datasetsObservables)
            .pipe(
                mergeMap(v => {
                    let updateRequests: Observable<Dataset>[] = [];
                    for (let i = 0; i < 4; i++) {
                        let dataset = datasets[i];
                        dataset.thematicMapSettings = {
                            isThematicMapDataset: true,
                            mainThematicFieldId: '0',
                            parentDatasetId: i !== 0 ? datasets[i - 1].id : null,
                            parentLinkFieldId: i !== 0 ? '1' : null
                        };
                        updateRequests.push(this.datasetService.updateDataset(dataset));
                    }
                    return forkJoin(updateRequests);
                })
            );
    }

    getThematicDatasetRequest(index: number): CreateDatasetRequest {
        let geometryShardCount = Math.max(1, this.overlaySizesInMb[index - 1] / 50);
        let tilesShardCount = geometryShardCount * 2;
        return {
            geometryType: DatasetGeometryType.COMPLEX,
            hasHazardHubEnabled: false,
            isLinkable: true,
            name: `Level ${index}`,
            accountId: null,
            fields: this.getFieldsForThematicDataset(index),
            isGeocoded: false,
            mainSummaryFieldIndex: 0,
            stylingOptions: {
                type: ColorScaleType.CONSTANT,
                colorizationFieldIndex: 0,
                defaultColor: 11184810,
                intervalOptions: [],
                backgroundOpacity: 1
            },
            type: DatasetType.GLOBAL_OVERLAY,
            geometryShardCount: geometryShardCount,
            geometryTileShardCount: tilesShardCount

        };
    }

    getFieldsForThematicDataset(index: number): DatasetField[] {
        let fields: DatasetField[] = [];
        fields.push({
            id: null,
            isSummary: true,
            isArray: false,
            isGenerated: false,
            name: 'Name',
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD
        });
        if (index > 1) {
            fields.push({
                id: null,
                isSummary: true,
                isArray: false,
                isGenerated: false,
                name: `Level ${index - 1} Name`,
                type: DatasetFieldSpecificType.SHORT_TEXT_FIELD
            });
        }
        return fields;
    }

}
