import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {Config, isEnabled} from '../../../environments/environment';
import {Functionalities} from '../../../environments/app-functionalities';

@Injectable({
    providedIn: 'root'
})
export class ConfigGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        let isPartOf = false;
        let partOf: Functionalities[] = route.data.partOf;

        partOf.forEach(part => {
            if (isEnabled(part)) {
                isPartOf = true;
            }
        });

        if (!isPartOf) {
            this.router.navigate(['/']);
        }
        return isPartOf;
    }

    // isEnabled(functionalityName: Functionalities): boolean {
    //     let r = Config.find((functionality) => functionality.name === functionalityName && functionality.enabled === true);
    //     if (r) {
    //         return true;
    //     }
    //     return false;
    // }

}
