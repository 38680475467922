import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'map-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    @Input () breadCrumbs = [];
    @HostBinding('class') class = 'breadcrumbs-inner';
  constructor() { }

  ngOnInit() {
  }

}
