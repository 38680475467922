import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AccountMember} from '../../model/member/account-member';
import {CreateMemberRequest} from '../../model/member/create-member-request';
import {AcceptMemberInvitationRequest} from '../../model/member/accept-member-invitation-request';
import {ChangePasswordRequest} from '../../model/user/change-password-request';

@Injectable({
    providedIn: 'root'
})
export class MembersService {

    constructor(private readonly http: HttpClient) {
    }


    createMember(request: CreateMemberRequest): Observable<AccountMember> {
        return this.http.post<AccountMember>(`/members`, request);
    }

    acceptMemberInvitation(request: AcceptMemberInvitationRequest): Observable<void> {
        return this.http.post<void>(`/members/accept`, request);
    }

    findAccountMembers(accountID: number): Observable<AccountMember[]> {
        return this.http.get<AccountMember[]>(`/members/account/${accountID}`);
    }

    addMemberToGroup(memberID: number, groupID: number): Observable<void> {
        return this.http.post<void>(`/members/${memberID}/group/${groupID}/add`, {});
    }

    removeMemberFromGroup(memberID: number, groupID: number): Observable<void> {
        return this.http.delete<void>(`/members/${memberID}/group/${groupID}/remove`);
    }

    deleteMember(memberID: number): Observable<void> {
        return this.http.delete<void>(`/members/${memberID}`);
    }

    generateMembers(account): any {
        const promises = [];
        for (let i = 0; i <= 20; i++) {
            const createMemberRequest: any = {
                accountID: account.id,
                groups: {},
                email: `random_element@gmail.com`,
                isAccountAdmin: false
            };
            const newPromise = this.createMember(createMemberRequest).toPromise();
            promises.push(newPromise);
        }
        return Promise.all(promises);
    }

    getRandomElement(array: any[]): any {
        return array[Math.floor(Math.random() * array.length)];
    }

    validateToken(token: string): Observable<void> {
        return this.http.get<void>(`/members/verify/${token}`);
    }

    inviteMember(memberID: number) {
        return this.http.post<void>(`/members/invite/${memberID}`, {});
    }

    updateMember(member: AccountMember) {
        // PUT /v2/members
        return this.http.put<void>(`/members`, member);
    }

}
