export class DataPointField {
    private value: any;

    constructor(field: FieldLocalInterface) {
        this.constructValue(field);
    }

    get fieldValue() {
        return this.value;
    }

    private setField(valueName: string, field: FieldLocalInterface) {
        let value = {id: field.id};
        value[valueName] = field.value;
        return value;
    }

    private constructValue(field: FieldLocalInterface) {
        switch (field.type) {
            case FieldType.DATE: {
                this.value = this.setField(ValueName.DATE, field);
                break;
            }
            case FieldType.TEXT: {
                this.value = this.setField(ValueName.TEXT, field);
                break;
            }
            case FieldType.NUMBER: {
                this.value = this.setField(ValueName.NUMBER, field);
                break;
            }
            default: {
                this.value = this.setField(ValueName.TEXT, field);
                break;
            }
        }
    }

}

// TODO why do we have field definitions again?
interface FieldLocalInterface {
    id: string;
    name: string;
    value: any;
    type: FieldType;
}

export const enum FieldType {
    DATE = 'DATE_FIELD',
    NUMBER = 'NUMBER_FIELD',
    TEXT = 'SHORT_TEXT_FIELD'
}

export const enum ValueName {
    TEXT = 'textValue',
    DATE = 'datetimeValue',
    NUMBER = 'numberValue'
    // "textArrayValue": null,
    // "datetimeArrayValue": null,
    // "numberArrayValue": null,
    // "unparsedValue":
}
