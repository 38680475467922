import {Point} from './point';

export interface GeometryFilterShape {
    type: Type;

    center?: Point; // for circle
    radius?: number; // for circle
    bottom?: number; // for rectangle
    left?: number; // for rectangle
    top?: number; // for rectangle
    right?: number; // for rectangle
    coordinates?: number[]; // for polygons


}

export enum Type {
    POLYGON = 'POLYGON',
    CIRCLE = 'CIRCLE',
    BOUNDS = 'BOUNDS'
}
