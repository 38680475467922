import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Dataset } from '../../model/dataset/dataset';
import { DatasetField } from '../../model/dataset/field/dataset-field';
import { DatapointDraft } from '../../model/datapoint/draft/datapoint-draft';
import {DraftFilter} from '../../model/filter/draft-filter';
import {DatapointFilter} from '../../model/datapoint/filter/datapoint-filter';

@Injectable({
    providedIn: 'root'
})
export class DraftService {
    private readonly baseRoute: string;

    constructor(private readonly http: HttpClient) {
    }

    findDraftsByFilter(datasetID: string, filter: any): Observable<DatapointDraft[]> {
        return this.http.post<DatapointDraft[]>(`/drafts/dataset/${ datasetID }/find`, filter);
    }

    findDraft(draftId: string, datasetId: string): Observable<DatapointDraft> {
        return this.http.get<DatapointDraft>(`/drafts/${ draftId }/dataset/${ datasetId }`);
    }

    deleteDraft(draftId: number, datasetId: string): Observable<number> {
        return this.http.delete<number>(`/drafts/${ draftId }/dataset/${ datasetId }`);
    }

    deleteDrafts(datasetId: string, draftsIDs: any[]): Observable<any[]> {
        return this.http.delete<any[]>(`/drafts/dataset/${ datasetId }`, {params: {draftsIDs: draftsIDs}});
    }

    deleteDraftsByFilter(datasetId: string, filter: DraftFilter): Observable<number> {
        return this.http.delete<number>(`/drafts/dataset/${ datasetId }/filter`, {params: {filter: JSON.stringify(filter)}});
    }

    updateDraftsByFilter(datasetId: number, updateDraftsByFilterRequest: UpdateDraftsByFilterRequest): Observable<void> {
        return this.http.put<void>(`/drafts/dataset/${ datasetId }/batch`, updateDraftsByFilterRequest);
    }

    createDrafts(datasetId: number, createDraftsRequest: CreateDraftsRequest): Observable<DatapointDraft[]> {
        return this.http.post<DatapointDraft[]>(`/drafts/dataset/${ datasetId }/batch`, createDraftsRequest);
    }

    approveDrafts(datasetId: string, draftIDs: number[], targetGroup: number, fieldIds: string[]): Observable<number> {
        return this.http.put<number>(`/drafts/dataset/${ datasetId }/approve`, draftIDs, {params: {fields: fieldIds, target: targetGroup.toString()}});
    }

    approveDraftsByFilter(datasetId: string, filter: DraftFilter, targetGroupId: number, fieldIds: string[]): Observable<number> {
        let jsonFilter = JSON.stringify(filter);
        return this.http.put<number>(`/drafts/dataset/${ datasetId }/approve/filter`, {}, {params: {filter: jsonFilter, target: targetGroupId.toString(), fields: fieldIds}});
    }

    updateDraft(datasetId: string, updateDraftRequest: UpdateDraftRequest): Observable<any> {
        return this.http.put<any>(`/drafts/dataset/${ datasetId }`, updateDraftRequest);
    }

    createDraft(datasetId: number, createDraftRequest: CreateDraftRequest): Observable<any> {
        return this.http.post<any>(`/drafts/dataset/${ datasetId }`, createDraftRequest);
    }



    linkDrafts(datasetId: string, draftsIDs: any[]): Observable<void> {
        return this.http.post<void>(`/drafts/${ datasetId }/link`, draftsIDs);

    }

    linkDraftsByFilter(datasetId: string, filter: any): Observable<void> {
        return this.http.post<void>(`/drafts/${ datasetId }/link/filter`, JSON.stringify((filter)));
    }

    tileDrafts(datasetId: string, draftsIDs: any[]): Observable<void> {
        return this.http.post<void>(`/drafts/${ datasetId }/tile`, draftsIDs);
    }

    tileDraftsByFilter(datasetId: string, filter: any): Observable<void> {
        return this.http.post<void>(`/drafts/${ datasetId }/tile/filter`, JSON.stringify((filter)));
    }

    getDatapointsPaginationInfo(draftFilter: DraftFilter): any {
        return this.http.get(`/drafts/aggregate/dataset/${draftFilter.datasetID}/count`, {params: {filter: JSON.stringify(draftFilter)}});
    }

    fetchGeoserverOverlays(GeoserverDatasetId: string): any {
        return this.http.get(`/geoserver/fetch/dataset/${GeoserverDatasetId}`);
    }
}

// tslint:disable:no-empty-interface
// TODO why do we have empty interfaces? To be removed
export interface DeleteDraftsRequest {

}

export interface CreateDraftRequest {

}

export interface UpdateDraftRequest {

}

export interface CreateDraftsRequest {

}

export interface UpdateDraftsByFilterRequest {

}

export interface ApproveDraftsRequest {

}

// DELETE /v2/drafts/dataset/{datasetID}
// deleteDrafts

// POST /v2/drafts/dataset/{datasetID}
// createDraft

// PUT /v2/drafts/dataset/{datasetID}
// updateDraft

// PUT /v2/drafts/dataset/{datasetID}/approve
// approveDrafts

