import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatapointFilter } from '../../model/datapoint/filter/datapoint-filter';
import { Observable } from 'rxjs';
import { DatasetFieldStatistics } from '../../model/analytics/dataset-field-statistics';
import { map } from 'rxjs/operators';
import { DatasetAnalyticsCount } from '../../model/analytics/dataset-analytics-count';

@Injectable({
    providedIn: 'root'
})
export class DraftsAggregateService {

    constructor(private readonly http: HttpClient) {
    }

    /**
     * If needed this method works in backend with multiple fields.
     */
    getDraftsFieldStatistics(datasetID: string, fieldID: string, filter: DatapointFilter): Observable<DatasetFieldStatistics> {
        let stringFilter = JSON.stringify(filter);
        let fieldIDs = JSON.stringify([fieldID]);
        return this.http.get<DatasetFieldStatistics[]>(`/drafts/aggregate/dataset/${ datasetID }/fields`, {params: {filter: stringFilter, fieldIDs: fieldIDs}})
            .pipe(map(response => response[0]));
    }

    getDraftsCount(datasetID: string, filter: DatapointFilter): Observable<DatasetAnalyticsCount> {
        let stringFilter = JSON.stringify(filter);
        return this.http.get<DatasetAnalyticsCount>(`/drafts/aggregate/dataset/${ datasetID }/count`, {params: {filter: stringFilter}});
    }

}
