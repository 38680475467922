import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Attachment} from '../model/attachment/attachment';

@Injectable()
export class AttachmentService {

    constructor(private readonly http: HttpClient) {
    }

    getAttachmentsForDatapoint(datapointId: string, datasetId: string): Observable<Attachment[]> {
        let params = new HttpParams()
            .set('datapointId', datapointId)
            .set('datasetId', datasetId);
        return this.http.get<Attachment[]>('/attachments', {params: params});
    }

    deleteAttachment(attachmentId: string, datapointId: string, datasetId: string): Observable<void> {
        let params = new HttpParams()
            .set('attachmentId', attachmentId)
            .set('datapointId', datapointId)
            .set('datasetId', datasetId);
        return this.http.delete<void>('/attachments', {params: params});
    }

    uploadAttachments(file: any, datapointId: string, datasetId: string): Observable<Attachment[]> {
        let params = new HttpParams()
            .set('datapointId', datapointId)
            .set('datasetId', datasetId);

        const formData = new FormData();
        for (let f of file) {
            formData.append('file', f);
        }
        return this.http.post<Attachment[]>(`/attachments`, formData, {params: params});
    }

}
