import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IntervalOption} from '../../../model/dataset/rendering/interval-option';
import {ColorUtils} from '../../../core/utils/color-utils';
import {GradientDetails} from '../../../model/legend/gradient-details';
import { MapThematicOverlayService } from '../../map-thematic-overlay.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'map-datapoint-map-legend',
    templateUrl: './datapoint-map-legend.component.html',
    styleUrls: ['./datapoint-map-legend.component.scss']
})
export class DatapointMapLegendComponent implements OnInit, OnDestroy {

    @Input() intervalOptions: IntervalOption[] = [];
    @Input() datasetName: string;
    @Input() datasetId: string;
    @Input() fieldLabel: string;
    @Output() removeLegend = new EventEmitter<string>();

    _gradientDetails: GradientDetails;
    linearGradientValue: string;

    @Input() set gradientDetails(gradientDetails: GradientDetails) {
        if (gradientDetails) {
            gradientDetails.gradientColors = gradientDetails.gradientColors.reverse();
            this._gradientDetails = gradientDetails;
            this.linearGradientValue = 'linear-gradient(to bottom,' + gradientDetails.gradientColors + ')';
        }
    }
    legendLimit = [5, 15, 50];
    private readonly subscriptions: Subscription = new Subscription();

    constructor(
        private readonly mapThematicOverlayService: MapThematicOverlayService) {}

    ngOnInit(): void {
        this.subscriptions.add(this.mapThematicOverlayService.onCustomizedLegends().subscribe((limit) => {
            if(limit.length > 0){
                this.legendLimit = limit;
            }
        }));
    }

    get ColorUtils() {
        return ColorUtils;
    }

    deleteLegend() {
        this.removeLegend.emit(this.datasetId);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
