export interface TableColumn {
    id: string;
    name: string;
    type: TableColumnType;
    horizontalAlignment? : TableColumnAlignment | null ;
}

export enum TableColumnType {
    INTEGER = 'INTEGER',
    INTEGER_LIST = 'INTEGER_LIST',
    DECIMAL = 'DECIMAL',
    DECIMAL_LIST = 'DECIMAL_LIST',
    TEXT = 'TEXT',
    TEXT_LIST = 'TEXT_LIST',
    DATE = 'DATE',
    DATE_LIST = 'DATE_LIST',
    UNKNOWN = 'UNKNOWN',
}

export enum TableColumnAlignment {
    GENERAL = "GENERAL",
    LEFT = "LEFT",
    CENTER = "CENTER",
    RIGHT = "RIGHT",
    FILL = "FILL",
    JUSTIFY = "JUSTIFY",
    CENTER_SELECTION = "CENTER_SELECTION",
    DISTRIBUTED = "DISTRIBUTED",
}

