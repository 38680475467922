export enum DatasetFieldSpecificType {
    SHORT_TEXT_FIELD = 'SHORT_TEXT_FIELD',
    LONG_TEXT_FIELD = 'LONG_TEXT_FIELD',
    TELEPHONE_TEXT_FIELD = 'TELEPHONE_TEXT_FIELD',
    EMAIL_TEXT_FIELD = 'EMAIL_TEXT_FIELD',
    URL_TEXT_FIELD = 'URL_TEXT_FIELD',
    NUMBER_FIELD = 'NUMBER_FIELD',
    DATE_FIELD = 'DATE_FIELD',
    RADIO_TEXT_FIELD = 'RADIO_TEXT_FIELD',
    CHECKBOX_TEXT_FIELD = 'CHECKBOX_TEXT_FIELD',
    SELECT_TEXT_FIELD = 'SELECT_TEXT_FIELD',
    LINKS_FIELD = 'LINKS_FIELD',
    MEMBERS_FIELD = 'MEMBERS_FIELD'
}
