export class RoutingUtils {

    private static readonly GLOBAL_OVERLAYS_BASE_URL = '/private/overlays/global-overlays';
    private static readonly DRAT_GLOBAL_OVERLAYS_BASE_URL = '/private/global-overlays';
    private static readonly EXTERNAL_OVERLAYS_BASE_URL = '/private/overlays/external-overlays';


    static getGlobalOverlaysDraftsRoute(datasetId: string): string {
        return `${ RoutingUtils.DRAT_GLOBAL_OVERLAYS_BASE_URL }/${ datasetId }/drafts`;
    }

    static getGlobalOverlaysDatapointsRoute(datasetId: string): string {
        return `${ RoutingUtils.DRAT_GLOBAL_OVERLAYS_BASE_URL }/${ datasetId }/datapoints/table`;
    }

    static getGlobalOverlaysEditRoute(datasetId: string): string {
        return `${ RoutingUtils.GLOBAL_OVERLAYS_BASE_URL }/${ datasetId }/edit`;
    }

    static getExternalOverlaysEditRoute(datasetId: string): string {
        return `${ RoutingUtils.EXTERNAL_OVERLAYS_BASE_URL }/${ datasetId }/edit`;
    }

    static getGlobalOverlaysEditAccountsRoute(datasetId: string): string {
        return `${ RoutingUtils.GLOBAL_OVERLAYS_BASE_URL }/${ datasetId }/edit-accounts`;
    }

    static getGlobalOverlaysRoute() {
        return RoutingUtils.GLOBAL_OVERLAYS_BASE_URL;
    }

    static getExternalOverlaysRoute() {
        return RoutingUtils.EXTERNAL_OVERLAYS_BASE_URL;
    }

    static getAccountDatasetDatapointsRoute(accountId: number, datasetId: string) {
        return `/private/account/${ accountId }/dataset/${ datasetId }/datapoints/map`;
    }

    static getAccountDatasetDraftsRoute(accountId: number, datasetId: string) {
        return `/private/account/${ accountId }/dataset/${ datasetId }/drafts`;
    }

    static getPrivateOverlaysEditRoute(datasetId: string, accountId: number): string {
        return `/private/account/${ accountId }/settings/edit-overlay/${ datasetId }`;
    }
    static getPrivateOverlaysCreateRoute(accountId: number): string {
        return `/private/account/${ accountId }/settings/create-overlay`;
    }
    static getPrivateOverlaysRoute(accountId: number): string {
        return `/private/account/${ accountId }/settings/private-overlays`;
    }
}
