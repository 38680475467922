import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import { MembersService } from '../../../../data-access-layer/members/members.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { Account } from 'src/app/model/account/account';
import { CreateMemberRequest } from '../../../../model/member/create-member-request';
import { DateUtils } from '../../../../core/utils/date-utils';
import {NotifService} from '../../../../core/notification/notif.service';
import {AccountServiceState} from '../../../../shared/services/account-service-state';

@Component({
    selector: 'map-create-member',
    templateUrl: './create-member.component.html',
    styleUrls: ['./create-member.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateMemberComponent {
    @Output() created = new EventEmitter();
    @Input() groupModel;
    @Input() currentAccount: Account;
    createMemberForm: FormGroup;
    today = new Date();
    tomorrow = new Date();

    constructor(
        private readonly memberService: MembersService,
        private readonly formBuilder: FormBuilder,
        private  readonly notifService: NotifService,
        private readonly accountServiceState: AccountServiceState,
        private readonly cd: ChangeDetectorRef
    ) {
        this.initForm();
        this.tomorrow.setDate(this.today.getDate() + 1);
    }


    private initForm() {
        this.createMemberForm = this.formBuilder.group({
            email: ['', [Validators.required,
                Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
            Validators.maxLength(50)]],
            isMember: [false],
            groups: [],
            isAccountAdmin: [{ value: false, disabled: true }],
            deleteDate: [undefined, [this.getDeleteDateValidator(() => this.createMemberForm.get('isMember').value)]]
        });
        // on isMember value change
        this.createMemberForm.get('isMember').valueChanges
            .subscribe(value => {
                this.createMemberForm.get('deleteDate').updateValueAndValidity();
            });
    }

    get DateUtils() {
        return DateUtils;
    }

    private constructCreateMemberRequest(): CreateMemberRequest {
        let deleteDate = this.createMemberForm.controls.deleteDate.value;
        return {
            accountID: this.currentAccount.id,
            email: this.createMemberForm.controls.email.value,
            groups: {},
            isAccountAdmin: false,
            deleteDate: this.createMemberForm.get('isMember').value ? new Date(deleteDate).getTime() : null
        };
    }

    createMember(): void {

        if (this.createMemberForm.valid) {
            this.memberService.createMember(this.constructCreateMemberRequest()).subscribe((member) => {
                this.accountServiceState.emitOnCreateMemberSuccess();
                this.initForm();
                this.cd.detectChanges();
            }, (err) => {
                console.log(err);
                this.notifService.error(err.error.message);
            });
        } else {
            console.log('Invalid form');
        }
    }

    // validator for Delete Date field
    getDeleteDateValidator(predicate) {
        return (formControl => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return Validators.required(formControl);
            }
            return null;
        });
    }
}
