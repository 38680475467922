import { Point } from "chart.js";

export class Crisis24Alert {
 
    private static readonly GREY = '#808080';
    private static readonly ORANGE = '#ff9900';
    private static readonly RED = '#ff0000';
    private static readonly id = "crisis24_alert";
    private static panelInstanceId = "crises24DatapointsAgTable";

    public static getColor(severity: string) {
        if (severity === Severity.LOW) {
            return this.GREY;
        } else if (severity === Severity.HIGH) {
            return this.RED;
        } else if (severity === Severity.MODERATE) {
            return this.ORANGE;
        }
    }

    public static getId() {
        return this.id;
    }

    public static getPanelId() {
        return this.panelInstanceId;
    }
}


export interface Crisis24Circle {
    location: Point;
    radius: number;
    severity?: string
}

export enum Severity {
    LOW = 'low',
    HIGH = 'high',
    MODERATE = 'moderate'
}

