import { Injectable } from '@angular/core';
import { Point } from '../../../model/geometry/point';
import { ClimateService } from '../../../data-access-layer/climate.service';
import { ClimateChart} from '../../../model/climate/climate-chart';
import { MeanTemperatureService } from './mean-temperature.service';
import { MinTemperatureService } from './min-temperature.service';
import { PopulationDensityService } from './population-density.service';
import { PrecipitationService } from './precipitation.service';
import { SeaLevelRiseService } from './sea-level-rise.service';
import { SnowfallService } from './snowfall.service';
import { SurfaceWindService } from './surface-wind.service';
import { MaxTemperatureService } from './max-temperature.service';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SeaLevelRiseWarmingService } from './sea-level-rise-warming.service';

@Injectable()
export class ClimateOverlayPermission {
  isMaxTemperatureEnabled: boolean; 
  isMeanTemperatureEnabled: boolean; 
  isMinTemperatureEnabled: boolean;
  isSnowFallEnabled: boolean;
  isPrecipitationEnabled: boolean; 
  isSurfaceWindEnabled: boolean;
  isPopulationDensityEnabled: boolean;
  isSeaLevelRiseEnabled: boolean;
  isSeaLevelRiseWarmingEnabled: boolean;
}

@Injectable()
export class DatapointsClimateChartService {

  locationMaxTemperatureSubscription: Subscription;
  locationMeanTemperatureSubscription: Subscription;
  locationMinTemperatureSubscription: Subscription;
  locationSnowfallSubscription: Subscription;
  locationPrecipitationSubscription: Subscription;
  locationSurfaceWindSubscription: Subscription;
  locationPopulationDensitySubscription: Subscription;
  locationSeaLevelRiseSubscription: Subscription;
  locationSeaLevelRiseWarmingSubscription: Subscription;

  addressProfileMaxTemperatureSubscription: Subscription;
  addressProfileMeanTemperatureSubscription: Subscription;
  addressProfileMinTemperatureSubscription: Subscription;
  addressProfileSnowfallSubscription: Subscription;
  addressProfilePrecipitationSubscription: Subscription;
  addressProfileSurfaceWindSubscription: Subscription;
  addressProfilePopulationDensitySubscription: Subscription;
  addressProfileSeaLevelRiseSubscription: Subscription;
  addressProfileSeaLevelRiseWarmingSubscription: Subscription;

  downloadRequestPayload: {maxTemperature: ClimateChart, meanTemperature: ClimateChart, minTemperature: ClimateChart, snowfallTemperature: ClimateChart, precipitation: ClimateChart, surfaceWind: ClimateChart, populationDensity: ClimateChart, seaLevelRise: ClimateChart, seaLevelRiseWarming: ClimateChart} = 
  {maxTemperature: null, meanTemperature: null, minTemperature: null, snowfallTemperature: null, precipitation: null, surfaceWind: null, populationDensity: null, seaLevelRise: null, seaLevelRiseWarming: null};

  chartGraphicsInstances: {maxTemperature: any, meanTemperature: any, minTemperature: any, snowfallTemperature: any, precipitation: any, surfaceWind: any, populationDensity: any, seaLevelRise: any, seaLevelRiseWarming: any} = 
  {maxTemperature: null, meanTemperature: null, minTemperature: null, snowfallTemperature: null, precipitation: null, surfaceWind: null, populationDensity: null, seaLevelRise: null, seaLevelRiseWarming: null};

  chartGraphicsConfiguration: {  format: 'JPEG' | 'PNG' | 'WEBP', 
                                 graphType:{ 
                                            maxTemperature: {title: string, instance: any}, meanTemperature: {title: string, instance: any}, minTemperature: {title: string, instance: any}, snowfallTemperature: {title: string, instance: any},
                                            precipitation: {title: string, instance: any}, surfaceWind: {title: string, instance: any}, populationDensity: {title: string, instance: any},
                                            seaLevelRise: {title: string, instance: any}, seaLevelRiseWarming: {title: string, instance: any}
                                          }
                              } = 
    { 
      format: 'PNG', 
      graphType: { 
                    maxTemperature: {title: 'Maximum Temperature', instance: null}, meanTemperature: {title: 'Mean Temperature', instance: null}, minTemperature: {title: 'Minimum Temperature', instance: null}, snowfallTemperature: {title: 'Snowfall', instance: null},
                    precipitation: {title: 'Precipitation', instance: null}, surfaceWind: {title: 'SurfaceWind', instance: null}, populationDensity: {title: 'Population Density', instance: null},
                    seaLevelRise: {title: 'Sea Level Rise', instance: null}, seaLevelRiseWarming: {title: 'Sea Level Rise Warming', instance: null}
                  }
    };

  constructor(private climateService: ClimateService,
    public maxTemperatureService: MaxTemperatureService,
    public meanTemperatureService: MeanTemperatureService,
    public minTemperatureService: MinTemperatureService,
    public snowfallService: SnowfallService,
    public precipitationService: PrecipitationService,
    public surfaceWindService: SurfaceWindService,
    public populationDensityService: PopulationDensityService,
    public seaLevelRiseService: SeaLevelRiseService,
    public seaLevelRiseWarmingService: SeaLevelRiseWarmingService) {

  }

  showLoading(ec) {
    ec.showLoading({
      text: 'Loading',
      color: '#c23531',
      textColor: '#000',
      maskColor: 'rgba(255, 255, 255, 0.8)',
      zlevel: 0,

      // Font size. Available since `v4.8.0`.
      fontSize: 12,
      // Show an animated "spinner" or not. Available since `v4.8.0`.
      showSpinner: true,
      // Radius of the "spinner". Available since `v4.8.0`.
      spinnerRadius: 10,
      // Line width of the "spinner". Available since `v4.8.0`.
      lineWidth: 5,
      // Font thick weight. Available since `v5.0.1`.
      fontWeight: 'normal',
      // Font style. Available since `v5.0.1`.
      fontStyle: 'normal',
      // Font family. Available since `v5.0.1`.
      fontFamily: 'sans-serif'
    });
  }

  checkClimatePermissions(climateOverlayPermission: ClimateOverlayPermission, datasetID: string) {
    this.resetDownloadPayload();
    this.climateService.isMaxTemperatureEnabled(datasetID).subscribe(response => { climateOverlayPermission.isMaxTemperatureEnabled = response });
    this.climateService.isMeanTemperatureEnabled(datasetID).subscribe(response => { climateOverlayPermission.isMeanTemperatureEnabled = response });
    this.climateService.isMinTemperatureEnabled(datasetID).subscribe(response => { climateOverlayPermission.isMinTemperatureEnabled = response });
    this.climateService.isSnowFallEnabled(datasetID).subscribe(response => { climateOverlayPermission.isSnowFallEnabled = response });
    this.climateService.isPrecipitationEnabled(datasetID).subscribe(response => { climateOverlayPermission.isPrecipitationEnabled = response });
    this.climateService.isSurfaceWindEnabled(datasetID).subscribe(response => { climateOverlayPermission.isSurfaceWindEnabled = response });
    this.climateService.isPopulationDensityEnabled(datasetID).subscribe(response => { climateOverlayPermission.isPopulationDensityEnabled = response });
    this.climateService.isSeaLevelRiseEnabled(datasetID).subscribe(response => { climateOverlayPermission.isSeaLevelRiseEnabled = response });
    this.climateService.isSeaLevelRiseWarmingEnabled(datasetID).subscribe(response => { climateOverlayPermission.isSeaLevelRiseWarmingEnabled = response });
  }

  subscribeLocationChartInstances(location: Point, datasetID: string) {
    this.locationMaxTemperatureSubscription = this.maxTemperatureService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchMaxTemperatureByLocation(location, datasetID, instance);
    });

    this.locationMeanTemperatureSubscription = this.meanTemperatureService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchMeanTemperatureByLocation(location, datasetID, instance);
    });

    this.locationMinTemperatureSubscription = this.minTemperatureService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchMinTemperatureByLocation(location, datasetID, instance);
    });

    this.locationSnowfallSubscription = this.snowfallService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchSnowfallByLocation(location, datasetID, instance);
    });

    this.locationPrecipitationSubscription = this.precipitationService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchPrecipitationByLocation(location, datasetID, instance);
    });

    this.locationSurfaceWindSubscription = this.surfaceWindService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchSurfacewindByLocation(location, datasetID, instance);
    });

    this.locationPopulationDensitySubscription = this.populationDensityService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchPopulationDensityByLocation(location, datasetID, instance);
    });

    this.locationSeaLevelRiseSubscription = this.seaLevelRiseService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchSeaLevelRiseByLocation(location, datasetID, instance);
    });

    this.locationSeaLevelRiseWarmingSubscription = this.seaLevelRiseWarmingService.getLocationChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchSeaLevelRiseWarmingByLocation(location, datasetID, instance);
    });
  }

  subscribeAddressChartInstances(location: Point, datasetID: string) {
    this.addressProfileMaxTemperatureSubscription = this.maxTemperatureService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchMaxTemperatureByLocation(location, datasetID, instance);
    });

    this.addressProfileMeanTemperatureSubscription = this.meanTemperatureService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchMeanTemperatureByLocation(location, datasetID, instance);
    });

    this.addressProfileMinTemperatureSubscription = this.minTemperatureService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchMinTemperatureByLocation(location, datasetID, instance);
    });

    this.addressProfileSnowfallSubscription = this.snowfallService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchSnowfallByLocation(location, datasetID, instance);
    });

    this.addressProfilePrecipitationSubscription = this.precipitationService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchPrecipitationByLocation(location, datasetID, instance);
    });

    this.addressProfileSurfaceWindSubscription = this.surfaceWindService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchSurfacewindByLocation(location, datasetID, instance);
    });

    this.addressProfilePopulationDensitySubscription = this.populationDensityService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchPopulationDensityByLocation(location, datasetID, instance);
    });

    this.addressProfileSeaLevelRiseSubscription = this.seaLevelRiseService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchSeaLevelRiseByLocation(location, datasetID, instance);
    });

    this.addressProfileSeaLevelRiseWarmingSubscription = this.seaLevelRiseWarmingService.getAddressChartSubject().pipe(take(1)).subscribe(instance => {
      this.fetchSeaLevelRiseWarmingByLocation(location, datasetID, instance);
    });
  }

  fetchMaxTemperatureByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchMaxTemperatureByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.maxTemperature = climateChart;
      this.hideChartInstance(instance);
      this.maxTemperatureService.updateMaxTempChart(instance, climateChart);
    });
  }

  fetchMeanTemperatureByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchMeanTemperatureByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.meanTemperature = climateChart;
      this.hideChartInstance(instance);
      this.meanTemperatureService.updateMeanTempChart(instance, climateChart);
    });
  }

  fetchMinTemperatureByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchMinTemperatureByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.minTemperature = climateChart;
      this.hideChartInstance(instance);
      this.minTemperatureService.updateMinTempChart(instance, climateChart);
    });
  }

  fetchSnowfallByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchSnowfallByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.snowfallTemperature = climateChart;
      this.hideChartInstance(instance);
      this.snowfallService.updateSnowfallChart(instance, climateChart);
    });
  }

  fetchPrecipitationByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchPrecipitationByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.precipitation = climateChart;
      this.hideChartInstance(instance);
      this.precipitationService.updatePrecipitationChart(instance, climateChart);
    });
  }

  fetchSurfacewindByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchSurfacewindByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.surfaceWind = climateChart;
      this.hideChartInstance(instance);
      this.surfaceWindService.updateSurfaceWindChart(instance, climateChart);
    });
  }

  fetchPopulationDensityByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchPopulationDensityByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.populationDensity = climateChart;
      this.hideChartInstance(instance);
      this.populationDensityService.updatePopulationDensityChart(instance, climateChart);
    });
  }

  fetchSeaLevelRiseByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchSeaLevelRiseByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.seaLevelRise = climateChart;
      this.hideChartInstance(instance);
      this.seaLevelRiseService.updateSeaLevelRiseChart(instance, climateChart);
    });
  }

  fetchSeaLevelRiseWarmingByLocation(location: Point, datasetID: string, instance: any) {
    this.climateService.fetchSeaLevelRiseWarmingByLocation(location, datasetID).subscribe((climateChart: ClimateChart) => {
      this.downloadRequestPayload.seaLevelRiseWarming = climateChart;
      this.hideChartInstance(instance);
      this.seaLevelRiseWarmingService.updateSeaLevelRiseWarmingChart(instance, climateChart);
    });
  }

  hideChartInstance(instance) {
    if (instance !== undefined) {
      instance.hideLoading();
    }
  }

  unsubscribeAddressChartInstances() {
    this.addressProfileMaxTemperatureSubscription.unsubscribe();
    this.addressProfileMeanTemperatureSubscription.unsubscribe();
    this.addressProfileMinTemperatureSubscription.unsubscribe();
    this.addressProfileSnowfallSubscription.unsubscribe();
    this.addressProfilePrecipitationSubscription.unsubscribe();
    this.addressProfileSurfaceWindSubscription.unsubscribe();
    this.addressProfilePopulationDensitySubscription.unsubscribe();
    this.addressProfileSeaLevelRiseSubscription.unsubscribe();
    this.addressProfileSeaLevelRiseWarmingSubscription.unsubscribe();
  }

  unsubscribeLocationChartInstances() {
    this.locationMaxTemperatureSubscription.unsubscribe();
    this.locationMeanTemperatureSubscription.unsubscribe();
    this.locationMinTemperatureSubscription.unsubscribe();
    this.locationSnowfallSubscription.unsubscribe();
    this.locationPrecipitationSubscription.unsubscribe();
    this.locationSurfaceWindSubscription.unsubscribe();
    this.locationPopulationDensitySubscription.unsubscribe();
    this.locationSeaLevelRiseSubscription.unsubscribe();
    this.locationSeaLevelRiseWarmingSubscription.unsubscribe();
  }

  resetDownloadPayload() {
    this.downloadRequestPayload = {maxTemperature: null, meanTemperature: null, minTemperature: null, snowfallTemperature: null, precipitation: null, surfaceWind: null, populationDensity: null, seaLevelRise: null, seaLevelRiseWarming: null};
  }
}
