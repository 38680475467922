import { ChangeDetectorRef, Component, ComponentRef, ElementRef, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Constants, DatapointsViewMode } from "src/app/constants";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetService } from "src/app/data-access-layer/dataset/dataset.service";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { Subscription, forkJoin } from "rxjs";
import { TreeStructureUtils } from "src/app/core/utils/tree-structure-utils";
import { TreeStructure } from "src/app/model/menu/tree-structure";
import { DatapointsPageStateService } from "../datapoints/datapoints-page-state.service";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { AccountService } from "src/app/data-access-layer/account/account.service";
import { WorkspaceItemService } from "src/app/data-access-layer/workspace-item/workspace-item.service";
import { WorkspaceItemType } from "src/app/model/workspace/workspace-item-type";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { ReportType } from "src/app/model/analytics/report-type";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { LinkDatasetService } from "src/app/data-access-layer/dataset/link-dataset.service";
import { ReportRequest } from "src/app/model/datapoint/report/report-request";
import { AggregateGroupRequest } from "src/app/model/datapoint/report/aggregate-group-request";
import { DatapointAggregateFieldType } from "src/app/model/datapoint/report/datapoint-aggregate-field-type";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { Aggregate } from "src/app/core/analytics/aggregate";
import { GroupTreeNode } from "src/app/model/overlay/group/group-with-overlays-tree-node";
import { ReportDisplayType } from "src/app/model/analytics/report-display-type";
import { ChartDisplayType } from "src/app/model/analytics/chart-display-type";
import { DatasetFieldSpecificType } from "src/app/model/dataset/dataset-field-specific.type";
import {
    MatCheckboxChange,
    MatDialog,
    MatTreeNestedDataSource,
    Sort,
} from "@angular/material";
import { OverlaysConstants } from "src/app/external-overlays/externaloverlays.constants";
import { Grouping } from "src/app/model/dataset/rendering/datapoint-converter-options";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatasetFieldFloatingOption } from "src/app/model/dataset/dataset-field-floating-option";
import { Count } from "src/app/core/analytics/count";
import { Ranking } from "src/app/core/analytics/ranking";
import { DatapointsService } from "src/app/data-access-layer/datapoints/datapoints.service";
import { DatasetFieldScope } from "src/app/model/dataset/dataset-field-scope";
import { TessadataNriFields } from "src/app/core/tessadata/tessadata-nri-fields";
import { isNullOrUndefined } from "util";
import { WorkspaceItemDialogComponent } from "../workspace-item/projection/workspace-item-dialog.component";
import { UserStateService } from "src/app/auth/user-state-service";
import { WorkspaceItemAccess } from "src/app/model/workspace/workspace-item-access";
import { RandomUtils } from "src/app/core/utils/random-utils";
import { DownloadReportItem } from "src/app/model/download/item/download-report-item";
import { DownloadReportRequest } from "src/app/model/download/download-report-request";
import { DownloadDatapointRequest } from "src/app/model/download/download-datapoint-request";
import { AttachmentUtils } from "src/app/core/utils/attachment-utils";
import { DownloadService } from "src/app/data-access-layer/download/download.service";
import { NotifService } from "src/app/core/notification/notif.service";
import { DownloadFileType } from "src/app/model/download/download-file-type";
import { DialogModel } from "src/app/model/dialog/dialog-model";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { take } from "rxjs/operators";
import { DownloadItemReportType } from "src/app/model/download/item/download-item-report-type";
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { SidePanelComponent } from "src/app/core/side-panel/side-panel.component";
import { SidePanelService } from "src/app/shared/services/side-panel.service";
import { SidePanels } from "src/app/shared/services/side-panel.helper";
import jsPDF from "jspdf";
// import html2pdf from "html2pdf.js";


type AnalitycsComponentType = {
    dataset: Dataset;
    nriFields: any
    isDashboardCall: boolean
}
@Component({
    selector: "map-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    @ViewChild("workspaceItemDialog", { static: true }) workspaceIemDialog: WorkspaceItemDialogComponent;
    @ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;
    dashboardWorkspaceItems: WorkspaceItem[] = [];

    dataset: Dataset;
    activeDataset: Dataset;
    thematicLevels: Dataset[] = [];
    linkedDatasets: Dataset[];
    datasetFields: DatasetField[];
    _analytics: WorkspaceItem[];
    _formulas: WorkspaceItem[];
    private readonly subscriptions: Subscription = new Subscription();
    private readonly floatingFieldsDataSubscription: Subscription =
        new Subscription();
    tableColumnsExcludedOverlayGroups: number[];

    aggregateModel = new Aggregate(
        this.datapointsPageStateService,
        this.aggregateService
    );

    rankingModel = new Ranking(
        this.datapointsPageStateService,
        this.datapointService
    );

    filterTreeStrcuture = new TreeStructureUtils();
    aggregateDatas: any = [];
    countDataArray: any = [];
    analyticData: any = [];

    groups: any[] = [];

    dropdownItems: TreeStructure[] = [];
    selectedDropdownItems: TreeStructure[] = [];
    selectedThematicLevel: any;
    groupAlias: string = "Groups";
    groupAliasForm: FormGroup;
    sidebarDataValues: any[] = [];
    thematicMapGrouping: string = Grouping.SUM;
    thematicMapMetric: DatasetField;
    isSidebarDataLoaded: boolean = false;
    selectedGroupIds = [];

    countModel = new Count(
        this.datapointsPageStateService,
        this.aggregateService
    );

    isSuperAdmin: boolean;

    readonly COUNT_COLUMN_ID = "count";
    readonly AGGREGATE_COLUMN_ID = "aggregate";
    readonly PERCENTAGE_COLUMN_ID = "percentage";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";
    readonly AVERAGE_COLUMN_ID = "average";
    readonly REPORT_TYPE_MASTER = ReportType;

    readonly RANKING_COLUMN_ID = "ranking";

    tessadataFields: {
        nriFields: DatasetField[];
        externalFields: DatasetField[];
    };

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly datasetService: DatasetService,
        private readonly overlayService: OverlaysService,
        private readonly groupService: GroupService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly accountService: AccountService,
        private readonly workspaceItemService: WorkspaceItemService,
        private readonly linkDatasetService: LinkDatasetService,
        private readonly aggregateService: DatapointsAggregateService,
        private readonly changeDetector: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private readonly datapointAggregateService: DatapointsAggregateService,
        private readonly datapointService: DatapointsService,
        private readonly userStateService: UserStateService,
        private readonly downloadService: DownloadService,
        private readonly notifService: NotifService,
        public readonly dialog: MatDialog,
        private readonly sidePanelService: SidePanelService,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        this.groupAliasForm = this.formBuilder.group({
            groupAlias: [this.groupAlias, Validators.required],
        });
        this.isSuperAdmin = this.userStateService.isSuperadmin;
    }

    ngOnInit() {
        let datasetId = this.route.snapshot.paramMap.get("datasetId");
        let accountId = +this.route.snapshot.paramMap.get("accountId");
        this.accountService.findAccount(accountId).subscribe((account) => {
            this.datapointsPageStateService.activeAccount = account;
            this.activeDataset = account.datasets.filter(
                (dataset) => dataset.id === datasetId
            )[0];
            this.findDataset(datasetId);
        });
        // console.log(this.userStateService.isSuperadmin);
    }

    get ReportDisplayType() {
        return ReportDisplayType;
    }

    get ReportType() {
        return ReportType;
    }

    get ChartDisplayType() {
        return ChartDisplayType;
    }

    public get groupsIds() {
        const groupIds = [];
        this.groups.forEach((rootGroupNode) => {
            this.recursiveOperation(rootGroupNode, (groupNode) => {
                groupIds.push(groupNode.value.id);
            });
        });
        return groupIds;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get datasetScope() {
        return DatasetFieldScope;
    }

    setDisplayType(counter, displayType: string) {
        this.analyticData[counter].displayType = displayType;
    }

    findDataset(datasetID: string) {
        this.datasetService.getDataset(datasetID).subscribe((response) => {
            this.dataset = response;
            this.fetchDashboardWorkspaceItems();
            this.datapointsPageStateService.setActiveDataset(
                this.activeDataset
            );
            this.datapointsPageStateService.setFilterAccountDatasets([this.activeDataset]);
            this.subscriptions.add(
                forkJoin(
                    this.overlayService.getAllAccountOverlays(
                        this.dataset.accountID
                    ),
                    this.groupService.getGroupsAsTree(
                        this.dataset.id,
                        this.dataset.accountID
                    ),
                    this.workspaceItemService.getAllWorkspaceItemsForUser(
                        this.dataset.id,
                        WorkspaceItemType.DASHBOARD
                    ),
                    this.workspaceItemService.getAllWorkspaceItemsForUser(
                        this.dataset.id,
                        WorkspaceItemType.FORMULA
                    ),
                    this.linkDatasetService.getLinkedAccountApplications(
                        this.dataset.id
                    ),
                    this.overlayService.getOverlayGroupsByTag(
                        OverlaysConstants.HIDE_GROUP_FROM_TABLE_COLUMNS_TAG
                    )
                ).subscribe(
                    ([
                        overlays,
                        groups,
                        analytics,
                        formulas,
                        linkedDatasets,
                        overlayGroups,
                    ]) => {
                        this._analytics = analytics;
                        this._formulas = formulas;
                        this.tableColumnsExcludedOverlayGroups =
                            overlayGroups.map((group) => group.id);
                        this.groups = groups;
                        this.linkedDatasets = linkedDatasets;
                        this.prepareThematicLevels(overlays);
                        this.selectedGroupIds = this.groupsIds;
                        this.prepareAnalytics();
                        this.prepareOverlays(overlays);
                        this.dropdownItems = this.convertToDropdownItems(
                            this.groups
                        );
                        this.selectedDropdownItems = this.dropdownItems;
                        this.sidebarDataValues = [];
                        this.loadReportData();
                    }
                )
            );
        });
    }

    prepareOverlays(overlays: Dataset[]) {
        this.thematicLevels = [];
        overlays.forEach((overlay) => {
            if (
                overlay.thematicMapSettings &&
                overlay.thematicMapSettings.isThematicMapDataset
            ) {
                let parentId = overlay.thematicMapSettings.parentDatasetId;
                this.thematicLevels.push(overlay);
            }
        });
        this.sortThematicLevels();
    }

    sortThematicLevels(): void {
        if (this.thematicLevels && this.thematicLevels.length) {
            this.thematicLevels = [...this.thematicLevels].sort((a, b) =>
                a.name.localeCompare(b.name)
            );
            this.defaultSelectThematicLevel();
        }
    }

    defaultSelectThematicLevel() {
        if (this.thematicLevels && this.thematicLevels.length) {
            this.selectedThematicLevel = this.thematicLevels[0];
        }
    }

    onLevelChange(levelID: string): void {
        this.selectedThematicLevel = this.thematicLevels.find(
            (level) => level.id === levelID
        );
        this.sidebarDataValues = [];
        this.loadReportData();
    }

    loadReportData() {
        this.isSidebarDataLoaded = false;
        const datasetId = this.route.snapshot.paramMap.get("datasetId");

        let floatingFieldsOfDatasets = [];
        let floatingFieldsIdsOfDatasets = [];
        if (this.dataset && this.dataset.fields && this.dataset.fields.length) {
            floatingFieldsOfDatasets = this.dataset.fields.filter(
                (item) => item.isFloating === true
            );
            floatingFieldsIdsOfDatasets = floatingFieldsOfDatasets.map(
                (item) => item.id
            );
        }
        this.thematicMapMetric = floatingFieldsOfDatasets[0];

        const filter = {
            datasetID: datasetId,
            groups: this.getSelectedGroupIds(),
            fields: [],
            links: [],
        };

        const projection = {
            datasetID: datasetId,
            fields: floatingFieldsIdsOfDatasets,
            links: [
                {
                    datasetID:
                        this.selectedThematicLevel &&
                        this.selectedThematicLevel.id,
                    fields: [
                        this.selectedThematicLevel &&
                            this.selectedThematicLevel.thematicMapSettings
                                .mainThematicFieldId,
                    ],
                },
            ],
        };

        this.datapointAggregateService
            .getDatapointsCount(datasetId, filter, projection)
            .subscribe((countResult) => {
                if (countResult && countResult.count) {
                    this.sidebarDataValues.push({
                        dataLabel: "Count",
                        dataValue: countResult.count,
                    });
                }

                this.addFloatingFieldValuesForSidebar(
                    floatingFieldsOfDatasets,
                    filter,
                    projection
                );
            });
    }

    addFloatingFieldValuesForSidebar(
        floatingFieldsOfDatasets,
        filter,
        projection
    ) {
        let loadedCount = 0;
        if (floatingFieldsOfDatasets && floatingFieldsOfDatasets.length) {
            floatingFieldsOfDatasets.forEach((field) => {
                let groups: AggregateGroupRequest[] = [];
                groups.push({
                    datasetID:
                        this.selectedThematicLevel &&
                        this.selectedThematicLevel.id,
                    fieldID:
                        this.selectedThematicLevel.thematicMapSettings
                            .mainThematicFieldId,
                });

                let formulaId = `${this.dataset.id}.${field.id}`;
                let reportRequest: ReportRequest = {
                    datasetID: this.dataset.id,
                    aggregateFieldCodes: [
                        {
                            aggregateFieldCode: `VAR_${formulaId}`,
                            id: formulaId,
                        },
                    ],
                    groups: groups,
                    aggregateFieldType: DatapointAggregateFieldType.FIELD,
                };

                const subscription = this.datapointAggregateService
                    .getDatapointsReport(
                        this.dataset.id,
                        filter,
                        reportRequest,
                        projection
                    )
                    .subscribe((result) => {
                        let totalValue = 0;
                        result.groupResults.forEach((groupResult) => {
                            if (
                                field.floatingOption ===
                                DatasetFieldFloatingOption.SUM
                            ) {
                                totalValue += groupResult.values[0].result;
                            } else {
                                totalValue +=
                                    groupResult.values[0].result /
                                    groupResult.values[0].count;
                            }
                        });
                        totalValue = Math.round(totalValue * 100) / 100;
                        this.sidebarDataValues.push({
                            dataLabel: `${field.name}`,
                            dataValue: totalValue,
                        });

                        loadedCount++;

                        if (loadedCount === floatingFieldsOfDatasets.length) {
                            this.isSidebarDataLoaded = true;
                        }
                    });

                this.floatingFieldsDataSubscription.add(subscription); // Add the subscription to the floatingFieldsDataSubscription object
            });
        } else {
            this.isSidebarDataLoaded = true;
        }
    }

    convertToDropdownItems(
        data: any[],
        parent?: TreeStructure
    ): TreeStructure[] {
        return data.map((item) => {
            const dropdownItem: TreeStructure = {
                id: item.value.id,
                params: {
                    accountID: item.value.accountID,
                    datasetID: item.value.datasetID,
                    type: item.value.type,
                    parent: parent,
                },
                name: item.value.name,
                selected: true,
                expanded: true,
            };

            if (item.children && item.children.length > 0) {
                dropdownItem.children = this.convertToDropdownItems(
                    item.children,
                    dropdownItem
                );
            }

            return dropdownItem;
        });
    }

    recursiveOperation(
        groupNode: GroupTreeNode,
        operation: (node: GroupTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) =>
            this.recursiveOperation(child, operation)
        );
        // this.changeEvent();
    }

    sortFields(fields: DatasetField[]) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            }
        });
        return fields;
    }

    generateReport(report: any) {
        let abc = this.aggregateModel.generateReport(
            report,
            this.dataset,
            this.selectedGroupIds
        );
    }

    onAggregateFieldSelected(
        event: MatCheckboxChange,
        field: DatasetField,
        counter: number
    ) {
        let aggregateData = this.analyticData[counter];
        if (event.checked) {
            aggregateData.selectedAggregateField = field;
            aggregateData.aggregateDatasetFields.forEach((f) => {
                f.selected = f.id === field.id;
            });
            aggregateData.selectedFormula = undefined;
            aggregateData.formulas.forEach(
                (formula) => (formula.selected = false)
            );
        } else {
            aggregateData.selectedAggregateField = undefined;
        }
        this.changeDetector.detectChanges();
    }

    onFormulaSelected(
        $event: MatCheckboxChange,
        selectedFormula: WorkspaceItem,
        counter: number
    ) {
        let aggregateData = this.analyticData[counter];
        if ($event.checked) {
            aggregateData.formulas.forEach((formula) => {
                formula.selected = formula.id === selectedFormula.id;
            });
            aggregateData.selectedFormula = selectedFormula;
            aggregateData.selectedAggregateField = undefined;
            this.datasetFields.forEach((f) => (f.selected = false));
        } else {
            aggregateData.selectedFormula = undefined;
            selectedFormula.selected = false;
            aggregateData.selectedAggregateField = undefined;
        }
        this.changeDetector.detectChanges();
    }

    onFieldsMenuClick(
        $event: MatCheckboxChange,
        field: DatasetField,
        counter: number
    ) {
        let aggregateData = this.analyticData[counter];
        let datasetFields =
            aggregateData.selectedBreakdownFieldsByDataset.get(
                this.activeDataset.id
            ) || [];
        if ($event.checked) {
            datasetFields.push(field);
            field.selected = true;
            aggregateData.selectedBreakdownFieldsCount++;
        } else {
            datasetFields = datasetFields.filter((f) => f.id !== field.id);
            field.selected = false;
            aggregateData.selectedBreakdownFieldsCount--;
        }

        if (datasetFields.length === 0) {
            aggregateData.selectedBreakdownFieldsByDataset.delete(
                this.activeDataset.id
            );
        } else {
            aggregateData.selectedBreakdownFieldsByDataset.set(
                this.activeDataset.id,
                datasetFields
            );
        }
    }

    onChartDisplayTypeChanged(report: any) {
        this.aggregateModel.populateChartData(report);
    }

    prepareCommanMenuOptions() {
        let tessadataFields: {
            nriFields: DatasetField[];
            externalFields: DatasetField[];
        };
        let tessadataGroupedFields: any[];
        tessadataFields = JSON.parse(
            JSON.stringify(
                this.datapointsPageStateService.getActiveTessadataFields()
            )
        );
        tessadataFields.externalFields.forEach(
            (field) => (field.selected = false)
        );
        tessadataFields.nriFields = this.sortFields(tessadataFields.nriFields);
        if (
            tessadataFields.externalFields &&
            tessadataFields.externalFields.length > 0
        ) {
            let externalDataset = this.sortFields(
                tessadataFields.externalFields
            );
            tessadataGroupedFields = externalDataset.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            for (const key in tessadataGroupedFields) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        tessadataGroupedFields,
                        key
                    )
                ) {
                    tessadataGroupedFields[key] = this.sortFields(
                        tessadataGroupedFields[key]
                    );
                }
            }
        }
        tessadataFields.nriFields = this.groupByNriFields(
            tessadataFields.nriFields
        );
        this.tessadataFields = tessadataFields;
        return {
            tessadataFields: tessadataFields,
            tessadataGroupedFields: tessadataGroupedFields,
        };
    }

    prepareAnalytics() {
        let reportElementIndex = 0;
        this.analyticData = [];
        const datasetFields = this.dataset.fields;
        this.datasetFields = this.sortFields(
            JSON.parse(JSON.stringify(datasetFields))
        );
        let menuOptionData = this.prepareCommanMenuOptions();

        this._analytics.forEach((analytic) => {
            const analyticParseData = JSON.parse(analytic.data);
            if (analyticParseData.length) {
                analyticParseData.forEach((data) => {
                    switch (data.type) {
                        case ReportType.AGGREGATE: {
                            const aggregateData: any =
                                this.aggregateModel.process(
                                    this.dataset,
                                    reportElementIndex,
                                    analytic,
                                    data,
                                    JSON.parse(JSON.stringify(datasetFields)),
                                    JSON.parse(JSON.stringify(this._formulas)),
                                    this.selectedGroupIds,
                                    menuOptionData
                                );
                            if (aggregateData !== undefined) {
                                aggregateData.id = analytic.id;
                                aggregateData.reportType = ReportType.AGGREGATE;
                                this.analyticData.push(aggregateData);
                            }
                            break;
                        }
                        case ReportType.COUNT: {
                            const countData = this.countModel.process(
                                this.dataset,
                                reportElementIndex,
                                analytic,
                                data,
                                this.selectedGroupIds,
                                menuOptionData
                            );
                            if (countData) {
                                countData.reportType = ReportType.COUNT;
                                this.analyticData.push(countData);
                            }
                            break;
                        }
                        case ReportType.RANKING: {
                            const rankingData = this.rankingModel.process(
                                this.dataset,
                                reportElementIndex,
                                analytic,
                                data,
                                JSON.parse(JSON.stringify(datasetFields)),
                                JSON.parse(JSON.stringify(this._formulas)),
                                menuOptionData,
                                this.selectedGroupIds
                            );
                            if (rankingData !== undefined) {
                                rankingData.id = analytic.id;
                                this.analyticData.push(rankingData);
                            }
                            reportElementIndex++;
                            break;
                        }
                    }

                    reportElementIndex++;
                });
            }
        });
        // console.log(this.analyticData);
    }

    prepareTesadata(tessadataGroups): TreeStructure[] {
        let sortData = [];
        for (const key in tessadataGroups) {
            if (Object.prototype.hasOwnProperty.call(tessadataGroups, key)) {
                const groups = tessadataGroups[key];
                sortData.push({
                    id: groups.value.id,
                    selected: true,
                    name: groups.value.name,
                    children: this.getGroups(groups.children),
                });
            }
        }
        sortData = sortData.sort((a, b) => (a.name < b.name ? -1 : 1));
        return sortData;
    }

    getGroups(groups): TreeStructure[] {
        let prepareGroups = [];
        groups.forEach((group) => {
            prepareGroups.push({
                id: group.value.id,
                name: group.value.name,
                selected: group.selected,
                params: {
                    field: group,
                    showCheckBox: true,
                    callType: "tesadataGroup",
                },
            });
        });
        return prepareGroups;
    }

    prepareThematicLevels(overlays: Dataset[]) {
        overlays.forEach((overlay) => {
            if (
                overlay.thematicMapSettings &&
                overlay.thematicMapSettings.isThematicMapDataset
            ) {
                let parentId = overlay.thematicMapSettings.parentDatasetId;
                this.thematicLevels.push(overlay);
            }
        });
    }

    getThematicLevelNumber(levelName: string) {
        const levelNameArray: string[] = levelName.split(" ");
        return levelNameArray[levelNameArray.length - 1];
    }

    switchView(viewMode: string): void {
        if (
            viewMode.toUpperCase() === DatapointsViewMode.MAP ||
            viewMode.toUpperCase() === DatapointsViewMode.TABLE
        ) {
            this.router.navigate([
                `private/account/${this.dataset.accountID}/dataset/${this.dataset.id}/datapoints/${viewMode}`,
            ]);
        } else {
            return;
        }
    }

    onSelectedItemsChanged(items: TreeStructure[]): void {
        this.sidebarDataValues = [];
        this.selectedDropdownItems = items;
        this.loadReportData();
        this.selectedGroupIds = this.selectedDropdownItems.map((item) => {
            return item.id;
        });
        this.prepareAnalytics();
    }

    removeAnalyticData(counter: number) {
        this.analyticData.splice(counter, 1);
    }

    isCountFieldSelected(fieldId: string, countReportData: any): boolean {
        if (
            countReportData.selectedFieldsByDataset
                .get(this.activeDataset.id)
                .find((field) => field.id === fieldId)
        ) {
            return true;
        }
        return false;
    }

    generateCountReport(data: any) {
        const params: any = {
            report: data,
            dataset: this.dataset,
            selectedFieldsByDataset: data.selectedFieldsByDataset,
            groupsIds: this.selectedGroupIds,
        };
        this.countModel.generateReportData(params);
    }

    onCountFieldsMenuClick(
        isSelected: boolean,
        field: any,
        reportElementIndex: number
    ) {
        const selectedFieldsArray = this.analyticData[
            reportElementIndex
        ].selectedFieldsByDataset.get(this.activeDataset.id);
        if (isSelected) {
            selectedFieldsArray.push(field);
            this.analyticData[reportElementIndex].selectedFieldsByDatasetCount++;
        } else {
            const filteredArray = selectedFieldsArray.filter(
                (obj) => obj.id !== field.id
            );
            this.analyticData[reportElementIndex].selectedFieldsByDatasetCount--;
            this.analyticData[reportElementIndex].selectedFieldsByDataset.set(
                this.activeDataset.id,
                filteredArray
            );
        }
    }

    onRankingFieldSelection(
        $event: MatCheckboxChange,
        field: DatasetField,
        counter: number
    ) {
        let rankingData = this.analyticData[counter];

        if ($event.checked) {
            rankingData.selectedRankingField = field;
            let foundIndex = rankingData.datasetFields.findIndex(
                (x) => x.id === field.id
            );
            rankingData.datasetFields[foundIndex].selected = true;
            let findExistingField = rankingData.selectedFields.find(
                (f) => f.id === rankingData.selectedRankingField.id
            );
            if (findExistingField) {
                let index = rankingData.datasetFields.findIndex(
                    (x) => x.id === field.id
                );
                rankingData.selectedFields.splice(index, 1);
            }
        } else {
            rankingData.selectedRankingField = undefined;
            let foundIndex = rankingData.datasetFields.findIndex(
                (x) => x.id === field.id
            );
            rankingData.datasetFields[foundIndex].selected = false;
        }
    }

    generateRankingReport(report: any) {
        let abc = this.rankingModel.generateReport(report, this.dataset, this.selectedGroupIds);
    }

    isNotAllDatasetSelected(
        fields: DatasetField[],
        isLocations?: boolean
    ): boolean {
        if (isLocations) {
            return (
                fields.filter(
                    (field) =>
                        !field.selected &&
                        field.scope === this.datasetScope.INTERNAL &&
                        field.id !== "created_on" &&
                        field.id !== "updated_on"
                ).length > 0
            );
        } else {
            return fields.filter((field) => !field.selected).length > 0;
        }
    }

    onRankingFieldsMenuClick(
        fieldSelected: boolean,
        field: DatasetField,
        report: any
    ) {
        if (fieldSelected) {
            report.selectedFields.push(field);
        } else {
            report.selectedFields.splice(
                report.selectedFields.indexOf(field),
                1
            );
        }
    }

    groupByNriFields(nriFields) {
        const groupBy = (array, key) => {
            // Return the end result
            return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                let symbol = null;
                if (currentValue[key].trim().split("-").length > 1) {
                    symbol = "-";
                } else if (currentValue[key].trim().split("–").length > 1) {
                    symbol = "–";
                }
                if (isNullOrUndefined(symbol)) {
                    (result[currentValue[key].trim()] =
                        result[currentValue[key].trim()] || []).push({
                        name: currentValue[key].trim(),
                        child: currentValue,
                    });
                } else {
                    (result[currentValue[key].trim().split(symbol)[0].trim()] =
                        result[
                            currentValue[key].trim().split(symbol)[0].trim()
                        ] || []).push({
                        name: currentValue[key].trim().split(symbol)[1],
                        child: currentValue,
                    });
                }
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
            }, {}); // empty object is the initial value for result object
        };
        const groupByObjectResponse = groupBy(nriFields, "name");
        let tempArray = [];
        let nriSummaryArray = [
            { name: "NRI Summary", isParentSelected: true, child: [] },
        ];
        Object.keys(groupByObjectResponse).map(function (key) {
            let nameSplitValue = key.split(" ");
            groupByObjectResponse[key][0].child.isProjected = true;
            if (
                [
                    TessadataNriFields.SUMMARY_SOVI_RATING,
                    TessadataNriFields.SUMMARY_RESL_RATING,
                ].includes(groupByObjectResponse[key][0].child.id)
            ) {
                nriSummaryArray[0].child.push({
                    name:
                        nameSplitValue[1] + " " + nameSplitValue[2] + " Rating",
                    child: groupByObjectResponse[key][0].child,
                });
            } else if (
                groupByObjectResponse[key][0].child.id ==
                TessadataNriFields.SUMMARY_EAL_RATING
            ) {
                nriSummaryArray[0].child.push({
                    name: nameSplitValue[1] + " Rating",
                    child: groupByObjectResponse[key][0].child,
                });
            } else if (
                groupByObjectResponse[key][0].child.id ==
                TessadataNriFields.SUMMARY_RISK_RATING
            ) {
                nriSummaryArray[0].child.push({
                    name: "Overall " + nameSplitValue[1],
                    child: groupByObjectResponse[key][0].child,
                });
            } else {
                tempArray.push({
                    name: key,
                    isParentSelected: true,
                    child: groupByObjectResponse[key],
                });
                return tempArray;
            }
        });

        if (nriSummaryArray[0].child.length > 0) {
            tempArray = [...nriSummaryArray, ...tempArray];
        }
        return tempArray;
    }

    // Tree code - start
    hasChild = (_: number, node: TreeStructure) =>
        !!node.children && node.children.length > 0;
    setParent(data, parent) {
        data.parent = parent;
        if (data.children) {
            data.children.forEach((x) => {
                this.setParent(x, data);
            });
        }
    }

    checkAllParents(node, treeControl) {
        if (node.parent) {
            const descendants = treeControl.getDescendants(node.parent);
            node.parent.selected = descendants.every((child) => child.selected);
            node.parent.indeterminate = descendants.some(
                (child) => child.selected
            );
            this.checkAllParents(node.parent, treeControl);
        }
    }
    todoItemSelectionToggle(checked, node, treeControl) {
        node.selected = checked;
        if (node.children) {
            node.children.forEach((x) => {
                this.todoItemSelectionToggle(checked, x, treeControl);
            });
        }
        this.checkAllParents(node, treeControl);
    }
    // Tree code - end

    sortData(sort: Sort, counter: number, reportType: ReportType) {
        if (reportType === ReportType.AGGREGATE) {
            this.aggregateModel.sortData(sort, this.analyticData[counter]);
        } else if (reportType === ReportType.COUNT) {
            this.countModel.sortData(sort, this.analyticData[counter]);
        } else if (reportType === ReportType.RANKING) {
            this.rankingModel.sortData(sort, this.analyticData[counter]);
        }
    }

    fetchDashboardWorkspaceItems() {
        this.workspaceItemService
            .getAllWorkspaceItemsForUser(
                this.dataset.id,
                WorkspaceItemType.DASHBOARD
            )
            .subscribe((items) => {
                this.dashboardWorkspaceItems = items;
                this.dashboardWorkspaceItems.forEach((item) => {
                    if (
                        this.userStateService.isSuperadmin ||
                        this.userStateService.isAccountAdmin(
                            this.dataset.accountID
                        )
                    ) {
                        item.isDeletable = true;
                    } else {
                        if (item.access === WorkspaceItemAccess.MEMBER) {
                            item.isDeletable = true;
                        }
                    }
                });
            });
    }

    saveWorkspaceItemToDashboard(i: number) {
        const dashboardReportItem = [];
        dashboardReportItem.push(
            this.prepareSpecificWorkspaceItemInfo(this.analyticData[i])
        );

        this.workspaceIemDialog.open(
            dashboardReportItem,
            WorkspaceItemType.DASHBOARD,
            true,
            true
        );
    }

    saveAsWorkspaceItemToDashboard(i: number) {
        const dashboardReportItem = [];
        dashboardReportItem.push(
            this.prepareSpecificWorkspaceItemInfo(this.analyticData[i])
        );

        this.workspaceIemDialog.open(
            dashboardReportItem,
            WorkspaceItemType.DASHBOARD,
            false,
            true
        );
    }

    private prepareSpecificWorkspaceItemInfo(report) {
        let formattedReportData: any = {};
        // console.log(report);
        switch (report.reportType) {
            case ReportType.AGGREGATE:
                let aggregateReportComponent = report;
                let jsonObject: any = {};
                jsonObject[this.activeDataset.id] =
                    report.selectedBreakdownFieldsByDataset.get(
                        this.activeDataset.id
                    );

                formattedReportData = {
                    id: RandomUtils.generateUUID(),
                    name: aggregateReportComponent.reportName || "",
                    selectedAggregateField:
                        aggregateReportComponent.selectedFields,
                    selectedBreakdownFieldsByDataset: jsonObject,
                    type: ReportType.AGGREGATE,
                };

                if (aggregateReportComponent.selectedFormula) {
                    formattedReportData.selectedFormula =
                        aggregateReportComponent.selectedFormula;
                } else {
                    formattedReportData.selectedAggregateField =
                        aggregateReportComponent.selectedAggregateField;
                }

                break;
            case ReportType.RANKING:
                let rankingReportComponent = report;
                formattedReportData = {
                    id: RandomUtils.generateUUID(),
                    name: rankingReportComponent.reportName || "",
                    selectedFields: rankingReportComponent.selectedFields,
                    noOfItems: rankingReportComponent.noOfItems,
                    type: ReportType.RANKING,
                };

                if (rankingReportComponent.selectedFormula) {
                    formattedReportData.selectedFormula =
                        rankingReportComponent.selectedFormula;
                } else {
                    formattedReportData.selectedRankingField =
                        rankingReportComponent.selectedRankingField;
                }
                break;
            case ReportType.COUNT:
                let countJsonObject: any = {};
                countJsonObject[this.activeDataset.id] =
                    report.selectedFieldsByDataset.get(this.activeDataset.id);

                formattedReportData = {
                    id: RandomUtils.generateUUID(),
                    name: report.reportName || "",
                    selectedFieldsByDataset: countJsonObject,
                    type: ReportType.COUNT,
                };
                break;
        }

        return formattedReportData;
    }

    downloadReport(reportElementIndex: number) {
        const component = this.analyticData[reportElementIndex];
        let downloadItems: DownloadReportItem[] = [];
        let reportHasTwoDimensionPieChart = false;

        if (component) {
            if (
                component.displayType === ReportDisplayType.PIE_CHART &&
                component.isTwoDimensionReport
            ) {
                reportHasTwoDimensionPieChart = true;
            }

            let reportDownloadRequest: DownloadReportItem =
                this.getReportDownloadRequest(component);

            if (reportDownloadRequest) {
                downloadItems.push(reportDownloadRequest);
            }
        }

        let reportRequest: DownloadReportRequest = {
            items: downloadItems,
        };

        let downloadRequest: DownloadDatapointRequest = {
            reportRequest: reportRequest,
            filter: this.getActiveFilter(),
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: DownloadFileType.XLSX,
        };

        if (reportHasTwoDimensionPieChart) {
            const dialogRef = this.dialog.open(DialogComponent, {
                data: new DialogModel(
                    "Confirm Download",
                    `Your pie chart has multiple criteria, but the spreadsheet download will only display one. We recommend downloading the bar charts instead.`,
                    "Cancel",
                    "Download"
                ),
            });
            dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((dialogResult) => {
                    if (dialogResult) {
                        this.downloadReportFile(downloadRequest);
                    }
                });
        } else {
            this.downloadReportFile(downloadRequest);
        }
    }

    private downloadReportFile(downloadRequest: DownloadDatapointRequest) {
        this.downloadService
            .downloadDatapoints(
                this.dataset.id,
                downloadRequest,
                "Analytics.xlsx"
            )
            .subscribe(
                (response) => {
                    AttachmentUtils.downloadFileWithName(
                        response,
                        "Analytics.xlsx"
                    );
                },
                (error) =>
                    this.notifService.error(
                        "Something went wrong during download"
                    )
            );
    }

    getActiveFilter() {
        return {
            datasetID: this.activeDataset.id,
            fields: [],
            groups: this.getSelectedGroupIds(),
            links: [],
        };
    }

    getSelectedGroupIds() {
        const selectedGroupsIds = this.selectedDropdownItems.map((item) => {
            return item.id;
        });
        return selectedGroupsIds || [];
    }

    getReportDownloadRequest(report): DownloadReportItem {
        if (report.displayType === ReportDisplayType.TABLE) {
            return report.tableDownloadRequest;
        } else if (
            report.displayType === ReportDisplayType.BAR_CHART ||
            report.displayType === ReportDisplayType.PIE_CHART
        ) {
            if (report.chartDownloadRequest) {
                if (report.displayType === ReportDisplayType.BAR_CHART) {
                    report.chartDownloadRequest.type =
                        DownloadItemReportType.AGGREGATE_BAR_CHART;
                } else if (report.displayType === ReportDisplayType.PIE_CHART) {
                    report.chartDownloadRequest.type =
                        DownloadItemReportType.AGGREGATE_PIE_CHART;
                }
            }
            return report.chartDownloadRequest;
        }
    }

    deleteWorkspaceItem(counter) {
        const item: WorkspaceItem = this.analyticData[counter];
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete "${item.reportName}"?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.workspaceItemService
                        .deleteWorkspaceItem(item.id)
                        .subscribe(
                            (success) => {
                                this.rightPanelCall(true);
                            },
                            (error) =>
                                this.notifService.error(error.error.message)
                        );
                }
            });
    }

    rightPanelCall(isShowDeleteNotification: boolean = false) {
        this.subscriptions.add(
            forkJoin(
                this.workspaceItemService.getAllWorkspaceItemsForUser(
                    this.dataset.id,
                    WorkspaceItemType.DASHBOARD
                ),
                this.workspaceItemService.getAllWorkspaceItemsForUser(
                    this.dataset.id,
                    WorkspaceItemType.FORMULA
                )
            ).subscribe(([analytics, formulas]) => {
                this._analytics = analytics;
                this._formulas = formulas;
                this.prepareAnalytics();
                if (isShowDeleteNotification) {
                    this.notifService.success("Item was deleted successfully");
                }
            })
        );
    }

    indicatorsDrop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.sidebarDataValues, event.previousIndex, event.currentIndex);
    }

    reportDrop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.analyticData, event.previousIndex, event.currentIndex);
    }

    openAnalyticsPanel() {
        this.analyticsPanel = undefined;
        this.showAnalyticsPanel();
        const { componentRef } = this.analyticsPanel.instance;
        if (componentRef) {
            componentRef.instance.removeAllReports();
            componentRef.instance.initialize();
        }
    }

    analyticsPanel: ComponentRef<SidePanelComponent>;
    
    showAnalyticsPanel() {
        if (this.analyticsPanel) {
            this.analyticsPanel.instance.showPanel();
        } else {
            this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
            this.analyticsPanel = this.sidePanelService.open<AnalitycsComponentType>(SidePanels.ANALYTICS,
                {
                    width: 600,
                    id: "analytics",
                    panelTitle: "Analytics",
                    resizeable: true,
                    panelIcon: "fa-analytics"
                },
                {
                    dataset: this.dataset,
                    nriFields: this.tessadataFields.nriFields,
                    isDashboardCall: true
                });
                this.workspaceItemService.isDashboardCallClose$.subscribe(response => {
                    if (response) {
                        this.rightPanelCall();
                        this.prepareAnalytics();
                        this.analyticsPanel.instance.closePanel();
                    }
                });
        }
    }

    ngOnDestroy(): void {
        if (this.analyticsPanel) {
            this.analyticsPanel.instance.closePanel();
            this.analyticsPanel = null;
        }
    }

    downloadPanel() {
//         const pages = document.querySelector('.dashboard-main-content') as HTMLElement;
//         let pdfOptions = {orientation: 'p', unit: 'px', format: [595, 842],  pagesplit: true};

//         var element = document.getElementById('pdfTable');
// var opt = {
//   margin:       0.1,
//   filename:     'myfile.pdf',
//   image:        { type: 'jpeg', quality: 0.20 },
//   html2canvas:  { scale: 2 },
//   jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
// };
//  console.log(element);
// // New Promise-based usage:
// html2pdf().from(element).set(opt).save();

    //     let doc = new jsPDF(pdfOptions);
    // doc.addHTML(this.pdfTable.nativeElement, function() {
    //    doc.save("obrz.pdf");
    // });
    
    //     const doc = new jsPDF();
   
    // const pdfTable = this.pdfTable.nativeElement;
   
    // var html = htmlToPdfmake(pdfTable.innerHTML);
     
    // const documentDefinition = { content: html };
    // pdfMake.createPdf(documentDefinition).open();
        // const doc = new jsPDF(pdfOptions);
    //     var printDoc = new jsPDF();
    // printDoc.fromHTML(pages);
    // printDoc.autoPrint();
    // printDoc.output("dataurlnewwindow"); 
        // globalThis.html2canvas = html2canvas;
        // console.log(pages);
        //   doc.html(pages, {
        //     callback: (doc: jsPDF) => {
        //       doc.deletePage(doc.getNumberOfPages());
        //       doc.save('pdf-export');
        //     }
        //   });

        // const doc = new jsPDF();
        // const specialElementHandlers = {
        //   '#editor': function (element, renderer) {
        //     return true;
        //   }
        // };
        // // const content = this.htmlContent.nativeElement;
        // doc.fromHTML(pages, 15, 15, {
        //   'width': 190,
        //   'elementHandlers': specialElementHandlers
        // });
        // doc.save('document.pdf');
    }
}
