import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {getBreadcrumbs, getLinks} from '../constants';

@Component({
  selector: 'map-account-header-component',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss']
})
export class AccountHeaderComponent implements OnInit, OnChanges {
  constructor() { }
  // @Input () componentName: string;
  @Input () showLinks = true;
    headerLinks;
    breadcrumbs;
  ngOnInit() {
  }
    ngOnChanges() {
      // if (this.componentName) {
      //     this.headerLinks = getLinks(this.componentName);
      //     this.breadcrumbs = getBreadcrumbs(this.componentName);
      // }
      // console.log(this.componentName);
    }
}
