import {RenderingOptions} from '../../model/dataset/rendering/rendering-options';
import {IntervalOption} from '../../model/dataset/rendering/interval-option';
import {VisualizationType} from '../../model/dataset/rendering/visualization-options';
import {ColorScaleType} from '../../model/dataset/rendering/color-scale-type';
import {DatapointConverterType} from '../../model/dataset/rendering/datapoint-converter-options';
import {GradientDetails} from '../../model/legend/gradient-details';
import {ColorUtils} from './color-utils';
import { isNumber, format } from 'util';

export class LegendUtils {
    /**
     * FIXED colorization
     * we use the backend approach for colorization where the datapoints values are transformed into units between 0 and 1
     * each datapoint value will be colorized using the formula color = colors[roundUp(unit * colors.length -1)]
     * so for each color we find the interval min and max using the above formula by finding the intervals in units and then transforming them into values
     * the formula to transform a number in unit is: unit = (n-min) / (max-min)
     * the formula to transform a string in unit is: unit = i / (values.length - 1) where values is the set of all field's values from all datapoints and  i is the position of the string in the values array
     * e.g for 4 colors and min=10 and max=300 we have
     * color1 =>   0 <= unit * 3 < 0.5   => c1 = [0, 0.16)    => c1 = [0, 56.4)
     * color2 => 0.5 <= unit * 3 < 1.5   => c2 = [0.16, 0.5)  => c2 = [56.4, 155)
     * color3 => 1.5 <= unit * 3 < 2.5   => c3 = [0.5, 0.83)  => c3 = [155, 250.7)
     * color4 => 3.5 <= unit * 3 <= 3    => c4 = [0.83, 1]    => c2 = [250.7, 300]
     */
    public static createIntervalsForLegend(renderingOptions: RenderingOptions, isGeoserver: boolean): IntervalOption[] {
        let intervals: IntervalOption[] = [];

        if (renderingOptions.visualizationOptions.type === VisualizationType.DEFAULT) {

            switch (renderingOptions.datasetStylingOptions.type) {
                case ColorScaleType.FIXED:
                    switch (renderingOptions.converterOptions.type) {
                        case DatapointConverterType.NUMBER_FIELD: {
                            this.createNumberIntervalsForFixedColorScale(renderingOptions, intervals);
                            break;
                        }
                        case DatapointConverterType.TEXT_FIELD: {
                            this.createTextIntervalsForFixedColorScale(renderingOptions, intervals, isGeoserver);
                            break;
                        }
                    }
                    break;
                case ColorScaleType.INTERVAL:
                    intervals = renderingOptions.datasetStylingOptions.intervalOptions;
                    break;
            }
        }
        return intervals;
    }

    private static createNumberIntervalsForFixedColorScale(renderingOptions: RenderingOptions, intervals: IntervalOption[]) {

        let colors = renderingOptions.datasetStylingOptions.colors;
        let converterOptionsMin = renderingOptions.converterOptions.minNumber;
        let converterOptionsMax = renderingOptions.converterOptions.maxNumber;
        let total = renderingOptions.converterOptions.total;

        if (converterOptionsMin === converterOptionsMax) {
            intervals.push({
                color: colors[0],
                minValue: converterOptionsMin,
                maxValue: converterOptionsMax,
                total: total
            });
        } else {
            let colorsLastIndex = colors.length - 1;
            let minIntervalIndex = 0;
            let maxIntervalIndex = 0.5;

            colors.forEach((color, index) => {
                let minIntervalUnit = minIntervalIndex / colorsLastIndex;
                let maxIntervalUnit = maxIntervalIndex / colorsLastIndex;
                let minIntervalValue = minIntervalUnit * converterOptionsMax - minIntervalUnit * converterOptionsMin + converterOptionsMin;
                let maxIntervalValue = maxIntervalUnit * converterOptionsMax - maxIntervalUnit * converterOptionsMin + converterOptionsMin;
                minIntervalValue = +minIntervalValue.toFixed(2).replace(/\.?0*$/g, '');
                if (index !== colorsLastIndex) {
                    maxIntervalValue = +(maxIntervalValue - 0.01).toFixed(2).replace(/\.?0*$/g, '');
                } else {
                    maxIntervalValue = +maxIntervalValue.toFixed(2).replace(/\.?0*$/g, '');
                }

                intervals.push({
                    color: color,
                    minValue: minIntervalValue,
                    maxValue: maxIntervalValue,
                    total: total
                });

                minIntervalIndex = maxIntervalIndex;
                if (index === colorsLastIndex - 1) {
                    maxIntervalIndex = colorsLastIndex;
                } else {
                    maxIntervalIndex += 1;
                }
            });
        }
    }

    private static createTextIntervalsForFixedColorScale(renderingOptions: RenderingOptions, intervals: IntervalOption[], isGeoserver: boolean) {
        let converterOptionsValues = renderingOptions.converterOptions.values;
        let colors = renderingOptions.datasetStylingOptions.colors;
        if (converterOptionsValues.length === 1) {
            intervals.push({
                color: colors[0],
                minValue: converterOptionsValues[0],
                maxValue: converterOptionsValues[0],
                isTextInterval: true,
                total: renderingOptions.converterOptions.total
            });
        } else {
            let values = renderingOptions.converterOptions.values;
            if (isGeoserver) {
                let array: number[] = new Array<number>(values.length);
                let combinationSeries: Array<Array<number>> = new Array();
                this.findCombinationsBySize(array, 0, values.length, values.length, colors.length, combinationSeries);
                let combination = combinationSeries[combinationSeries.length - 1].reverse();
                let start = 0;
                for (let i = 0; i <= combination.length; i++) {
                    let range = combination[i];
                    let end = start + range;
                    let subSetValues: String[] = values.slice(start, end <= values.length ? end : values.length);
                    start = end;
                    if (colors[i] !== undefined) {
                        intervals.push({
                            color: colors[i],
                            minValue: subSetValues[0],
                            maxValue: subSetValues[subSetValues.length - 1],
                            isTextInterval: true,
                            total: renderingOptions.converterOptions.total
                        });
                    }
                }
            } else {
                let unitsByValues = new Map<string, number>();
                let tempIntervalsGroups = new Map<number, string[]>();
                for (let i = 0; i < values.length; i++) {
                    const colorCode = colors[Math.round(i / (values.length - 1) * (colors.length - 1))];
                    unitsByValues.set(values[i], colorCode);
                }

                unitsByValues.forEach((outerElement, outerKey) => {
                    let calculateInterval = [];
                    unitsByValues.forEach((innerElement, innerKey) => {
                        if (outerElement === innerElement ) {
                            calculateInterval.push(innerKey);
                        }
                    });
                    tempIntervalsGroups.set(outerElement, calculateInterval);
                });
                
                tempIntervalsGroups.forEach((element, key) => {
                    intervals.push({
                        color: key,
                        minValue: element[0],
                        maxValue: element[element.length - 1],
                        isTextInterval: true,
                        total: renderingOptions.converterOptions.total
                    });
                });
            }
        }
    }
    

    public static findCombinationsBySize(array: number[], index: number, target: number, reducedTarget: number, comboSize: number, result: Array<Array<number>>) {
        if (reducedTarget < 0) {
            return;
        }; //base condition
        if (reducedTarget == 0) { //we found whole sum
            let combinations: Array<number> = [];
            for (let i = 0; i < index; i++) {
                combinations.push(array[i]);
            }
            if (combinations.length == comboSize) {
                result.push(combinations);
            }
            return;
        }

        let previous: number = (index == 0) ? 1 : array[index - 1];

        for (let k = previous; k <= target; k++) {
            array[index] = k;
            this.findCombinationsBySize(array, index + 1, target, reducedTarget - k, comboSize, result);
        }
    }  

    static createGradientForLegend(renderingOptions: RenderingOptions, isGeoserver: boolean): GradientDetails {
        if (renderingOptions.visualizationOptions.type === VisualizationType.DEFAULT) {
            if (renderingOptions.datasetStylingOptions.type === ColorScaleType.GRADIENT) {
                switch (renderingOptions.converterOptions.type) {
                    case DatapointConverterType.NUMBER_FIELD: {
                        return this.createNumbersGradientDetails(renderingOptions);
                    }
                    case DatapointConverterType.TEXT_FIELD: {
                        return this.createTextGradientDetails(renderingOptions, isGeoserver);
                    }
                }
            }
        }
        return null;
    }

    private static createNumbersGradientDetails(renderingOptions: RenderingOptions) {
        let colorsInBase10 = renderingOptions.datasetStylingOptions.colors;
        let converterOptionsMin = renderingOptions.converterOptions.minNumber;
        let converterOptionsMax = renderingOptions.converterOptions.maxNumber;
        let colorsInHex;

        if (converterOptionsMin === converterOptionsMax) {
            colorsInHex = [ColorUtils.colorToBase64(colorsInBase10[0])];
        } else {
            colorsInHex = colorsInBase10.map(color => ColorUtils.colorToBase64(color));
        }
        return {
            gradientColors: colorsInHex,
            gradientMax: converterOptionsMax,
            gradientMin: converterOptionsMin
        };
    }

    private static createTextGradientDetails(renderingOptions: RenderingOptions, isGeoserver: boolean) {
        let converterOptionsValues = renderingOptions.converterOptions.values;
        let colorsInBase10 = renderingOptions.datasetStylingOptions.colors;
        if (converterOptionsValues.length === 1) {
            return {
                gradientColors: [ColorUtils.colorToBase64(colorsInBase10[0])],
                gradientMax: converterOptionsValues[0],
                gradientMin: converterOptionsValues[0]
            };
        } else {
            if (isGeoserver) {
                let colorsInHex = [];
                let array: number[] = new Array<number>(converterOptionsValues.length);
                let combinationSeries: Array<Array<number>> = new Array();
                this.findCombinationsBySize(array, 0, converterOptionsValues.length, converterOptionsValues.length, colorsInBase10.length, combinationSeries);
                let combination = combinationSeries[combinationSeries.length - 1].reverse();
                let start = 0;
                for (let i = 0; i < combination.length; i++) {
                    let range = combination[i];
                    let end = start + range;
                    start = end;

                    let colorIndex = colorsInBase10.length -1 <= i ? colorsInBase10.length - 1 : i;
                    let tempRelativeUnit = i/ (combination.length - 1);
                    let relativeUnit = isNumber(tempRelativeUnit) ? Number(tempRelativeUnit) : tempRelativeUnit;
                    let nextIndex = colorIndex < colorsInBase10.length - 1 ? colorIndex + 1 : colorIndex;
        
                    let  nextColor = this.generateColor(colorsInBase10[colorIndex], colorsInBase10[nextIndex], this.normalize(Number(relativeUnit)));
                    colorsInHex.push("#"+ColorUtils.decimalToHexString(nextColor));
                }
                return {
                    gradientColors: colorsInHex,
                    gradientMax: converterOptionsValues[converterOptionsValues.length - 1],
                    gradientMin: converterOptionsValues[0]
                };
            } else {
                let colorsInHex = colorsInBase10.map(color => ColorUtils.colorToBase64(color));
                return {
                    gradientColors: colorsInHex,
                    gradientMax: converterOptionsValues[converterOptionsValues.length - 1],
                    gradientMin: converterOptionsValues[0]
                };
            }

        }
    }

    private static normalize(value: number, min: number = 0.0, max: number = 1.0): number {
        return 1 - ((value - min) / (max - min));
    }

    private static generateColor(minColor: number, maxColor: number, relativeUnit: number): number {
        if (minColor == maxColor) {
            return minColor;
        }
        let minRGBComponents: number[] = new ColorUtils(minColor).getColorComponents(new Array(3));
        let maxRGBComponents: number[] = new ColorUtils(maxColor).getColorComponents(new Array(3));
        let avgRGBComponents: number[] = this.avgRgbColors(minRGBComponents, maxRGBComponents, relativeUnit);
        return new ColorUtils(1).Color(avgRGBComponents[0], avgRGBComponents[1], avgRGBComponents[2]);
    }

    private static avgRgbColors(color1: number[], color2:number[], unit: number): number[] {
        let a: number[] = new Array(3);
        a[0] =  this.colorBetween(color1[0], color2[0], unit);
        a[1] =  this.colorBetween(color1[1], color2[1], unit);
        a[2] =  this.colorBetween(color1[2], color2[2], unit);
        return a;
    }

    private static colorBetween(color1: number, color2: number, unit: number): number {
        return Math.sqrt(Math.pow(color1, 2) * (1 - unit) + Math.pow(color2, 2) * unit);
    }

}
