import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {TwoFactorAuthService} from '../two-factor-auth-service';
import {NotifService} from '../../core/notification/notif.service';
import {CustomErrorStateMatcher} from '../../shared/error-state-matcher';
import {TwoFactorAuthKey} from '../../model/two-factor/two-factor-auth-key';

@Component({
    selector: 'map-two-factor-auth',
    templateUrl: './two-factor-auth.component.html',
    styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnInit {

    twoFactorAuthForm: FormGroup;
    twoFactorAuthKey: TwoFactorAuthKey;
    matcher: CustomErrorStateMatcher;
    email: string;

    constructor(private twoFactorAuthService: TwoFactorAuthService,
                private notifService: NotifService,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService) {
        this.twoFactorAuthForm = new FormGroup({
            code: new FormControl('', [Validators.required])
        });
        this.matcher = new CustomErrorStateMatcher();
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.email = params.get('email');
            this.twoFactorAuthService.getTwoFactorAuthKeyByUser(this.email).subscribe(key => {
                this.twoFactorAuthKey = key;
            });

        })
    }

    submit() {
        let code = this.twoFactorAuthForm.controls.code.value;
        this.twoFactorAuthService.confirmTwoFactorAuth(code, this.email).subscribe(
            emptyResponse => {
                let loginRequest = JSON.parse(JSON.stringify(this.authService.temporaryLoginRequest));
                loginRequest.code = code;
                this.authService.login(loginRequest).subscribe(
                    success => {
                        this.router.navigate(['private/account']);
                    },
                    error => {
                        this.notifService.error(error.error.message);
                    });
            }, error => this.notifService.error(error.error.message)
        );
    }

    cancel() {
        this.authService.logout();
    }
}
