import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Datapoint} from '../../../../model/datapoint/datapoint';
import {AttachmentUtils} from '../../../../core/utils/attachment-utils';
import {Attachment} from '../../../../model/attachment/attachment';
import {AttachmentService} from '../../../../data-access-layer/attachment.service';
import {AuthService} from '../../../../auth/auth.service';
import {environment} from '../../../../../environments/environment';
import {DialogComponent} from '../../../../shared/dialog/dialog.component';
import {DialogModel} from '../../../../model/dialog/dialog-model';
import {MatDialog} from '@angular/material/dialog';
import {take} from 'rxjs/operators';
import {UserStateService} from '../../../../auth/user-state-service';
import {DatapointsPageStateService} from '../../datapoints-page-state.service';

@Component({
    selector: 'map-location-profile-attachments',
    templateUrl: './location-profile-attachments.component.html',
    styleUrls: ['./location-profile-attachments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationProfileAttachmentsComponent implements OnInit {

    @Input('datapoint') datapoint: Datapoint;
    @Input('datasetId') datasetId: string;

    @Output() locationHasUploadedAttachments = new EventEmitter<boolean>();

    panelIsExpended = false;
    isUploading = false;

    attachments: Attachment[] = [];
    hasWritePermission = false;

    constructor(private attachmentService: AttachmentService,
                private changeDetectorRef: ChangeDetectorRef,
                private userStateService: UserStateService,
                private datapointsPageStateService: DatapointsPageStateService,
                private dialog: MatDialog,
                private authService: AuthService) {

    }


    ngOnInit(): void {
        this.hasWritePermission = this.userStateService.hasWriteAccess(this.datapointsPageStateService.activeAccountId)
        // this.attachments.forEach(attachment => attachment.icon = AttachmentUtils.getFileIconByExtension(attachment.name)); // prepare icons
        this.fetchAttachments();
    }

    fetchAttachments() {
        this.attachmentService.getAttachmentsForDatapoint(this.datapoint.id, this.datasetId).subscribe(attachments => {
            this.attachments = attachments.map(attachment => {
                attachment.icon = AttachmentUtils.getFileIconByExtension(attachment.name);
                return attachment;
            });
            this.locationHasUploadedAttachments.emit(!!this.attachments.length);
            this.changeDetectorRef.detectChanges();
        });
    }

    downloadAttachment(attachment: Attachment) {
        let url = `${environment.baseUrl}/attachments/download?attachmentId=${attachment.id}&datapointId=${this.datapoint.id}&datasetId=${this.datasetId}&token=${this.authService.getToken()}` ;
        // if (AttachmentUtils.isImage(attachment)) {
        //     url='/736x/09/bc/c8/09bcc8ff98c9f45b67b7800a3d6bb8c4.jpg';
        // }
        // if (AttachmentUtils.isPdf(attachment)) {
        //     url = '/images/default/sample.pdf';
        // }
        window.open(url);
    }

    onAttachmentUpload(event: any) {
        let file1 = event.target.files;
        this.isUploading = true;
        this.attachmentService.uploadAttachments(file1, this.datapoint.id, this.datasetId).subscribe(onSuccess => {
            this.fetchAttachments();
            this.isUploading = false;
            this.changeDetectorRef.detectChanges();
        }, onError => {
            this.isUploading = false;
            this.changeDetectorRef.detectChanges();
        });
        this.locationHasUploadedAttachments.emit(true);
    }

    deleteAttachment(attachmentId: string) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete attachment?`
            )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
                if (dialogResult) {
                    this.attachmentService.deleteAttachment(attachmentId, this.datapoint.id, this.datasetId).subscribe(success => this.fetchAttachments());
                }
            }
        );
    }

}
