// tslint:disable:no-bitwise
import {Pipe, PipeTransform} from '@angular/core';

const Status = {
    0: 'STATUS_AVAILABLE',
    1: 'VALID',
    2: 'INVALID',
    4: 'NOT TILED',
    8: 'NOT LINKED',
    16: 'GEOCODING',
    32: 'TEMPORARY',
    63: 'STATUS_ALL'
};

@Pipe({
    name: 'draftStatus'
})
export class DraftStatusPipe implements PipeTransform {
    transform(value: number) {
        let status = [];
        for (let i = 0; i <= 6; i++) {
            if ((value & Math.pow(2, i))) {
                status.push(Status[Math.pow(2, i)]);
            }
        }
        return status.join(', ');
    }

}
export function DraftStatusTransform(value: number) {
        let status = [];
        for (let i = 0; i <= 6; i++) {
            if ((value & Math.pow(2, i))) {
                status.push(Status[Math.pow(2, i)]);
            }
        }
        return status.join(', ');
}
