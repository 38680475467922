import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WorkspaceItemType} from '../../model/workspace/workspace-item-type';
import {Observable, Subject} from 'rxjs';
import {WorkspaceItem} from '../../model/workspace/workspace-item';
import {CreateWorkspaceItemRequest} from '../../model/workspace/create-workspace-item-request';
import {UpdateWorkspaceItemRequest} from '../../model/workspace/update-workspace-item-request';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceItemService {

    isDashboardCallClose$: Observable<boolean>;
    private _isDashboardCallClose: Subject<boolean> = new Subject<boolean>();

    constructor(private readonly http: HttpClient) {
        this.isDashboardCallClose$ = this._isDashboardCallClose.asObservable();
    }

    setDashboardCall(value) {
        this._isDashboardCallClose.next(value);
    }

    createWorkspaceItem(request: CreateWorkspaceItemRequest): Observable<WorkspaceItem> {
        return this.http.post<WorkspaceItem>(`/workspace-items`, request);
    }

    updateWorkspaceItem(request: UpdateWorkspaceItemRequest): Observable<WorkspaceItem> {
        return this.http.put<WorkspaceItem>(`/workspace-items`, request);
    }

    deleteWorkspaceItem(id: number): Observable<void> {
        return this.http.delete<void>(`/workspace-items/${id}`);
    }

    getAllWorkspaceItemsForUser(datasetId: string, type?: WorkspaceItemType): Observable<WorkspaceItem[]> {
        let params = type ? {type: type} : null;
        return this.http.get<WorkspaceItem[]>(`/workspace-items/dataset/${datasetId}`, {params: params});
    }

    getWorkspaceItemsForMember(datasetId: string, type: WorkspaceItemType): Observable<WorkspaceItem[]> {
        return this.http.get<WorkspaceItem[]>(`/workspace-items/dataset/${datasetId}/member`, {params: {type: type}});
    }


    getABC(): Observable<any> {
        return this.http.get<any>(`http://35.180.189.177/api/ndvi/`);
    }


}
