import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AssignAccountsToGlobalOverlayRequest, AssignGlobalOverlaysToAccountRequest} from 'src/app/model/overlay/assign-accounts-to-global-overlays';
import {BehaviorSubject, Observable} from 'rxjs';
import {Dataset, UpdateDatasetRequest} from '../../model/dataset/dataset';
import {GroupWithOverlaysTreeNode} from '../../model/overlay/group/group-with-overlays-tree-node';
import {Account} from '../../model/account/account';
import {CreateDatasetRequest} from '../../model/dataset/create-dataset-request';
import {OverlayGroup} from '../../model/overlay/group/overlay-group';


@Injectable({
    providedIn: 'root'
})
export class OverlaysService {

    constructor(private readonly http: HttpClient) {
    }

    getMyOverlays(): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/overlays`);
    }

    createOverlay(request: CreateDatasetRequest): Observable<Dataset> {
        return this.http.post<Dataset>(`/overlays`, request);
    }

    createExternalOverlay(request: CreateDatasetRequest): Observable<Dataset> {
        return this.http.post<Dataset>(`/tessadata`, request);
    }

    updateExternalDataset(request: UpdateDatasetRequest): Observable<Dataset> {
        return this.http.put<Dataset>('/tessadata', request);
        
    }
    /**
     * returns the global overlays where the account has access to.
     */
    getAccountGlobalOverlays(accountID: number): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/overlays/account/${accountID}/global`);
    }

  /**
     * returns the Tessadata overlays where the account has access to.
     */
    getAccountTessadataOverlays(accountID: number): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/overlays/account/${accountID}/external`);
    }

    getAccountPrivateOverlays(accountID: number): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/overlays/account/${accountID}/private`);
    }

    getAllGlobalOverlays(): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/overlays/global`);
    }

    getAllTessadataOverlays(): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/overlays/external`);
    }

    /**
     * Private overlays + global overlays where the account has access to.
     */
    getAllAccountOverlays(accountID: number): Observable<Dataset[]> {
        return this.http.get<Dataset[]>(`/overlays/account/${accountID}`);
    }

    getAccountsForGlobalOverlay(datasetID: string): Observable<Account[]> {
        return this.http.get<Account[]>(`/overlays/${datasetID}/accounts`);
    }

    getAccountsForExtrnalOverlay(datasetID: string): Observable<Account[]> {
        return this.http.get<Account[]>(`/overlays/${datasetID}/accounts/external`);
    }

    assignAccountsToGlobalOverlay(accountIDs: number[], datasetID: any): Observable<void> {
        return this.http.post<void>(`/overlays/${datasetID}/accounts/assign`, accountIDs);
    }

    assignAccountsToExternlOverlay(accountIDs: number[], datasetID: any): Observable<void> {
        return this.http.post<void>(`/tessadata/${datasetID}/accounts/assign`, accountIDs);
    }
   
    assignGlobalOverlaysToAccount(request: AssignGlobalOverlaysToAccountRequest): Observable<void> {
        return this.http.post<void>(`/overlays/accounts/${request.accountID}/assign`, request.datasetIDs);
    }

    assignExternalOverlaysToAccount(request: AssignGlobalOverlaysToAccountRequest): Observable<void> {
        return this.http.post<void>(`/tessadata/accounts/${request.accountID}/assign`, request.datasetIDs);
    }

    deleteExternalOverlay(datasetID: string): Observable<void> {
        return this.http.delete<void>(`/tessadata/${datasetID}`);
    }

    deleteOverlay(datasetID: string): Observable<void> {
        return this.http.delete<void>(`/overlays/${datasetID}`);
    }

    getExternalOverlayGroupsAsTree(accountID: number): Observable<GroupWithOverlaysTreeNode[]> { 
        return this.http.get<GroupWithOverlaysTreeNode[]>(`/overlays/externaldataset/${accountID}/tree`);
    }
    getOverlayGroupsAsTree(accountID: number): Observable<GroupWithOverlaysTreeNode[]> {
        // getDatasetsWithGroupsAsTree
        return this.http.get<GroupWithOverlaysTreeNode[]>(`/overlays/account/${accountID}/tree`);
    }

    refresh(datasetID: string): Observable<any> {
        return this.http.get(`/overlays/${datasetID}/refresh`);
    }

    refreshAerisData() {
        return this.http.post(`/overlays/aeris/refresh`, null);
    }

    initAerisData() {
        return this.http.post(`/overlays/aeris/initialize`, null);
    }


    updateOverlayGroup(request: UpdateOverlayGroupRequest): Observable<any> {
        return this.http.put(`/overlayGroups/update`, request);
    }

    createOverlayGroup(request: CreateOverlayGroupRequest): Observable<any> {
        return this.http.post(`/overlayGroups/create`, request);
    }

    createExternalOverlayGroup(request: CreateOverlayGroupRequest): Observable<any> {
        return this.http.post(`/externalgroups/create`, request);
    }

    deleteExternalOverlayGroup(overlayGroupId: number): Observable<any> {
        return this.http.delete(`/externalgroups/${overlayGroupId}`);
    }

    updateExternalOverlayGroup(request: UpdateOverlayGroupRequest): Observable<any> {
        return this.http.put(`/externalgroups/update`, request);
    }

    deleteOverlayGroup(overlayGroupId: number): Observable<any> {
        return this.http.delete(`/overlayGroups/${overlayGroupId}`);
    }

    getOverlayGroup(overlayGroupId: number): Observable<any> {
        return this.http.get(`/overlayGroups/${overlayGroupId}`);
    }

    getOverlayGroupsByTag(tag: string): Observable<OverlayGroup[]> {
        return this.http.get<OverlayGroup[]>(`/overlayGroups/tag/${tag}`);
    }

    getAllOverlayGroups(): Observable<any> {
        // GroupStructure
        return this.http.get(`/overlayGroups/all`);
    }
    // getAccountPrivateOverlays(accountID: string): Observable<any> {
    //     // GroupStructure
    //     return this.http.get(`/overlays/account/${accountID}/private `);
    // }

    getAllExternalGroups() : Observable<any>{
        return this.http.get(`/externalgroups/all`);
    }
}

export interface CreateOverlayGroupRequest {

    id: string;
    name: string;
    parentID: number;
    tag?: string;
}

export interface UpdateOverlayGroupRequest {

    id: number;
    name?: string;
    parentID: number;
    tag?: string;
}

// GET /v2/overlayGroups/all
// getAllOverlayGroups
//
//
// POST /v2/overlayGroups/create
// createOverlayGroup
//
// PUT /v2/overlayGroups/update
// updateOverlayGroup
//
//
// DELETE /v2/overlayGroups/{overlayGroupId}
// deleteOverlayGroup
//
//
// GET /v2/overlayGroups/{overlayGroupId}
// getOverlayGroup
