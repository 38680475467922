import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class SuperadminGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router
    ) {

    }

    canActivate(): boolean {
        if (!this.authService.isAuthenticated()) {
            this.router.navigate(['/public/login']);
            return false;
        }
        return this.authService.getPrincipal().isSuperadmin;
    }

}
