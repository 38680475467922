import {TableColumn} from '../upload/table/table-column';
import {TableRow} from '../upload/table/table-row';
import {DownloadReportItem} from './item/download-report-item';
import {DownloadItemReportType} from './item/download-item-report-type';

export class DownloadReportTableRequest implements DownloadReportItem {
    type: DownloadItemReportType = DownloadItemReportType.TABLE;
    title: string;
    columns: TableColumn[];
    rows: TableRow[];
    footerRow: TableRow;



    constructor(title: string, columns: TableColumn[], rows: TableRow[], footerRow: TableRow) {
        this.title = title;
        this.columns = columns;
        this.rows = rows;
        this.footerRow = footerRow;
        this.type = DownloadItemReportType.TABLE;
    }
}
