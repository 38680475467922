import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

const START_AT = 0;

@Component({
    selector: 'map-paginator',
    templateUrl: './maptycs-paginator.component.html',
    styleUrls: ['./maptycs-paginator.component.scss']
})
export class MaptycsPaginatorComponent implements OnInit, OnChanges {
    @Output() paginationChangeEvent: EventEmitter<{ numberOfItemsPerPage: number, currentPage: number, onPageLoadCall: boolean }> =
        new EventEmitter<{ numberOfItemsPerPage: number, currentPage: number, onPageLoadCall: boolean }>(null);
    @Input() numberOfItemsPerPage = 20;
    @Input() totalNumberOfElements;
    @Input() currentPageNumber;
    onPageLoadCall: boolean = true;

    lastPage = 0;
    firstPage = START_AT;
    // tslint:disable-next-line:variable-name
    _currentPage: BehaviorSubject<number> = new BehaviorSubject(START_AT);

    constructor() {
    }

    get currentPage() {
        return this._currentPage.getValue();
    }

    set currentPage(value) {
        if (value >= START_AT) {
            this._currentPage.next(value);
        } else {
            this._currentPage.next(1);
        }
    }

    ngOnInit(): void {
        this._currentPage
            .pipe(debounceTime(200))
            .subscribe((value) => {
                this.paginationChangeEvent.emit({numberOfItemsPerPage: this.numberOfItemsPerPage, currentPage: value, onPageLoadCall: this.onPageLoadCall});
            });
    }

    ngOnChanges(): void {
        if (this.totalNumberOfElements < this.numberOfItemsPerPage) {
            this.lastPage = START_AT;
        } else {
            this.lastPage = Math.floor(this.totalNumberOfElements / this.numberOfItemsPerPage);
        }
        this.goToFirstPage();
    }

    nextPage(flag: boolean): void {
        this.onPageLoadCall = flag;
        this.currentPage = this.currentPage + 1;
    }

    previousPage(flag: boolean): void {
        this.onPageLoadCall = flag;
        this.currentPage = this.currentPage - 1;
    }

    goToFirstPage(flag: boolean = true): void {
        if (!flag) {
            this.onPageLoadCall = flag;
        }
        this.currentPage = this.firstPage;
    }

    goToLastPage(flag: boolean): void {
        this.onPageLoadCall = flag;
        this.currentPage = this.lastPage;
    }

    goToPageNumber(page: number, flag: boolean): void {
        this.onPageLoadCall = flag;
        this.currentPage = page;
    }

    get pageRange(): number[] {
        let pageRange = [];
        let start = this.currentPage - 2;
        let end = this.currentPage + 2;
        if (this.currentPage < 3) {
            start = START_AT;
            end = 5;
        }
        if (this.currentPage > (this.lastPage - 3)) {
            start = this.lastPage - 4;
            end = this.lastPage;
        }
        for (let i = start; i <= end; i++) {
            if (i >= START_AT && i <= this.lastPage) {
                pageRange.push(i);
            }
        }
        return pageRange;
    }

    goToElement(elementNumber: number): void {
        this.currentPage = Math.floor(elementNumber / this.numberOfItemsPerPage);
    }
}
