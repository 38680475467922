import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderStateService {
    state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    update(value: any) {
        this.state.next(value);
    }

    get value() {
        return this.state.asObservable();
    }
}
