export enum Functionalities {
    NOTIFICATIONS = 'NOTIFICATIONS',
    OVERLAYS = 'OVERLAYS',
    GROUPS = 'GROUPS',
    ANALYTICS = 'ANALYTICS',
    DRAFTS = 'DRAFTS',
    DATAPOINTS = 'DATAPOINTS',
    THEMATICMAP = 'THEMATICMAP',
    DOWNLOAD_DATAPOINTS = 'DOWNLOAD_DATAPOINTS',
    ALTER_DATAPOINTS = 'ALTER_DATAPOINTS',
    TESSADATA_DATA = 'TESSADATA_DATA'
}
