import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaxTemperatureService } from './max-temperature.service';
import { MeanTemperatureService } from './mean-temperature.service';
import { MinTemperatureService } from './min-temperature.service';
import { PopulationDensityService } from './population-density.service';
import { PrecipitationService } from './precipitation.service';
import { SeaLevelRiseService } from './sea-level-rise.service';
import { SurfaceWindService } from './surface-wind.service';
import { SnowfallService } from './snowfall.service';
import { ClimateOverlayPermission } from './datapoints-climate-chart.service';
import { SeaLevelRiseWarmingService } from './sea-level-rise-warming.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ClimateOverlayPermission,
    MaxTemperatureService,
    MeanTemperatureService,
    MinTemperatureService,
    PopulationDensityService,
    PrecipitationService,
    SeaLevelRiseService,
    SnowfallService,
    SurfaceWindService,
    SeaLevelRiseWarmingService
  ]
})
export class ClimateModule { }
