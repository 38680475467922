import { TooltipComponent } from "@angular/material";

export class ObjectUtils {

    public static clone<T>(object: T): T {
        return JSON.parse(JSON.stringify(object));
    }

    public formatStringWithHTMLtags() {
        Object.defineProperty(TooltipComponent.prototype, 'message', {
            set(v: any) {
                const el = document.querySelectorAll('.mat-tooltip');
         
                if (el) {
                    el[el.length - 1].innerHTML = v;
                }
            },
         });
    }
}
