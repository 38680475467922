import { Component, OnInit, ViewChild } from '@angular/core';
import { IntegrationService } from '../data-access-layer/integration/integration.service';
import { IntegrationClientInfo } from '../model/integration/integration-client-info';
import { AccountService } from '../data-access-layer/account/account.service';
import { Account } from '../model/account/account';
import { SidePanelComponent } from '../core/side-panel/side-panel.component';
import { NotifService } from '../core/notification/notif.service';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { DialogModel } from '../model/dialog/dialog-model';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { EditIntegratedClientComponent } from './edit-integrated-client/edit-integrated-client.component';

@Component({
    selector: 'map-integrated-clients',
    templateUrl: './integrated-clients.component.html',
    styleUrls: ['./integrated-clients.component.scss']
})
export class IntegratedClientsComponent implements OnInit {

    @ViewChild('editIntegratedClientPanel', { static: false }) editIntegratedClientPanel: SidePanelComponent;
    @ViewChild('createIntegratedClientPanel', { static: false }) createIntegratedClientPanel: SidePanelComponent;
    @ViewChild('editIntegratedClientComponent', { static: false }) editIntegratedClientComponent: EditIntegratedClientComponent;

    clients: IntegrationClientInfo[] = [];
    displayedColumns: string[] = ['platform', 'platformClient', 'accountKey', 'accountPassword', 'account', 'actions'];
    accounts: Account[] = [];


    constructor(private readonly integrationService: IntegrationService,
        private readonly accountService: AccountService,
        private readonly notifService: NotifService,
        public readonly dialog: MatDialog) {
    }

    ngOnInit() {
        this.fetchIntegratedClients();
        this.accountService.getMyAccounts().subscribe(accounts => {
            this.accounts = accounts;
            this.clients.forEach(client => {
                let account = this.accounts.find(account => account.id === client.accountId);
                client.accountName = account ? account.name : null;
            });
        })
    }

    private fetchIntegratedClients() {
        this.integrationService.getIntegrationClients().subscribe(clients => {
            clients.forEach(client => {
                let account = this.accounts.find(account => account.id === client.accountId);
                client.accountName = account ? account.name : null;
            });

            this.clients = clients;
        });
    }

    openCreateIntegratedClientPanel() {
        this.createIntegratedClientPanel.showPanel();
    }

    openEditIntegrationClientPanel(row: IntegrationClientInfo) {
        this.editIntegratedClientComponent.initForm(row);
        this.editIntegratedClientPanel.showPanel();
    }

    deleteIntegratedClient(row: IntegrationClientInfo) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete "${row.platformClient}"?`
            )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.integrationService.deleteIntegrationClient(row.id).subscribe(
                    success => {
                        this.notifService.success("Client integration was successfully deleted");
                        this.fetchIntegratedClients();
                    }, error => this.notifService.error(error.error.message));
            }
        });
    }

    closeCreateIntegrationClientPanel() {
        this.createIntegratedClientPanel.hidePanel();
        this.fetchIntegratedClients();
    }

    closeUpdateIntegrationClientPanel() {
        this.editIntegratedClientPanel.hidePanel();
        this.fetchIntegratedClients();
    }

    // openCreateIntegratedClientPanel() {
    //     this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
    //     this.createIntegratedClientPanel = this.sidePanelService.open<CreateIntegratedClientComponentType>(SidePanels.CREATE_INTEGRATED_CLIENT,
    //         {
    //             width: 400,
    //             panelTitle: "Create Client",
    //             id: "editIntegratedClientPanel"
    //         },
    //         {
    //             accounts: this.accounts
    //         });

    //     this.integratedClientsServiceState.onCreateSuccess$.subscribe(() => {
    //         this.closeCreateIntegrationClientPanel();
    //     });
    // }

    // openEditIntegrationClientPanel(row: IntegrationClientInfo) {
    //     this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
    //     this.editIntegratedClientPanel = this.sidePanelService.open<EditIntegratedClientComponentType>(SidePanels.EDIT_INTEGRATED_CLIENT,
    //         {
    //             width: 400,
    //             panelTitle: "Edit Client",
    //             panelIcon: "fa-pencil",
    //             id: "createIntegratedClientPanel"
    //         });

    //     this.integratedClientsServiceState.editIntegratedClientComponentInit$.subscribe(() => {
    //         const { componentRef } = this.editIntegratedClientPanel.instance;
    //         if (componentRef) {
    //             componentRef.instance.initForm(row);
    //         }
    //     });

    //     this.integratedClientsServiceState.onUpdateSuccess$.subscribe(() => {
    //         this.closeUpdateIntegrationClientPanel();
    //     });
    // }
}
