import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {DatapointsService} from '../../../data-access-layer/datapoints/datapoints.service';
import {debounceTime, take} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {DatapointField} from '../../../model/datapoint/datapoint-field';
import {SortOrder} from '../../../model/filter/draft-filter-sort';
import {Dataset} from '../../../model/dataset/dataset';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DatapointFilterObject} from '../../../model/datapoint/datapoint-filter-object';
import {ProjectedDatapoint} from '../../../model/datapoint/projected-datapoint';
import {DatapointsPageStateService} from '../datapoints-page-state.service';
import {FilterBarItem} from '../../../model/datapoint/draft/table/filter-bar-item';
import {NotifService} from '../../../core/notification/notif.service';
import {SortEvent} from '../../../core/directives/sort.directive';
import {MaptycsTableComponent} from '../../../core/maptycs-table/maptycs-table.component';
import {DatapointFilter} from '../../../model/datapoint/filter/datapoint-filter';
import {MaptycsPaginatorComponent} from '../../../core/maptycs-table/maptycs-paginator/maptycs-paginator.component';
import {MatDialog} from '@angular/material';
import {DialogComponent} from '../../../shared/dialog/dialog.component';
import {DialogModel} from '../../../model/dialog/dialog-model';
import {DatasetFieldType} from '../../../model/dataset/dataset-field-type';
import {TessadataService} from '../../../data-access-layer/tessadata.service';
import { DatapointProjection } from 'src/app/model/datapoint/projection/datapoint-projection';
import { ObjectUtils } from 'src/app/core/utils/object-utils';

const DIRECTION_MAP = {['asc']: SortOrder.ASCENDANT, ['desc']: SortOrder.DESCENDANT};

interface TableColumn {
    name: string;
    fixedWidth: boolean;
    width: number;
}

@Component({
    selector: 'map-datapoints-table',
    templateUrl: './datapoints-table.component.html',
    styleUrls: ['./datapoints-table.component.scss']
})
export class DatapointsTableComponent implements OnInit, OnChanges, OnDestroy {

    @Input() filter: DatapointFilterObject;
    @Input() dataset: Dataset;
    @Input() filterItems: FilterBarItem[];
    @Output() Update = new EventEmitter<{ dataset: Dataset, datapointID: string }>();
    @ViewChild('updateDatapoint', {static: false}) updateDatapoint;
    @ViewChild('content', {static: true}) content: ElementRef;

    private readonly subscriptions: Subscription = new Subscription();

    datapoints: Array<ProjectedDatapoint>;
    dataSource: MatTableDataSource<DatapointTableRow>;
    fetchDatapointsSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    columns: Column[];
    @ViewChild('maptycsTable', {static: false}) maptycsTable: MaptycsTableComponent;
    @ViewChild('paginator', {static: false}) paginator: MaptycsPaginatorComponent;
    paginationInfo: { count: number, id: string };

    constructor(
        private readonly service: DatapointsPageStateService,
        private readonly notifService: NotifService,
        private readonly dpService: DatapointsService,
        private readonly tessadataService: TessadataService,
        public readonly dialog: MatDialog
    ) {
        this.datapoints = [];
    }

    ngOnInit(): void {
        let fetchDatapointsByFilter = () => {
            if (this.dataset.geometryType !== 'NONE' && this.dataset.geometryType !== 'COMPLEX') {
                this.filter.projection.geometryPrecision = 25;
            }
            this.dpService.getDatapointsByFilter(this.filter)
                .pipe(take(1))
                .subscribe(datapoints => {
                    datapoints.forEach(datapoint => {
                        datapoint.selected = this.maptycsTable.selectAll;
                    });
                    this.datapoints = datapoints;
                    this.columns = this.getColumns();
                    //     .map((column) => {
                    //     return {name: column.columnName, width: column.width}
                    // });
                    this.content.nativeElement.scroll({behavior: 'smooth', top: 0});
                    this.fetchPaginationInfo(this.filter.filter, this.filter.projection);
                }, error => this.notifService.error('Something went wrong... Please check the FILTERS.'));
        };
        this.subscriptions.add(this.fetchDatapointsSubject
            .pipe(debounceTime(500))
            .subscribe(reset => {
                if (reset === true) {
                    this.resetTable();
                }
                fetchDatapointsByFilter();
            }));

    }

    get selectAll() {
        if (this.maptycsTable) {
            return this.maptycsTable.selectAll;
        }
    }

    set selectAll(value: boolean) {
        if (this.maptycsTable) {
            this.maptycsTable.selectAll = value;
        }
    }

    ngOnChanges(): void {
        this.applyChanges();
    }

    getDataset(id): Dataset {
        return this.service.getDataset(id);
    }

    resetTable(): void {
        this.skip = 0;
        this.datapoints = [];
        this.selectAll = false;
    }

    get skip() {
        return this.filter.skip;
    }

    set skip(value) {
        this.filter.skip = value;
    }

    get limit() {
        return this.filter.limit;
    }

    fetchDatapoints(resetTable = true): void {
        this.fetchDatapointsSubject.next(resetTable);
    }

    updateDatapointById(datapointID: string): void {
        this.Update.emit({dataset: this.dataset, datapointID: datapointID});
    }

    public fetchExternalData(externalDatasetsIds: string[], selectedDatapointIds?: string[]) {
        if (this.selectAll === false) {
            if (selectedDatapointIds.length > 0) {
                let clonedFilter = this.applyProjetionForTessadata(externalDatasetsIds);
                this.tessadataService.fetchExternalData(clonedFilter.projection, this.dataset.id, externalDatasetsIds, selectedDatapointIds, clonedFilter.filter).subscribe(success => {
                    this.notifService.success('The fetching process has started');
                });
            }
        } else {
            let clonedFilter = this.applyProjetionForTessadata(externalDatasetsIds);
            this.tessadataService.fetchExternalDataByFilter(clonedFilter.projection, this.dataset.id, externalDatasetsIds, clonedFilter.filter).subscribe(success => {
                this.notifService.success('The fetching process has started');
            });
        }
    }

    private applyProjetionForTessadata(externalDatasetsIds: string[]): DatapointFilterObject {
        let clonedFilter = ObjectUtils.clone(this.filter);
        clonedFilter.projection.datasetID = this.dataset.id;
        let fields = clonedFilter.projection.fields.filter(f => {
            return externalDatasetsIds.find(externalDatasetsId => {
                return f.startsWith(externalDatasetsId);
            });
            });
        clonedFilter.projection.fields = fields;
        clonedFilter.projection.geometryPrecision = 25;
        return clonedFilter;
    }

    public deleteSelected(): void {
        if (this.selectAll === false) {
            let selectedDatapointIds = this.getSelectedDatapointIds();
            if (selectedDatapointIds.length > 0) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    data: new DialogModel(
                        'Confirm Action',
                        `Are you sure you want to delete ${selectedDatapointIds.length} datapoint(s)?`
                    )
                });
                dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
                    if (dialogResult) {
                        this.subscriptions.add(this.dpService.deleteDatapoints(this.dataset.id, selectedDatapointIds).subscribe((res) => {
                            this.fetchDatapoints();
                        }));
                    }
                });
            }
        } else {
            if (!this.paginationInfo.count) {
                return;
            }
            const dialogRef = this.dialog.open(DialogComponent, {
                data: new DialogModel(
                    'Confirm Action',
                    `Are you sure you want to delete ${this.paginationInfo.count} datapoint(s)?`
                )
            });
            dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
                if (dialogResult) {
                    this.subscriptions.add(this.dpService.deleteDatapointsByFilter(this.dataset.id, this.filter.filter).subscribe((res) => {
                        this.notifService.success(`Successfully deleted ${res} datapoints`);
                        this.fetchDatapoints();
                    }));
                }
            });
        }
    }

    getSelectedDatapointIds(): string[] {
        let ids = this.maptycsTable.selected.items.map((item) => {
            return item.id;
        });
        return ids || [];
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onPaginationChange(paginationChangeEvent: { numberOfItemsPerPage: number, currentPage: number }): void {
        this.skip = (paginationChangeEvent.currentPage) * this.limit;
        this.fetchDatapoints(false);
    }

    fetchPaginationInfo(filter: DatapointFilter, projection:DatapointProjection): void {
        /*this.subscriptions.add(this.dpService.getDatapointsPaginationInfo(filter, projection).subscribe((count) => {
            this.paginationInfo = count;
        }, err => {
            console.log(err);
        }));*/
    }

    getColumns(): Column[] {
        let columns: Column[] = [];
        if (this.filter.projection.formulas) {
            this.filter.projection.formulas.forEach(formula => {
                columns.push({
                    datasetID: this.dataset.id,
                    columnName: formula.name,
                    columnID: formula.name,
                    isFormula: true
                });
            });
        }
        this.filter.projection.fields.forEach(fieldID => {
            let datasetField = this.dataset.fields.find(field => field.id === fieldID);
            let headerName = datasetField.displayName === null || datasetField.displayName === undefined ? datasetField.name : datasetField.displayName;
            if (datasetField.isDisplayedInProjection) {
                let column = {
                    datasetID: this.dataset.id,
                    columnName: headerName,
                    columnID: fieldID,
                    fixedWidth: datasetField.baseType === DatasetFieldType.NUMBER,
                    width: '120px'
                };
                columns.push(column);
            }
        });
        this.filter.projection.links.forEach((link) => {
            link.fields.forEach((fieldID) => {
                let datasetField = this.getDataset(link.datasetID).fields.find(searchedDatasetField => searchedDatasetField.id === fieldID);
                if (datasetField.isDisplayedInProjection) {
                    let dataset = this.getDataset(link.datasetID);
                    let column = {
                        datasetID: dataset.id,
                        columnName: datasetField.name + ' / ' + dataset.name,
                        columnID: fieldID,
                        fixedWidth: datasetField.baseType === DatasetFieldType.NUMBER,
                        width: '120px'
                    };
                    columns.push(column);
                }
            });
        });
        return columns;
    }

    onSort({column, direction}: SortEvent): void {
        let col = this.getColumns().find((searchedColumn) =>
            searchedColumn.columnName === column);
        let activeDataset = this.getDataset(col.datasetID);
        let activeField = activeDataset.fields.find(field => {
            return field.id === col.columnID;
        });
        if (direction === '') {
            // remove all sorting, if direction is empty
            this.filter.sort.datasetID = this.dataset.id;
            this.filter.sort.links = [];
            this.filter.sort.fields = [];
            // this.resetTable();
            this.fetchDatapoints(false);
            return;
        }
        if (activeField.datasetID && activeField.datasetID !== this.dataset.id) {
            // empty native dataset fields, if sorted by foreign dataset
            this.filter.sort.fields = [];
            // 1
            this.filter.sort.datasetID = this.dataset.id;
            this.filter.sort.links = [{
                datasetID: activeField.datasetID,
                fields: [{id: activeField.id, sortOrder: DIRECTION_MAP[direction]}],
                linkFields: [],
                links: []
            }
            ];
        } else {
            // empty foreign dataset fields, if sorted by native dataset
            this.filter.sort.datasetID = this.dataset.id;
            this.filter.sort.links = [];
            this.filter.sort.fields = [{id: activeField.id, sortOrder: DIRECTION_MAP[direction]}];

        }
        this.fetchDatapoints(false);
    }

    private applyChanges(): void {
        if (!this.filter || !this.dataset) {
            return;
        }
        this.selectAll = false;
        if (this.paginator) {
            this.paginator.goToElement(this.filter.skip);
        }
        this.fetchDatapoints();
    }
}

export interface DatapointTableRow {
    datapointID: string;
    status?: number;
    selected?: boolean;
    geocodingAccuracy?: string;
    fields: { [key: string]: DatapointField }; //  Map that contains fields by ids
}

export interface Column {
    datasetID: string;
    columnName: string;
    columnID: string;
    fixedWidth?: boolean;
    width?: string;
    direction?: string;
    isFormula?: boolean;
}
