import { Directive , ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[DecimalPoint]'
})
export class DecimalOnlyDirective {
  @Input() DecimalPoint = 0;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private el: ElementRef) {
  }
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    if(current.indexOf('.') != -1 ){
      let value_array = current.split('.');
      if((value_array.length > 1) && (value_array[1] != undefined && value_array[1] != null) && value_array[1].length >= this.DecimalPoint) event.preventDefault();
    }
  }
}
