import Point = google.maps.Point;
import {Constants} from '../../constants';

export class TilingUtils {

    static readonly subdomains = ['a', 'b', 'c', 'd'];

    /**
     * This will append a subdomain in front of the main domain. This subdomain is generated randomly based on zoom and tile index
     */
    static getUrlWithSubdomainForTile(point: Point, zoom: number): string {
        let baseDomain = Constants.BASE_DOMAIN;
        let subdomain = TilingUtils.subdomains[(point.x + point.y + zoom) % 4];
        return baseDomain.replace('://', `://${subdomain}.`);
    }
}
