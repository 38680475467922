export interface DatapointSort {
    fields: { id: string, sortOrder: SortOrder }[];
    linkFields?: string[]; // TODO delete me
    datasetID: string;
    links?: any[];
    statusOrder?: SortOrder;
    geocodingAccuracyOrder?: SortOrder;
}
export enum SortOrder {
    ASCENDANT='ASCENDANT',
    DESCENDANT = 'DESCENDANT'
}
