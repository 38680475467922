import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'symbolUnitNumberFormat'
})
export class SymbolUnitNumberFormatPipe implements PipeTransform {

    transform(value: number): any {
        if (isNaN(value)) {
            return value;
        }
        
        let firstUnitsPart;
        let secondUnitsPart;
        let decimalPart;
        let destructuredValue;
        if (value < 1) {
            return value;
        }
        if (value >= 1 && value < 1000) {
            return Math.round(value);
        }
        if (value > 999 && value < 1000000) {
            destructuredValue = value / 1000;
            firstUnitsPart = Math.trunc(destructuredValue);
            decimalPart = (destructuredValue) - firstUnitsPart;
            secondUnitsPart = parseFloat(decimalPart.toFixed(1));
            return firstUnitsPart + secondUnitsPart + 'K';
        }
        if (value > 999999 && value < 1000000000) {
            destructuredValue = value / 1000000;
            firstUnitsPart = Math.trunc(destructuredValue);
            decimalPart = (destructuredValue) - firstUnitsPart;
            secondUnitsPart = parseFloat(decimalPart.toFixed(1));
            return firstUnitsPart + secondUnitsPart + 'M';
        }
        if (value > 999999999 && value < 1000000000000) {
            destructuredValue = value / 1000000000;
            firstUnitsPart = Math.trunc(destructuredValue);
            decimalPart = (destructuredValue) - firstUnitsPart;
            secondUnitsPart = parseFloat(decimalPart.toFixed(1));
            return firstUnitsPart + secondUnitsPart + 'B';
        }
    }

}
