import { NgModule } from '@angular/core';
import { MapComponent } from './map/map.component';
import {MapInfoBoxComponent} from './map/info-box/map-info-box.component';
import {DatapointMapLegendComponent} from './map/datapoint-map-legend/datapoint-map-legend.component';
import {SharedModule} from '../shared/shared.module';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';

@NgModule({
    declarations: [
        MapComponent,
        MapInfoBoxComponent,
        DatapointMapLegendComponent
    ],
    imports: [
        SharedModule,
        MatGoogleMapsAutocompleteModule
    ],
    exports: [
        MapComponent,
        DatapointMapLegendComponent
    ]
})
export class MapModule {
}
