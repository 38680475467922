import {Component} from '@angular/core';

@Component({
    selector: 'map-test',
    templateUrl: './map-component-test.html',
    styleUrls: ['./map-component-test.scss']
})
export class MapComponentTest {
// google maps zoom level
    zoom: number = 8;

    // initial center position for the map
    lat: number = 51.673858;
    lng: number = 7.815982;

    hovering() {
        console.log('hover');
    }

    clicking() {
        console.log('clicked');
    }

    leaving() {
        console.log('leaving');
    }

}
