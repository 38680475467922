import { Injectable } from "@angular/core";
import { Observable, Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AccountServiceState {
    nameUpdate$: Observable<boolean>;
    onCreateSuccess$: Observable<boolean>;
    onUpdateGroupSuccess$: Observable<boolean>;
    onCreateGroupSuccess$: Observable<boolean>;
    onCreateMemberSuccess$: Observable<boolean>;
    onEditOverlaySuccess: Observable<boolean>;
    editOverlaysComponentInit$: Observable<boolean>;
    createExternaloverlayComponentInit$: Observable<boolean>;
    private readonly _nameUpdate: Subject<boolean>;
    private readonly _onCreateSuccess: Subject<boolean>;
    private readonly _onUpdateGroupSuccess: Subject<boolean>;
    private readonly _onCreateGroupSuccess: Subject<boolean>;
    private readonly _onCreateMemberSuccess: Subject<boolean>;
    private readonly _onEditOverlaySuccess: Subject<boolean>;
    private readonly _editOverlayAccountComponentInit: Subject<boolean>;
    private readonly _createExternaloverlayInit: Subject<boolean>;


    constructor() {
        this._nameUpdate = new Subject<boolean>();
        this.nameUpdate$ = this._nameUpdate.asObservable();

        this._onCreateSuccess = new Subject<boolean>();
        this.onCreateSuccess$ = this._onCreateSuccess.asObservable();

        this._onCreateGroupSuccess = new Subject<boolean>();
        this.onCreateGroupSuccess$ = this._onCreateGroupSuccess.asObservable();

        this._onUpdateGroupSuccess = new Subject<boolean>();
        this.onUpdateGroupSuccess$ = this._onUpdateGroupSuccess.asObservable();

        this._onCreateMemberSuccess = new Subject<boolean>();
        this.onCreateMemberSuccess$ = this._onCreateMemberSuccess.asObservable();

        this._onEditOverlaySuccess = new Subject<boolean>();
        this.onEditOverlaySuccess = this._onEditOverlaySuccess.asObservable();

        this._editOverlayAccountComponentInit = new Subject<boolean>();
        this.editOverlaysComponentInit$ = this._editOverlayAccountComponentInit.asObservable();

        this._createExternaloverlayInit = new Subject<boolean>();
        this.createExternaloverlayComponentInit$ = this._createExternaloverlayInit.asObservable();

    }

    emitNameUpdate(updateSuccessfully: boolean): void {
        this._nameUpdate.next(updateSuccessfully);
    }

    emitOnCreateSuccess(): void {
        this._onCreateSuccess.next(true);
    }

    emitOnUpdateGroupSuccess(): void {
        this._onUpdateGroupSuccess.next(true);
    }

    emitOnCreateGroupSuccess(): void {
        this._onCreateGroupSuccess.next(true);
    }

    emitOnEditOverlaySuccess(): void {
        this._onEditOverlaySuccess.next(true);
    }

    emitOnCreateMemberSuccess(): void {
        this._onCreateMemberSuccess.next(true);
    }

    emitOnInitEditOverlay(): void {
        this._editOverlayAccountComponentInit.next(true);
    }

    emitOnExternalOverlayCreate(): void{
        this._createExternaloverlayInit.next(true);
    }
}
