import {Component, OnInit} from '@angular/core';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {GeographicalRegion} from '../model/geographical-region/geographical-region';
import {GeographicalRegionsService} from './geographical-regions.service';
import {DialogUpdateRegionComponent} from './dialog-update-region/dialog-update-region.component';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {DialogModel} from '../model/dialog/dialog-model';
import {debounceTime, take} from 'rxjs/operators';
import {NotifService} from '../core/notification/notif.service';
import { BehaviorSubject } from 'rxjs';


const LIMIT = 20;
const DEFAULT_SKIP = 0;

@Component({
    selector: 'map-geographical-regions',
    templateUrl: './geographical-regions.component.html',
    styleUrls: ['./geographical-regions.component.scss']
})
export class GeographicalRegionsComponent implements OnInit {
    
    private filterRegionsSubject: BehaviorSubject<string> = new BehaviorSubject('');

    isTableDataLoading: boolean;
    regionsDataSource: MatTableDataSource<GeographicalRegion>;
    currentDisplayedColumns: string[] = ['name', 'points-count', 'actions'];
    limit: number;
    skip: number;
    filteredRegions: string;
    numberOfRegions: number;

    constructor(private readonly geoRegionsService: GeographicalRegionsService, public dialog: MatDialog, private readonly notifService: NotifService) {
        this.limit = LIMIT;
        this.skip = DEFAULT_SKIP;
        this.filteredRegions = '';
        this.isTableDataLoading = true;
    }

    ngOnInit() {
        this.geoRegionsService.findRegions(this.filteredRegions, null, null).subscribe(
            (listOfRegions) => {
                this.numberOfRegions = listOfRegions.length;
            }
        );

        this.filterRegionsSubject.pipe(debounceTime(200)).subscribe(searchTextValue => {
            this.handleSearch(searchTextValue);
        });
    }

    onPaginationChange(paginationChangeEvent: { numberOfItemsPerPage: number, currentPage: number }) {
        this.skip = (paginationChangeEvent.currentPage) * this.limit;
        this.geoRegionsService.findRegions(this.filteredRegions, this.skip, this.limit).subscribe(
            (response) => {
                this.regionsDataSource = new MatTableDataSource(response);
                this.isTableDataLoading = false;
            }
        );
    }

    applyFilter(searchTextValue: string): void {
        this.filterRegionsSubject.next(searchTextValue);
    }
    

    handleSearch(filterValue: string): void {
        this.filteredRegions = filterValue;
        this.geoRegionsService.findRegions(this.filteredRegions, this.skip, this.limit).subscribe((response) => {
            this.regionsDataSource = new MatTableDataSource(response);
        });
        // console.log(filterValue);
        this.geoRegionsService.getRegionsCount(filterValue).subscribe((response) => {
            this.numberOfRegions = response;
        });
    }

    openDialogUpdateRegion(region: GeographicalRegion): void {
        const matDialogRef = this.dialog.open(DialogUpdateRegionComponent, {
            data: region
        });
        matDialogRef.componentInstance.onUpdateSuccess.subscribe((updatedRegion) => this.onUpdatedRegion());
    }

    fetchRegions(): void {
        this.geoRegionsService.findRegions(this.filteredRegions, this.skip, this.limit).subscribe((response) => {
            this.regionsDataSource = new MatTableDataSource(response);
        });
        this.geoRegionsService.getRegionsCount(this.filteredRegions).subscribe((response) => {
            this.numberOfRegions = response;
        });
    }

    onUpdatedRegion() {
        this.fetchRegions();
        this.dialog.closeAll();
    }

    deleteRegion(region: GeographicalRegion): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete "${region.name}" region?`
            )
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.geoRegionsService.deleteRegion(region.id).subscribe((res) => {
                    this.notifService.success('Region Deleted');
                    this.dialog.closeAll();
                    this.fetchRegions();
                }, (err) => {
                    this.notifService.error(err.error.message);
                });
            }
        });
    }

    deleteAllList(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete ${this.numberOfRegions} region(s)?`
            )
        });
        // console.log(this.filteredRegions);
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.geoRegionsService.deleteRegionsByName(this.filteredRegions).subscribe((response) => {
                    this.notifService.success('List Deleted');
                    this.dialog.closeAll();
                    this.fetchRegions();
                }, (err) => {
                    this.notifService.error(err.error.message);
                });
            }
        });
    }

    uploadFiles(event) {
        const files = event.target.files;
        if (files.length > 2) {
            this.notifService.error('You can add maximum 2 files');
        } else if (files.length < 2) {
            this.notifService.error('You need to add a .shp file and a .dbf file');
        } else {
            this.isTableDataLoading = true;
            this.geoRegionsService.uploadZonesFile(files).subscribe((response) => {
                this.isTableDataLoading = false;
                this.fetchRegions();
                this.notifService.success('Regions added successfully');
            }, error => {
                this.isTableDataLoading = false;
                this.notifService.error(error.error.message);
            });
        }
    }

}
