export class NRIUtils {

    // we need this in order to be able to sort the risks. smaller value, the bigger the risk is
    private static readonly numericalRiskValues = {
        'Very High': 1,
        'Relatively High': 2,
        'Relatively Moderate': 3,
        'Relatively Low': 4,
        'Very Low': 5,
        'No Rating': 6,
        'Not Applicable': 7,
        'Insufficient Data': 8
    };

    // here we have the colors for each risk index defined above
    public static riskValueColorsByNumbers = {
        1: '#f50000',
        2: '#ff9426',
        3: '#ffdd7b'
    };

    public static getNumericRiskValue(textValue: string) {
        return this.numericalRiskValues[textValue];
    }

}
