import {Component, OnInit, Inject, HostBinding, ViewChild, TemplateRef, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Dataset} from 'src/app/model/dataset/dataset';
import {DatasetField} from 'src/app/model/dataset/field/dataset-field';
import {DraftService} from 'src/app/data-access-layer/draft/draft.service';
import {NotifService} from '../../../core/notification/notif.service';
import {MatDialog, MatStepper} from '@angular/material';
import {group} from '@angular/animations';

@Component({
    selector: 'map-approve-draft',
    templateUrl: './approve-draft.component.html',
    styleUrls: ['./approve-draft.component.scss']
})
export class ApproveDraftComponent implements OnInit, OnChanges {
    @ViewChild('approveModal', {static: true}) approveModalTemplate: TemplateRef<any>;
    currentStepIndex = 0;
    fieldsFormGroup: FormGroup;
    fields: DatasetField[];
    selectedGroupID: number;
    @Input() dialogData: ApproveDialogDraftData;
    disableSubmit = false;
    @Output() approveCompleted = new EventEmitter();
    isLoading = false;

    constructor(
        private readonly draftService: DraftService,
        private readonly formBuilder: FormBuilder,
        private readonly notifService: NotifService,
        private readonly  dialog: MatDialog,
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.dialogData) {
            this.initForm();
        }
    }

    onNoClick(): void {
        this.closeModal();
    }

    initForm() {
        this.fields = this.dialogData.dataset.fields.filter(field => !field.isGenerated);
        this.fieldsFormGroup = this.formBuilder.group({});
        this.fields.forEach(field => {
            this.fieldsFormGroup.addControl(field.id, new FormControl(true, Validators.required));
        });
    }

    submitDraftsForApproval() {
        if (this.dialogData.selectAll) {
            this.approveDraftsByAfilter();
            return;
        } else {
            this.approveSelectedDrafts();
        }
    }

    approveDraftsByAfilter() {
        this.isLoading = true;
        this.disableSubmit = true;
        let filter = this.dialogData.filter.filter;
        let approvedFields = this.getSelectedFieldIdsAsArray(this.fieldsFormGroup.value);
        this.draftService.approveDraftsByFilter(this.dialogData.dataset.id, filter, this.selectedGroupID, approvedFields).subscribe((res) => {
            this.notifService.success(`Successfully approved ${res} drafts`);
            this.approveCompleted.emit('approved');
            this.isLoading = false;
            this.closeModal();
        }, err => {
            this.isLoading = false;
            this.approveCompleted.emit('not approved');
            this.closeModal();
        });
    }

    approveSelectedDrafts() {
        this.isLoading = true;
        this.disableSubmit = true;
        this.draftService.approveDrafts(this.dialogData.dataset.id, this.dialogData.drafts, this.selectedGroupID, this.getSelectedFieldIdsAsArray(this.fieldsFormGroup.value)).subscribe((res) => {
                this.notifService.success(`Successfully approved ${res} drafts`);
                this.approveCompleted.emit('approved');
                this.isLoading = false;
                this.closeModal();
            },
            err => {
                this.isLoading = false;
                this.approveCompleted.emit('not approved');
                this.closeModal();
            });
    }

    getSelectedFieldIdsAsArray(fieldsFormGroupValue: any): string[] {
        let selectedFieldIdsAsArray: string[] = [];
        for (let fieldID in fieldsFormGroupValue) {
            if (Object.prototype.hasOwnProperty.call(fieldsFormGroupValue, fieldID)) {
                if (fieldsFormGroupValue[fieldID] === true) {
                    selectedFieldIdsAsArray.push(fieldID);
                }
            }
        }
        return selectedFieldIdsAsArray;
    }

    selectGroup(groupID: number) {
        this.selectedGroupID = groupID;
    }

    openComponent(): void {
        this.dialog.open(this.approveModalTemplate, {disableClose: true});
    }

    closeModal(): void {
        this.dialog.closeAll();
    }
}

export interface ApproveDialogDraftData {
    accountID?: number;
    dataset: Dataset;
    drafts: number[];
    selectAll: boolean;
    filter: any;
}

export interface ApproveDraftsRequest {
    fields: string[];
    datasetID: string;
    target: number;
    draftIDs: number[];
}
