export class ColorUtils {

    private static readonly GREEN = '#47d147';
    private static readonly YELLOW = '#ffff00';
    private static readonly ORANGE = '#ff9900';
    private static readonly RED = '#ff0000';

    value: number;
    
    constructor(rgb: number) {
        this.value = rgb;
    }

    static colorToBase64(color: number) {
        return '#' + ColorUtils.zeroPad(color.toString(16));
    }

    static colorFromBase64(color: string) {
        return parseInt(color.substr(1), 16);
    }

    static defaultColorScale(): string[] {
        return [ColorUtils.GREEN, ColorUtils.YELLOW, ColorUtils.ORANGE, ColorUtils.RED];
    }

    static generateRandomHexColor(): string {
        return `#${Math.floor(Math.random()*16777215).toString(16)}`;
    }

    private static zeroPad(num) {
        let zero = 6 - num.toString().length + 1; // 6 digits
        return `${Array(+(zero > 0 && zero)).join('0')}${num}`;
    }
    

    public getColorComponents(compArray: number[]): number[] {
        return this.getRGBColorComponents(compArray);
    }

    public getRGBColorComponents(compArray: number[]): number[] {
        let f: number[] = compArray;
        f[0] = Number(this.getRed() /255);
        f[1] = Number(this.getGreen() /255);
        f[2] = Number(this.getBlue() /255);
        return f;
    }

    public getRed(): number {
        return (this.value >> 16) & 255;
    }

    public getGreen(): number {
        return (this.value >> 8) & 255;
    }

    public getBlue(): number {
        return (this.value >> 0) & 255;
    }

    public Color(r: number, g: number, b: number) {
        this.ColorParent( r*255+0.5, g*255+0.5, b*255+0.5, 255);
        this.testColorValueRange(r,g,b,1.0);
        let frgbvalue = new Array(3);
        frgbvalue[0] = r;
        frgbvalue[1] = g;
        frgbvalue[2] = b;
       return this.value;
    }

    public ColorParent(r: number, g: number, b: number, a: number) {
        this.value = ((a & 255) << 24) |
                ((r & 255) << 16) |
                ((g & 255) << 8)  |
                ((b & 255) << 0);
        this.testColorValueRange(r,g,b,255);
    }

    private testColorValueRange(r: number, g: number, b: number, a: any) {
        let rangeError: boolean = false;
        let badComponentString: String = "";

        if ( a < 0 || a > 255) {
            rangeError = true;
            badComponentString = badComponentString + " Alpha";
        }
        if ( r < 0 || r > 255) {
            rangeError = true;
            badComponentString = badComponentString + " Red";
        }
        if ( g < 0 || g > 255) {
            rangeError = true;
            badComponentString = badComponentString + " Green";
        }
        if ( b < 0 || b > 255) {
            rangeError = true;
            badComponentString = badComponentString + " Blue";
        }
        if ( rangeError == true ) {
        console.log("Color parameter outside of expected range:"
        + badComponentString);
        }
    }

    static decimalToHexString(number: number) {
        if (number < 0) {
            number = 0x00FFFFFF + number + 1;
        }
        return number.toString(16).toUpperCase();
    }
}
