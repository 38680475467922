import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Point} from '../../model/geometry/point';

declare var google: any;

@Injectable({providedIn: 'root'})
export class GoogleGeocodingService {
    private geoCoder: google.maps.Geocoder;

    reverseGeocoding(point: Point): Observable<string> {
        if (!this.geoCoder) {
            this.geoCoder = new google.maps.Geocoder();
        }

        const location = {
            lat: point.y,
            lng: point.x
        };

        return new Observable(subscriber => {
            this.geoCoder.geocode({location}, (results: google.maps.GeocoderResult[]) => {
                if (results && results[0]) {
                    subscriber.next(results[0].formatted_address);
                    subscriber.complete();
                }
            });
        });
    }
}
