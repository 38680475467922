export enum MapStateActionEnum {
    NONE = 'NONE',
    LOAD = 'LOAD',
    REDRAW_ALL = 'REDRAW_ALL', // redraw everything
    REDRAW = 'REDRAW',
    SHAPES = 'SHAPES',
    UPDATE_OVERLAY = 'UPDATE_OVERLAY', // update an existing overlay
    INSERT_OVERLAY = 'INSERT_OVERLAY', // insert a new overlay
    REMOVE_OVERLAY = 'REMOVE_OVERLAY', // remove an overlay
    CLEAR_SHAPES = 'CLEAR_SHAPES', // clear all shapes
}
