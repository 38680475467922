import {DatapointConverterType, Grouping} from '../../model/dataset/rendering/datapoint-converter-options';
import {ColorScaleType} from '../../model/dataset/rendering/color-scale-type';
import {VisualizationType} from '../../model/dataset/rendering/visualization-options';
import {RenderingOptions} from '../../model/dataset/rendering/rendering-options';
import {ColorUtils} from '../utils/color-utils';

export const thematicMapOverlayRenderingOptions: RenderingOptions = {
    converterOptions: {
        datasetID: '',
        accountApplicationDatasetId: '',
        accountApplicationFieldId: '5',
        fieldID: '',
        grouping: Grouping.SUM,
        type: DatapointConverterType.NONE,
    },
    datasetStylingOptions: {
        colorizationFieldIndex: 1,
        backgroundOpacity: 0.8,
        colors: [],
        defaultColor: 15559202,
        markerId: null,
        type: ColorScaleType.INTERVAL,
        intervalOptions: [
            {color: 1, minValue: 0, maxValue: 1000000, total: 15000000},
            {color: 1, minValue: 1000000, maxValue: 2000000, total: 15000000},
            {color: 1, minValue: 2000000, maxValue: 15000000, total: 15000000},
            {color: 1, minValue: 15000000, maxValue: null, total: 15000000}
        ]
    },
    visualizationOptions: {
        type: VisualizationType.THEMATIC_MAP
    }
};

const GREEN = ColorUtils.colorFromBase64('#00d000');
const YELLOW = ColorUtils.colorFromBase64('#ffdd55');
const ORANGE = ColorUtils.colorFromBase64('#ff6600');
const RED = ColorUtils.colorFromBase64('#d00000');

export const thematicMapColors = [GREEN, YELLOW, ORANGE, RED];
export const NR_THEMATIC_MAP_LEVELS = 4;

const percentageLimitForGreen = 5;
const percentageLimitForYellow = 15;
const percentageLimitForOrange = 50;

export const percentageForColorIntervals = [percentageLimitForGreen, percentageLimitForYellow, percentageLimitForOrange];

const maxPercentageLimitForGreen = 97;
const maxPercentageLimitForYellow = 98;
const maxPercentageLimitForOrange = 99;

export const maxPercentageIntervals = [maxPercentageLimitForGreen, maxPercentageLimitForYellow, maxPercentageLimitForOrange];


// export const thematicMapIntervals = {
//     4: [ // countries
//         {color: GREEN, minValue: 0, maxValue: 10_000_000},
//         {color: YELLOW, minValue: 10_000_000, maxValue: 500_000_000},
//         {color: ORANGE, minValue: 500_000_000, maxValue: 3_000_000_000},
//         {color: RED, minValue: 3_000_000_000, maxValue: null}
//     ],
//     3: [ // states
//         {color: GREEN, minValue: 0, maxValue: 200_000_000},
//         {color: YELLOW, minValue: 200_000_000, maxValue: 500_000_000},
//         {color: ORANGE, minValue: 500_000_000, maxValue: 1_000_000_000},
//         {color: RED, minValue: 1_000_000_000, maxValue: null}
//     ],
//     2: [ // counties
//         {color: GREEN, minValue: 0, maxValue: 50_000_000},
//         {color: YELLOW, minValue: 50_000_000, maxValue: 100_000_000},
//         {color: ORANGE, minValue: 100_000_000, maxValue: 150_000_000},
//         {color: RED, minValue: 150_000_000, maxValue: null}
//     ],
//     1: [ // zipcodes
//         {color: GREEN, minValue: 0, maxValue: 1000},
//         {color: YELLOW, minValue: 1000, maxValue: 50_000_000},
//         {color: ORANGE, minValue: 50_000_000, maxValue: 100_000_000},
//         {color: RED, minValue: 100_000_000, maxValue: null}
//     ],
// };

// export const thematicMapIntervals = [
//     [ // countries
//         {color: GREEN, minValue: 0, maxValue: 10_000_000},
//         {color: YELLOW, minValue: 10_000_000, maxValue: 500_000_000},
//         {color: ORANGE, minValue: 500_000_000, maxValue: 3_000_000_000},
//         {color: RED, minValue: 3_000_000_000, maxValue: null}
//     ],
//     [ // states
//         {color: GREEN, minValue: 0, maxValue: 200_000_000},
//         {color: YELLOW, minValue: 200_000_000, maxValue: 500_000_000},
//         {color: ORANGE, minValue: 500_000_000, maxValue: 1_000_000_000},
//         {color: RED, minValue: 1_000_000_000, maxValue: null}
//     ],
//     [ // counties
//         {color: GREEN, minValue: 0, maxValue: 50_000_000},
//         {color: YELLOW, minValue: 50_000_000, maxValue: 100_000_000},
//         {color: ORANGE, minValue: 100_000_000, maxValue: 150_000_000},
//         {color: RED, minValue: 150_000_000, maxValue: null}
//     ],
//     [ // zipcodes
//         {color: GREEN, minValue: 0, maxValue: 10_000_000},
//         {color: YELLOW, minValue: 10_000_000, maxValue: 50_000_000},
//         {color: ORANGE, minValue: 50_000_000, maxValue: 100_000_000},
//         {color: RED, minValue: 100_000_000, maxValue: null}
//     ]];
