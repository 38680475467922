import {Validators} from '@angular/forms';
import {MaptycsApplication} from '../../model/account/maptycs-application';
import {DatasetType} from '../../model/dataset/dataset-type';
import {DatasetFieldSpecificType} from '../../model/dataset/dataset-field-specific.type';
import {DirectedLinkDatasetId} from '../../model/dataset/link/directed-link-dataset-id';
import {CreateDatasetRequest} from '../../model/dataset/create-dataset-request';
import {DatasetGeometryType} from '../../model/dataset/dataset-geometry-type';
import {ColorScaleType} from '../../model/dataset/rendering/color-scale-type';
import {LinkDatasetRelationship} from '../../model/dataset/link/link-dataset-relationship';

export const createAccountControlsConfig = {
    name: ['', Validators.required],
    locations: [true, Validators.required],
    policies: [true, Validators.required],
    claims: [true, Validators.required]
};


// used in account panel component to determine selected links between datasets
export const enum DatasetBitValues {
    LOCATIONS = 1,
    POLICIES = 2,
    CLAIMS = 4
}

export const createAccountLocationsDataset: CreateDatasetRequest = {
    application: MaptycsApplication.LOCATIONS,
    name: 'Locations',
    type: DatasetType.ACCOUNT_APPLICATION,
    geometryType: DatasetGeometryType.POINT,
    isGeocoded: true,
    isLinkable: true,
    hasHazardHubEnabled: false,
    mainSummaryFieldIndex: 0,
    stylingOptions: {
        type: ColorScaleType.CONSTANT,
        colorizationFieldIndex: 0
    },
    fields: [
        {
            isRequired: true,
            isSummary: true,
            isLocked: true,
            tags: [
                'COUNTRY'
            ],
            order: 1,
            name: 'Country',
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD
        },
        {
            isRequired: true,
            order: 2,
            name: 'Zip Code',
            isHighCardinality: true,
            tags: [
                'ZIPCODE'
            ],
            isLocked: true,
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD,
            isSummary: true
        },
        {
            isRequired: true,
            order: 3,
            name: 'City',
            isHighCardinality: true,
            tags: [
                'CITY'
            ],
            isLocked: true,
            isSummary: true,
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD
        },
        {
            isRequired: true,
            order: 4,
            name: 'Street',
            isHighCardinality: true,
            tags: [
                'STREET'
            ],
            isLocked: true,
            isSummary: true,
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD
        },
        {
            isRequired: false,
            order: 5,
            isHighCardinality: true,
            name: 'Street Extra',
            isLocked: true,
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD
        }
    ]
};

export const createAccountPoliciesDataset: CreateDatasetRequest = {
    application: MaptycsApplication.POLICIES,
    name: 'Policies',
    type: DatasetType.ACCOUNT_APPLICATION,
    geometryType: DatasetGeometryType.NONE,
    isGeocoded: false,
    isLinkable: true,
    hasHazardHubEnabled: false,
    fields: [],
    mainSummaryFieldIndex: -1
};

export const datasetConfig: { key: string, text: string, bitValue: number }[] = [
    {key: 'locations', text: 'locations', bitValue: DatasetBitValues.LOCATIONS},
    {key: 'policies', text: 'policies', bitValue: DatasetBitValues.POLICIES},
    {key: 'claims', text: 'claims', bitValue: DatasetBitValues.CLAIMS},
];


export const createAccountClaimsDataset: CreateDatasetRequest = {
    application: MaptycsApplication.CLAIMS,
    name: 'Claims',
    type: DatasetType.ACCOUNT_APPLICATION,
    geometryType: DatasetGeometryType.NONE,
    isGeocoded: false,
    isLinkable: true,
    hasHazardHubEnabled: false,
    mainSummaryFieldIndex: 0,
    fields: [
        {
            isSummary: true,
            order: 1,
            name: 'Claimant',
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD
        },
        {
            order: 2,
            name: 'Claim Status',
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD,
            isSummary: true
        },
        {
            order: 3,
            name: 'Coverage',
            isSummary: true,
            type: DatasetFieldSpecificType.SHORT_TEXT_FIELD
        },
        {
            order: 4,
            name: 'Date',
            isSummary: true,
            type: DatasetFieldSpecificType.DATE_FIELD
        },
        {
            order: 5,
            name: 'Paid',
            type: DatasetFieldSpecificType.NUMBER_FIELD
        },
        {
            order: 6,
            name: 'Incurred',
            type: DatasetFieldSpecificType.NUMBER_FIELD
        },
        {
            order: 7,
            name: 'Outstanding',
            isSummary: true,
            type: DatasetFieldSpecificType.NUMBER_FIELD
        }
    ]
};

export const createAccountLinkLocationPolicies: DirectedLinkDatasetId = {
    bitValue: DatasetBitValues.LOCATIONS + DatasetBitValues.POLICIES,
    headApplication: 'LOCATIONS',
    tailApplication: 'POLICIES',
    relationship: LinkDatasetRelationship.BIDIRECTIONAL,
    fields: []
};
export const createAccountLinkLocationClaims: DirectedLinkDatasetId = {
    bitValue: DatasetBitValues.LOCATIONS + DatasetBitValues.CLAIMS,
    headApplication: 'LOCATIONS',
    tailApplication: 'CLAIMS',
    relationship: LinkDatasetRelationship.BIDIRECTIONAL,
    fields: []
};
export const createAccountLinkPoliciesClaims: DirectedLinkDatasetId = {
    bitValue: DatasetBitValues.POLICIES + DatasetBitValues.CLAIMS,
    headApplication: 'POLICIES',
    tailApplication: 'CLAIMS',
    relationship: LinkDatasetRelationship.BIDIRECTIONAL,
    fields: []
};
export const DirectedLinksArray: DirectedLinkDatasetId[] = [createAccountLinkLocationPolicies, createAccountLinkLocationClaims, createAccountLinkPoliciesClaims];
export const DatasetsArray: CreateDatasetRequest[] = [createAccountPoliciesDataset, createAccountClaimsDataset, createAccountLocationsDataset];
