import { AerisData } from "src/app/dataset/datapoints/datapoints-location-profile/location-profile/location-profile.component";
import { isObject } from "util";

export class Aeris {

    static computeAerisData(input: any, text: string, depth: number): AerisData[] {
        let array: AerisData[] = [];

        if (!input) {
        } else if (Array.isArray(input)) {
            // treat array
            array.push({ depth: depth, text: text, value: '' })
            for (const item of input) {
                array.push(...this.computeAerisData(item, '', depth + 1));
            }
        } else if (isObject(input)) {
            // treat object
            array.push({ depth: depth, text: text, value: '' })
            Object.keys(input).forEach((key: string) => {
                array.push(...this.computeAerisData(input[key], key, depth + 1));
            });
        } else {
            array.push({ depth: depth, text: text, value: input });
        }
        return array;
    }
}