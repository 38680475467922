import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roundUpNumber',
})
export class RoundUpNumberPipe implements PipeTransform {

  transform(value: any): any {
      if (!value || isNaN(value)) {
          return value;
      }
      return Math.round(value);
  }
}
