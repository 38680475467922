export class LocationProfile {
    readonly INTERNAL = 'INTERNAL';
    readonly EXTERNAL = 'EXTERNAL';
    readonly CLIMATE = 'CLIMATE';
    readonly APG = 'APG';
    readonly NRI = 'NRI';
    readonly POI = 'POI';
    readonly AIR_QUALITY = 'AIR_QUALITY';
    readonly CONDITIONS = 'CONDITIONS';
    readonly FORECASTS = 'FORECASTS';
    readonly TROPICALCYCLONES = 'TROPICALCYCLONES';
    readonly ALERTS = 'ALERTS';
    readonly CONVECTIVE_AND_OUTLOOK = 'CONVECTIVE_AND_OUTLOOK';
    readonly DROUGHTS_AND_MONITOR = 'DROUGHTS_AND_MONITOR';
    readonly FIRES = 'FIRES';
    readonly FIRES_AND_OUTLOOK = 'FIRES_AND_OUTLOOK';
    readonly PLACES = 'PLACES';
    readonly RIVERS = 'RIVERS';
    readonly STROMCELLS = 'STROMCELLS';
    readonly THREATS = 'THREATS';
}

export enum dropdownMenuList {
    INTERNAL = 'Internal',
    EXTERNAL= 'External',
    CLIMATE = 'Climate',
    APG = 'APG',
    NRI = 'NRI',
    POI = 'POI',
    AIR_QUALITY = 'Air Quality',
    CONDITIONS = 'Conditions',
    FORECASTS = 'Forecasts',
    TROPICALCYCLONES = 'Tropicalcyclones',
    ALERTS = 'Alerts',
    CONVECTIVE_AND_OUTLOOK = 'Convective/Outlook',
    DROUGHTS_AND_MONITOR = 'Droughts/Monitor',
    FIRES = 'Fires',
    FIRES_AND_OUTLOOK = 'Fires/Outlook',
    PLACES = 'Places',
    RIVERS = 'Rivers',
    STROMCELLS = 'Stromcells',
    THREATS = 'Threats'
}

export enum addressProfileDropdownMenuList {
    EXTERNAL= 'External',
    NRI = 'NRI',
    POI = 'POI',
    APG = 'APG',
    CLIMATE = 'Climate',
    AIR_QUALITY = 'Air Quality',
    CONDITIONS = 'Conditions',
    FORECASTS = 'Forecasts',
    TROPICALCYCLONES = 'Tropicalcyclones',
    ALERTS = 'Alerts',
    CONVECTIVE_AND_OUTLOOK = 'Convective/Outlook',
    DROUGHTS_AND_MONITOR = 'Droughts/Monitor',
    FIRES = 'Fires',
    FIRES_AND_OUTLOOK = 'Fires/Outlook',
    PLACES = 'Places',
    RIVERS = 'Rivers',
    STROMCELLS = 'Stromcells',
    THREATS = 'Threats'
}