import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {fieldControlsConfig} from './field.constants';
import {DatasetField} from '../../model/dataset/field/dataset-field';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DatasetFieldSpecificType} from '../../model/dataset/dataset-field-specific.type';
import {DatasetFieldFloatingOption} from '../../model/dataset/dataset-field-floating-option';
import {DatasetFieldType} from '../../model/dataset/dataset-field-type';
import {DatasetUtils} from '../../core/utils/dataset-utils';

@Component({
    selector: 'map-field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldComponent implements OnInit {
    private subscription: Subscription;

    fieldForm: FormGroup;
    expanded: boolean;
    localField: DatasetField;

    @Output() delete = new EventEmitter<DatasetField>();
    @Input() allowFloatingFields: boolean;

    defaultFloatingOption = DatasetFieldFloatingOption.SUM;

    @Input('field')
    set field(newField: DatasetField) {
        this.localField = newField;
        this.updateField(newField);
    }

    get field(): DatasetField {
        return this.localField;
    }

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly changeDetector: ChangeDetectorRef
    ) {
        this.fieldForm = formBuilder.group(fieldControlsConfig);
        this.expanded = true;
        this.subscription = new Subscription();
    }

    ngOnInit(): void {
        this.subscription = this.fieldForm.valueChanges
            .pipe(
                debounceTime(300)
            )
            .subscribe((val: DatasetField) => {
                this.localField.name = val.name;
                this.localField.isSummary = val.isSummary;
                this.localField.isRequired = val.isRequired;
                this.localField.isArray = this.fieldForm.controls.isArray.disabled ? this.fieldForm.controls.isArray.value : val.isArray;
                this.localField.isHighCardinality = val.isHighCardinality;
                this.localField.isFloating = val.isFloating;
                this.localField.floatingOption = val.isFloating ? val.floatingOption : null;
                this.localField.type = this.fieldForm.controls.type.disabled ? this.fieldForm.controls.type.value : val.type;

                this.changeDetector.detectChanges();
            });
    }

    updateField(newField: DatasetField): void {
        this.fieldForm.controls.name.setValue(newField.name);
        // this.fieldForm.controls.description.setValue(newField.description);
        this.fieldForm.controls.isSummary.setValue(newField.isSummary);
        this.fieldForm.controls.isRequired.setValue(newField.isRequired);
        this.fieldForm.controls.isArray.setValue(newField.isArray);
        this.fieldForm.controls.isHighCardinality.setValue(newField.isHighCardinality);
        this.fieldForm.controls.isFloating.setValue(newField.isFloating);
        this.fieldForm.controls.floatingOption.setValue(newField.floatingOption ? newField.floatingOption : DatasetFieldFloatingOption.SUM);
        this.fieldForm.controls.type.setValue(newField.type);

        if (this.localField.id) {
            this.fieldForm.controls.isArray.disable();
            this.fieldForm.controls.type.disable();
        } else {
            this.fieldForm.controls.isArray.enable();
            this.fieldForm.controls.type.enable();
        }

        this.changeDetector.detectChanges();
    }

    deleteField(field: DatasetField) {
        this.delete.emit(field);
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get DatasetFieldFloatingOption() {
        return DatasetFieldFloatingOption;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    getDatasetFieldType(field: DatasetField) {
        return DatasetUtils.getDatasetFieldType(field);
    }

}
