import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifService} from '../../../core/notification/notif.service';
import {AuthService} from '../../auth.service';
import {Constants} from '../../../constants';


@Component({
    selector: 'app-forgot-password-confirm',
    templateUrl: './forgot-password-confirm.component.html'
})
export class ForgotPasswordConfirmationComponent implements OnInit {

    tokenIsInvalid = false;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private notifService: NotifService) {

    }

    ngOnInit(): void {

        let token = this.activatedRoute.snapshot.paramMap.get('token');
        if (token) {
            this.authService.confirmForgotPassword(token)
                .subscribe(
                    success => {
                        this.notifService.success('Your new password has been activated');
                        this.router.navigate(['/public/login']);
                    }, error => {
                        if (error.status === Constants.BUSINESS_ERROR_CODE) {
                            this.tokenIsInvalid = true;
                        }
                    }
                );
        }
    }


}
