import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'textFilter',
    pure: false
})
export class TextFilterPipe implements PipeTransform {
    transform(items: any[], searchValue: string, searchField: string): any {
        if (!searchValue || !searchField) {
            return items;
        }
        return items.filter(item => {
            let field = item[searchField].toLowerCase();
            if (!field) return false;

            return field.indexOf(searchValue.toLowerCase()) >= 0;
        });
    }
}
