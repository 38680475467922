import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from "@angular/core";
import { EventEmitter } from 'events';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MapStateService } from "src/app/map/map-state.service";
import { Crisis24Alert } from "src/app/model/datapoint/crisis24-alert";
import { SidePanelComponent } from '../../core/side-panel/side-panel.component';
import { ISidePanel, SidePanels } from './side-panel.helper';


@Injectable({ providedIn: 'root' })
export class SidePanelService {
    sidePanels$: Observable<ComponentRef<SidePanelComponent>[]>;
    onHide$: Observable<string>;
    onDropDownValueChanged$: Observable<string>;
    private readonly _sidePanels: BehaviorSubject<ComponentRef<SidePanelComponent>[]>;
    private rootViewContainer: ViewContainerRef;
    private _onHide: Subject<string>;
    private _onDropDownValueChanged: Subject<string> = new Subject<string>();

    constructor(private factoryResolver: ComponentFactoryResolver, 
        private readonly mapStateService: MapStateService) {
        this._sidePanels = new BehaviorSubject<ComponentRef<SidePanelComponent>[]>([]);
        this.sidePanels$ = this._sidePanels.asObservable();

        this._onHide = new Subject();
        this.onHide$ = this._onHide.asObservable();
        this.onDropDownValueChanged$ = this._onDropDownValueChanged.asObservable();
    }

    setRootViewContainerRef(viewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }

    /**
     * This method will open a side panel dynamically injecting a custom component referred through the componentToLoad param
     * @param componentToLoad custom component to load in the side panel
    * @param componentConfigs inputs and outputs of the custom component
     * @param param2 config object for the side panel
     * @returns
     */
    open<T>(
        componentToLoad: SidePanels,
        { size, width, panelTitle, panelIcon, backdrop, resizeable, id }: ISidePanel,
        componentConfigs?: T) {
        const factory =
            this.factoryResolver.resolveComponentFactory(SidePanelComponent);
        const component = factory.create(this.rootViewContainer.parentInjector);

        component.instance.id = id;
        component.instance.size = size;
        component.instance.width = width;
        component.instance.panelTitle = panelTitle;
        component.instance.panelIcon = panelIcon;
        component.instance.backdrop = backdrop;
        component.instance.resizeable = resizeable;
        component.instance.component = componentToLoad;
        component.instance.componentConfigs = componentConfigs;

        this.rootViewContainer.insert(component.hostView);

        component.instance.closeModal$.subscribe(() => {
            this.deleteSidePanel(component);
        });

        component.instance.onPanelFirst$.subscribe((instance: SidePanelComponent) => {
            this.setPanelFirst(instance);
        });

        component.instance.hideModal$.subscribe((instance: SidePanelComponent) => {
            if (instance.id === Crisis24Alert.getPanelId()) {
                this.mapStateService.removeCrisisCircle(true);
            }
            this._onHide.next(instance.id);
        });

        component.instance.dropdownValueChanged$.subscribe((value: string) => {
            this._onDropDownValueChanged.next(value);
        });

        this.addSidePanel(component);

        return component;
    }

    /**
     * This method will bring the panel with the corresponding id in front of all the other panels by changing his zIndex
     * @param component
     */
    setPanelFirst(component: SidePanelComponent) {
        let sidePanelsValue = this._sidePanels.value;
        const indexOfPanel = sidePanelsValue.findIndex((panel: ComponentRef<SidePanelComponent>) => panel.instance.id === component.id);

        const { length } = this._sidePanels.value;
        sidePanelsValue[indexOfPanel].instance.zIndex = (length - indexOfPanel) * 100;

        this._sidePanels.next(sidePanelsValue);
    }

    /**
     * Helper method for shifting items in an array from an index to another
     * @param array
     * @param oldIndex
     * @param newIndex
     * @returns
     */
    moveItem(array: ISidePanel[], oldIndex: number, newIndex: number): ISidePanel[] {
        if (newIndex >= array.length) {
            let k = newIndex - array.length + 1;
            while (k--) {
                array.push(undefined);
            }
        }
        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
        return array;
    }

    /**
     * Store the reference of the new opened side panel
     * @param component
     */
    addSidePanel(component: ComponentRef<SidePanelComponent>) {
        const sidePanelsValue = this._sidePanels.value;
        const existingPanel = sidePanelsValue.find((instance: any) => component.instance.id === instance.id);

        if (!existingPanel) {
            sidePanelsValue.push(component);
            this._sidePanels.next(sidePanelsValue);
            this.setPanelFirst(component.instance);
        }
    }

    /**
   * Delete the reference of the mentioned side panel
   * @param component
   */
    deleteSidePanel(component: ComponentRef<ISidePanel>) {
        const sidePanelsValue = this._sidePanels.value;
        const indexOfPanel = sidePanelsValue.findIndex((componentRef: any) => component.instance.id === componentRef.instance.id);
        sidePanelsValue.splice(indexOfPanel, 1);
        this._sidePanels.next(sidePanelsValue);
        component.destroy();
    }
}
